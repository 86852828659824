import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import HighlightOutlinedIcon from "@mui/icons-material/HighlightTwoTone";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const regionsOfInterestStatus = {
  "font-size": "13px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

const ImageROI = ({ switchAccordion, expanded, handleChange, activeRegions, addRemoveCheck, highlightRegion, addRemoveRegion, highlightColor }) => {
  const { theme } = useTheme();
  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const sectionTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#666" : "#cea5fb",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
  };
  const regionsOfInterestHeader = {
    color: theme === "light" ? "#666" : "#c6cdd5",
    "font-size": "15px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  return (
    <Accordion sx={accordionHeaderStyle} expanded={expanded === "roisHeader"} onChange={switchAccordion("roisHeader")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="roisHeader-content" id="roisHeader-header">
        <Typography sx={{ width: "50%", flexShrink: 0, marginRight: "15px" }}>Regions Of Interest</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Stack direction="row" divider={<Divider sx={{ backgroundColor: theme === "light" ? "primary" : "#c6cdd5" }} orientation="vertical" flexItem />} spacing={2}>
            <Typography sx={regionsOfInterestHeader}>Highlight</Typography>
            <Box>
              <Stack direction="row">
                <Typography sx={regionsOfInterestHeader}>All</Typography>
                <Checkbox sx={{ "&.Mui-checked": { color: "#399dbc" }, "margin-left": "5px", padding: "0px" }} onChange={handleChange("addRemoveAllCheckBox")} checked={addRemoveCheck} size="small" />
              </Stack>
            </Box>
            <Typography sx={regionsOfInterestHeader}>Status</Typography>
          </Stack>
        </Box>
        <Divider sx={{ backgroundColor: theme === "light" ? "primary" : "#c6cdd5" }} orientation="horizontal"></Divider>
        <Box sx={{ mt: 1, backgroundColor: "#f2f2f2", boxShadow: 2, borderRadius: 1 }}>
          <List dense={true}>
            {activeRegions !== null &&
              activeRegions.map((region) => (
                <ListItem sx={{ mb: 0, pb: 0, pt: 0 }}>
                  <ListItemIcon>
                    <IconButton
                      id={"highlight" + region.id}
                      aria-label="highlight"
                      onClick={() => highlightRegion(region)}
                      //sx={{'padding':'1px','margin-right':'50px'}}
                      sx={{ padding: "1px", ml: 1, mr: 5.5 }}
                    >
                      <HighlightOutlinedIcon id={"highlightIcon" + region.id} sx={{ color: highlightColor }} fontSize="small" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemIcon sx={{ mr: 0, "min-width": "30px" }}>
                    <IconButton id={"add" + region.id} aria-label="add" onClick={() => addRemoveRegion(region, "add")} sx={{ padding: "1px", mr: 0 }}>
                      <AddCircleOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemIcon sx={{ mr: 0, "min-width": "30px" }}>
                    <IconButton
                      id={"remove" + region.id}
                      aria-label="remove"
                      onClick={() => addRemoveRegion(region, "remove")}
                      //sx={{'padding':'1px','margin-right':'45px'}}
                      sx={{ padding: "1px", mr: 4 }}
                    >
                      <RemoveCircleOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText>
                    <Box sx={{ mr: 1 }}>
                      <Typography color={region.active ? "blue" : "#e95407"} sx={regionsOfInterestStatus}>
                        {region.active ? "active" : "inactive"}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ImageROI;
