import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {GithubPicker} from 'react-color';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import HelpCenterIcon from '@mui/icons-material/HelpCenter'; 
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';              
import FormControl from '@mui/material/FormControl'
import ListSubheader from '@mui/material/ListSubheader';
import './../../../style.scss';
export default function CustomizedDialogs({
  alertDialog,
  onClose,
  alertTitle,
  severity,
  strongTitle,
  title,
  priorAnalysisStatusStyle,
  moduleName,
  openPriorAnalysisDialog,
  closePriorAnalysisDialog,
  whichAnalysisDialog,
  closeWhichAnalysisDialog,
  viewDefaultAnalysis,
  viewUserModAnalysis,
  colorDialog,
  closeColorDialog,
  NotDraggablePaperComponent,
  changeORColor,
  setColorTags,
  userColorTag,
  targetDialog,
  closeTargetDialog,
  StyledPopper,
  ListboxComponent,
  changeTarget,
  setTargetTags,
  closeLabelDialog,
  labelDialog,
  PaperComponent,
  changeObjectComposition,
  objectComposition,
  changeProteinStandard,
  proteinStandard,
  changeProteinControl,
  proteinControl,
  setLabelTags,
  analyzingMW,
  confirmMLClassDialog,
  closeConfirmMLClassDialog,
  alertTitleStyle,
  verificationDict,
  alertFilenameStyle,
  alertMLClassStyle,
  updateVerifiedClassAgree,
  updateVerifiedClassDisagree,
  updateVerifiedClassNotSure,
  setDialogs,
  noAnaSelectDialog,
  closenoAnaSelectDialog,
  continueSubmitForAnalysis,
  showWhichResults,
  proteinList,
  lysateList,
  proteinTarget,
  lysateTarget,
  allowedToLeave,
  index
}) {
  const menuLabelItemStyle = { 'background-color': '#0042801c', 'padding-left': '0px', 'color': '#666', 'font-size': '13px', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }
  const menuListGroupStyle = { 'color': '#666', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }
  return (
    <div>
      {moduleName === 'UploadStage' &&
        <Dialog
          open={alertDialog}
          keepMounted
          onClose={onClose}
          aria-describedby="alert-dialog-description"
        >
          <Alert severity={severity}>
            <AlertTitle>{alertTitle}</AlertTitle>
            {title}<strong>{strongTitle}</strong>
          </Alert>
        </Dialog>
      }
      {moduleName === 'PriorAnalysisDialog' &&
        <Dialog
          open={openPriorAnalysisDialog}
          onClose={closePriorAnalysisDialog}
        >
          <DialogTitle>Retrieving and Working on Prior Analysis</DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                Each time you click "Submit For Analysis" you are creating a unique Analysis ID in the Cloud that will contain all your analysis on the submitted image. This will allow you to retrieve and then continue analysis you have yet to complete.
              </Typography>
              <Button
                sx={{ mb: 4, ml: 3, mt: 1, "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }}
                variant="contained">Submit for Analysis
              </Button>
              <Typography>   
                The drop down menu inside Previous Analysis shows if you have submitted this image for analysis before.  Prior work is organized with a simple integer increment. For example, if you have analyzed "Image 0" three times, you will see 0_0, 0_1 and 0_2 in the menu (if you do not, you can click the Refresh Icon to update the list).
              </Typography>
              <FormControl className='priorAnalysisDialog' variant="standard" sx={{ ml: 3, mb: 2, minWidth: 100 }}>
                <InputLabel id="select-label-for-priorAnalsyisDialog">Image 0</InputLabel>
                <Select
                  labelId="select-label-for-priorAnalsyisDialog"
                  id="select-prior-analysisIDDialog"
                  label="Image 0"
                  sx={priorAnalysisStatusStyle}
                >
                  <MenuItem sx={priorAnalysisStatusStyle} value="None"><em>None</em></MenuItem>
                  <MenuItem sx={priorAnalysisStatusStyle}>0_0</MenuItem>
                  <MenuItem sx={priorAnalysisStatusStyle}>0_1</MenuItem>
                  <MenuItem sx={priorAnalysisStatusStyle}>0_2</MenuItem>
                </Select>
              </FormControl>
              <Typography>
                When you select "Retrieve", all prior saved work will be displayed and any new work that you perform can be saved relative to that id.<br></br><br></br> <em><strong>To save current work you must click the upload icon.</strong></em>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closePriorAnalysisDialog}>Got It!</Button>
          </DialogActions>
        </Dialog>

      }
      {moduleName === 'whichAnalysisDialog' &&
        <Dialog
          open={whichAnalysisDialog}
          onClose={closeWhichAnalysisDialog}
          aria-describedby='choose-which-analysis'
        >
          <DialogTitle>Which Analysis Do You Want?</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>You have previously worked on this data and stored that progress for later recovery.  Would you like to view the Default Analysis or your Prior Work-Up?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={viewDefaultAnalysis}>Start from Default Analysis</Button>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={viewUserModAnalysis}>Continue Prior Work-Up</Button>
          </DialogActions>
        </Dialog>
      }
      {moduleName === 'colorDialog' &&
        <Dialog
          open={colorDialog}
          onClose={closeColorDialog}
          aria-describedby='set-color-dialog-description'
          PaperComponent={NotDraggablePaperComponent}
        >
          <DialogTitle>Color Options</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Assign a color to the currently visible objects.
            </DialogContentText>
            <Box>
              <GithubPicker
                color={userColorTag}
                onChangeComplete={changeORColor}
                colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C']}
              >
              </GithubPicker>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={setColorTags}>Set Tags</Button>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeColorDialog}>Close</Button>
          </DialogActions>
        </Dialog> 
      }
      {moduleName === 'targetDialog' &&
        <Dialog
          open={targetDialog}
          onClose={closeTargetDialog}
          aria-describedby='set-target-dialog-description'
          PaperComponent={NotDraggablePaperComponent}
        >
          <DialogTitle>Protein Target</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Select a protein Target that describes the highlighted objects.
            </DialogContentText>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Autocomplete
                disableListWrap
                freeSolo
                sx={{ width: 300 }}
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                options={showWhichResults ? proteinList : lysateList}
                renderInput={(params) => <TextField {...params} label={showWhichResults ? "Search Human Proteome" : "Search Cell Lysate"} />}
                renderOption={(props, option) => [props, option]}
                value={showWhichResults ? proteinTarget : lysateTarget}
                onChange={changeTarget}
              >
              </Autocomplete>


            </Stack>
          </DialogContent>
          <DialogActions>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={setTargetTags}>Set Tags</Button>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeTargetDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      }
      {moduleName === 'closeLabelDialog' &&
        <Dialog
          open={labelDialog}
          onClose={closeLabelDialog}
          aria-describedby='set-label-dialog-description'
          PaperComponent={PaperComponent}
        >
          <DialogTitle style={{ cursor: 'move' }}>Optional Labels</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>Choose from a list of labels that describe the currently visible objects. <br></br><br></br></Typography>
            </DialogContentText>
            <DialogContentText sx={{ mb: 2 }}>
              <Typography><strong><u>If</u></strong> your highlighted selection is a MW standard Lane, setting a MW standard will trigger MW analysis of all objects and update your table.</Typography>
            </DialogContentText>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <FormControl size="small">
                <Select
                  autoFocus
                  value={objectComposition}
                  onChange={changeObjectComposition}
                //label="composition"
                >
                  <MenuItem sx={{ menuLabelItemStyle }} value='undefined'><em>None</em></MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle }} value='protein'>protein</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle }} value='dna'>dna</MenuItem>
                </Select>
                <FormHelperText>composition</FormHelperText>
              </FormControl>
              <FormControl size="small">
                <Select
                  value={proteinStandard}
                  onChange={changeProteinStandard}
                >
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='undefined'><em>None</em></MenuItem>
                  <ListSubheader sx={{ menuListGroupStyle }} value='ppps'>Precision Plus Protein Standards</ListSubheader>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='dualColor'>Dual Color</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='allBlue'>All Blue</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='kaleidoscope'>Kaleidoscope</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='dualExtra'>Dual Extra</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='unstained'>Unstained</MenuItem>
                  <ListSubheader sx={{ menuListGroupStyle }} value='nps'>Natural Protein Standards</ListSubheader>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='broadRange'>Broad-Range</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='lowRange'>Low-Range</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='highRange'>High-Range</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='polypeptide'>Polypeptide</MenuItem>
                </Select>
                <FormHelperText>standard type</FormHelperText>
              </FormControl>
              <FormControl size="small">
                <Select
                  autoFocus
                  value={proteinControl}
                  onChange={changeProteinControl}
                //label="composition"
                >
                  <MenuItem sx={{ menuLabelItemStyle }} value='undefined'><em>None</em></MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle }} value='positive'>positive</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle }} value='negative'>negative</MenuItem>
                  <MenuItem sx={{ menuLabelItemStyle }} value='sample'>sample</MenuItem>
                </Select>
                <FormHelperText>control type</FormHelperText>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>

            <LoadingButton
              variant='contained'
              loading={analyzingMW}
              sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }}
              onClick={setLabelTags}
            >
              Set Tags
            </LoadingButton>
            <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeLabelDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      }

      {moduleName === 'confirmMLClassDialog' &&
        <Dialog
          open={confirmMLClassDialog}
          keepMounted
          onClose={closeConfirmMLClassDialog}
          aria-describedby='confirm-ml-class-dialog'
          fullWidth={true}
        >
          <DialogTitle
            sx={alertTitleStyle}
          >
            Please Confirm Bio-Rad's Object Classification
          </DialogTitle>
          <DialogContent>
            {verificationDict !== null && verificationDict.map((theImage, index) => {
              if (theImage.userVerifiedClass === 'notVerified') {
                return (
                  <Box sx={{ mb: 1 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Box sx={{ 'width': '32%' }}>
                        <Box>
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Typography sx={alertTitleStyle}>Filename:</Typography>
                            <Typography sx={alertFilenameStyle}>{theImage.filename}</Typography>
                          </Stack>
                        </Box>
                        <Box >
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Typography sx={alertTitleStyle}>Classified as:</Typography>
                            <Typography sx={alertMLClassStyle}>{theImage.mlClass}</Typography>
                          </Stack>
                        </Box>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Button
                            variant="contained"
                            size='small'
                            startIcon={<ThumbUpAltIcon fontSize="small" />}
                            disabled={eval('testDisableB' + String(index))}
                            //disabled={window['testDisabledB'+String(index)]}
                            id={'agree' + String(index)}
                            sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                            onClick={() => updateVerifiedClassAgree}
                          >
                            Agree
                          </Button>
                          <Button
                            variant="contained"
                            size='small'
                            startIcon={<ThumbDownAltIcon fontSize="small" />}
                            id={'disagree' + String(index)}
                            disabled={eval('testDisableB' + String(index))}
                            sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                            onClick={() => updateVerifiedClassDisagree}
                          >
                            Disagree
                          </Button>
                          <Button
                            variant="contained"
                            size='small'
                            startIcon={<HelpCenterIcon fontSize="small" />}
                            id={'notSure' + String(index)}
                            disabled={eval('testDisableB' + String(index))}
                            sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                            onClick={() => updateVerifiedClassNotSure}
                          >
                            Not Sure
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                )
              } else {
                return (
                  <Box></Box>
                )
              }
            })}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }}
              disabled={!allowedToLeave}
              onClick={() => setDialogs}
            >
              Finish
            </Button>
          </DialogActions>
        </Dialog>
      }

      {moduleName === 'noAnaSelectDialog' &&
        <Dialog
          open={noAnaSelectDialog}
          keepMounted
          aria-describedby="alert-dialog-description"
        >
          <Alert severity="warning" sx={{ 'width': '400px' }}>
            <AlertTitle sx={{ 'font-weight': 'bold' }}>Warning</AlertTitle>
            <Typography>It appears you have not reviewed any Regions Of Interest that Bio-Rad identified; or defined manually any Regions to analyze in the image above. <br></br> <br></br> If you still wish to proceed, analysis will be performed on Bio-Rad identified regions of interest (which could be empty).</Typography>

            <Box
              sx={{
                'width': '100%',
                display: 'flex',
                flexDirection: 'row-reverse',
                'margin-top': '20px'
              }}>

              <Button
                id='userChoseToReturn'
                sx={{ color: 'black' }}
                variant="text"
                onClick={closenoAnaSelectDialog}
              >
                Return
              </Button>
              <Button
                id='userChoseToProceed'
                sx={{ color: 'black' }}
                variant="text"
                onClick={() => continueSubmitForAnalysis}
              >
                Proceed
              </Button>

            </Box>

          </Alert>
        </Dialog>
      }
    </div>
  );

}