import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const WhichAnalysisDialog = ({ closeWhichAnalysisDialog, handleChange, open }) => (
  <Dialog
    open={open}
    onClose={closeWhichAnalysisDialog} 
    aria-describedby='choose-which-analysis'
  >
    <DialogTitle>Which Analysis Do You Want?</DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>You have previously worked on this data and stored that progress for later recovery.  Would you like to view the Default Analysis or your Prior Work-Up?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={handleChange('viewDefaultAnalysis')}>Start from Default Analysis</Button>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={handleChange('viewUserModAnalysis')}>Continue Prior Work-Up</Button>
    </DialogActions>
  </Dialog>
);

export default WhichAnalysisDialog;
