
import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack'
const ImageFilters = ({
  changeContrast,  
  changeBrightness,
  invertImage,
  handleChange,
  imageFilterBrightness,
  imageFilterContrast
}) => (
  <div className='imageFilters'>
    <Divider sx={{ 'margin': '0px', 'color': '#FFFFFF', '&::before': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' }, '&::after': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' } }} textAlign="left" variant="middle">Image Filters</Divider>
    <div className='imageFiltersContent'>
      <Stack
        direction="row"
        spacing={1.2}
      >
      <Box 
        //sx={{ width: 60 }}
      >
        <Tooltip title="Invert" placement="top">
          <IconButton
            onClick={invertImage}
          >
            <InvertColorsIcon sx={{ "color":'#88e5ff','padding': '0px', '&:hover': { color: 'orange' } }}></InvertColorsIcon>
          </IconButton>
        </Tooltip>
      </Box>      
      <Box 
        sx={{ pt: 0.5, width: 60 }}
      >
        <Tooltip title="Brightness" placement="top">
        <Slider
          sx={{ "color":'#88e5ff','padding': '0px', '&:hover': { color: 'orange' } }}
          getAriaLabel={() => 'Brightness'}
          size="small"
          value={imageFilterBrightness}
          onChange={changeBrightness}
          valueLabelDisplay="off"
          defaultValue={100}
          step={10}
          min={0}
          max={1000}
        />
        </Tooltip>
      </Box>
      <Box 
        sx={{ pt: .5, width: 60 }}
      >
        <Tooltip title="Contrast" placement="top">
        <Slider
          sx={{ "color":'#88e5ff','padding': '0px', '&:hover': { color: 'orange' } }}
          getAriaLabel={() => 'Contrast'}
          size="small"
          value={imageFilterContrast}
          onChange={changeContrast}
          valueLabelDisplay="off"
          defaultValue={100}
          step={10}
          min={0}
          max={400}
        />
        </Tooltip>
      </Box>
      </Stack>
    </div>
  </div>
);

export default ImageFilters;