import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const alertMLClassStyle = { 'padding-left': '0px', 'text-decoration': 'underline', 'color': '#7000ff', 'font-size': '14px', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" };
const alertTitleStyle = { 'color': '#666', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" };
const alertFilenameStyle = { 'padding-left': '0px', 'color': 'black', 'font-size': '14px', "font-weight": '400', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" };

const ConfirmMLClassDialog = ({
  open,
  handleChange,
  verificationDict,
  updateVerifiedClass,
  allowedToLeave,
  setDialogs,
  testDisableB0,
  testDisableB1
}) => {
  const testDisableB = [testDisableB0, testDisableB1];
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleChange('closeConfirmMLClassDialog')}
      aria-describedby='confirm-ml-class-dialog'
      fullWidth={true}
    >
      <DialogTitle
        sx={alertTitleStyle}
      >
        Please Confirm Bio-Rad's Object Classification
      </DialogTitle>
      <DialogContent>
        {verificationDict !== null && verificationDict.map((theImage, index) => {
          if (theImage.userVerifiedClass === 'notVerified') {
            return (
              <Box sx={{ mb: 1 }}>
                <Stack
                  direction="row"
                  spacing={1}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box sx={{ 'width': '32%' }}>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={1}
                      >
                        <Typography sx={alertTitleStyle}>Filename:</Typography>
                        <Typography sx={alertFilenameStyle}>{theImage.filename}</Typography>
                      </Stack>
                    </Box>
                    <Box >
                      <Stack
                        direction="row"
                        spacing={1}
                      >
                        <Typography sx={alertTitleStyle}>Classified as:</Typography>
                        <Typography sx={alertMLClassStyle}>{theImage.mlClass}</Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Stack
                      direction="row"
                      spacing={1}
                    >
                      <Button
                        variant="contained"
                        size='small'
                        startIcon={<ThumbUpAltIcon fontSize="small" />}
                        disabled={testDisableB[index]}
                        //disabled={window['testDisabledB'+String(index)]}
                        id={'agree' + String(index)}
                        sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                        onClick={() => updateVerifiedClass(index, 'agree')}
                      >
                        Agree
                      </Button>
                      <Button
                        variant="contained"
                        size='small'
                        startIcon={<ThumbDownAltIcon fontSize="small" />}
                        id={'disagree' + String(index)}
                        disabled={testDisableB[index]}
                        //disabled={window['testDisabledB'+String(index)]}
                        sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                        onClick={() => updateVerifiedClass(index, 'disagree')}
                      >
                        Disagree
                      </Button>
                      <Button
                        variant="contained"
                        size='small'
                        startIcon={<HelpCenterIcon fontSize="small" />}
                        id={'notSure' + String(index)}
                        disabled={testDisableB[index]}
                        //disabled={window['testDisabledB'+String(index)]}
                        sx={{ 'width': '110px', 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' } }}//,"&:disabled": {'background-color': 'grey'}}}
                        onClick={() => updateVerifiedClass(index, 'notSure')}
                      >
                        Not Sure
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            )
          } else {
            return (
              <Box></Box>
            )
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }}
          disabled={!allowedToLeave}
          //onClick={()=>setConfirmMLClassDialog(false)}
          onClick={() => setDialogs({ confirmMLClassDialog: false })}
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmMLClassDialog;