import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import * as uuid from "uuid";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import Row from "./Row";
import DateRangeComponent from "./datePicker";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const RecentUpload = ({ recentUpload, onImageGroupClick, handleChange, linearProgressIndicator, theDateRange, setTheDateRange, imageRetrievalBool }) => {
  const { theme } = useTheme();
  const subTitleStyle = {
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    fontFamily: styles.fontFamily,
  };
  const tableHeadRowBGStyle = { "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41", "border-bottom": "solid", "border-width": "1.1px", "border-color": "#666" };
  const refreshTableStyle = { padding: "0px", color: theme === "light" ? "#adadad" : "#2a2d41", "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41" };
  const tableRowStyle = {
    "padding-left": "0px",
    "background-color": theme === "light" ? "#f9f9f9" : "#474c6f",
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const tableStickyHeadRowTextStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "white",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  return (
    <Box className="recentUploadsContent" sx={{ overflow: "hidden", m: 1, border: "solid", borderColor: "#b1b1b1", borderStyle: "dashed", borderWidth: "1px", borderRadius: "10px", padding: "1px" }}>
      <Divider sx={subTitleStyle} textAlign="left" variant="middle">
        Search Your Personal Database
      </Divider>
      <Box sx={{ display: imageRetrievalBool ? "block" : "none" }}>
        <DateRangeComponent theDateRange={theDateRange} setTheDateRange={setTheDateRange} handleChange={handleChange} />
        <Box className="recentUploadTable">
          <TableContainer component={Paper} sx={{ "max-height": "300px" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={tableHeadRowBGStyle}>
                  <TableCell sx={refreshTableStyle}>
                    <Tooltip title="Refresh All Groups">
                      <IconButton onClick={handleChange("syncRecentStatusTable")}>
                        <CloudSyncOutlinedIcon sx={{ color: "#8b8b8b" }} fontSize="small"></CloudSyncOutlinedIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" sx={tableStickyHeadRowTextStyle}>
                    Upload Date
                  </TableCell>
                  <TableCell align="right" sx={tableStickyHeadRowTextStyle}>
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={tableRowStyle}>
                {recentUpload !== null && recentUpload.map((recentRow) => <Row sx={tableRowStyle} key={String(uuid.v4())} row={recentRow} onImageGroupClick={onImageGroupClick} />)}
              </TableBody>
            </Table>
          </TableContainer>
          {linearProgressIndicator && (
            <Box sx={{ color: "#a1a1a1" }}>
              <LinearProgress color="inherit" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RecentUpload;
