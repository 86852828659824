import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const ImageInfoContent = ({ expanded, switchAccordion, theImageInfo }) => {
  const { theme } = useTheme();
  const imageInfoKeyStyle = {
    "padding-left": "0px",
    color: theme === "light" ? "#780000" : "#a2d2fb",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const imageInfoValueStyle = {
    "padding-left": "0px",
    color: theme === "light" ? "#000000" : "#ecf2f8",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const dividerStyle = {
    color: theme === "light" ? "#6500ff" : "#faa356",
    backgroundColor: theme === "light" ? "primary" : "#c6cdd536",
    fontSize: "15px",
    fontFamily: styles.fontFamily,
  };
  return (
    <Accordion sx={accordionHeaderStyle} expanded={expanded === "imageHeader"} onChange={switchAccordion("imageHeader")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="imageHeader-content" id="imageHeader-header">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>Image Info</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {theImageInfo !== null && (
          <div className="imageTags">
            <Divider sx={dividerStyle} textAlign="left" variant="middle">
              File
            </Divider>
            <div className="tagElements">
              <Typography>
                <span style={imageInfoKeyStyle}>File Name:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.fileName["S"] + String(".scn")}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Acquisition Date:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.date["S"]}</span>
              </Typography>
            </div>
            <Divider sx={dividerStyle} textAlign="left" variant="middle">
              Hardware/Software
            </Divider>
            <div className="tagElements">
              <Typography>
                <span style={imageInfoKeyStyle}>Imager:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.imagerType["S"]}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Software:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.software["S"]}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Version:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.softwareVersion["S"]}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Serial Number:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.serialNumber["S"]}</span>
              </Typography>
            </div>
            <Divider sx={dividerStyle} textAlign="left" variant="middle">
              Lighting Conditions
            </Divider>
            <div className="tagElements">
              <Typography>
                <span style={imageInfoKeyStyle}>Excitation:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.excitationSource["S"]}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Emission:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.emmissionFilter["S"]}</span>
              </Typography>
              <Typography>
                <span style={imageInfoKeyStyle}>Illumination Mode:</span>
                <span style={imageInfoValueStyle}>{theImageInfo.illuminationMode["S"]}</span>
              </Typography>
              {theImageInfo.hasOwnProperty("exposureTime") && (
                <Typography>
                  <span style={imageInfoKeyStyle}>Exposure Time (sec):</span>
                  <span style={imageInfoValueStyle}>{theImageInfo.exposureTime["S"]}</span>
                </Typography>
              )}
            </div>
            <Divider sx={dividerStyle} textAlign="left" variant="middle">
              Application
            </Divider>
            <div className="tagElements">
              <Typography>
                <span style={imageInfoValueStyle}>{theImageInfo.imageApplication["S"]}</span>
              </Typography>
            </div>
            <Divider sx={dividerStyle} textAlign="left" variant="middle">
              MW Standard
            </Divider>
            <div className="tagElements">
              <Typography>
                <span style={imageInfoValueStyle}>{theImageInfo.standard["S"]}</span>
              </Typography>
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ImageInfoContent;
