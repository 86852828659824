import React, { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
//import { sectionTitleStyle } from '../style';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const chooseFileBoxStyle = { "min-height": "260px", width: "22%", "box-shadow": "0 0 10px rgba(132, 130, 130, 0.43)", "border-radius": "10px", "margin-right": "5px", transition: "width 2s" };

const AnalyticsSelectBox = ({ refreshAllAnalysis, imageRetrievalWidth, changeImageRetrievalWidth, children }) => {
  const { theme } = useTheme();
  const sectionTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#666" : "#cea5fb",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
  };

  return (
    <Box sx={{ minHeight: "260px", width: imageRetrievalWidth, boxShadow: "0 0 10px rgba(132, 130, 130, 0.43)", borderRadius: "10px", marginRight: "5px" }}>
      <Box className="stagingHeader" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Box className="stagingHeaderText" sx={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
          <Stack direction="row">
            <Box>
              <Typography sx={sectionTitleStyle} variant="h5">
                Image Retrieval
              </Typography>
            </Box>
            <Box className="stagingHeaderRefresh" sx={{ ml: 1, mt: 1.5 }}>
              <Tooltip title="Refresh All">
                <IconButton onClick={refreshAllAnalysis}>
                  <RefreshIcon sx={{ color: "#8b8b8b" }}></RefreshIcon>
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ mt: 0.5, mr: 1 }}>
          <Tooltip title={imageRetrievalWidth === "22%" ? "minimize" : "maximize"}>
            <IconButton onClick={changeImageRetrievalWidth}>
              {imageRetrievalWidth === "22%" ? (
                <ArrowBackOutlinedIcon sx={{ color: "#8b8b8b" }}></ArrowBackOutlinedIcon>
              ) : (
                <ArrowForwardOutlinedIcon sx={{ color: "#8b8b8b" }}></ArrowForwardOutlinedIcon>
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
export default AnalyticsSelectBox;
