import { React, useState } from "react";

import { TextField, Box, Typography, Divider, Button, Stack, MenuItem } from "@mui/material";

const buttonStyle = { "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" } };
const PrivateDownload = ({ sharedID, changeTheID, handleChange, displayGetPrivateID, setDatabase, database }) => {
  const databases = [
    { value: "ila-app-data-storage", label: "default ILA data storage" },
    { value: "mcb-data-storage", label: "mcb data storage" },
  ];
  //let [database, setDatabase] = useState('ila-app-data-storage');

  return (
    <Box sx={{ overflow: "hidden", display: displayGetPrivateID, m: 1, border: "solid", borderColor: "#b1b1b1", borderStyle: "dashed", borderWidth: "1px", borderRadius: "10px", padding: "1px" }}>
      <Divider sx={{ color: "#399dbc" }} textAlign="left" variant="middle">
        Internal Employee Tools
      </Divider>

      <Stack direction="column" spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
        <Box className="imageIDRetriever">
          <Stack direction="row">
            <Box sx={{ overflow: "hidden", ml: 2, mb: 2 }}>
              <TextField variant="standard" size="small" autoComplete="off" label="Get Image ID" value={sharedID} onChange={changeTheID} sx={{ width: 192 }}></TextField>
            </Box>
            <Box sx={{ mt: 1.5, ml: 1.5 }}>
              <Button size="small" onClick={handleChange("syncPrivateDownload")} variant="contained" sx={buttonStyle}>
                Get
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box
          className="dataSciencDataBase"
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "35ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-select-database"
            select
            size="small"
            label="Change Image Database"
            defaultValue="default data storage"
            value={database}
            helperText="This changes which database you search"
            onChange={(event) => {
              setDatabase(event.target.value);
            }}
          >
            {databases.map((option) => (
              <MenuItem
                //sx={{'color':'#666','font-size':'14px',"font-weight":'300',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}
                key={option.value}
                value={option.value}
              >
                <Typography
                  sx={{
                    color: "#666",
                    "font-size": "14px",
                    "font-weight": "300",
                    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                  }}
                >
                  {option.label}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Stack>
    </Box>
  );
};
export default PrivateDownload;
