import React from "react";
import Layout from "./components/layout/layout";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "./theme/ThemeContext";

LicenseInfo.setLicenseKey("b4beea374e78bb9cdcb350ac7bdd4023T1JERVI6NDI1MjgsRVhQSVJZPTE2ODI1NTIyNTkwMDAsS0VZVkVSU0lPTj0x");

function App() {
  return (
    <ThemeProvider>
      <Box className="App">
        <Router>
          <Routes>
            <Route exact path="*" element={<Layout authorized={true} />}></Route>
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
