import { React } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const ImageDisplayBox = ({ imageDisplayWidth, children }) => {
  const { theme } = useTheme();
  const sectionTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#666" : "#cea5fb",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
  };

  return (
    <Box
      sx={{
        width: `${imageDisplayWidth}`,
        "min-height": "260px",
        "box-shadow": "0 0 10px rgba(132, 130, 130, 0.43)",
        "border-radius": "10px",
        "margin-right": "5px",
        //"transition":"width 1s"
      }}
    >
      <div className="displayImagesHeader">
        <div className="displayImagesHeaderText">
          <Typography sx={sectionTitleStyle} variant="h5">
            Image Display
          </Typography>
        </div>
      </div>
      <div className="displayImageContent">{children}</div>
    </Box>
  );
};

export default ImageDisplayBox;
