import React from 'react';
import PropTypes from 'prop-types';
import Typography from'@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const EnhancedTableToolbar = ({ diplaySelection, displaySelectionColor, handleChange, numSelected }) => (
  <div className='testSelect'>
    {numSelected > 0 &&
      <Toolbar
        
        variant='dense'
        sx={{
          
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >       
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected (limit 2)
        </Typography>
          
        <Tooltip title="Display Selection">
          
          
          <IconButton
            color = {displaySelectionColor}
            onClick = {diplaySelection}
          >
            <Typography>Display</Typography>
            <DoubleArrowOutlinedIcon />
          </IconButton>
          
        </Tooltip>      
      </Toolbar>
    }
  </div>  
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  displaySelectionColor: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EnhancedTableToolbar;