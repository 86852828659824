import React from 'react';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const ToManyAlertDialog = ({ open, handleChange }) => (  <Dialog
  open={open}
  keepMounted
  onClose={handleChange('closeToManyDialog')}
  aria-describedby="alert-dialog-description"
>
  <Alert severity="error" sx={{ 'width': '400px' }}>
    <AlertTitle>Error</AlertTitle>
    At this time you may only select two or fewer images for analysis.
  </Alert>
</Dialog>);
  
export default ToManyAlertDialog;