import React, { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stage, Layer, Image, Line, Circle, Text } from 'react-konva';
import useImage from 'use-image';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import ArrowCircleLeftRightToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

const CustomToolbar = ({
    handleTPNStepLeft,
    handleTPNStepRight,
    resetTPNDialog,
    tpnMapping,
    changeTPNNormLane
  }) => {
    //console.log(tpnMapping)
    //console.log(tpnMapping.mapping.tpnNormalizingLane)
    return(
        <GridToolbarContainer className = 'testScroll' sx = {{'overflow-x':'auto'}}>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >            
                <Box sx={{mt:1}}>
                    <Stack
                        direction='row'
                    >                    
                        <Box>
                            <Tooltip title="Step Selection Left" placement="top">
                                <IconButton>
                                    <WestIcon onClick={handleTPNStepLeft}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Tooltip title="Step Selection Right" placement="top">
                                <IconButton>
                                    <EastIcon onClick={handleTPNStepRight}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Stack>
                </Box>
                <Box >
                    <Button
                        sx={{mt:1.5,'border': '1px solid grey','color':'white',"background-color":'#399dbc','&:hover': {'background-color': '#c4673a'},"&:disabled": {'background-color': 'grey'}}}
                        size='small'
                        onClick={resetTPNDialog}//createTPNMappingDict()}
                    >
                        reset / resize
                    </Button>
                </Box> 
                <Box>
                    {tpnMapping!==null && 
                    <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                        <InputLabel>Norm. Lane</InputLabel>
                        
                        <Select
                         variant='outlined'
                         label="Norm. Lane"
                         size = "small"
                         defaultValue={tpnMapping.mapping.indexOfNormalizingLane}
                         onChange={changeTPNNormLane}
                        > 
                            {tpnMapping.mapping.immutableTPNLaneNumber.map((lane,index)=>{
                                return(
                                    <MenuItem value={index}>{lane}</MenuItem>
                                )
                            })}                           
                        </Select>

                    </FormControl>
                    }
                </Box>
            </Stack>
        </GridToolbarContainer>
    )
  }

const TPNAlignDialog = ({
    open,
    closeTPNAlignDialog,
    imagesForShow,
    imageFilters,
    stage0,
    stage1,  
    tpnLaneArrays,
    tpnMapping,
    tpnMappingLines,    
    handleTPNStepLeft,
    handleTPNStepRight,
    tpnGridAPIRef,
    resetTPNDialog,
    changeTPNNormLane 
  }) => {
    
    
    
    //const tpnLaneArray = (tpnMapping['indexOfTPN']===0) ? tpnLaneArrays['ar0']['laneCanvasRegionArray'] : tpnLaneArrays['ar1']['laneCanvasRegionArray']
    //const notTPNLaneArray = (tpnMapping['indexOfTPN']===0) ? tpnLaneArrays['ar1']['laneCanvasRegionArray'] : tpnLaneArrays['ar0']['laneCanvasRegionArray']
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    //console.log(tpnMapping.mapping.indexOfNormalizingLane)
    //console.log('tpnLaneArrays',tpnLaneArrays)
    //console.log('tpnMapping',tpnMapping)
    //console.log('stage0',stage0)
    //console.log('stage1',stage1)
    //console.log(stage0.scaleX)

    var gradient
    ///THIS IS A HARD CODED VALUE FOR NOW, BUT NEEDS TO BE SMARTER
    gradient = ctx.createLinearGradient(400/2, 0, 400/2, 400 + 400);
    //}else{
    //    gradient = ctx.createLinearGradient(0, 0, 0, stage0.canvasHeight - tpnLaneArrays[0]['laneCanvasRegionArray'][0]['laneCenter'][0]['y'] + stage1.canvasHeight + tpnLaneArrays[1]['laneCanvasRegionArray'][0]['laneCenter'][0]['y']);
    //}
    //console.log(stage0.canvasHeight - tpnLaneArrays[0]['laneCanvasRegionArray'][0]['laneCenter'][0]['y'] + stage1.canvasHeight + tpnLaneArrays[1]['laneCanvasRegionArray'][0]['laneCenter'][0]['y'])
    //var gradient = ctx.createLinearGradient(400/2, 0, 400/2, 400 + 400);
    //var gradient = ctx.createLinearGradient(0, 0, 0, stage0.canvasHeight - tpnLaneArrays[0]['laneCanvasRegionArray'][0]['laneCenter'][0]['y'] + stage1.canvasHeight + tpnLaneArrays[1]['laneCanvasRegionArray'][0]['laneCenter'][0]['y']);
    gradient.addColorStop(0.00, 'violet');
    gradient.addColorStop(1 / 7, 'indigo');
    gradient.addColorStop(2 / 7, 'blue');
    gradient.addColorStop(3 / 7, 'green')
    gradient.addColorStop(4 / 7, 'yellow');
    gradient.addColorStop(5 / 7, 'orange');
    gradient.addColorStop(6 / 7, 'red');
    
    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }
    
    const TPN0 = () => {
        const [image] = useImage(imagesForShow[tpnMapping['mapping']['indexOfTPN']], 'anonymous', 'origin');          
        return <Image image={image} />;
    };
    const TPN1 = () => {
        const [image] = useImage(imagesForShow[tpnMapping['mapping']['notIndexOfTPN']], 'anonymous', 'origin');        
        return <Image image={image} />;
    }   
    //console.log(tpnMapping)
    
    //const normingIndex = tpnMapping['indexOfNormalizingLane']
    
    //const theColumns = Array.from(tpnMapping['targetImage'].values())
    //console.log(tpnMapping)
    //console.log(Array.from(tpnMapping['targetLaneNumber']))
    const columns = [
        { field: "id", headerName: "lane", width: 90, hide:true, sortable:false },
        {
          field: "sfLane",
          headerName: "Stain-free Lane",
          width: 110,
          editable: false,
          sortable:false,          
          align:'center'
        },
        {
          field: "targetLane",
          headerName: "Target Lane",
          width: 90,
          editable: false,
          sortable:false,
          align:'center'
        }
        
      ];


    return(        
        <div>
        <Dialog
            open={open}
            onClose={closeTPNAlignDialog}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth = {false} 
            maxWidth = 'lg'   
        >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Auto TPN: Lane Alignment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm(or Adjust) the automated TPN Lane Mapping.
          </DialogContentText>
          <Stack
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >          
            <Box>
            {tpnMapping!==null && 
            <Stage 
                width={stage0.canvasWidth}
                height={stage0.canvasHeight + stage1.canvasHeight}                                  
            >                    
                <Layer                                                         
                    scaleX={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.scaleX * stage0.transform : stage1.scaleX * stage1.transform}                                    
                    scaleY={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.scaleY * stage0.transform : stage1.scaleX * stage1.transform}                                    
                    x={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.posX : stage1.posX }
                    y={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.posY : stage1.posY}                            
                >
                    <TPN0></TPN0>                        
                </Layer>
                {tpnLaneArrays!==null && 
                    <Layer
                        scaleX={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.scaleX * stage0.transform : stage1.scaleX * stage1.transform}                                    
                        scaleY={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.scaleY * stage0.transform : stage1.scaleX * stage1.transform}                                    
                        x={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.posX : stage1.posX}
                        y={(tpnMapping['mapping']['indexOfTPN']===0) ? stage0.posY : stage1.posY}                           
                    >
                            
                        {tpnLaneArrays[tpnMapping['mapping']['indexOfTPN']]['laneCanvasRegionArray'].map((region,index) => {
                        return (
                            <React.Fragment key={region.id}>                               

                            <Line
                                name={"region" + String(region.id)}
                                points={region.points.flatMap(p => [p.x, p.y])}
                                stroke={region.color}//{(index===tpnMapping['mapping']['indexOfNormalizingLane']) ? 'red' : region.color}
                                fill={region.fill}
                                strokeWidth={2}                                    
                                closed={region.closed}                                    
                            />
                                                               
                            <Circle x={parseInt(region.laneCenter.flatMap(p=>p.x))} y = {parseInt(region.laneCenter.flatMap(p=>p.y))} radius={8} fill='cyan'></Circle>
                             
                            </React.Fragment>
                        )
                        })}
                        
                    </Layer>
                }
                <Layer                                                           
                    scaleX={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.scaleX * stage1.transform : stage0.scaleX * stage0.transform}                                    
                    scaleY={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.scaleY * stage1.transform : stage0.scaleX * stage0.transform}                                    
                    x={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.posX : stage0.posX}
                    y={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.posY+stage0.canvasHeight : stage0.posY+stage1.canvasHeight }
                    
                >
                    <TPN1></TPN1>                        
                </Layer>
                {tpnLaneArrays!==null && 
                    <Layer
                        scaleX={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.scaleX * stage1.transform : stage0.scaleX * stage0.transform}                                    
                        scaleY={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.scaleY * stage1.transform : stage0.scaleX * stage0.transform}                                    
                        x={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.posX : stage0.posX}
                        y={(tpnMapping['mapping']['indexOfTPN']===0) ? stage1.posY+stage0.canvasHeight : stage0.posY+stage1.canvasHeight}
                        
                    >
                        {tpnLaneArrays[tpnMapping['mapping']['notIndexOfTPN']]['laneCanvasRegionArray'].map(region => {
                        return (
                            <React.Fragment key={region.id}>                              
                                 
                                <Line
                                    name={"region" + String(region.id)}
                                    points={region.points.flatMap(p => [p.x, p.y])}
                                    stroke={region.color}
                                    fill={region.fill}
                                    strokeWidth={2}                                    
                                    closed={region.closed}                                
                                /> 
                                                                    
                                <Circle x={parseInt(region.laneCenter.flatMap(p=>p.x))} y = {parseInt(region.laneCenter.flatMap(p=>p.y))} radius={2} fill='orange'></Circle>                                    
                                
                            </React.Fragment>
                        )
                        })}
                    </Layer>
                }
                {tpnMappingLines!==null && 
                    <Layer                                
                    >
                        {tpnMappingLines.map(region => {                            
                            return (
                                <React.Fragment>
                                    <Line
                                        points={region}
                                        stroke={gradient}                                        
                                        strokeWidth = {1}
                                        
                                    ></Line>
                                </React.Fragment> 
                            )
                        })}
                    </Layer>
                }                   
            </Stage>
            }
            </Box>
            <Box sx={{width:'100%'}}>
                <DialogContentText>
                    Adjust Lane Mappings or Normalization Lane
                </DialogContentText>
                {tpnMapping!==null && 
                    <Box sx={{height:'auto',width:'100%'}}>
                        <DataGridPro 
                            sx={{
                                boxShadow: 4,
                                //border: 2,
                                //borderColor: '#acb0ad',
                                '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                                },
                                
                            }}
                            disableColumnMenu
                            autoHeight={true}
                            rows={tpnMapping['mapping']['gridRows']}
                            columns={columns}
                            density="compact"
                            disableRowSelectionOnClick={true}
                            pagination={false}
                            checkboxSelection={true}
                            apiRef={tpnGridAPIRef}
                            components={{
                                Toolbar: () => (
                                <CustomToolbar
                                    handleTPNStepLeft={handleTPNStepLeft}
                                    handleTPNStepRight={handleTPNStepRight}
                                    resetTPNDialog={resetTPNDialog}
                                    tpnMapping={tpnMapping} 
                                    changeTPNNormLane={changeTPNNormLane}                               
                                    /> )
                            }}
                        >                                
                        </DataGridPro>
                    </Box>
                }
                
            </Box>
          </Stack>
        </DialogContent>
        
      </Dialog>    
        
        </div>
    )
  };
  export default TPNAlignDialog;