import "./analytics.scss";
import "../home/home.scss";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AWS from "aws-sdk";
import * as AWSConfig from "../../awsConfig";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import mlNotReady from "../../assets/mlNotFinished.png";
import { Stage, Image, Layer, Line } from "react-konva";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Stack from "@mui/material/Stack";
import * as uuid from "uuid";
import useImage from "use-image";
import regression from "regression";

import { useGridApiRef } from "@mui/x-data-grid-pro";

import {
  bubbleScalar,
  bubbleChartTitles,
  normalizedBubbleChartTitles,
  lineChartTitles,
  lineProfileOptions,
  bubbleOptions,
  screenDims,
  defaultScatCJSData,
  defaultMWLineChartData,
  defaultTotalLaneChartData,
  mwStandards,
  mwLineChartOptions,
  totalLaneChartOptions,
  jellyBeanColors,
  devUsers,
} from "./config";
import { downloadURI, changeColor, createUniqueArray } from "./utils";

import LabelDialog from "./dialogs/LabelDialog";
import ColorDialog from "./dialogs/ColorDialog";
import TargetDialog from "./dialogs/TargetDialog";
import TPNAlignDialog from "./dialogs/TPNAlignDialog";
import TPNRequestedDialog from "./dialogs/TPNRequestedDialog";
import NoAnaSelectDialog from "./dialogs/NoAnaSelectDialog";
import PriorAnalysisDialog from "./dialogs/PriorAnalysisDialog";
import ShareAnalysisDialog from "./dialogs/ShareAnalysisDialog";
import WhichAnalysisDialog from "./dialogs/WhichAnalysisDialog";
import ConfirmMLClassDialog from "./dialogs/ConfirmMLClassDialog";
import GelBlotBorderDialog from "./dialogs/GelBlotBorderDialog";
import NoFilesAlertDialog from "./dialogs/NoFilesAlertDialog";
import ToManyAlertDialog from "./dialogs/ToManyAlertDialog";
import RecentUpload from "./AnalyticsSelect/RecentUpload";
import CurrentSelection from "./AnalyticsSelect/CurrentSelection/index";
import AnalyticsSelectBox from "./AnalyticsSelect/AnalyticsSelectBox";
import ImageDisplayBox from "./ImageDisplay/ImageDisplayBox";
import ImageInfoContent from "./ImageContent/ImageInfoContent";
import ImageROI from "./ImageContent/ImageROI";
import CurrentAnalysis from "./ImageContent/CurrenAnalysis";
import PreviousAnalysis from "./ImageContent/PreviousAnalysis";
import ImageContentBox from "./ImageContent/ImageContentBox";
import PrivateDownload from "./AnalyticsSelect/PrivateSelection/index";
import ImageFilters from "./ImageDisplay/ImageAnotate/ImageFilters";
import ImageInfo from "./ImageDisplay/ImageAnotate/ImageInfo";
import ImageROIDisplay from "./ImageDisplay/ImageAnotate/ImageROIDisplay";
import AnalyticsGraph from "./ImageDisplay/AnalyticsTableAndGraph/AnalyticsGraph";
import AnalyticsTable from "./ImageDisplay/AnalyticsTableAndGraph/AnalyticsTable";
import AnalyticsTableAndGraphContainer from "./ImageDisplay/AnalyticsTableAndGraph/AnalyticsTableAndGraphContainer";
import { useTheme } from "../../theme/ThemeContext";
import { styles } from "../../theme/ThemeStyle";
import MUITooltip from "@mui/material/Tooltip"
import { Chart as ChartJS, LineElement, PointElement, RadialLinearScale, ArcElement, Legend, CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
ChartJS.register(LineElement, PointElement, RadialLinearScale, ArcElement, CategoryScale, LinearScale, LogarithmicScale, BarElement,Tooltip, Legend, Title, zoomPlugin, annotationPlugin);

//LicenseInfo.setLicenseKey(
//  'b4beea374e78bb9cdcb350ac7bdd4023T1JERVI6NDI1MjgsRVhQSVJZPTE2ODI1NTIyNTkwMDAsS0VZVkVSU0lPTj0x',
//);

AWS.config.update({
  region: AWSConfig.creds.bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSConfig.creds.indentityPoolId,
    RoleArn: AWSConfig.creds.roleArn,
  }),
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: AWSConfig.creds.bucketName },
  useAccelerateEndpoint: true,
});

const dynamodb = new AWS.DynamoDB({
  apiVersion: "2012-08-10",
});

const lambda = new AWS.Lambda({
  apiVersion: "2015-03-31",
});

export default function Analytics() {
  const { theme } = useTheme();

  let wrapperRef1 = useRef();

  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const regionsOfInterestHeader = {
    color: "#666",
    "font-size": "15px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const genericImageNameStyle = {
    color: "#88e5ff",
    "font-size": "14px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };

  const [recentUpload, setRecentUpload] = useState(null);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [theDateRange, setTheDateRange] = useState([null, null]);
  let [dialogs, setDialogs] = useState({
    noFilesAlertDialog: false,
    confirmMLClassDialog: false,
    noAnaSelectDialog: false,
    gelBlotBorderDialog: false,
    toManyAlertDialog: false,
    labelDialog: false,
    targetDialog: false,
    colorDialog: false,
    tpnAlignDialog: false,
    openTPNRequestedDialog: false,
  });
  let [openBackdrop, setOpenBackdrop] = useState(false);
  const [theImageInfo, setTheImageInfo] = useState(null);
  const [highlightColor, setHighlightColor] = useState("grey");
  let [linearProgressIndicator, setLinearProgressIndicator] = useState(false);
  let [displaySelectionColor, setDisplaySelectionColor] = useState("default");
  let [uploadIconColor, setUploadIconColor] = useState("default");
  let [inputSharedID, setInputSharedID] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  let [widthOfImageRetrieval, setWidthOfImageRetrieval] = useState("22%");
  let [imageRetrievalBool, setImageRetrievalBool] = useState(true);
  let [widthOfImageDisplay, setWidthOfImageDisplay] = useState("55%");
  /////for current table
  const [selected, setSelected] = React.useState([]);
  const [selectedID, setSelectedID] = React.useState([]);
  const [selectedURL, setSelectedURL] = React.useState([]);
  const [selectedBGRURL, setSelectedBGRURL] = React.useState([]);
  const [imagesForShow, setImagesForShow] = React.useState(null);
  const [imageIDsForShow, setImageIDsForShow] = React.useState(null);
  const [brightnessValue0, setBrightnessValue0] = useState(100);
  const [brightnessValue1, setBrightnessValue1] = useState(100);
  const [contrastValue0, setContrastValue0] = useState(100);
  const [contrastValue1, setContrastValue1] = useState(100);
  const [invertImageValue0, setInvertImageValue0] = useState(0);
  const [invertImageValue1, setInvertImageValue1] = useState(0);
  const [fb1, setfb1] = useState(false);
  const [fb0, setfb0] = useState(false);
  const [fll1, setfll1] = useState(false);
  const [fll0, setfll0] = useState(false);
  const [rb1, setrb1] = useState(false);
  const [rb0, setrb0] = useState(false);
  let [sat0, setsat0] = useState(false);
  let [sat1, setsat1] = useState(false);
  let [gb0, setgb0] = useState(false);
  let [gb1, setgb1] = useState(false);
  let [activeRegions, setActiveRegions] = useState([]);
  let [activeLysateRegions1, setActiveLysateRegions1] = useState([]);
  let [activeBandRegions1, setActiveBandRegions1] = useState([]);
  let [activeLysateRegions0, setActiveLysateRegions0] = useState([]);
  let [activeBandRegions0, setActiveBandRegions0] = useState([]);
  let [activeBandBoxRegions0, setActiveBandBoxRegions0] = useState([]);
  let [activeBandBoxRegions1, setActiveBandBoxRegions1] = useState([]);
  let [activeGelBlotRegions0, setActiveGelBlotRegions0] = useState([]);
  let [activeGelBlotRegions1, setActiveGelBlotRegions1] = useState([]);
  let [activeSatRegions0, setActiveSatRegions0] = useState([]);
  let [activeSatRegions1, setActiveSatRegions1] = useState([]);
  let [activeImage, setActiveImage] = useState(null);
  let [activeROI, setActiveROI] = useState(null);
  let [addRemoveCheck, setAddRemoveCheck] = useState(false);
  let [retrieveVisState, setRetrieveVisState] = useState({ image0: "hidden", image1: "hidden" });
  let [laneChecks, setLaneChecks] = useState({
    centerLane: false,
    boxLane: true,
    polygonLane: false,
    relativeMobility: false,
  });
  let [relativeChecks, setRelativeChecks] = useState({
    relativeToBand: true,
    relativeToLane: false,
    totalLane: false,
    mwFit: false,
  });
  let [yAxisChecks, setYAxisChecks] = useState({
    mw: false,
    vertPos: true,
  });

  let [manualAnalysisBool, setManualAnalysisBool] = useState(false);
  let [visibleLaneArray, setVisibleLaneArray] = useState({});
  let [tpnLaneArrays, setTPNLaneArrays] = useState(null);
  let [tpnMapping, setTPNMapping] = useState(null);
  let [resetTPNMapping, setResetTPNMapping] = useState(null);
  let [defaultTPNData, setDefaultTPNData] = useState(null);
  let [tpnImageID, setTPNImageID] = useState(null);
  //let [tpnGridRowData,setTPNGridRowData]=useState(null)
  let [tpnMappingLines, setTPNMappingLines] = useState(null);
  let [tpnLaneNumber, setTPNLaneNumber] = useState(null);
  let [tpnRequested, setTPNRequested] = useState(false);
  let [showTPNSignal, setShowTPNSignal] = useState(false);
  let [showBoxSignals, setShowBoxSignals] = useState(true);

  let imageFilters = {
    0: {
      br: brightnessValue0,
      inv: invertImageValue0,
      con: contrastValue0,
      fb: fb0,
      fll: fll0,
      rb: rb0,
      sat: sat0,
      gb: gb0,
    },
    1: {
      br: brightnessValue1,
      inv: invertImageValue1,
      con: contrastValue1,
      fb: fb1,
      fll: fll1,
      rb: rb1,
      sat: sat1,
      gb: gb1,
    },
  };

  //TPN
  let [tpnPassed, setTPNPassed] = useState(false);
  let [didCurrentUploadChange, setDidCurrentUploadChange] = useState(false);
  let [CJSData, setCJSData] = useState(null);
  let [scatCJSData, setScatCJSData] = useState(defaultScatCJSData);
  let [mwLineChartData, setMWLineChartData] = useState(defaultMWLineChartData);
  let [totalLaneChartData, setTotalLaneChartData] = useState(defaultTotalLaneChartData);
  let [laneCJSData, setLaneCJSData] = useState(null);
  let [laneIndexArray, setLaneIndexArray] = useState([]);

  let bubbleChartRef = useRef(bubbleOptions);
  let lineChartRef = useRef(null);
  let imageDisplayBoxRef = useRef();
  let [chartType, setChartType] = useState("Polar");
  let laneScalar = 1;
  //dataGrid
  let [gridRowData, setGridRowData] = useState(null);

  const apiRef = useGridApiRef();
  const tpnGridAPIRef = useGridApiRef();
  let [changeIconColor, setChangeIconColor] = useState("inherit");
  let [showWhichResults, setShowWhichResults] = useState(true);

  //for object registry image
  let [ORActiveImage, setORActiveImage] = useState(null);
  let [ORImage, setORImage] = useState(null);
  let [RMBounds, setRMBounds] = useState({ v_extent: null, top: null, bottom: null });
  let [ORRegionArray, setORRegionArray] = useState(null);
  let [laneRegionArray, setLaneRegionArray] = useState(null);
  let [displayRegionArray, setDisplayRegionArray] = useState(null);

  let [currentTable, setCurrentTable] = useState(null);
  let [objectAnnots, setObjectAnnots] = useState({
    objectComposition: "undefined",
    proteinStandard: "undefined",
    proteinControl: "undefined",
    userColorTag: "#b32aa9",
    proteinTarget: "",
  });
  const [proteinList, setProteinList] = useState(null);
  const [analyzingMW, setAnalyzingMW] = useState(false);
  let [loadingPriorAnalysis, setLoadingPriorAnalysis] = useState({ image0: false, image1: false });
  let [uniqueUserBandTags, setUniqueUserBandTags] = useState([]);
  let [uniqueUserLysateTags, setUniqueUserLysateTags] = useState([]);
  const [lysateList, setLysateList] = useState(["HEK293", "HeLa", "MCF-7", "Jurkat", "HepG2", "K562", "A549", "LNCaP", "MOLT-4", "NIH/3T3"]);
  const [lysateTarget, setLysateTarget] = useState("");
  //for currentAnalysis
  let [image0AnalysisStatus, setImage0AnalysisStatus] = useState(null);
  let [image1AnalysisStatus, setImage1AnalysisStatus] = useState(null);
  let [analysisStatusColor0, setAnalysisStatusColor0] = useState("grey");
  let [analysisStatusColor1, setAnalysisStatusColor1] = useState("grey");
  let [analyticResponse0, setAnalyticResponse0] = useState(null);
  let [analyticResponse1, setAnalyticResponse1] = useState(null);
  let [readyToViewCurrent0, setReadyToViewCurrent0] = useState(false);
  let [readyToViewCurrent1, setReadyToViewCurrent1] = useState(false);
  let [viewingAnalysisID, setViewingAnalysisID] = useState(null);
  let [verificationDict, setVerificationDict] = useState(null);

  ////for prior Analyses
  let [indexOfAnalysis, setIndexOfAnalysis] = useState(null);
  let [allPriorAnalysis, setAllPriorAnalysis] = useState(null);
  let [priorAnalysis0, setPriorAnalysis0] = useState(null);
  let [priorAnalysis1, setPriorAnalysis1] = useState(null);
  let [selectedPriorAnalysisID, setSelectedPriorAnalysisID] = useState(null);
  let [selectedPriorAnalysis0, setSelectedPriorAnalysis0] = useState("");
  let [selectedPriorAnalysis1, setSelectedPriorAnalysis1] = useState("");
  let [whichAnalysisDialog, setWhichAnalysisDialog] = useState(false);
  let [testDisableB0, setTestDisableB0] = useState(true);
  let [testDisableB1, setTestDisableB1] = useState(true);
  let [allowedToLeave, setAllowedToLeave] = useState(false);
  /////for analysis store progress

  const onChangeZoom1 = (refWrapper) => {
    setStage1({
      posX: refWrapper.state.positionX,
      posY: refWrapper.state.positionY,
      transform: refWrapper.state.scale,
      scaleX: stage1.scaleX,
      scaleY: stage1.scaleY,
      canvasHeight: stage1.canvasHeight,
      canvasWidth: stage1.canvasWidth,
    });
  };
  //let layerRef = React.useRef(null)
  const stageRef0 = React.useRef();
  const stageRef1 = React.useRef();
  let [naturalWidth0, setNaturalWidth0] = React.useState(0);
  let [naturalHeight0, setNaturalHeight0] = React.useState(0);
  //let [stage0, setStage0] = useState({})
  //let [stage1, setStage1] = useState({})
  let [stage0, setStage0] = useState({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
  let [stage1, setStage1] = useState({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
  let [naturalWidth1, setNaturalWidth1] = React.useState(0);
  let [naturalHeight1, setNaturalHeight1] = React.useState(0);
  let [regions0, setRegions0] = React.useState([]);
  let [boxRegions0, setBoxRegions0] = React.useState([]);
  let [selectRegion0, setSelectRegion0] = React.useState(null);
  let [drawingROI0, setDrawingROI0] = React.useState(false);
  //console.log('screenDims',screenDims)

  let [minimizedDim, setMinimizedDim] = React.useState(null); //React.useState(parseInt(0.086256469 * screenDims.width))// / screenDims.pixelRatio))//org 150
  let [middleDim, setMiddleDim] = React.useState(null); //React.useState(parseInt(0.23001725 * screenDims.width))// / screenDims.pixelRatio))//org 400
  let [maximizedDim, setMaximizedDim] = React.useState(null); //React.useState(parseInt(0.37377803335 * screenDims.width))// / screenDims.pixelRatio)) //org 650
  //let [minimizedDim, setMinimizedDim] = React.useState(parseInt(screenDims.scalarMinDim * screenDims.width * screenDims.pixelRatio))//org 150
  //let [middleDim, setMiddleDim] = React.useState(parseInt(screenDims.scalarMidDim * screenDims.width * screenDims.pixelRatio))//org 400
  //let [maximizedDim, setMaximizedDim] = React.useState(parseInt(screenDims.scalarMaxDim * screenDims.width * screenDims.pixelRatio)) //org 650
  let [styleWidthOfCanvas0, setStyleWidthOfCanvas0] = React.useState("650px");
  let [styleWidthOfCanvas1, setStyleWidthOfCanvas1] = React.useState("650px");
  let [allowInteraction0, setAllowInteraction0] = React.useState(false);
  let [allowInteraction1, setAllowInteraction1] = React.useState(false);
  let [hideImageMods1, setHideImageMods1] = React.useState("block");
  let [hideImageMods0, setHideImageMods0] = React.useState("block");

  const ref0_L0 = useRef("image0_L0");
  const ref0_L1 = useRef("image0_L1");

  let [drawingROI1, setDrawingROI1] = React.useState(false);
  let [regions1, setRegions1] = React.useState([]);
  let [boxRegions1, setBoxRegions1] = React.useState([]);
  let [selectRegion1, setSelectRegion1] = React.useState(null);
  let [openPriorAnalysisDialog, setOpenPriorAnalysisDialog] = useState(false);
  let [openShareAnalysisDialog, setOpenShareAnalysisDialog] = useState(false);
  let [sharingAnalysisID, setSharingAnalysisID] = useState("undefined");
  let [displayGetPrivateID, setDisplayGetPrivateID] = useState("none");
  let [database, setDatabase] = useState("ila-app-data-storage");

  const ref1_L0 = useRef("image1_L0");
  const ref1_L1 = useRef("image1_L1");
  let whatToDisplay = "new";

  const switchAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const switchAccordion2 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const switchAccordion3 = (panel) => (event, isExpanded3) => {
    setExpanded3(isExpanded3 ? panel : false);
  };

  useEffect(() => {
    var allDevUserIDs = devUsers.map((item) => item.userID);
    if (allDevUserIDs.includes(sessionStorage.getItem("userID"))) {
      setDisplayGetPrivateID("block");
    } else {
      setDisplayGetPrivateID("none");
    }

    var params = {
      Bucket: "ila-app-data-storage",
      Key: "referenceFiles/proteinList.json",
    };
    s3.getObject(params, function (err, data) {
      if (err) {
        console.log(err);
      } else {
        //console.log(JSON.parse(data.Body))
        setProteinList(JSON.parse(data.Body)["proteinName"]);
      }
    });
    if (imageDisplayBoxRef.current) {
      setStyleWidthOfCanvas0(String(imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) + "px");
      setStyleWidthOfCanvas1(String(imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) + "px");
      setMiddleDim(imageDisplayBoxRef.current.children[0].offsetWidth * 0.495);
      setMaximizedDim(imageDisplayBoxRef.current.children[0].offsetWidth * 0.75);
      setMinimizedDim(imageDisplayBoxRef.current.children[0].offsetWidth * 0.25);
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: imageDisplayBoxRef.current.children[0].offsetWidth * 0.495,
        canvasHeight: ((imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth0) * naturalHeight0,
        scaleX: (imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth0,
        scaleY: (((imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth0) * naturalHeight0) / naturalHeight0,
      });
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: imageDisplayBoxRef.current.children[0].offsetWidth * 0.495,
        canvasHeight: ((imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth1) * naturalHeight1,
        scaleX: (imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth1,
        scaleY: (((imageDisplayBoxRef.current.children[0].offsetWidth * 0.495) / naturalWidth1) * naturalHeight1) / naturalHeight1,
      });
    } else {
      console.log("the imageDisplayBoxRef did not change");
    }
  }, [widthOfImageRetrieval]);

  function changeImageRetrievalWidth() {
    if (widthOfImageRetrieval === "22%") {
      setWidthOfImageRetrieval("2.5%");
      setWidthOfImageDisplay(String(97 - 20 - 2.5) + "%");
      setImageRetrievalBool(false);
      //resetTPNDialog()
      //setStyleWidthOfCanvas0(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
      //setStyleWidthOfCanvas1(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
      //setMiddleDim(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)
      //setMaximizedDim(imageDisplayBoxRef.current.children[0].offsetWidth*0.75)
      //setMinimizedDim(imageDisplayBoxRef.current.children[0].offsetWidth*0.25)
      //console.log(imageDisplayBoxRef.current.offsetWidth)
    } else {
      setWidthOfImageRetrieval("22%");
      setWidthOfImageDisplay(String(97 - 20 - 22) + "%");
      setImageRetrievalBool(true);
      //resetTPNDialog()
      //setStyleWidthOfCanvas0(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
      //setStyleWidthOfCanvas1(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
      //setMiddleDim(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)
      //setMaximizedDim(imageDisplayBoxRef.current.children[0].offsetWidth*0.75)
      //setMinimizedDim(imageDisplayBoxRef.current.children[0].offsetWidth*0.25)

      //console.log(styleWidthOfCanvas0)
      //console.log(imageDisplayBoxRef.current.offsetWidth)
    }
  }

  function swapBoxSignals() {
    setShowBoxSignals(!showBoxSignals);
    changeChartJSData(apiRef.current.getVisibleRowModels(), "updateChartFromTable", yAxisChecks.mw, relativeChecks.relativeToBand, showTPNSignal, !showBoxSignals);
    updateBubbleChartRef(yAxisChecks.mw, relativeChecks.relativeToBand, showTPNSignal, !showBoxSignals);
  }

  function UpdateORImage() {
    const [image] = useImage(ORImage);
    //var testWidth = 650
    return (
      <Image image={image} width={ORActiveImage === "image0" ? naturalWidth0 : naturalWidth1} height={ORActiveImage === "image0" ? naturalHeight0 : naturalHeight1} />
      //<Image image={image} width={ORActiveImage==='image0' ? testWidth : testWidth} height={ORActiveImage==='image0' ? (testWidth/naturalWidth0)*naturalHeight0 : (testWidth/naturalWidth1)*naturalHeight1} />
    );
  }

  function queryForPrivateFile(imageID) {
    //console.log('privateFile')
    if (imageID !== null) {
      queryTableWithSingleImageID(imageID).then(function (response) {
        setOpenBackdrop(false);
        var familyIDs = [response[0]["Items"][0]["familyID"]["S"]];
        var fileDict = [response[0]["Items"][0]];
        //console.log('uniqueFamilyIDs',familyIDs)
        //console.log('fileDict',fileDict)
        setRecentUpload(consolidateFamilies([...new Set(familyIDs)], fileDict));
      });
    } else {
      setOpenBackdrop(false);
      alert("you need to either copy an image id into the input, or refresh the page and try again");
    }
  }

  function consolidateFamilies(uniqueIDs, fileDict) {
    //console.log('am i running this consolidateFamilies function')
    /// I do not need to modify this for the new mcb data ila call

    //console.log('uniqueFamilyIDs',uniqueIDs)
    //console.log('fileDict',fileDict)
    var rowArray = [];
    var mlStat = 0;
    var finalCount = 4;
    //console.log(fileDict)
    for (var i = 0; i < uniqueIDs.length; i++) {
      rowArray[i] = {};
      rowArray[i]["uploadGroup"] = [];
      rowArray[i]["familyID"] = uniqueIDs[i];
      rowArray[i]["status"] = [];
      for (var j = 0; j < Object.keys(fileDict).length; j++) {
        if (String(fileDict[j]["familyID"]["S"]) === String(uniqueIDs[i])) {
          mlStat = 0;
          var cd = fileDict[j]["uploadDate"]["N"];
          rowArray[i]["uploadDate"] = String(cd.slice(4, 6) + "-" + cd.slice(6, 8) + "-" + cd.slice(0, 4));
          rowArray[i]["fullUploadDate"] = cd;
          rowArray[i]["uploadComment"] = fileDict[j]["uploadComment"]["S"];
          var group = {};
          group["fileName"] = fileDict[j]["fileName"]["S"];
          group["status"] = fileDict[j]["pngRawStatus"]["S"];
          group["imageClass"] = fileDict[j]["imageClass"]["S"];
          group["imageID"] = fileDict[j]["imageID"]["S"];
          if (fileDict[j].hasOwnProperty("TPNRequested")) {
            group["TPNRequested"] = fileDict[j]["TPNRequested"]["S"];
            if (fileDict[j]["TPNRequested"]["S"] === "true") {
              group["TPNLane"] = fileDict[j]["TPNLane"]["N"];
              group["TPNImageID"] = fileDict[j]["TPNImageID"]["S"];
              if (fileDict[j]["TPNAnalysisStatus"]["L"][0]["S"] === "complete") {
                group["TPNAnalysisStatus"] = "done";
                group["TPNAnalysisID"] = fileDict[j]["TPNAnalysisIDs"]["L"][0]["S"];
                group["TPNLaneMapping"] = fileDict[j]["TPNLaneMapping"]["M"];
                group["TPNStatusColor"] = "#399dbc";
                //setTPNPassed(true)
              } else if (fileDict[j]["TPNAnalysisStatus"]["L"][0]["S"] === "failed") {
                group["TPNAnalysisStatus"] = "failed";
                group["TPNStatusColor"] = "red";
                group["TPNAnalysisID"] = "na";
                group["TPNLaneMapping"] = "na";
                //setTPNPassed(false)
              } else if (fileDict[j]["TPNAnalysisStatus"]["L"][0]["S"] === "running") {
                group["TPNStatusColor"] = "orange";
                group["TPNAnalysisStatus"] = "running";
                group["TPNAnalysisID"] = "na";
                group["TPNLaneMapping"] = "na";
                //setTPNPassed(false)
              } else {
                group["TPNStatusColor"] = "orange";
                group["TPNAnalysisStatus"] = "pending";
                group["TPNAnalysisID"] = "na";
                group["TPNLaneMapping"] = "na";
              }
            } else {
              group["TPNAnalysisStatus"] = "na";
              group["TPNStatusColor"] = "#399dbc";
              group["TPNLane"] = "na";
              group["TPNImageID"] = "na";
              group["TPNAnalysisID"] = "na";
              group["TPNLaneMapping"] = "na";
              //setTPNPassed(false)
            }
          }
          if (fileDict[j]["pngRawStatus"]["S"] === "Pending") {
            group["statusColor"] = "orange";
          } else {
            group["statusColor"] = "#399dbc"; //'green'
          }

          if (fileDict[j]["mlClassPrediction"]["S"] !== "Pending") {
            //console.log('class mlstat added',fileDict[j]['uploadComment'])
            mlStat += 1;
            if (parseFloat(fileDict[j]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"]) > parseFloat(fileDict[j]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"])) {
              group["mlClass"] = "membrane";
              group["mlConf"] = fileDict[j]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"];
            } else {
              group["mlClass"] = "gel";
              group["mlConf"] = fileDict[j]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"];
            }
          }

          if (fileDict[j]["laneFindKey_8bit"]["S"] !== "Pending") {
            //console.log('lysate mlstat added',fileDict[j]['uploadComment'])
            mlStat += 1;
          }

          if (fileDict[j]["bandFindKey_8bit"]["S"] !== "Pending") {
            //console.log('band mlstat added',fileDict[j]['uploadComment'])
            mlStat += 1;
          }
          if (fileDict[j]["bgRemovedKey_8bit"]["S"] !== "Pending") {
            //console.log('bgr mlstat added',fileDict[j]['uploadComment'])
            mlStat += 1;
          }
          if (fileDict[j].hasOwnProperty("gelFindKey_8bit")) {
            finalCount = 5;
            if (fileDict[j]["gelFindKey_8bit"]["S"] !== "Pending") {
              //console.log('border mlstat added',fileDict[j]['uploadComment'])
              mlStat += 1;
            }
          }
          group["allMLStatus"] = String(mlStat) + "/" + String(finalCount);
          if (mlStat / finalCount !== 1) {
            //if(group['allMLStatus']!=='5/5'){
            group["allMLStatusColor"] = "orange";
            rowArray[i]["status"].push(1);
          } else {
            group["allMLStatusColor"] = "#399dbc"; //'green'
            rowArray[i]["status"].push(0);
          }

          rowArray[i]["uploadGroup"].push(group);
        }
      }
    }
    return rowArray;
  }

  function queryForRecentFiles() {
    let uniqueFamilyIDs = [];
    var fullResponse = [];
    var theRange = whatIsTheRange(theDateRange);
    var filterExp;
    //var expFilter
    //var expValues
    var queryDBParams;
    //if (database!=='ila-app-data-storage'){
    queryDBParams = {
      IndexName: "userID-index",
      ExpressionAttributeValues: {
        ":id": { S: sessionStorage.getItem("userID") },
        ":startD": { N: String(theRange[0]) },
        ":endD": { N: String(theRange[1]) },
        ":idb": { S: database },
      },
      ExpressionAttributeNames: {
        "#ud": "uploadDate",
        "#idb": "imageDataBucket",
      },
      KeyConditionExpression: "userID = :id",
      FilterExpression: "#ud >= :startD and #ud <= :endD and #idb = :idb", // Replace 'attribute_name' with the actual attribute name you want to check
      TableName: "ila-rawImage-table",
    };
    //}else{
    //  queryDBParams = {
    //    IndexName: 'userID-index',
    //   ExpressionAttributeValues: {
    //        ':id': { S: sessionStorage.getItem('userID') },
    //        ':startD': { N: String(theRange[0]) },
    //        ':endD': { N: String(theRange[1]) }
    //    },
    //    ExpressionAttributeNames: {
    //        '#ud': 'uploadDate'
    //    },
    //   KeyConditionExpression: "userID = :id",
    //    FilterExpression: "#ud >= :startD and #ud <= :endD and attribute_not_exists(imageDataBucket)", // Replace 'attribute_name' with the actual attribute name you want to check
    //    TableName: 'ila-rawImage-table'
    //  }
    //}

    //console.log(queryDBParams)

    function getRecentFiles(err, data) {
      if (err) {
        console.log(err);
        setOpenBackdrop(false);
      } else {
        for (var i = 0; i < data.Items.length; i++) {
          fullResponse.push(data.Items[i]);
          try {
            uniqueFamilyIDs.push(data.Items[i].familyID.S);
          } catch (err) {
            uniqueFamilyIDs.push("undefined");
          }
        }
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("Scanning for more...");
          queryDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.query(queryDBParams, getRecentFiles);
        } else {
          if (fullResponse.length === 0) {
            setOpenBackdrop(false);
            setDialogs({ noFilesAlertDialog: true });
            setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
          } else {
            //console.log(fullResponse)
            setOpenBackdrop(false);
            setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
          }
        }
      }
    }

    dynamodb.query(queryDBParams, getRecentFiles);
  }

  function scanForRecentFiles() {
    let d = new Date(Date.now());
    var fullResponse = [];
    let uniqueFamilyIDs = [];
    let theExpressionDateFilter = String(td(d).year) + String(td(d).month) + String(td(d).day) + String(td(d).hour) + String(td(d).minute) + String(td(d).second);
    //console.log('recent prior date',theExpressionDateFilter)
    let scanDBParams = {
      TableName: "ila-rawImage-table",
      AttributesToGet: [
        "familyID",
        "uploadDate",
        "uploadComment",
        "fileName",
        "pngRawStatus",
        "imageClass",
        "imageID",
        "mlClassPrediction",
        "laneFindKey_8bit",
        "bandFindKey_8bit",
        "bgRemovedKey_8bit",
        "gelFindKey_8bit",
      ],
      ScanFilter: {
        userName: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: sessionStorage.getItem("username") }],
        },
        uploadDate: {
          ComparisonOperator: "GE",
          AttributeValueList: [{ N: theExpressionDateFilter }],
        },
      },
    };

    dynamodb.scan(scanDBParams, getRecentFiles);
    function td(d) {
      var theReturnDate = {};
      var theMonth = d.getMonth(); //+1
      var theDay = d.getDate();
      var theHour = d.getHours();
      var theMinute = d.getMinutes();
      var theSecond = d.getSeconds();
      var theYear = d.getFullYear();
      if (theMonth === 0) {
        theReturnDate["month"] = "12";
        theReturnDate["year"] = theYear - 1;
      } else if (theMonth > 0 && theMonth < 10) {
        theReturnDate["month"] = "0" + String(theMonth);
        theReturnDate["year"] = theYear;
      } else {
        theReturnDate["month"] = String(theMonth);
        theReturnDate["year"] = theYear;
      }

      if (theDay < 10) {
        theReturnDate["day"] = "0" + String(theDay);
      } else {
        theReturnDate["day"] = String(theDay);
      }
      if (theHour < 10) {
        theReturnDate["hour"] = "0" + String(theHour);
      } else {
        theReturnDate["hour"] = String(theHour);
      }
      if (theMinute < 10) {
        theReturnDate["minute"] = "0" + String(theMinute);
      } else {
        theReturnDate["minute"] = String(theMinute);
      }
      if (theSecond < 10) {
        theReturnDate["second"] = "0" + String(theSecond);
      } else {
        theReturnDate["second"] = String(theSecond);
      }
      return theReturnDate;
    }

    function getRecentFiles(err, data) {
      if (err) {
        console.log(err);
      } else {
        for (var i = 0; i < data.Items.length; i++) {
          fullResponse.push(data.Items[i]);
          try {
            uniqueFamilyIDs.push(data.Items[i].familyID.S);
          } catch (err) {
            uniqueFamilyIDs.push("undefined");
          }
        }
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("Scanning for more...");
          scanDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.scan(scanDBParams, getRecentFiles);
        } else {
          //console.log('the response from update',fullResponse)
          if (fullResponse.length === 0) {
            //setLinearProgressIndicator(false)
            setOpenBackdrop(false);
            //setNoFilesAlertDialog(true)
            setDialogs({ noFilesAlertDialog: true });
            setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
          } else {
            //setLinearProgressIndicator(false)
            setOpenBackdrop(false);
            setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
          }
        }
      }
    }

    function consolidateFamilies(uniqueIDs, fileDict) {
      console.log("am i running this consolidate");
      var rowArray = [];
      var mlStat = 0;
      var finalCount = 4;
      for (var i = 0; i < uniqueIDs.length; i++) {
        rowArray[i] = {};
        rowArray[i]["uploadGroup"] = [];
        rowArray[i]["familyID"] = uniqueIDs[i];

        for (var j = 0; j < Object.keys(fileDict).length; j++) {
          if (String(fileDict[j]["familyID"]["S"]) === String(uniqueIDs[i])) {
            mlStat = 0;
            var cd = fileDict[j]["uploadDate"]["N"];
            rowArray[i]["uploadDate"] = String(cd.slice(4, 6) + "-" + cd.slice(6, 8) + "-" + cd.slice(0, 4));
            rowArray[i]["fullUploadDate"] = cd;
            rowArray[i]["uploadComment"] = fileDict[j]["uploadComment"]["S"];
            var group = {};
            group["fileName"] = fileDict[j]["fileName"]["S"];
            group["status"] = fileDict[j]["pngRawStatus"]["S"];
            group["imageClass"] = fileDict[j]["imageClass"]["S"];
            group["imageID"] = fileDict[j]["imageID"]["S"];
            if (fileDict[j]["pngRawStatus"]["S"] === "Pending") {
              group["statusColor"] = "orange";
            } else {
              group["statusColor"] = "#399dbc"; //'green'
            }

            if (fileDict[j]["mlClassPrediction"]["S"] !== "Pending") {
              mlStat += 1;
              if (parseFloat(fileDict[j]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"]) > parseFloat(fileDict[j]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"])) {
                group["mlClass"] = "membrane";
                group["mlConf"] = fileDict[j]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"];
              } else {
                group["mlClass"] = "gel";
                group["mlConf"] = fileDict[j]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"];
              }
            }

            if (fileDict[j]["laneFindKey_8bit"]["S"] !== "Pending") {
              mlStat += 1;
            }

            if (fileDict[j]["bandFindKey_8bit"]["S"] !== "Pending") {
              mlStat += 1;
            }
            if (fileDict[j]["bgRemovedKey_8bit"]["S"] !== "Pending") {
              mlStat += 1;
            }
            if (fileDict[j].hasOwnProperty("gelFindKey_8bit")) {
              finalCount = 5;
              if (fileDict[j]["gelFindKey_8bit"]["S"] !== "Pending") {
                mlStat += 1;
              }
            }
            group["allMLStatus"] = String(mlStat) + "/" + String(finalCount);
            if (mlStat / finalCount !== 1) {
              //if(group['allMLStatus']!=='5/5'){
              group["allMLStatusColor"] = "orange";
            } else {
              group["allMLStatusColor"] = "#399dbc"; //'green'
            }

            rowArray[i]["uploadGroup"].push(group);
          }
        }
      }
      return rowArray;
    }
  }

  function updateInfoPanel(IDForInfo) {
    //console.log('kaboom',IDForInfo)
    var params = {
      ExpressionAttributeValues: {
        ":id1": { S: IDForInfo },
      },
      KeyConditionExpression: "imageID = :id1",
      TableName: "ila-scnTag-table",
    };
    dynamodb.query(params, function (err, data) {
      if (err) {
        console.log(err);
      } else {
        //console.log('SCN QUERY',data.Items[0])
        setTheImageInfo(data.Items[0]);
      }
    });
  }
  const refreshAllAnalysis = () => {
    setIndexOfAnalysis(null);
    setMinimizedDim(null);
    setMiddleDim(null);
    setMaximizedDim(null);
    setNaturalWidth0(0);
    setNaturalHeight0(0);
    setNaturalWidth1(0);
    setNaturalHeight1(0);
    setStyleWidthOfCanvas0("650px");
    setStyleWidthOfCanvas1("650px");
    setStage0({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
    setStage1({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
    setVisibleLaneArray({});
    setManualAnalysisBool(false);
    setTPNLaneArrays(null);
    setTPNMappingLines(null);
    setDefaultTPNData(null);
    setTPNMapping(null);
    setShowTPNSignal(false);
    setResetTPNMapping(null);
    //setTPNGridRowData(null)
    setTPNImageID(null);
    setTPNLaneNumber(null);
    setTPNRequested(false);
    setDisplaySelectionColor("default");
    setUploadIconColor("default");
    setTestDisableB0(true);
    setTestDisableB1(true);
    setAllowedToLeave(false);
    setObjectAnnots({
      objectComposition: "undefined",
      proteinStandard: "undefined",
      proteinTarget: "",
      proteinControl: "undefined",
      userColorTag: "#b32aa9",
    });
    setAnalyzingMW(false);
    setCurrentTable(null);
    //stage0.scaleX = null
    //stage0.scaleY = null
    //stage0.posX = 0
    //stage0.posY = 0
    //stage0.transform = 1
    //setStage0({ stage0 })

    //stage1.scaleX = null
    //stage1.scaleY = null
    //stage1.posX = 0
    //stage1.posY = 0
    //stage1.transform = 1
    //setStage1(stage1)
    setTPNPassed(false);
    setDidCurrentUploadChange(false);
    setLinearProgressIndicator(false);
    setGridRowData(null);
    setCJSData(null);
    setScatCJSData(defaultScatCJSData);
    setMWLineChartData(defaultMWLineChartData);
    setTotalLaneChartData(defaultTotalLaneChartData);
    setAllPriorAnalysis(null);
    setPriorAnalysis0(null);
    setPriorAnalysis1(null);
    setSelectedPriorAnalysis0("");
    setSelectedPriorAnalysis1("");
    setSelectedPriorAnalysisID(null);
    setAddRemoveCheck(false);
    setCurrentUpload(null);
    setTheImageInfo(null);
    setfb0(false);
    setfb1(false);
    setfll0(false);
    setfll1(false);
    setActiveRegions([]);
    setActiveLysateRegions1([]);
    setActiveBandRegions1([]);
    setActiveLysateRegions0([]);
    setActiveBandRegions0([]);
    setActiveBandBoxRegions0([]);
    setActiveBandBoxRegions1([]);
    setActiveGelBlotRegions0([]);
    setActiveGelBlotRegions1([]);
    setActiveSatRegions0([]);
    setActiveSatRegions1([]);
    //setActiveImage(null)
    setActiveROI(null);
    setORActiveImage(null);
    setActiveImage(null);
    setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
    setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
    setYAxisChecks({ mw: false, vertPos: true });
    setRMBounds({ v_extent: null, top: null, bottom: null });
    setORRegionArray(null);
    setLaneRegionArray(null);
    setLaneIndexArray([]);
    setDisplayRegionArray(null);
    setExpanded3(false);
    setExpanded2(false);
    setExpanded(false);
    setORImage(null);
    setRegions0([]);
    setRegions1([]);
    setBoxRegions0([]);
    setBoxRegions1([]);
    setTheImageInfo(null);
    setImagesForShow(null);
    setImageIDsForShow(null);
    setImage0AnalysisStatus(null);
    setImage1AnalysisStatus(null);
    setAnalysisStatusColor0("grey");
    setAnalysisStatusColor1("grey");
    setAnalyticResponse0(null);
    setAnalyticResponse1(null);
    setViewingAnalysisID(null);
    setUniqueUserBandTags([]);
    setUniqueUserLysateTags([]);
    setReadyToViewCurrent0(false);
    setReadyToViewCurrent1(false);
    setVerificationDict(null);
    //console.log('hey')
    changeInputSharedID(null);
    setInputSharedID(null);
    setSharingAnalysisID("undefined");
  };

  const shareImage = (image) => {
    setActiveImage(image);
    updateImageInfo(image);
  };

  const saveImage = (image) => {
    setActiveImage(image);
    updateImageInfo(image);
    downloadURI(stageRef0.current.toDataURL(), `${image.charAt(0).toUpperCase() + image.slice(1)}.png`);
  };

  function handleDownload(imageUrl, type) {
    //console.log(imageUrl)
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", type === "bgr" ? "image_bgr.png" : "image.png");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  const handleChange = (prop) => (event, value) => {
    var theSwapIndex;
    var visibleIndexArray;
    var labelArray;

    switch (prop) {
      default:
        break;
      case "saveImage0":
        handleDownload(selectedBGRURL[0], "bgr");
        handleDownload(selectedURL[0], "image");
        break;
      case "saveImage1":
        handleDownload(selectedBGRURL[1], "bgr");
        handleDownload(selectedURL[1], "image");
        break;
      case "changeDateRange":
        //console.log(value)
        setTheDateRange([null, null]);
        break;
      case "getDateRangeValue":
        console.log(value);
        break;
      case "syncPrivateDownload":
        refreshAllAnalysis();
        setOpenBackdrop(true);
        queryForPrivateFile(inputSharedID);

        break;
      case "syncRecentStatusTable":
        //console.log(whatIsTheRange(theDateRange))

        setOpenBackdrop(true);
        //setLinearProgressIndicator(true)

        queryForRecentFiles();
        //scanForRecentFiles()
        break;
      case "closeConfirmMLClassDialog":
        setDialogs({ confirmMLClassDialog: false });
        break;
      case "closeToManyDialog":
        setDialogs({ toManyAlertDialog: false });
        break;
      case "closeGBBDialog":
        setDialogs({ gelBlotBorderDialog: false });
        break;
      case "closenoAnaSelectDialog":
        setDialogs({ noAnaSelectDialog: false });
        break;
      case "changeBrightness1":
        setBrightnessValue1(value);
        setActiveImage("image1");
        updateImageInfo("image1");
        break;
      case "invertImage1":
        setActiveImage("image1");
        updateImageInfo("image1");
        if (invertImageValue1 === 0) {
          setInvertImageValue1(100);
        } else {
          setInvertImageValue1(0);
        }
        break;
      case "fb1":
        setHighlightColor("grey");
        setActiveImage("image1");
        updateImageInfo("image1");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);
        if (fb1 === false) {
          setAllActiveDims("image1");
          setfll1(false);
          setgb1(false);
          setsat1(false);
          setActiveROI("bands");
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
          setExpanded("roisHeader");
        } else {
          setAllDeactiveDims("image1");
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
          setExpanded(false);
          setActiveROI(null);
        }
        setfb1(value);
        break;
      case "sat0":
        setActiveImage("image0");
        updateImageInfo("image0");
        setHighlightColor("grey");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[0]);
        if (sat0 === false) {
          setAllActiveDims("image0");
          setfb0(false);
          setfll0(false);
          setgb0(false);
          setActiveROI("saturatedPixels");
          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["saturationROIs"], "saturatedPixels", "red", theSwapIndex);
        } else {
          setAllDeactiveDims("image0");
          setActiveROI(null);
          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["saturationROIs"], "saturatedPixels", "red", theSwapIndex);
          setExpanded(false);
        }
        setsat0(value);
        break;
      case "sat1":
        setActiveImage("image1");
        updateImageInfo("image1");
        setHighlightColor("grey");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);
        if (sat1 === false) {
          setAllActiveDims("image1");
          setfll1(false);
          setgb1(false);
          setfb1(false);
          setActiveROI("saturatedPixels");
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["saturationROIs"], "saturatedPixels", "red", theSwapIndex);
        } else {
          setAllDeactiveDims("image1");
          setExpanded(false);
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["saturationROIs"], "saturatedPixels", "red", theSwapIndex);
          setActiveROI(null);
        }
        setsat1(value);
        break;
      case "gb0":
        setActiveImage("image0");
        updateImageInfo("image0");
        setHighlightColor("grey");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[0]);
        //console.log(gb0)
        if (gb0 === false) {
          setAllActiveDims("image0");
          setfb0(false);
          setfll0(false);
          setsat0(false);
          setActiveROI("gbBorder");
          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc", theSwapIndex);
          setExpanded("roisHeader");
        } else {
          setAllDeactiveDims("image0");
          setActiveROI(null);
          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc", theSwapIndex);
          setExpanded(false);
        }
        setgb0(value);
        break;

      case "gb1":
        setActiveImage("image1");
        updateImageInfo("image1");
        setHighlightColor("grey");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);
        if (gb1 === false) {
          setAllActiveDims("image1");
          setfb1(false);
          setfll1(false);
          setsat1(false);
          setActiveROI("gbBorder");
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc", theSwapIndex);
          setExpanded("roisHeader");
        } else {
          setAllDeactiveDims("image1");
          setActiveROI(null);
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc", theSwapIndex);
          setExpanded(false);
        }
        setgb1(value);

        break;
      case "fll0":
        setHighlightColor("grey");
        setActiveImage("image0");
        updateImageInfo("image0");
        setSelectRegion0(null);
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[0]);
        if (fll0 === false) {
          setAllActiveDims("image0");
          setfb0(false);
          setgb0(false);
          setsat0(false);
          setActiveROI("lysate");

          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203", theSwapIndex);
          setExpanded("roisHeader");
        } else {
          setAllDeactiveDims("image0");
          setActiveROI(null);
          setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203", theSwapIndex);
          setExpanded(false);
        }
        setfll0(value);
        break;
      case "fll1":
        setHighlightColor("grey");
        setActiveImage("image1");
        updateImageInfo("image1");
        //setSelectRegion1(null)
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);
        if (fll1 === false) {
          setAllActiveDims("image1");
          setActiveROI("lysate");
          setfb1(false);
          setgb1(false);
          setsat1(false);
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203", theSwapIndex);
          setExpanded("roisHeader");
        } else {
          setAllDeactiveDims("image1");
          setActiveROI(null);
          setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203", theSwapIndex);
          setExpanded(false);
        }
        setfll1(value);
        break;

      case "toggleAxisToVertPos":
        if (relativeChecks.relativeToBand === false && relativeChecks.relativeToLane === false) {
          setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
          changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleAxisToVertPos", false, true, showTPNSignal, showBoxSignals);
          updateBubbleChartRef(false, true, showTPNSignal, showBoxSignals);
        } else {
          changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleAxisToVertPos", false, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals);
          updateBubbleChartRef(false, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals);
        }
        updateLineChartRef(false, laneCJSData, laneChecks);
        setYAxisChecks({ mw: false, vertPos: true });
        break;
      case "showImageInfoImage 0":
        setActiveImage("image0");
        updateImageInfo("image0");
        //console.log('set image info in side panel')
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[0]);
        //console.log(currentUpload[theSwapIndex]['scnTags'])
        if (currentUpload[theSwapIndex]["scnTags"] === "true") {
          updateInfoPanel(currentUpload[theSwapIndex]["imageID"]);
          setExpanded("imageHeader");
        }
        break;
      case "showImageInfoImage 1":
        setActiveImage("image1");
        updateImageInfo("image1");
        theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);
        //console.log(currentUpload[theSwapIndex]['scnTags'])
        if (currentUpload[theSwapIndex]["scnTags"] === "true") {
          updateInfoPanel(currentUpload[theSwapIndex]["imageID"]);
          setExpanded("imageHeader");
        }
        //console.log('set image info in side panel')
        break;
      case "addRemoveAllCheckBox":
        setAddRemoveCheck(!addRemoveCheck);
        if (value) {
          addRemoveAll("remove");
        } else {
          addRemoveAll("add");
        }
        break;
      case "switchResult":
        createChartJSData(value, false, showTPNSignal);
        break;
      case "changeChartType":
        setChartType(event.target.value);
        //console.log(event.target.value)
        //createChartJSData(showWhichResults)
        break;
      case "viewPriorAnalysis0":
        //console.log(event.target.value)
        if (event.target.value === "None") {
          //console.log('am i setting this to null')
          setRetrieveVisState({ image0: "hidden", image1: retrieveVisState.image1 });
          setSelectedPriorAnalysisID(event.target.value);
          setSelectedPriorAnalysis0(event.target.value);
          setCJSData(null);
          setScatCJSData(defaultScatCJSData);
          setMWLineChartData(defaultMWLineChartData);
          setTotalLaneChartData(defaultTotalLaneChartData);
          setGridRowData(null);
          setORActiveImage(null);
          setORImage(null);
          setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
          setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
          setYAxisChecks({ mw: false, vertPos: true });
        } else {
          setRetrieveVisState({ image0: "visible", image1: retrieveVisState.image1 });
          setActiveImage("image0");
          setORActiveImage("image0");
          updateImageInfo("image0");
          setSelectedPriorAnalysisID(event.target.value);
          setSelectedPriorAnalysis0(event.target.value);
          setORImage(null);
          setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
          setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
          setYAxisChecks({ mw: false, vertPos: true });
          setLoadingPriorAnalysis({ image0: true, image1: loadingPriorAnalysis.image1 });
          //console.log('tpnRequested',tpnRequested)
          if (tpnRequested) {
            queryTableWithSingleImageID(String(imageIDsForShow[0])).then(function (value) {
              var dataArray = value[0]["Items"][0];
              var analysisIndex = findIndexByString(dataArray["analysisIDs"]["L"], event.target.value);
              setIndexOfAnalysis(analysisIndex);
              //console.log('index of analysis',indexOfAnalysis)
              //console.log('imageID',imageIDsForShow[0])
              getAnalysis(String(imageIDsForShow[0]), value, 0, "viewManualAnalysisWithTPN", analysisIndex);
            });
          } else {
            setIndexOfAnalysis(null);
            getAnalysis(String(imageIDsForShow[0]), event.target.value, 0, "viewPriorAnalysis", null);
          }
        }
        break;
      case "viewPriorAnalysis1":
        if (event.target.value === "None") {
          //console.log('am i setting this to null')
          setRetrieveVisState({ image0: retrieveVisState.image0, image1: "hidden" });
          setSelectedPriorAnalysisID(event.target.value);
          setSelectedPriorAnalysis1(event.target.value);
          setCJSData(null);
          setScatCJSData(defaultScatCJSData);
          setMWLineChartData(defaultMWLineChartData);
          setTotalLaneChartData(defaultTotalLaneChartData);
          setGridRowData(null);
          setORActiveImage(null);
          setORImage(null);
          setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
          setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
          setYAxisChecks({ mw: false, vertPos: true });
        } else {
          setRetrieveVisState({ image0: retrieveVisState.image0, image1: "visible" });
          setActiveImage("image1");
          setORActiveImage("image1");
          updateImageInfo("image1");
          setSelectedPriorAnalysisID(event.target.value);
          setSelectedPriorAnalysis1(event.target.value);
          //console.log('tpnRequested',tpnRequested)
          if (tpnRequested) {
            queryTableWithSingleImageID(String(imageIDsForShow[1])).then(function (value) {
              var dataArray = value[0]["Items"][0];
              var analysisIndex = findIndexByString(dataArray["analysisIDs"]["L"], event.target.value);
              setIndexOfAnalysis(analysisIndex);
              //console.log('index of analysis',indexOfAnalysis)
              //console.log('imageID',imageIDsForShow[1])
              getAnalysis(String(imageIDsForShow[1]), value, 1, "viewManualAnalysisWithTPN", analysisIndex);
            });
          } else {
            setIndexOfAnalysis(null);
            getAnalysis(String(imageIDsForShow[1]), event.target.value, 1, "viewPriorAnalysis", null);
          }
          //getAnalysis(String(imageIDsForShow[1]), event.target.value, 1, 'viewPriorAnalysis',null)
          setORImage(null);
          setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
          setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
          setYAxisChecks({ mw: false, vertPos: true });
          setLoadingPriorAnalysis({ image0: loadingPriorAnalysis.image0, image1: true });
        }
        break;
      // case 'updatePriorAnalysis':
      //   setAllPriorAnalysis(preparePriorAnalysisDictionary(selectedID))
      //   break;
      case "resetIconColor":
        setChangeIconColor("warning");
        break;
      case "changeObjectComposition":
        //setObjectComposition(event.target.value)
        setObjectAnnots({
          objectComposition: event.target.value,
          proteinStandard: objectAnnots.proteinStandard,
          proteinTarget: objectAnnots.proteinTarget,
          proteinControl: objectAnnots.proteinControl,
          userColorTag: objectAnnots.userColorTag,
        });
        setUploadIconColor("warning");
        break;
      case "changeProteinStandardBool":
        //setProteinStandardBool(event.target.value)
        break;
      case "changeProteinStandard":
        //console.log(event.target.value)
        //setProteinStandard(event.target.value)
        setObjectAnnots({
          objectComposition: objectAnnots.objectComposition,
          proteinStandard: event.target.value,
          proteinTarget: objectAnnots.proteinTarget,
          proteinControl: objectAnnots.proteinControl,
          userColorTag: objectAnnots.userColorTag,
        });
        setUploadIconColor("warning");
        break;
      case "changeProteinControl":
        //setProteinControl(event.target.value)
        setObjectAnnots({
          objectComposition: objectAnnots.objectComposition,
          proteinStandard: objectAnnots.proteinStandard,
          proteinTarget: objectAnnots.proteinTarget,
          proteinControl: event.target.value,
          userColorTag: objectAnnots.userColorTag,
        });
        setUploadIconColor("warning");
        break;

      case "viewDefaultAnalysis":
        //console.log(ORActiveImage)
        //console.log('viewingAnalysisID =',viewingAnalysisID)

        if (ORActiveImage === "image0") {
          //console.log('imageID = ',imageIDsForShow[0])
          getAnalysis(imageIDsForShow[0], viewingAnalysisID, 0, "viewDefaultAnalysis", null);
        } else if (ORActiveImage === "image1") {
          //console.log('imageID = ',imageIDsForShow[1])
          getAnalysis(imageIDsForShow[1], viewingAnalysisID, 1, "viewDefaultAnalysis", null);
        }
        break;
      case "viewUserModAnalysis":
        if (ORActiveImage === "image0") {
          getAnalysis(imageIDsForShow[0], viewingAnalysisID, 0, "viewUserModAnalysis", null);
        } else if (ORActiveImage === "image1") {
          //console.log('imageID = ',imageIDsForShow[1])
          getAnalysis(imageIDsForShow[1], viewingAnalysisID, 1, "viewUserModAnalysis", null);
        }
        break;
      case "jellyBeans":
        labelArray = jellyBeanColors;
        visibleIndexArray = getVisibleIndex();
        updateDataTable(visibleIndexArray, labelArray, "setColor", null, null, "jellyBeanTagger");
        setDialogs({ colorDialog: false });
        break;
      case "setColorTags":
        labelArray = [objectAnnots.userColorTag];
        visibleIndexArray = getVisibleIndex();
        updateDataTable(visibleIndexArray, labelArray, "setColor", null, null, "colorTagger");
        //setColorDialog(false)
        setDialogs({ colorDialog: false });
        break;
      case "setLabelTags":
        //console.log(objectComposition,proteinStandard,proteinControl)
        setUploadIconColor("warning");
        //org
        //labelArray = [objectComposition,proteinStandard,proteinControl]
        //new
        labelArray = [objectAnnots.objectComposition, objectAnnots.proteinStandard, objectAnnots.proteinControl];
        visibleIndexArray = getVisibleIndex();
        if (labelArray[1] !== "undefined") {
          setAnalyzingMW(true);
          //in this case the user has chosen a standard and therefore we need to try and calc mw of all proteins
          //setProteinStandardBool(true)
          labelArray.push(true);
          //run lamba invoke update
          var payload = JSON.stringify({ mw: { type: "Buffer", data: mwStandards[objectAnnots.proteinStandard] }, vpos: { type: "Buffer", data: sortVPos() } });
          lambda.invoke(
            {
              FunctionName: "arn:aws:lambda:us-west-2:223634448983:function:ila-mw-lambda",
              Payload: payload,
            },
            function (err, data) {
              if (err) {
                console.log(err);
              } else {
                //console.log('STATUS CODE',data.StatusCode)
                console.log("mw payload", payload);
                if (data.StatusCode === 200) {
                  updateDataTable(visibleIndexArray, labelArray, "setMW", JSON.parse(data.Payload), "doubleExp", "labelTagger");
                  //create mwLineChartData
                  setMWLineChartData(createMWChartDataset(JSON.parse(data.Payload)));
                } else {
                  //console.log('something went wrong, try a poorer performance local regression')
                  updateDataTable(
                    visibleIndexArray,
                    labelArray,
                    "setMW",
                    regression.exponential(fitArray(mwStandards[objectAnnots.proteinStandard], sortVPos()), { order: 2, precision: 5 }).equation,
                    "singleExp",
                    "labelTagger"
                  );
                }
              }
            }
          );
        } else {
          //setProteinStandardBool(false)
          labelArray.push(false);
          //console.log('in correct location')
          updateDataTable(visibleIndexArray, labelArray, "doNotSetMW", null, null, "labelTagger");
          //invoke update with no lambda
        }
        //setLabelDialog(false)
        break;
    }
  };

  function createMWChartDataset(mwFit) {
    //create mwLineChartData
    function appendDataToArray(dataSubsetX, dataSubsetY) {
      var data = [];
      for (var i = 0; i < dataSubsetX.length; i++) {
        data.push({ x: dataSubsetX[i], y: dataSubsetY[i] });
      }
      return data;
    }
    let datasets = new Array(2);
    datasets[0] = {};
    datasets[0]["backgroundColor"] = "cyan";
    datasets[0]["data"] = appendDataToArray(mwFit["xFit"]["data"], mwFit["yFit"]["data"]);
    datasets[0]["label"] = "fit";
    datasets[0]["showLine"] = true;
    datasets[0]["pointRadius"] = 0;
    datasets[0]["borderColor"] = "cyan";
    datasets[0]["borderWidth"] = 0.5;
    datasets[0]["borderDash"] = [3, 3];
    datasets[0]["order"] = 0;

    datasets[1] = {};
    datasets[1]["backgroundColor"] = "white";
    datasets[1]["data"] = appendDataToArray(mwFit["xVals"]["data"], mwFit["yVals"]["data"]);
    datasets[1]["label"] = "standards";
    datasets[1]["pointHoverRadius"] = 8;
    datasets[1]["order"] = 1;

    //console.log('mw payload response',mwFit)
    return { datasets: datasets };
  }

  function updateLineChartRef(mwBoolean, laneCJSData, laneChecks) {
    if (laneCJSData != null && laneChecks.centerLane === true && laneCJSData["datasets"].length <= 8) {
      var xAxisTitle = mwBoolean === false ? "Vertical Position" : "Molecular Weight";
      lineChartRef.current.options = {
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
                mode: "y",
              },
            },
          },
          legend: {
            display: true,
            position: "right",
            align: "center",
          },
          title: {
            color: "white",
            font: {
              weight: 300,
              size: 14,
              family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            },
            display: true,
            text: lineChartTitles.title,
          },
        },
        scales: {
          y: {
            type: "linear",
            grid: {
              color: "#aeaaaa",
              tickLength: 4,
            },
            title: {
              display: true,
              text: lineChartTitles.yAxis,
            },
          },
          x: {
            grid: {
              color: "#aeaaaa",
              tickLength: 4,
            },
            title: {
              display: true,
              text: xAxisTitle,
            },
          },
        },
      };
      lineChartRef.current.update();
    }
  }

  function updateBubbleChartRef(yAxisState, relativeToState, showTPNSignal, showBoxSignals) {
    //console.log(bubbleChartRef)
    //console.log('from update',laneScalar)
    var yAxisTitle = yAxisState === false ? "Vertical Position" : "Molecular Weight";
    var theBubbleScalar = relativeToState === true ? 1 / bubbleScalar : (laneScalar["lgv"] * 100) / bubbleScalar;
    var toReverse = yAxisState === false ? true : false;
    var theScale = yAxisState === false ? "linear" : "logarithmic";
    var theAbundanceTag = relativeToState === true ? "relative to largest band" : "percent of lane";
    var yAxisTag = yAxisState === false ? "vertical pos." : "mw";
    bubbleChartRef.current.options = {
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
              mode: "y",
            },
          },
        },
        legend: {
          display: false,
        },
        title: {
          color: showTPNSignal ? "#83fa1b" : "white",
          font: {
            weight: 300,
            size: 14,
            family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          },
          display: true,
          text: showTPNSignal ? (showBoxSignals ? normalizedBubbleChartTitles.boxTitle : normalizedBubbleChartTitles.title) : showBoxSignals ? bubbleChartTitles.boxTitle : bubbleChartTitles.title,
        },
        tooltip: {
          yAlign: "top",
          borderColor: "#399dbc",
          caretPadding: 20,
          bodyAlign: "left",
          textAlign: "left",
          backgroundColor: "#399dbc",
          bodyColor: "#000",

          callbacks: {
            label: (context) => {
              return [
                ` lane : ${context.raw.x}`,
                ` ${yAxisTag} : ${context.raw.y.toFixed(2)}`,
                ` ${theAbundanceTag} : ${(context.raw.r * theBubbleScalar).toFixed(3)}`,
                ` relative mobility : ${context.raw.rmb === "tbd" ? context.raw.rmb : context.raw.rmb.toFixed(2)}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          type: theScale,
          grid: {
            color: "#aeaaaa",
            tickLength: 4,
          },
          title: {
            display: true,
            text: yAxisTitle,
          },
          reverse: toReverse,
        },
        x: {
          //min:0,
          offset: true,
          grid: {
            color: "#aeaaaa",
            tickLength: 4,
          },
          title: {
            display: true,
            text: bubbleChartTitles.xAxis,
          },
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    };
    bubbleChartRef.current.update();
  }

  function resetDisplayStates() {
    setObjectAnnots({
      objectComposition: "undefined",
      proteinStandard: "undefined",
      proteinTarget: "",
      proteinControl: "undefined",
      userColorTag: "#b32aa9",
    });
    setDisplaySelectionColor("default");
    setUploadIconColor("default");
    setAnalyzingMW(false);
    setCurrentTable(null);
    setCJSData(null);
    setScatCJSData(defaultScatCJSData);
    setMWLineChartData(defaultMWLineChartData);
    setTotalLaneChartData(defaultTotalLaneChartData);
    setAddRemoveCheck(false);
    setfb0(false);
    setfb1(false);
    setfll0(false);
    setfll1(false);
    setActiveRegions([]);
    setActiveLysateRegions1([]);
    setActiveBandRegions1([]);
    setActiveLysateRegions0([]);
    setActiveBandRegions0([]);
    setActiveBandBoxRegions0([]);
    setActiveBandBoxRegions1([]);
    setActiveGelBlotRegions0([]);
    setActiveGelBlotRegions1([]);
    setActiveSatRegions0([]);
    setActiveSatRegions1([]);
    setTheImageInfo(null);
    setActiveImage(null);
    setORRegionArray(null);
    setLaneRegionArray(null);
    setLaneIndexArray([]);
    setDisplayRegionArray(null);
    setActiveROI(null);
    setORActiveImage(null);
    setORImage(null);
    setVisibleLaneArray({});
    setTPNLaneArrays(null);
    setManualAnalysisBool(false);
    setShowTPNSignal(false);
    //setTPNMapping(null)
    setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
    setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
    setYAxisChecks({ mw: false, vertPos: true });
    setExpanded3(false);
    setImage0AnalysisStatus(null);
    setImage1AnalysisStatus(null);
    setAnalysisStatusColor0("grey");
    setAnalysisStatusColor1("grey");
    //setVisibleLaneArray(null)
    setAnalyticResponse0(null);
    setAnalyticResponse1(null);
    setViewingAnalysisID(null);
    setUniqueUserBandTags([]);
    setUniqueUserLysateTags([]);
    setReadyToViewCurrent0(false);
    setReadyToViewCurrent1(false);
    setVerificationDict(checkVerifiedClasses());
    changeInputSharedID(null);
    setInputSharedID(null);
    setSharingAnalysisID("undefined");
  }

  function updateImageInfo(whichImage) {
    var imageIndex;
    if (String(whichImage) === "image0") {
      imageIndex = 0;
    } else {
      imageIndex = 1;
    }
    var theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[imageIndex]);
    //console.log(currentUpload[theSwapIndex]['scnTags'])
    if (currentUpload[theSwapIndex]["scnTags"] === "true") {
      updateInfoPanel(currentUpload[theSwapIndex]["imageID"]);
      //setExpanded('imageHeader')
    } else {
      setTheImageInfo(null);
    }
  }

  function checkVerifiedClasses() {
    var veriDict = [];
    //var testing if this causes the hook to proceed
    function getIndex(index) {
      return currentUpload.findIndex((item) => item.imageID === selectedID[index]);
    }
    for (var i = 0; i < selectedID.length; i++) {
      veriDict[i] = {};
      veriDict[i]["filename"] = currentUpload[getIndex(i)]["fileName"];
      veriDict[i]["imageID"] = selectedID[i];
      veriDict[i]["mlClass"] = currentUpload[getIndex(i)]["mlClass"];
      veriDict[i]["userVerifiedClass"] = currentUpload[getIndex(i)]["userVerifiedClass"];
      if (currentUpload[getIndex(i)]["userVerifiedClass"] === "notVerified") {
        //setConfirmMLClassDialog(true)
        setDialogs({ confirmMLClassDialog: true });
        setAllowedToLeave(false);
        veriDict[i]["disableButton"] = false;
        if (i === 0) {
          setTestDisableB0(false);
        } else if (i === 1) {
          setTestDisableB1(false);
        }
      } else {
        veriDict[i]["disableButton"] = true;
        if (i === 0) {
          setTestDisableB0(true);
        } else if (i === 1) {
          setTestDisableB1(true);
        }
      }
    }
    return veriDict;
  }

  function getVisibleIndex() {
    var indexArray = [];
    for (var i = 0; i < ORRegionArray.length; i++) {
      indexArray.push(ORRegionArray[i]["id"]);
    }
    return indexArray;
  }

  function updateDataTable(visibleIndexArray, labelArray, updateType, fitVars, whichFitFunction, fromWhichTagger) {
    const mwIndex = (num, arr) => {
      let curr = arr[0],
        diff = Math.abs(num - curr);
      let index = 0;
      for (var i = 0; i < arr.length; i++) {
        let newDiff = Math.abs(num - arr[i]);
        if (newDiff < diff) {
          diff = newDiff;
          curr = arr[i];
          index = i;
        }
      }
      return index;
    };
    function calcMW(whichFitFunction, fitVars, currentIndex) {
      //console.log('whichFitFunction',whichFitFunction)
      //console.log('fitVars',fitVars)
      var currentMW;
      if (whichFitFunction === "singleExp") {
        currentMW = fitVars[0] * Math.exp(fitVars[1] * gridRowData[currentIndex]["verticalPos"]);
        gridRowData[currentIndex]["molecularWeight"] = currentMW;
        //labelArray.push(fitVars[0]*Math.exp(fitVars[1]*gridRowData[currentIndex]['verticalPos']))
        //updateAnalyticResponse(visibleIndex,theObject,theImage,labelArray,fromWhichTagger)
      } else if (whichFitFunction === "doubleExp") {
        if (fitVars.hasOwnProperty("errorType")) {
          var donuthing = 0;
        } else {
          currentMW = fitVars["xFit"]["data"][mwIndex(x, fitVars["yFit"]["data"])];
          gridRowData[currentIndex]["molecularWeight"] = currentMW;
        }
      }
      if (currentIndex === gridRowData.length - 1) {
        if (fitVars.hasOwnProperty("errorType")) {
          setAnalyzingMW(false);
          alert("Unable to fit mw to lane.  Screen shot and send this to Clayton.  ERROR = " + JSON.stringify(fitVars));
        } else {
          //console.log('did i just run this')
          setGridRowData(gridRowData);
          setAnalyzingMW(false);
        }
      }
      return currentMW;
    }
    var index;
    if (fromWhichTagger === "targetTagger") {
      for (var k = 0; k < gridRowData.length; k++) {
        index = visibleIndexArray.indexOf(gridRowData[k]["id"].toString());
        if (index !== -1) {
          setGridRowData(updateGridRowData(visibleIndexArray[index], "targetTagger"));
          if (ORActiveImage === "image1" && showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'bands','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image1" && !showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'lysates','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'bands','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image0", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && !showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'lysates','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image0", labelArray, fromWhichTagger);
          }
        }
      }
      return { mwArray: "NA" };
    }
    if (fromWhichTagger === "jellyBeanTagger") {
      //console.log('jellyBeanTagger')
      for (var j = 0; j < gridRowData.length; j++) {
        index = visibleIndexArray.indexOf(gridRowData[j]["id"].toString());
        if (index !== -1) {
          setORRegionArray(updateORRegionArray(fromWhichTagger));
          setDisplayRegionArray(updateORRegionArray(fromWhichTagger));
          setGridRowData(updateGridRowData(visibleIndexArray[index], "jellyBeanTagger"));

          if (ORActiveImage === "image1" && showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'bands','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image1", [labelArray[index]], fromWhichTagger);
          } else if (ORActiveImage === "image1" && !showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'lysates','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image1", [labelArray[index]], fromWhichTagger);
          } else if (ORActiveImage === "image0" && showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'bands','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image0", [labelArray[index]], fromWhichTagger);
          } else if (ORActiveImage === "image0" && !showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'lysates','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image0", [labelArray[index]], fromWhichTagger);
          }
        }
      }
      return { mwArray: "NA" };
    }

    if (fromWhichTagger === "colorTagger") {
      //console.log(visibleIndexArray)
      for (var j = 0; j < gridRowData.length; j++) {
        index = visibleIndexArray.indexOf(gridRowData[j]["id"].toString());
        if (index !== -1) {
          setORRegionArray(updateORRegionArray(fromWhichTagger));
          setDisplayRegionArray(updateORRegionArray(fromWhichTagger));
          setGridRowData(updateGridRowData(visibleIndexArray[index], "colorTagger"));
          if (ORActiveImage === "image1" && showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'bands','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image1" && !showWhichResults) {
            //setAnalyticResponse1(updateAnalyticResponse(visibleIndexArray[index],'lysates','image1',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'bands','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image0", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && !showWhichResults) {
            //setAnalyticResponse0(updateAnalyticResponse(visibleIndexArray[index],'lysates','image0',labelArray,fromWhichTagger))
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image0", labelArray, fromWhichTagger);
          }
        }
      }
      return { mwArray: "NA" };
    }
    if (fromWhichTagger === "labelTagger") {
      var mwArray = [];
      for (var i = 0; i < gridRowData.length; i++) {
        var x = gridRowData[i]["verticalPos"];
        index = visibleIndexArray.indexOf(gridRowData[i]["id"].toString());
        //console.log('index',index)
        if (index !== -1) {
          setGridRowData(updateGridRowData(visibleIndexArray[index], "labelTagger"));
          if (ORActiveImage === "image1" && showWhichResults) {
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image1" && !showWhichResults) {
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image1", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && showWhichResults) {
            updateAnalyticResponse(visibleIndexArray[index], "bands", "image0", labelArray, fromWhichTagger);
          } else if (ORActiveImage === "image0" && !showWhichResults) {
            updateAnalyticResponse(visibleIndexArray[index], "lysates", "image0", labelArray, fromWhichTagger);
          }
        }
        if (showWhichResults) {
          if (updateType === "setMW") {
            if (ORActiveImage === "image1") {
              //console.log(whichFitFunction,fitVars,i)
              mwArray.push(calcMW(whichFitFunction, fitVars, i));
            } else if (ORActiveImage === "image0") {
              mwArray.push(calcMW(whichFitFunction, fitVars, i));
            }
            if (i === gridRowData.length - 1) {
              //console.log(mwArray)
              updateAnalyticResponseJustMW(mwArray);
            }
          }
        }
      }
      return { mwArray: mwArray };
    }
  }

  function updateAnalyticResponseJustMW(mwArray) {
    var i;
    var whichObjects = "lysates";
    if (showWhichResults) {
      whichObjects = "bands";
    }
    if (ORActiveImage === "image1") {
      for (i = 0; i < gridRowData.length; i++) {
        analyticResponse1[whichObjects][gridRowData[i]["id"]]["userTagList"]["mw"] = mwArray[i];
      }
      return setAnalyticResponse1(analyticResponse1);
    } else if (ORActiveImage === "image0") {
      for (i = 0; i < gridRowData.length; i++) {
        analyticResponse0[whichObjects][gridRowData[i]["id"]]["userTagList"]["mw"] = mwArray[i];
      }
      return setAnalyticResponse0(analyticResponse0);
    }
  }
  function updateGridRowData(index, whichTagger) {
    for (var i = 0; i < gridRowData.length; i++) {
      if (gridRowData[i]["id"].toString() === index) {
        if (whichTagger === "targetTagger") {
          gridRowData[i]["target"] = objectAnnots.proteinTarget;
        } else if (whichTagger === "colorTagger") {
          gridRowData[i]["highlightColor"] = objectAnnots.userColorTag;
        } else if (whichTagger === "labelTagger") {
          gridRowData[i]["proteinStandard"] = objectAnnots.proteinStandard;
          gridRowData[i]["composition"] = objectAnnots.objectComposition;
          gridRowData[i]["control"] = objectAnnots.proteinControl;
        } else if (whichTagger === "jellyBeanTagger") {
          gridRowData[i]["highlightColor"] = jellyBeanColors[i];
        }
      }
    }
    return gridRowData;
  }

  function updateORRegionArray(fromWhichTagger) {
    for (var i = 0; i < ORRegionArray.length; i++) {
      ORRegionArray[i]["color"] = fromWhichTagger === "jellyBeanTagger" ? jellyBeanColors[i] : objectAnnots.userColorTag;
      ORRegionArray[i]["fill"] = showBoxSignals ? false : fromWhichTagger === "jellyBeanTagger" ? jellyBeanColors[i] : objectAnnots.userColorTag;
    }
    return ORRegionArray;
  }

  function updateAnalyticResponse_2(indexArray, whichImage, labelArray) {
    if (whichImage === "image0") {
      for (var i = 0; i < indexArray.length; i++) {
        analyticResponse0["bands"][indexArray[i]]["userTagList"]["rmb"] = labelArray[i];
      }
    } else {
      for (var j = 0; j < indexArray.length; j++) {
        analyticResponse1["bands"][indexArray[j]]["userTagList"]["rmb"] = labelArray[j];
      }
    }
    return [whichImage === "image0" ? setAnalyticResponse0(analyticResponse0) : setAnalyticResponse1(analyticResponse1)];
  }

  function updateAnalyticResponse(index, whichObjects, whichImage, labelArray, fromWhichTagger) {
    if (whichImage === "image1" && fromWhichTagger === "rmbTagger") {
      analyticResponse1[whichObjects][index]["rmb"] = labelArray[0];
      return [setAnalyticResponse1(analyticResponse1), setUniqueUserBandTags(createUniqueArray(analyticResponse1, whichObjects, index))];
    } else if (whichImage === "image0" && fromWhichTagger === "rmbTagger") {
      return [setAnalyticResponse0(analyticResponse0), setUniqueUserBandTags(createUniqueArray(analyticResponse0, whichObjects, index))];
    }
    if (whichImage === "image1" && fromWhichTagger === "labelTagger") {
      analyticResponse1[whichObjects][index]["userTagList"]["composition"] = labelArray[0];
      analyticResponse1[whichObjects][index]["userTagList"]["standardType"] = labelArray[1];
      analyticResponse1[whichObjects][index]["userTagList"]["control"] = labelArray[2];
      analyticResponse1[whichObjects][index]["userTagList"]["standardBool"] = labelArray[3];
      if (whichObjects === "bands") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserBandTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserLysateTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      }
    } else if (whichImage === "image0" && fromWhichTagger === "labelTagger") {
      analyticResponse0[whichObjects][index]["userTagList"]["composition"] = labelArray[0];
      analyticResponse0[whichObjects][index]["userTagList"]["standardType"] = labelArray[1];
      analyticResponse0[whichObjects][index]["userTagList"]["control"] = labelArray[2];
      analyticResponse0[whichObjects][index]["userTagList"]["standardBool"] = labelArray[3];
      if (whichObjects === "bands") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserBandTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserLysateTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      }
    } else if (whichImage === "image1" && fromWhichTagger === "colorTagger") {
      analyticResponse1[whichObjects][index]["userTagList"]["displayColor"] = labelArray[0];
      if (whichObjects === "bands") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserBandTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserLysateTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      }
    } else if (whichImage === "image0" && fromWhichTagger === "colorTagger") {
      //console.log(labelArray[0])
      analyticResponse0[whichObjects][index]["userTagList"]["displayColor"] = labelArray[0];
      if (whichObjects === "bands") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserBandTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserLysateTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      }
    } else if (whichImage === "image1" && fromWhichTagger === "jellyBeanTagger") {
      //console.log(labelArray[0])
      analyticResponse1[whichObjects][index]["userTagList"]["displayColor"] = labelArray[0];
      if (whichObjects === "bands") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserBandTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserLysateTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      }
    } else if (whichImage === "image0" && fromWhichTagger === "jellyBeanTagger") {
      //console.log(labelArray[0])
      //console.log(index)
      analyticResponse0[whichObjects][index]["userTagList"]["displayColor"] = labelArray[0];
      if (whichObjects === "bands") {
        //console.log(labelArray[0])
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserBandTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserLysateTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      }
    } else if (whichImage === "image1" && fromWhichTagger === "targetTagger") {
      analyticResponse1[whichObjects][index]["userTagList"]["target"] = labelArray[0];
      if (whichObjects === "bands") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserBandTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse1(analyticResponse1), setUniqueUserLysateTags(createUniqueArray(analyticResponse1, whichObjects, index))];
      }
    } else if (whichImage === "image0" && fromWhichTagger === "targetTagger") {
      analyticResponse0[whichObjects][index]["userTagList"]["target"] = labelArray[0];
      if (whichObjects === "bands") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserBandTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      } else if (whichObjects === "lysates") {
        return [setAnalyticResponse0(analyticResponse0), setUniqueUserLysateTags(createUniqueArray(analyticResponse0, whichObjects, index))];
      }
    }
  }

  function sortVPos() {
    var vPos = [];
    for (var i = 0; i < Object.keys(ORRegionArray).length; i++) {
      //vPos.push(ORRegionArray[i]['verticalPos'])
      vPos.push(ORRegionArray[i]["vpos"]);
    }
    return vPos.sort(function (a, b) {
      return a - b;
    });
  }

  function fitArray(mwOfStandard, svPos) {
    var thePolyArray = [];
    for (var i = 0; i < svPos.length; i++) {
      thePolyArray.push([svPos[i], mwOfStandard[i]]);
    }
    return thePolyArray;
  }

  function setAllDeactiveDims(deactivateImage) {
    if (String(deactivateImage) === "image0") {
      setAllowInteraction0(false);
      setHideImageMods1("block");
      setStyleWidthOfCanvas0(String(middleDim) + "px");
      setStyleWidthOfCanvas1(String(middleDim) + "px");
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: middleDim,
        canvasHeight: (middleDim / naturalWidth0) * naturalHeight0,
        scaleX: middleDim / naturalWidth0,
        scaleY: ((middleDim / naturalWidth0) * naturalHeight0) / naturalHeight0,
      });
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: middleDim,
        canvasHeight: (middleDim / naturalWidth1) * naturalHeight1,
        scaleX: middleDim / naturalWidth1,
        scaleY: ((middleDim / naturalWidth1) * naturalHeight1) / naturalHeight1,
      });
    } else if (String(deactivateImage) === "image1") {
      setAllowInteraction1(false);
      setHideImageMods0("block");
      setStyleWidthOfCanvas0(String(middleDim) + "px");
      setStyleWidthOfCanvas1(String(middleDim) + "px");
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: middleDim,
        canvasHeight: (middleDim / naturalWidth0) * naturalHeight0,
        scaleX: middleDim / naturalWidth0,
        scaleY: ((middleDim / naturalWidth0) * naturalHeight0) / naturalHeight0,
      });
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: middleDim,
        canvasHeight: (middleDim / naturalWidth1) * naturalHeight1,
        scaleX: middleDim / naturalWidth1,
        scaleY: ((middleDim / naturalWidth1) * naturalHeight1) / naturalHeight1,
      });
    }
  }

  function setAllActiveDims(activateImage) {
    //console.log('hey')
    if (String(activateImage) === "image0") {
      setAllowInteraction0(true);
      setHideImageMods1("none");
      setStyleWidthOfCanvas0(String(maximizedDim) + "px");
      setStyleWidthOfCanvas1(String(minimizedDim) + "px");
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: maximizedDim,
        canvasHeight: (maximizedDim / naturalWidth0) * naturalHeight0,
        scaleX: maximizedDim / naturalWidth0,
        scaleY: ((maximizedDim / naturalWidth0) * naturalHeight0) / naturalHeight0,
      });
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: minimizedDim,
        canvasHeight: (minimizedDim / naturalWidth1) * naturalHeight1,
        scaleX: minimizedDim / naturalWidth1,
        scaleY: ((minimizedDim / naturalWidth1) * naturalHeight1) / naturalHeight1,
      });
    } else if (String(activateImage) === "image1") {
      setAllowInteraction1(true);
      setHideImageMods0("none");
      setStyleWidthOfCanvas0(String(minimizedDim) + "px");
      setStyleWidthOfCanvas1(String(maximizedDim) + "px");
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: minimizedDim,
        canvasHeight: (minimizedDim / naturalWidth0) * naturalHeight0,
        scaleX: minimizedDim / naturalWidth0,
        scaleY: ((minimizedDim / naturalWidth0) * naturalHeight0) / naturalHeight0,
      });
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: maximizedDim,
        canvasHeight: (maximizedDim / naturalWidth1) * naturalHeight1,
        scaleX: maximizedDim / naturalWidth1,
        scaleY: ((maximizedDim / naturalWidth1) * naturalHeight1) / naturalHeight1,
      });
    }
  }
  //handleChange functions
  async function updateTPNMapping(mapLanes, gridIDOfMapLanes) {
    if (mapLanes.length > 0) {
      for (var i = 0; i < mapLanes.length; i++) {
        tpnMapping["mapping"]["tpnLaneNumber"][gridIDOfMapLanes[i]] = mapLanes[i];
      }
    }
    return tpnMapping;
  }

  function updateTPNGridRows(tpnMapping) {
    for (var i = 0; i < tpnMapping["mapping"]["tpnLaneNumber"].length; i++) {
      tpnMapping["mapping"]["gridRows"][i]["id"] = i;
      tpnMapping["mapping"]["gridRows"][i]["sfLane"] = tpnMapping["mapping"]["tpnLaneNumber"][i];
      tpnMapping["mapping"]["gridRows"][i]["targetLane"] = tpnMapping["mapping"]["targetLaneNumber"][i];
    }
    return tpnMapping;
  }

  function getMaxLane(response) {
    //console.log(response)
    var maxLane = 0;
    for (var i = 0; i < response["laneProfiles"].length; i++) {
      if (response["laneProfiles"][i]["laneName"] > maxLane) {
        maxLane = response["laneProfiles"][i]["laneName"];
        //console.log(maxLane)
      }
    }
    return maxLane;
  }

  function laneCenter(canvasLaneBox) {
    var xValsTotal = 0;
    var yValsTotal = 0;
    var count = 0;
    for (var i = 0; i < canvasLaneBox.length; i++) {
      xValsTotal += canvasLaneBox[i]["x"];
      yValsTotal += canvasLaneBox[i]["y"];
      count++;
    }
    return [{ x: xValsTotal / count, y: yValsTotal / count }];
  }

  async function prepTPNLanes(currentMapping) {
    var indexOfTPN = currentMapping.mapping.indexOfTPN;
    var laneCanvasRegionArray0 = [];
    var laneCanvasRegionArray1 = [];
    var laneIndexArray0 = [];
    var laneIndexArray1 = [];
    var tpnImage1;
    var tpnImage0;
    var theColor0;
    var theColor1;
    //console.log('do i run this when i click view analysis')
    //console.log('insidePrepTPNLanes:')
    //console.log('analyticResponse0',analyticResponse0)
    //console.log('analyticResponse1',analyticResponse1)
    for (var i = 0; i < analyticResponse0["laneProfiles"].length; i++) {
      laneIndexArray0.push(parseInt(analyticResponse0["laneProfiles"][i]["laneName"]));
      if (indexOfTPN === 0) {
        theColor0 = currentMapping.mapping.tpnNormalizingLane === parseInt(analyticResponse0["laneProfiles"][i]["laneName"]) ? "orange" : analyticResponse0["laneProfiles"][i]["color"];
      } else {
        theColor0 = analyticResponse0["laneProfiles"][i]["color"];
      }
      laneCanvasRegionArray0.push({
        id: "L" + String(i),
        color: theColor0, //analyticResponse0['laneProfiles'][i]['color'],
        points: analyticResponse0["laneProfiles"][i]["points"]["canvasLaneBox"],
        laneName: analyticResponse0["laneProfiles"][i]["laneName"],
        laneCenter: laneCenter(analyticResponse0["laneProfiles"][i]["points"]["canvasLaneBox"]),
        closed: true,
      });
    }
    for (var j = 0; j < analyticResponse1["laneProfiles"].length; j++) {
      laneIndexArray1.push(parseInt(analyticResponse1["laneProfiles"][j]["laneName"]));
      if (indexOfTPN === 1) {
        theColor1 = currentMapping.mapping.tpnNormalizingLane === parseInt(analyticResponse1["laneProfiles"][j]["laneName"]) ? "orange" : analyticResponse1["laneProfiles"][j]["color"];
      } else {
        theColor1 = analyticResponse0["laneProfiles"][j]["color"];
      }
      laneCanvasRegionArray1.push({
        id: "L" + String(j),
        color: theColor1, //analyticResponse1['laneProfiles'][j]['color'],
        points: analyticResponse1["laneProfiles"][j]["points"]["canvasLaneBox"],
        laneName: analyticResponse1["laneProfiles"][j]["laneName"],
        laneCenter: laneCenter(analyticResponse1["laneProfiles"][j]["points"]["canvasLaneBox"]),
        closed: true,
      });
    }

    return { laneIndexArray0: laneIndexArray0, laneCanvasRegionArray0: laneCanvasRegionArray0, laneIndexArray1: laneIndexArray1, laneCanvasRegionArray1: laneCanvasRegionArray1 };
    //return [createTPNMappingLines(laneIndexArray0,laneCanvasRegionArray0,laneIndexArray1,laneCanvasRegionArray1),
    //        setTPNLaneArrays({0:{'imageID':analyticResponse0['imageID'],'laneIndexArray':laneIndexArray0,'laneCanvasRegionArray':laneCanvasRegionArray0},1:{'imageID':analyticResponse1['imageID'],'laneIndexArray':laneIndexArray1,'laneCanvasRegionArray':laneCanvasRegionArray1}})]
    //}
  }

  function createTPNMappingLines(laneIndexArray0, laneCanvasRegionArray0, laneIndexArray1, laneCanvasRegionArray1, currentMapping) {
    //console.log(tpnMapping)
    var indexOfTPN = currentMapping["mapping"]["indexOfTPN"];
    var notIndexOfTPN = currentMapping["mapping"]["notIndexOfTPN"];
    var tpnLaneNumber = currentMapping["mapping"]["tpnLaneNumber"];
    var targetLaneNumber = currentMapping["mapping"]["targetLaneNumber"];
    var currentTPNLane;
    var mappingTargetLane;
    var tpnIndex;
    var targetIndex;
    var tpnLaneCenter;
    var targetLaneCenter;
    var lineArrayPoints = [];
    var tpnXScalar, targetXScalar, targetYOffset;
    var tpnYScalar, targetYScalar;
    for (var i = 0; i < tpnLaneNumber.length; i++) {
      currentTPNLane = tpnLaneNumber[i];
      mappingTargetLane = targetLaneNumber[i];
      if (indexOfTPN === 0) {
        //console.log('i am in the if')
        tpnXScalar = stage0.scaleX * stage0.transform;
        tpnYScalar = stage0.scaleY * stage0.transform;
        targetXScalar = stage1.scaleX * stage1.transform;
        targetYScalar = stage1.scaleY * stage1.transform;
        targetYOffset = stage1.posY + stage0.canvasHeight;
        tpnIndex = laneIndexArray0.indexOf(currentTPNLane);
        targetIndex = laneIndexArray1.indexOf(mappingTargetLane);
        tpnLaneCenter = laneCanvasRegionArray0[tpnIndex]["laneCenter"][0];
        targetLaneCenter = laneCanvasRegionArray1[targetIndex]["laneCenter"][0];
        //console.log(tpnIndex,tpnLaneCenter,targetIndex,targetLaneCenter)
        lineArrayPoints.push([
          parseInt(tpnLaneCenter["x"] * tpnXScalar),
          parseInt(tpnLaneCenter["y"] * tpnYScalar),
          parseInt(targetLaneCenter["x"] * targetXScalar),
          parseInt(targetLaneCenter["y"] * targetYScalar + targetYOffset),
        ]);
      } else {
        //console.log('i am in the else')
        tpnXScalar = stage1.scaleX * stage1.transform;
        tpnYScalar = stage1.scaleY * stage1.transform;
        targetXScalar = stage0.scaleX * stage0.transform;
        targetYScalar = stage0.scaleY * stage0.transform;
        targetYOffset = stage0.posY + stage1.canvasHeight;
        tpnIndex = laneIndexArray1.indexOf(currentTPNLane);
        targetIndex = laneIndexArray0.indexOf(mappingTargetLane);
        tpnLaneCenter = laneCanvasRegionArray1[tpnIndex]["laneCenter"][0];
        targetLaneCenter = laneCanvasRegionArray0[targetIndex]["laneCenter"][0];
        lineArrayPoints.push([
          parseInt(tpnLaneCenter["x"] * tpnXScalar),
          parseInt(tpnLaneCenter["y"] * tpnYScalar),
          parseInt(targetLaneCenter["x"] * targetXScalar),
          parseInt(targetLaneCenter["y"] * targetYScalar + targetYOffset),
        ]);
      }
    }
    return lineArrayPoints;
    //return [setTPNMappingLines(lineArrayPoints)]
  }

  async function newLaneNewGrid(selectedRows, stepDirection) {
    var newTPNLaneValues = [];
    var gridIDofSelection = [];
    if (stepDirection === "right") {
      if (parseInt(selectedRows[selectedRows.length - 1]["sfLane"]) < parseInt(tpnMapping["mapping"]["indexOfTPN"] === 0 ? getMaxLane(analyticResponse0) : getMaxLane(analyticResponse1))) {
        for (var i = 0; i < selectedRows.length; i++) {
          newTPNLaneValues.push(parseInt(selectedRows[i]["sfLane"]) + 1);
          gridIDofSelection.push(parseInt(selectedRows[i]["id"]));
        }
      } else {
        alert("If you step your current selection to the right, you would end up with a stain-free lane number that is greater than the maximum lane count.");
      }
    } else {
      if (parseInt(selectedRows[0]["sfLane"]) >= 2) {
        for (var j = 0; j < selectedRows.length; j++) {
          newTPNLaneValues.push(parseInt(selectedRows[j]["sfLane"]) - 1);
          gridIDofSelection.push(parseInt(selectedRows[j]["id"]));
        }
      } else {
        alert("If you step your current selection to the left, you would end up with a stain-free lane number that is a zero. Minimum allowed lane = 1.");
      }
    }

    return { newTPNLaneVals: newTPNLaneValues, newGridIDs: gridIDofSelection };
  }

  async function updateTPNIndex(newIndex) {
    tpnMapping.mapping.indexOfNormalizingLane = newIndex;
    tpnMapping.mapping.tpnNormalizingLane = tpnMapping.mapping.immutableTPNLaneNumber[newIndex];
    return tpnMapping;
  }

  async function createNewNormalizerMap(theMapping) {
    var indexOfTPN = theMapping.mapping.indexOfTPN;
    var normalizerLaneName = [];
    var normalizerValue = [];
    //console.log(theMapping)
    getNewLaneNormalizer(theMapping).then(function (value) {
      if (indexOfTPN === 0) {
        for (var i = 0; i < analyticResponse0["laneProfiles"].length; i++) {
          normalizerLaneName.push(analyticResponse0["laneProfiles"][i]["laneName"]);
          normalizerValue.push(parseInt(analyticResponse0["laneProfiles"][i]["laneIntegration"]) / parseInt(value));
          //normalizerMap[analyticResponse0['laneProfiles'][i]['laneName']] = analyticResponse0['laneProfiles'][i]['laneIntegration']/value
        }
      } else {
        for (var j = 0; j < analyticResponse1["laneProfiles"].length; j++) {
          normalizerLaneName.push(analyticResponse1["laneProfiles"][j]["laneName"]);
          normalizerValue.push(parseInt(analyticResponse1["laneProfiles"][j]["laneIntegration"]) / parseInt(value));
          //normalizerMap[analyticResponse1['laneProfiles'][j]['laneName']] = analyticResponse1['laneProfiles'][j]['laneIntegration']/value
        }
      }
    });
    return { tpnLaneName: normalizerLaneName, normalizer: normalizerValue };
  }

  async function subUpdateResponse(whichResponse, normMapping, theMapping) {
    //console.log(whichResponse)
    //console.log(normMapping)
    //console.log(theMapping)
    var mi;
    var mi2;
    var n;
    var o;
    var currentTargetLane;
    var correspondingTPNLane;
    var currentTargetLane1;
    var correspondingTPNLane1;
    for (var j = 0; j < whichResponse["bands"].length; j++) {
      currentTargetLane = parseInt(whichResponse["bands"][j]["laneName"]);
      correspondingTPNLane = theMapping.mapping.tpnLaneNumber[theMapping.mapping.targetLaneNumber.indexOf(currentTargetLane)];
      mi = normMapping["tpnLaneName"].indexOf(correspondingTPNLane);
      n = normMapping["normalizer"][mi];
      whichResponse["bands"][j]["normedLaneIntegration"] = parseInt(whichResponse["bands"][j]["laneIntegration"]) / n;
      whichResponse["bands"][j]["normedROISignal"] = parseInt(whichResponse["bands"][j]["roiSignal"]) / n;
    }
    for (var i = 0; i < whichResponse["laneProfiles"].length; i++) {
      currentTargetLane1 = parseInt(whichResponse["laneProfiles"][i]["laneName"]);
      correspondingTPNLane1 = theMapping.mapping.tpnLaneNumber[theMapping.mapping.targetLaneNumber.indexOf(currentTargetLane1)];
      mi2 = normMapping["tpnLaneName"].indexOf(correspondingTPNLane1);
      o = normMapping["normalizer"][mi2];
      whichResponse["laneProfiles"][i]["normedLaneIntegration"] = parseInt(whichResponse["laneProfiles"][i]["laneIntegration"]) / o;
    }
    return whichResponse;
  }

  async function nowUpdateResponse(normMapping, theMapping) {
    var notIndexOfTPN = theMapping.mapping.notIndexOfTPN;
    if (notIndexOfTPN === 0) {
      subUpdateResponse(analyticResponse0, normMapping, theMapping).then(function (value) {
        setAnalyticResponse0(value);
      });
    } else {
      subUpdateResponse(analyticResponse1, normMapping, theMapping).then(function (value) {
        setAnalyticResponse1(value);
      });
    }
    return true;
  }

  async function getNewLaneNormalizer(theMapping) {
    var indexOfTPN = theMapping.mapping.indexOfTPN;
    var normalizer;
    if (indexOfTPN === 0) {
      for (var i = 0; i < analyticResponse0["laneProfiles"].length; i++) {
        if (analyticResponse0["laneProfiles"][i]["laneName"] === theMapping.mapping.tpnNormalizingLane) {
          normalizer = analyticResponse0["laneProfiles"][i]["laneIntegration"];
        }
      }
      //console.log(analyticResponse0)
    } else {
      for (var j = 0; j < analyticResponse1["laneProfiles"].length; j++) {
        if (analyticResponse1["laneProfiles"][j]["laneName"] === theMapping.mapping.tpnNormalizingLane) {
          normalizer = analyticResponse1["laneProfiles"][j]["laneIntegration"];
        }
      }
      //console.log(analyticResponse1)
    }
    return normalizer;
  }

  function changeTPNNormLane(event) {
    //console.log(event.target.value)
    //setOpenBackdrop(true)
    updateTPNIndex(event.target.value).then(function (val1) {
      setTPNMapping(val1);
      prepTPNLanes(val1).then(function (val2) {
        setTPNMappingLines(createTPNMappingLines(val2["laneIndexArray0"], val2["laneCanvasRegionArray0"], val2["laneIndexArray1"], val2["laneCanvasRegionArray1"], val1));
        setTPNLaneArrays({
          0: {
            imageID: analyticResponse0["imageID"],
            laneIndexArray: val2["laneIndexArray0"],
            laneCanvasRegionArray: val2["laneCanvasRegionArray0"],
          },
          1: { imageID: analyticResponse1["imageID"], laneIndexArray: val2["laneIndexArray1"], laneCanvasRegionArray: val2["laneCanvasRegionArray1"] },
        });
        //createNewNormalizerMap(val1).then(function(val5){nowUpdateResponse(val5,val1).then(function(val6){createChartJSData(true,true)})})
      });
      createNewNormalizerMap(val1).then(function (val5) {
        nowUpdateResponse(val5, val1).then(function (val6) {
          createChartJSData(true, true, showTPNSignal);
        });
      });
    });
  }

  function handleTPNStepRight() {
    //console.log(analyticResponse0)
    var selectedRows = Array.from(tpnGridAPIRef.current.getSelectedRows().values());
    if (selectedRows.length > 0) {
      newLaneNewGrid(selectedRows, "right").then(function (value) {
        if (value["newTPNLaneVals"].length > 0) {
          updateTPNMapping(value["newTPNLaneVals"], value["newGridIDs"]).then(function (val1) {
            setTPNMapping(updateTPNGridRows(val1));
            prepTPNLanes(val1).then(function (val2) {
              setTPNMappingLines(createTPNMappingLines(val2["laneIndexArray0"], val2["laneCanvasRegionArray0"], val2["laneIndexArray1"], val2["laneCanvasRegionArray1"], val1));
              setTPNLaneArrays({
                0: {
                  imageID: analyticResponse0["imageID"],
                  laneIndexArray: val2["laneIndexArray0"],
                  laneCanvasRegionArray: val2["laneCanvasRegionArray0"],
                },
                1: { imageID: analyticResponse1["imageID"], laneIndexArray: val2["laneIndexArray1"], laneCanvasRegionArray: val2["laneCanvasRegionArray1"] },
              });
            });
            createNewNormalizerMap(val1).then(function (val5) {
              nowUpdateResponse(val5, val1).then(function (val6) {
                createChartJSData(true, true, showTPNSignal);
              });
            });
          });
        }
      });
    } else {
      alert("You must first select a lane or group of lanes you want to step to the left");
      return true;
    }
  }
  function handleTPNStepLeft() {
    var selectedRows = Array.from(tpnGridAPIRef.current.getSelectedRows().values());
    //console.log(selectedRows)
    if (selectedRows.length > 0) {
      newLaneNewGrid(selectedRows, "left").then(function (value) {
        if (value["newTPNLaneVals"].length > 0) {
          updateTPNMapping(value["newTPNLaneVals"], value["newGridIDs"]).then(function (val1) {
            setTPNMapping(updateTPNGridRows(val1));
            prepTPNLanes(val1).then(function (val2) {
              setTPNMappingLines(createTPNMappingLines(val2["laneIndexArray0"], val2["laneCanvasRegionArray0"], val2["laneIndexArray1"], val2["laneCanvasRegionArray1"], val1));
              setTPNLaneArrays({
                0: {
                  imageID: analyticResponse0["imageID"],
                  laneIndexArray: val2["laneIndexArray0"],
                  laneCanvasRegionArray: val2["laneCanvasRegionArray0"],
                },
                1: { imageID: analyticResponse1["imageID"], laneIndexArray: val2["laneIndexArray1"], laneCanvasRegionArray: val2["laneCanvasRegionArray1"] },
              });
            });
            createNewNormalizerMap(val1).then(function (val5) {
              nowUpdateResponse(val5, val1).then(function (val6) {
                createChartJSData(true, true, showTPNSignal);
              });
            });
          });
        }
      });
    } else {
      alert("You must first select a lane or group of lanes you want to step to the left");
      return true;
    }
  }

  function resetTPNDialog() {
    createTPNMappingDict().then(function (value) {
      setTPNMapping(value);
      prepTPNLanes(value).then(function (val1) {
        setTPNMappingLines(createTPNMappingLines(val1["laneIndexArray0"], val1["laneCanvasRegionArray0"], val1["laneIndexArray1"], val1["laneCanvasRegionArray1"], value));
        setTPNLaneArrays({
          0: {
            imageID: analyticResponse0["imageID"],
            laneIndexArray: val1["laneIndexArray0"],
            laneCanvasRegionArray: val1["laneCanvasRegionArray0"],
          },
          1: { imageID: analyticResponse1["imageID"], laneIndexArray: val1["laneIndexArray1"], laneCanvasRegionArray: val1["laneCanvasRegionArray1"] },
        });
      });
    });
  }

  async function createTPNMappingDict() {
    var vtargetLaneNumber = [];
    var vtpnLaneNumber = [];
    var imutTPNLaneNumber = [];
    var vmapDict = currentUpload[0]["TPNLaneMapping"];
    //console.log('currentUpload0',currentUpload[0])
    //console.log('currentUpload1',currentUpload[1])
    var vtpnGridRows = [];
    if (currentUpload[0]["TPNRequested"] === "true" && currentUpload[0]["TPNAnalysisStatus"] === "done") {
      var vtpnImageID = currentUpload[0]["TPNImageID"];
      var vindexOfTPNImage = selectedID.indexOf(tpnImageID);
      var vnotIndexOfTPNImage = vindexOfTPNImage === 0 ? 1 : 0;
      var vtpnNormalizingLane = parseInt(currentUpload[vindexOfTPNImage]["TPNLane"]);
      var vindexOfNormalizingLane;

      for (let key in vmapDict) {
        vtargetLaneNumber.push(parseInt(vmapDict[key]["M"]["targetLaneNumber"]["N"]));
        vtpnLaneNumber.push(parseInt(vmapDict[key]["M"]["tpnLaneNumber"]["N"]));
        imutTPNLaneNumber.push(parseInt(vmapDict[key]["M"]["tpnLaneNumber"]["N"]));
        if (parseInt(vmapDict[key]["M"]["tpnLaneNumber"]["N"]) === vtpnNormalizingLane) {
          vindexOfNormalizingLane = parseInt(key);
        }
        vtpnGridRows.push({ id: key, sfLane: vmapDict[key]["M"]["tpnLaneNumber"]["N"], targetLane: vmapDict[key]["M"]["targetLaneNumber"]["N"] });
      }
      return {
        mapping: {
          indexOfNormalizingLane: vindexOfNormalizingLane,
          tpnNormalizingLane: vtpnNormalizingLane,
          indexOfTPN: vindexOfTPNImage,
          notIndexOfTPN: vnotIndexOfTPNImage,
          tpnImageID: vtpnImageID,
          targetLaneNumber: vtargetLaneNumber,
          tpnLaneNumber: vtpnLaneNumber,
          immutableTPNLaneNumber: imutTPNLaneNumber,
          gridRows: vtpnGridRows,
          mapLines: null,
        },
      };
    } else {
      return null;
    }
  }

  function updateChartFromTable() {
    //console.log('am i running this')
    setExpanded3("analyticCharts");
    setLaneChecks({ centerLane: false, boxLane: false, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
    setChangeIconColor("inherit");
    changeChartJSData(apiRef.current.getVisibleRowModels(apiRef), "updateChartFromTable", yAxisChecks.mw, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals);
  }

  function diplaySelection() {
    //setStyleWidthOfCanvas0(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
    //setStyleWidthOfCanvas1(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth)+'px')
    setStyleWidthOfCanvas0(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth) + "px");
    setStyleWidthOfCanvas1(String(imageDisplayBoxRef.current.children[0].children[0].offsetWidth) + "px");
    setMiddleDim(imageDisplayBoxRef.current.children[0].children[0].offsetWidth);
    setMaximizedDim(imageDisplayBoxRef.current.children[0].offsetWidth * 0.75);
    setMinimizedDim(imageDisplayBoxRef.current.children[0].offsetWidth * 0.25);

    //console.log('this is what the middleDim should be',imageDisplayBoxRef.current.children[0].children[0].offsetWidth)
    setExpanded2("imageAnnotate");
    if (selected.length > 2) {
      //setToManyAlertDialog(true)
      setDialogs({ toManyAlertDialog: true });
    } else {
      //console.log('was tpn requested',tpnRequested)
      if (tpnRequested) {
        //then we need to make sure that the user selects two images, otherwise the application will fail
        //console.log('i am if first if statement')
        if (selected.length === 2) {
          resetDisplayStates();
          prepCanvasForDrawing();
          setImagesForShow(selectedURL);
          setImageIDsForShow(selectedID);
          setAllPriorAnalysis(preparePriorAnalysisDictionary(selectedID));
          createTPNMappingDict().then(function (value) {
            setTPNMapping(value);
            //console.log(value)
          });
          setExpanded("priorAnalysisHeader");
        } else {
          //here we need to open an alert dialog.
          //console.log('we need to open an alert dialog')
          setDialogs({ openTPNRequestedDialog: true });
        }
      } else {
        resetDisplayStates();
        prepCanvasForDrawing();
        setImagesForShow(selectedURL);
        setImageIDsForShow(selectedID);
        setAllPriorAnalysis(preparePriorAnalysisDictionary(selectedID));
        createTPNMappingDict().then(function (value) {
          setTPNMapping(value);
          //console.log(value)
        });
        setExpanded("priorAnalysisHeader");
      }
    }
  }

  function showPriorAnalysisDialog() {
    setOpenPriorAnalysisDialog(true);
  }

  function closePriorAnalysisDialog() {
    setOpenPriorAnalysisDialog(false);
  }

  function showShareAnalysisDialog() {
    if (ORActiveImage !== null) {
      var theSharingID = String(uuid.v4());
      setSharingAnalysisID(theSharingID);
      updateDBWithShareID(theSharingID);
      setOpenShareAnalysisDialog(true);
    } else {
      alert("You have nothing to share at the moment.");
    }
  }

  function closeShareAnalysisDialog() {
    setOpenShareAnalysisDialog(false);
  }
  function closeLabelDialog() {
    setDialogs({ labelDialog: false });
  }

  function closeTPNRequestedDialog() {
    setDialogs({ openTPNRequestedDialog: false });
  }

  function closeTPNAlignDialog() {
    setDialogs({ tpnAlignDialog: false });
  }

  function closeTargetDialog() {
    //setTargetDialog(false)
    setDialogs({ targetDialog: false });
  }

  function closeWhichAnalysisDialog() {
    setWhichAnalysisDialog(false);
  }

  function closeColorDialog() {
    //console.log(uniqueUserBandTags)
    //setColorDialog(false)
    setDialogs({ colorDialog: false });
  }

  function closeNoFilesDialog() {
    //setNoFilesAlertDialog(false)
    setDialogs({ noFilesAlertDialog: false });
  }

  function changeContrast0(value) {
    setContrastValue0(value.target.value);
    setActiveImage("image0");
    updateImageInfo("image0");
  }

  function changeContrast1(value) {
    setContrastValue1(value.target.value);
    setActiveImage("image1");
    updateImageInfo("image1");
  }

  function changeBrightness0(value) {
    setBrightnessValue0(value.target.value);
    setActiveImage("image0");
    updateImageInfo("image0");
  }

  function changeBrightness1(value) {
    setBrightnessValue1(value.target.value);
    setActiveImage("image1");
    updateImageInfo("image1");
  }

  function updatePriorAnalysis() {
    //console.log('did I run this')
    //console.log(selectedID)
    setAllPriorAnalysis(preparePriorAnalysisDictionary(selectedID));
  }

  function handleProteinBand1(value) {
    //console.log(styleWidthOfCanvas0,styleWidthOfCanvas1)
    var theSwapIndex;

    setHighlightColor("grey");
    setActiveImage("image1");
    updateImageInfo("image1");
    setSelectRegion1(null);
    if (currentUpload[1]["bandFindKey_8bit"] === "Pending") {
      //this means the ML is either incomplete or failed rerun dynamoscan to check for comletion
      console.log("ml is pending");
      syncTableWithDB(sessionStorage.getItem("uploadDateTime"), "from-findBandsCheckBox");
      //now give user an alert to try again in just a moment
    } else {
      theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[1]);

      if (fb1 === false) {
        setAllActiveDims("image1");
        setActiveROI("bands");
        setfll1(false);
        setgb1(false);
        setsat1(false);
        setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
        setExpanded("roisHeader");
      } else {
        setAllDeactiveDims("image1");
        setActiveROI(null);
        setRegionsFromCheckBox(1, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
        setExpanded(false);
      }
    }
    setfb1(value.target.checked);
  }

  function handleProteinBand0(value) {
    //console.log(styleWidthOfCanvas0,styleWidthOfCanvas1)
    var theSwapIndex;

    setHighlightColor("grey");
    setActiveImage("image0");
    updateImageInfo("image0");
    setSelectRegion0(null);
    if (currentUpload[0]["bandFindKey_8bit"] === "Pending") {
      //this means the ML is either incomplete or failed rerun dynamoscan to check for comletion
      console.log("ml is pending");
      syncTableWithDB(sessionStorage.getItem("uploadDateTime"), "from-findBandsCheckBox");
      //now give user an alert to try again in just a moment
    } else {
      theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[0]);

      if (fb0 === false) {
        //console.log('fb0 is false',fb0)
        setAllActiveDims("image0");
        setfll0(false);
        setgb0(false);
        setsat0(false);
        setActiveROI("bands");
        setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
        setExpanded("roisHeader");
      } else {
        //console.log('fb0 is true - in this case I should be deleting the list',fb0)
        setAllDeactiveDims("image0");
        setRegionsFromCheckBox(0, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90", theSwapIndex);
        setExpanded(false);
        setActiveROI(null);
      }
    }
    setfb0(value.target.checked);
  }

  function invertImage0() {
    setActiveImage("image0");
    updateImageInfo("image0");
    if (invertImageValue0 === 0) {
      setInvertImageValue0(100);
    } else {
      setInvertImageValue0(0);
    }
  }

  function invertImage1() {
    setActiveImage("image1");
    updateImageInfo("image1");
    if (invertImageValue1 === 0) {
      setInvertImageValue1(100);
    } else {
      setInvertImageValue1(0);
    }
  }

  function changeORColor(event) {
    //console.log('color:',event.hex)
    setUploadIconColor("warning");
    //setUserColorTag(event.hex)
    setObjectAnnots({
      objectComposition: objectAnnots.objectComposition,
      proteinStandard: objectAnnots.proteinStandard,
      proteinTarget: objectAnnots.proteinTarget,
      proteinControl: objectAnnots.proteinControl,
      userColorTag: event.hex,
    });
  }

  function changeTarget(value) {
    setUploadIconColor("warning");
    if (showWhichResults) {
      //setProteinTarget(value)
      setObjectAnnots({
        objectComposition: objectAnnots.objectComposition,
        proteinStandard: objectAnnots.proteinStandard,
        proteinTarget: value.target.textContent,
        proteinControl: objectAnnots.proteinControl,
        userColorTag: objectAnnots.userColorTag,
      });
    } else {
      setLysateTarget(value.target.textContent);
    }
  }

  function openTPNAlignDialog() {
    //console.log('should I be opening the dialog')
    setDialogs({ tpnAlignDialog: true });
  }

  function setTargetTags() {
    var visibleIndexArray;
    var labelArray;

    setUploadIconColor("warning");
    if (showWhichResults) {
      labelArray = [objectAnnots.proteinTarget];
    } else {
      labelArray = [lysateTarget];
    }
    visibleIndexArray = getVisibleIndex();
    updateDataTable(visibleIndexArray, labelArray, "setTarget", null, null, "targetTagger");
  }

  function toggleBoxLane(value) {
    if (value.target.checked) {
      setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
      updateLaneDisplay("canvasLaneBox", "toggleBoxLane", visibleLaneArray);
      //console.log('laneRegionArray:',laneRegionArray)
      //setDisplayRegionArray(laneRegionArray)
    } else {
      setLaneChecks({ centerLane: false, boxLane: false, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
      //console.log('ORRegionArray',ORRegionArray)
      setDisplayRegionArray(ORRegionArray);
    }
  }

  function toggleRelativeMobility(value) {
    if (value.target.checked) {
      //console.log('I should be turning on start line')
      setLaneChecks({ centerLane: laneChecks.centerLane, boxLane: laneChecks.boxLane, polygonLane: laneChecks.polygonLane, relativeMobility: true });
    } else {
      setLaneChecks({ centerLane: laneChecks.centerLane, boxLane: laneChecks.boxLane, polygonLane: laneChecks.polygonLane, relativeMobility: false });
      //console.log('I should be turning off start line')
    }
  }

  function calcRelativeMobility(event) {
    var visibleIndex = getVisibleIndex();
    //console.log(ORActiveImage)
    //console.log(yAxisChecks,relativeChecks)
    var name = event.target.attrs.name;
    var points = event.target.attrs.points;
    var labelArray = [];
    var allowedIndexArray = [];
    var distance = parseInt(String(event.target.attrs.y));
    var stopPosit = points[9] + distance;
    //console.log('ORRegionArray',ORRegionArray)
    //console.log('gridRowData',gridRowData)
    //console.log('visibleIndex',visibleIndex)
    //console.log('name',name)
    //var visibleTable = apiRef.current.getVisibleRowModels()
    //console.log(visibleIndex)
    //console.log(visibleTable)
    for (var i = 0; i < visibleIndex.length; i++) {
      let valueToFind = parseInt(visibleIndex[i]);
      var index;
      if (gridRowData[i]["band"] !== -1) {
        if (name === "endRF") {
          //console.log(visibleIndex[i],gridRowData[visibleIndex[i]]['verticalPos'])
          //console.log('fromBottomMove RM =',parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top))
          index = gridRowData.findIndex((item) => item["id"] === valueToFind);
          gridRowData[index]["relativeMobility"] = parseInt(String(gridRowData[index]["verticalPos"]) - RMBounds.top) / (stopPosit - RMBounds.top);
          labelArray.push(parseInt(String(gridRowData[index]["verticalPos"]) - RMBounds.top) / (stopPosit - RMBounds.top));
          allowedIndexArray.push(index);
          //gridRowData[visibleIndex[i]]['relativeMobility'] = parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top)
          //labelArray.push(parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top))
          //allowedIndexArray.push(visibleIndex[i])
          //updateAnalyticResponse_2(visibleIndex[i],'bands',ORActiveImage,[parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top)],'rmbTagger')
        } else {
          //console.log('fromTopMove RM =',parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - stopPosit)/(RMBounds.bottom - stopPosit))

          index = gridRowData.findIndex((item) => item["id"] === valueToFind);
          //console.log(index,valueToFind)
          gridRowData[index]["relativeMobility"] = parseInt(String(gridRowData[index]["verticalPos"]) - stopPosit) / (RMBounds.bottom - stopPosit);
          labelArray.push(parseInt(String(gridRowData[index]["verticalPos"]) - RMBounds.top) / (stopPosit - RMBounds.top));
          allowedIndexArray.push(index);
          //gridRowData[visibleIndex[i]]['relativeMobility'] = parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - stopPosit)/(RMBounds.bottom - stopPosit)
          //labelArray.push(parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top))
          //allowedIndexArray.push(visibleIndex[i])
          //updateAnalyticResponse(visibleIndex[i],'bands',ORActiveImage,[parseInt(String(gridRowData[visibleIndex[i]]['verticalPos']) - stopPosit)/(RMBounds.bottom - stopPosit)],'rmbTagger')
        }
      }
    }
    setUploadIconColor("warning");
    //visibleTable.forEach(function (value,key){
    //  if(value['band']!==-1){
    //    if(name==='endRF'){
    //      console.log('fromBottomMove RM =',parseInt(String(value['verticalPos']) - RMBounds.top)/(stopPosit - RMBounds.top))
    //    }else{
    //      console.log('fromTopMove RM =',parseInt(String(value['verticalPos']) - stopPosit)/(RMBounds.bottom - stopPosit))
    //    }
    //  }
    //})
    return [
      changeChartJSData(apiRef.current.getVisibleRowModels(apiRef), "updateChartFromRMB", yAxisChecks.mw, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals),
      name === "endRF"
        ? setRMBounds({ v_extent: RMBounds.v_extent, top: RMBounds.top, bottom: stopPosit })
        : setRMBounds({ v_extent: RMBounds.v_extent, top: stopPosit - 20, bottom: RMBounds.bottom }),
      updateAnalyticResponse_2(allowedIndexArray, ORActiveImage, labelArray),
    ];
  }
  function changeInputSharedID(value) {
    //console.log(value.target.value)
    if (value === null) {
      //console.log('wtf, i can't seem to figure out how to reset the inputShareID to null')
      setInputSharedID(null);
    } else {
      setInputSharedID(value.target.value);
    }
  }
  function togglePolygonLane(value) {
    if (value.target.checked) {
      setLaneChecks({ centerLane: false, boxLane: false, polygonLane: true, relativeMobility: laneChecks.relativeMobility });
      updateLaneDisplay("canvasLanePolygon", "togglePolygonLane", visibleLaneArray);
    } else {
      setLaneChecks({ centerLane: false, boxLane: false, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
      setDisplayRegionArray(ORRegionArray);
    }
  }

  function toggleCenterLaneLine(value) {
    //console.log('laneCJSData',laneCJSData)
    if (value.target.checked) {
      //console.log(visibleLaneArray)
      setLaneChecks({ centerLane: true, boxLane: false, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
      updateLaneDisplay("canvasLaneCenters", "toggleCenterLaneLine", visibleLaneArray);
    } else {
      setLaneChecks({ centerLane: false, boxLane: false, polygonLane: false, relativeMobility: laneChecks.relativeMobility });
      //console.log('ORRegionArray',ORRegionArray)
      setDisplayRegionArray(ORRegionArray);
    }
  }

  function toggleTotalLane() {
    setRelativeChecks({ relativeToBand: false, relativeToLane: false, totalLane: true, mwFit: false });
    setYAxisChecks({ mw: false, vertPos: false });
  }

  function toggleMWFit() {
    setRelativeChecks({ relativeToBand: false, relativeToLane: false, totalLane: false, mwFit: true });
    setYAxisChecks({ mw: false, vertPos: false });
  }
  function toggleRelativeToBand() {
    setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
    if (yAxisChecks.mw === false && yAxisChecks.vertPos === false) {
      setYAxisChecks({ mw: false, vertPos: true });
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleRelativeToLane", false, true, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(false, true, showTPNSignal, showBoxSignals);
      updateLineChartRef(false, laneCJSData, laneChecks);
    } else {
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleRelativeToLane", yAxisChecks.mw, true, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(yAxisChecks.mw, true, showTPNSignal, showBoxSignals);
      updateLineChartRef(yAxisChecks.mw, laneCJSData, laneChecks);
    }
  }

  function toggleRelativeToLane() {
    //console.log(visibleLaneArray)
    setRelativeChecks({ relativeToBand: false, relativeToLane: true, totalLane: false, mwFit: false });
    if (yAxisChecks.mw === false && yAxisChecks.vertPos === false) {
      setYAxisChecks({ mw: false, vertPos: true });
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleRelativeToLane", false, false, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(false, false, showTPNSignal, showBoxSignals);
      updateLineChartRef(false, laneCJSData, laneChecks);
    } else {
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleRelativeToLane", yAxisChecks.mw, false, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(yAxisChecks.mw, false, showTPNSignal, showBoxSignals);
      updateLineChartRef(yAxisChecks.mw, laneCJSData, laneChecks);
    }
  }

  function toggleAxisToMW() {
    if (relativeChecks.relativeToBand === false && relativeChecks.relativeToLane === false) {
      setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleAxisToMW", true, true, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(true, true, showTPNSignal, showBoxSignals);
    } else {
      changeChartJSData(apiRef.current.getVisibleRowModels(), "toggleAxisToMW", true, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals);
      updateBubbleChartRef(true, relativeChecks.relativeToBand, showTPNSignal, showBoxSignals);
    }
    setYAxisChecks({ mw: true, vertPos: false });
    updateLineChartRef(true, laneCJSData, laneChecks);
  }

  // before handle change function
  function addRemoveAll(addOrRemove) {
    //console.log(activeImage,activeROI)
    if (activeImage === "image0" && activeROI === "bands") {
      updateRegionsForShow(null, addOrRemove, activeBandRegions0, 3, "all");
    } else if (activeImage === "image0" && activeROI === "lysate") {
      updateRegionsForShow(null, addOrRemove, activeLysateRegions0, 2, "all");
    } else if (activeImage === "image0" && activeROI === "gbBorder") {
      updateRegionsForShow(null, addOrRemove, activeGelBlotRegions0, 4, "all");
    } else if (activeImage === "image1" && activeROI === "gbBorder") {
      updateRegionsForShow(null, addOrRemove, activeGelBlotRegions1, 5, "all");
    } else if (activeImage === "image1" && activeROI === "bands") {
      updateRegionsForShow(null, addOrRemove, activeBandRegions1, 1, "all");
    } else if (activeImage === "image1" && activeROI === "lysate") {
      updateRegionsForShow(null, addOrRemove, activeLysateRegions1, 0, "all");
    }
  }

  function testFunction() {
    //console.log('did i run this')
    //console.log(tpnRequested,showTPNSignal)
    //createChartJSData(true,tpnRequested,!showTPNSignal)
    changeChartJSData(apiRef.current.getVisibleRowModels(), "updateChartFromTable", yAxisChecks.mw, relativeChecks.relativeToBand, !showTPNSignal, showBoxSignals);
    //console.log(relativeChecks)
    updateBubbleChartRef(yAxisChecks.mw, relativeChecks.relativeToBand, !showTPNSignal, showBoxSignals);
    setShowTPNSignal(!showTPNSignal);

    //setShowWhichResults(!showWhichResults)
  }

  function changeChartJSData(visibleTable, fromWhichCase, whichYAxis, whichRelativeScalar, showTPNSignal, showBoxSignals) {
    //console.log('visibleTable',visibleTable)
    //console.log('fromWhichCase',fromWhichCase)
    //console.log('whichYAxis',whichYAxis)
    //console.log('whichRelativeScalar',whichRelativeScalar)
    //console.log('showTPNSignal',showTPNSignal)
    //console.log('showBoxSignals',showBoxSignals)
    var cjsData;
    var data = {};
    var scatData = {};
    var totalLaneData = {};

    function largestVal(scatPoints) {
      var cv = 0;
      var cx = 0;
      var idx = 0;
      for (var i = 0; i < scatPoints.length; i++) {
        if (scatPoints[i]["r"] > cv) {
          cv = scatPoints[i]["r"];
          idx = i;
        }
        if (parseInt(scatPoints[i]["x"]) > cx) {
          cx = parseInt(scatPoints[i]["x"]);
        }
      }
      return { lgv: cv, idxOflgv: idx, lgx: cx };
    }

    function newLargestLaneScalar(scatPoints) {
      var cv = 0;
      for (var j = 0; j < scatPoints.length; j++) {
        //i think i need to modify this, now that I don not scale by area for the signals in the table
        //if ((scatPoints[j]['r'] * scatPoints[j]['roiArea']) / scatPoints[j]['laneIntegration'] > cv) {
        //  cv = (scatPoints[j]['r'] * scatPoints[j]['roiArea']) / scatPoints[j]['laneIntegration']
        if (scatPoints[j]["r"] / scatPoints[j]["laneIntegration"] > cv) {
          cv = scatPoints[j]["r"] / scatPoints[j]["laneIntegration"];
        }
      }
      return { lgv: cv };
    }

    function newPrepScatCJSData(scatPoints, whichRelativeScalar) {
      var totalLanePoints = [];
      var uniqueLane = [];
      if (whichRelativeScalar) {
        //scaling all objects to the largets band
        var bandScalar = largestVal(scatPoints);
        var theScalarPoint;
        for (var i = 0; i < scatPoints.length; i++) {
          scatPoints[i]["r"] = (scatPoints[i]["r"] / bandScalar["lgv"]) * bubbleScalar;
          //console.log(scatPoints[i]['x'])
          if (!uniqueLane.includes(scatPoints[i]["x"])) {
            totalLanePoints.push({ x: Math.floor(scatPoints[i]["x"]).toString(), y: scatPoints[i]["laneIntegration"] });
            uniqueLane.push(scatPoints[i]["x"]);
          }
          if (i === bandScalar["idxOflgv"]) {
            theScalarPoint = [scatPoints[i]];
          }
        }
      } else {
        laneScalar = newLargestLaneScalar(scatPoints);

        //this describes the size of the bubble
        for (var j = 0; j < scatPoints.length; j++) {
          //i think i need to modify this, now that I don not scale by area for the signals in the table
          //scatPoints[j]['r'] = ((scatPoints[j]['r'] * scatPoints[j]['roiArea']) / scatPoints[j]['laneIntegration'] / laneScalar['lgv']) * bubbleScalar
          scatPoints[j]["r"] = (scatPoints[j]["r"] / scatPoints[j]["laneIntegration"] / laneScalar["lgv"]) * bubbleScalar;
          if (!uniqueLane.includes(scatPoints[j]["x"])) {
            totalLanePoints.push({ x: Math.floor(scatPoints[j]["x"]).toString(), y: scatPoints[j]["laneIntegration"] });
            uniqueLane.push(scatPoints[j]["x"]);
          }
        }
      }
      return { scatPoints: scatPoints, theScalarPoint: theScalarPoint, totalLanePoints: totalLanePoints }; //, totalLaneLabel:totalLaneLabel }
    }

    function largestLaneScalar(scatPoints, laneArray) {
      var cv = 0;
      for (var i = 0; i < laneArray.length; i++) {
        for (var j = 0; j < scatPoints.length; j++) {
          //i think i need to modify this, now that I don not scale by area for the signals in the table
          //if ((scatPoints[j]['r'] * scatPoints[j]['roiArea']) / laneRegionArray[laneIndexArray.indexOf(laneArray[i])]['laneIntegration'] > cv) {
          //  cv = (scatPoints[j]['r'] * scatPoints[j]['roiArea']) / laneRegionArray[laneIndexArray.indexOf(laneArray[i])]['laneIntegration']
          //}
          if (scatPoints[j]["r"] / laneRegionArray[laneIndexArray.indexOf(laneArray[i])]["laneIntegration"] > cv) {
            cv = scatPoints[j]["r"] / laneRegionArray[laneIndexArray.indexOf(laneArray[i])]["laneIntegration"];
          }
        }
      }
      return { lgv: cv };
    }

    function prepScatCJSData(scatPoints, whichRelativeScalar, laneArray) {
      if (whichRelativeScalar) {
        //scaling all objects to the largets band
        var bandScalar = largestVal(scatPoints);
        var theScalarPoint;
        for (var i = 0; i < scatPoints.length; i++) {
          scatPoints[i]["r"] = (scatPoints[i]["r"] / bandScalar["lgv"]) * bubbleScalar;
          if (i === bandScalar["idxOflgv"]) {
            theScalarPoint = [scatPoints[i]];
            //console.log(theScalarPoint)
          }
        }
      } else {
        laneScalar = largestLaneScalar(scatPoints, laneArray);
        for (var j = 0; j < laneArray.length; j++) {
          for (var k = 0; k < scatPoints.length; k++) {
            if (scatPoints[k]["x"] === laneArray[j]) {
              scatPoints[k]["r"] = ((scatPoints[k]["r"] * scatPoints[k]["roiArea"]) / laneRegionArray[laneIndexArray.indexOf(laneArray[j])]["laneIntegration"] / laneScalar["lgv"]) * bubbleScalar;
            }
          }
        }
      }
      return { scatPoints: scatPoints, theScalarPoint: theScalarPoint };
    }

    function createVisibleLaneArrayDictionary(borderIDs, visibleTable) {
      //console.log('borderIDs from update',borderIDs)
      const tableAsArray = Array.from(visibleTable.values());

      var laneDict = {};
      for (var j = 0; j < borderIDs.length; j++) {
        laneDict[j] = {};
        laneDict[j]["borderID"] = borderIDs[j];
        laneDict[j]["laneArray"] = [];
        for (var i = 0; i < tableAsArray.length; i++) {
          if (tableAsArray[i]["borderID"] === borderIDs[j]) {
            if (!laneDict[j]["laneArray"].includes(parseInt(tableAsArray[i]["lane"]))) {
              laneDict[j]["laneArray"].push(parseInt(tableAsArray[i]["lane"]));
            }
          }
        }
      }
      return laneDict;
    }

    function prepData(visibleTable, whichROI, whichYAxis, fromWhichCase) {
      let objectClass = "";
      if (whichROI) {
        objectClass = "bands";
      } else {
        objectClass = "lysates";
      }
      var signals = [];
      //var signals_bgr = []
      var labels = [];
      var color = [];
      var ORCanvasRegionArray = [];
      var ids = [];
      var scatPoint = [];
      var laneNames = [];
      var borderIDs = [];
      //var boxPoints = []
      if (chartType === "Polar") {
        visibleTable.forEach(function (value, key) {
          if (value["band"] !== -1) {
            //console.log(value)
            labels.push(String(value["lane"]));
            signals.push(showTPNSignal ? value["normalizedSignal"] : showBoxSignals ? value["boxSignal"] : value["signal"]);
            color.push(String(changeColor()));
            ids.push(value["id"]);
            if (whichYAxis) {
              scatPoint.push({
                y: value["molecularWeight"],
                x: parseInt(value["lane"]),
                r: showTPNSignal ? (showBoxSignals ? value["normalizedBoxSignal"] : value["normalizedSignal"]) : showBoxSignals ? value["boxSignal"] : value["signal"],
                rmb: value["relativeMobility"],
                roiArea: value["roiArea"],
                laneIntegration: showTPNSignal ? value["normalizedLaneIntegration"] : value["laneIntegration"],
              });
            } else {
              scatPoint.push({
                y: value["verticalPos"],
                x: parseInt(value["lane"]),
                r: showTPNSignal ? (showBoxSignals ? value["normalizedBoxSignal"] : value["normalizedSignal"]) : showBoxSignals ? value["boxSignal"] : value["signal"],
                rmb: value["relativeMobility"],
                roiArea: value["roiArea"],
                laneIntegration: showTPNSignal ? value["normalizedLaneIntegration"] : value["laneIntegration"],
              });
            }
            if (!laneNames.includes(parseInt(value["lane"]))) {
              laneNames.push(parseInt(value["lane"]));
            }
            if (!borderIDs.includes(value["borderID"])) {
              borderIDs.push(value["borderID"]);
            }
            if (fromWhichCase === "updateChartFromTable") {
              ORCanvasRegionArray.push({
                id: String(value["id"]),
                fill: showBoxSignals ? false : String(value["highlightColor"]),
                color: String(value["highlightColor"]),
                points: showBoxSignals ? value["boxPoints"] : value["points"],
                vpos: value["verticalPos"],
                closed: true,
              });
            }
          }
        });
      }
      return {
        borderIDs: borderIDs,
        laneNames: laneNames,
        labels: labels,
        signals: signals,
        color: color,
        objectRegistry: ORCanvasRegionArray,
        ids: ids,
        scatPoints: scatPoint,
        dataSetClass: objectClass,
      }; //,theVisibleTable:currentTableArray}
    }

    cjsData = prepData(visibleTable, showWhichResults, whichYAxis, fromWhichCase);
    //console.log('cjsData',prepData(visibleTable, showWhichResults, whichYAxis, fromWhichCase))
    var test = newPrepScatCJSData(cjsData["scatPoints"], whichRelativeScalar, cjsData["laneNames"]);
    scatData["datasets"] = [
      { label: cjsData["dataSetClass"], order: 1, borderWidth: 0.5, backgroundColor: showTPNSignal ? "#c9eb3480" : "#FFFFFF30", borderColor: "white", data: test["scatPoints"] },
      { label: "theScalar", order: 0, backgroundColor: showTPNSignal ? "#e71bfa95" : "#1563BD98", data: test["theScalarPoint"] },
    ];
    data["labels"] = cjsData["labels"];
    data["datasets"] = [{ label: "signals", data: cjsData["signals"], backgroundColor: cjsData["color"], borderWidth: 1 }];
    //totalLaneData['datasets']=[{label:test['totalLaneLabel'],data:test['totalLanePoints']}]
    //console.log('change CJSData',data)
    //console.log('change scatCJSData',scatData)
    setCJSData(data);
    setScatCJSData(scatData);
    setTotalLaneChartData(updateTotalLaneArray(test));
    if (fromWhichCase === "updateChartFromTable") {
      setORRegionArray(cjsData["objectRegistry"]);
      setDisplayRegionArray(cjsData["objectRegistry"]);
    }
    if (ORActiveImage === "image0") {
      if (showWhichResults) {
        setUniqueUserBandTags(createUniqueArray(analyticResponse0, "bands", cjsData["ids"]));
      } else {
        setUniqueUserLysateTags(createUniqueArray(analyticResponse0, "lysates", cjsData["ids"]));
      }
    } else if (ORActiveImage === "image1") {
      if (showWhichResults) {
        setUniqueUserBandTags(createUniqueArray(analyticResponse1, "bands", cjsData["ids"]));
      } else {
        setUniqueUserLysateTags(createUniqueArray(analyticResponse1, "lysates", cjsData["ids"]));
      }
    }
    //setVisibleLaneArray(cjsData['laneNames'])
    setVisibleLaneArray(createVisibleLaneArrayDictionary(cjsData["borderIDs"], visibleTable));

    function updateTotalLaneArray(theArray) {
      var data = {};
      data["datasets"] = [
        {
          label: "total lane",
          data: theArray["totalLanePoints"],
          backgroundColor: showTPNSignal ? "#c9eb3480" : "#679dbd95",
          //borderColor:'rgb(255, 99, 132)',
          //pointStyle: 'circle',
          //hitRadius: 8,
          //hoverRadius: 8,
          //borderWidth:1,
          //radius:5,
          //showLine:true,
          //borderDash:[3, 3]
        },
      ];
      return data;
    }
  }

  function createChartJSProfiles(displayedLanes) {
    //console.log(displayedLanes)
    var chartData = {};
    var posit;
    var colorPos;
    var colorDict = { 0: ["#29b6f6", "#0277bd"], 1: ["#66bb6a", "#2e7d32"], 2: ["#ab47bc", "#6a1b9a"], 3: ["#f44336", "#c62828"] };
    chartData["datasets"] = [];
    //if i'm only ever going to show two lanes why bother with the loop.  Dang - people want to see more than one lane
    //console.log('how many lanes',displayedLanes.length)
    if (displayedLanes.length === 1) {
      chartData["datasets"] = [{}, {}]; //logic required shape
      chartData["datasets"][0]["label"] = "Lane " + displayedLanes[0]["laneName"];
      chartData["datasets"][0]["data"] = displayedLanes[0]["lineProfileOfLane"];
      chartData["datasets"][0]["interpData"] = displayedLanes[0]["lineProfileInterp"];
      chartData["datasets"][0]["borderColor"] = colorDict[0][0]; //'#88e5ff'
      chartData["datasets"][0]["showLine"] = true;
      chartData["datasets"][0]["pointRadius"] = 0;
      chartData["datasets"][0]["pointHoverRadius"] = 0;
      chartData["datasets"][0]["borderWidth"] = 1.5;
      chartData["datasets"][1]["label"] = "Lane " + displayedLanes[0]["laneName"] + "_BGR";
      chartData["datasets"][1]["data"] = displayedLanes[0]["bgLineProfileOfLane"];
      chartData["datasets"][1]["borderColor"] = colorDict[0][1]; //'red'
      chartData["datasets"][1]["showLine"] = true;
      chartData["datasets"][1]["pointRadius"] = 0;
      chartData["datasets"][1]["pointHoverRadius"] = 0;
      chartData["datasets"][1]["borderWidth"] = 1.5;
    } else {
      for (var i = 0; i < displayedLanes.length; i++) {
        posit = i * 2;
        colorPos = i % 4;
        chartData["datasets"].push({}, {});
        chartData["datasets"][posit] = {};
        chartData["datasets"][posit + 1] = {};
        chartData["datasets"][posit]["label"] = "Lane " + displayedLanes[i]["laneName"];
        chartData["datasets"][posit]["data"] = displayedLanes[i]["lineProfileOfLane"];
        chartData["datasets"][posit]["interpData"] = displayedLanes[i]["lineProfileInterp"];
        chartData["datasets"][posit]["borderColor"] = colorDict[colorPos][0];
        chartData["datasets"][posit]["showLine"] = true;
        chartData["datasets"][posit]["pointRadius"] = 0;
        chartData["datasets"][posit]["pointHoverRadius"] = 0;
        chartData["datasets"][posit]["borderWidth"] = 1.5;
        chartData["datasets"][posit + 1]["label"] = "Lane " + displayedLanes[i]["laneName"] + "_BGR";
        chartData["datasets"][posit + 1]["data"] = displayedLanes[i]["bgLineProfileOfLane"];
        chartData["datasets"][posit + 1]["borderColor"] = colorDict[colorPos][1];
        chartData["datasets"][posit + 1]["showLine"] = true;
        chartData["datasets"][posit + 1]["pointRadius"] = 0;
        chartData["datasets"][posit + 1]["pointHoverRadius"] = 0;
        chartData["datasets"][posit + 1]["borderWidth"] = 1.5;
      }
    }

    //return [displayedLanes.length === 2 ? makeDiffLane(chartData) : setLaneCJSData(chartData)]
    return [setLaneCJSData(chartData)];
  }

  function createInterpProfileArrays(chartData) {
    var yVals_0 = [];
    var yVals_1 = [];
    var xVals = [];
    for (var i = 0; i < chartData["datasets"][0]["interpData"].length; i++) {
      yVals_0.push(chartData["datasets"][0]["interpData"][i]["y"]);
      yVals_1.push(chartData["datasets"][2]["interpData"][i]["y"]);
      xVals.push(chartData["datasets"][0]["interpData"][i]["x"]);
    }
    return [yVals_0, yVals_1, xVals, yVals_0.reduce((a, b) => a + b, 0), yVals_1.reduce((a, b) => a + b, 0)];
  }

  function createDiffProfileArray(interpArrays) {
    let diffArray = [];
    if (interpArrays[3] > interpArrays[4]) {
      diffArray = interpArrays[0].map(function (num, idx) {
        //return num - interpArrays[1][idx];
        return { x: interpArrays[2][idx], y: num - interpArrays[1][idx] };
      });
    } else {
      diffArray = interpArrays[0].map(function (num, idx) {
        //return interpArrays[1][idx] - num;
        return { x: interpArrays[2][idx], y: interpArrays[1][idx] - num };
      });
    }
    return diffArray;
  }

  function makeDiffLane(chartData) {
    //console.log(chartData)
    //let interpArrays = createInterpProfileArrays(chartData)
    //let diffArray = createDiffProfileArray(createInterpProfileArrays(chartData))
    chartData["datasets"].push({});
    chartData["datasets"][4] = {};
    chartData["datasets"][4]["label"] = "Difference";
    chartData["datasets"][4]["data"] = createDiffProfileArray(createInterpProfileArrays(chartData));
    chartData["datasets"][4]["borderColor"] = "#c7be3c";
    chartData["datasets"][4]["showLine"] = true;
    chartData["datasets"][4]["pointRadius"] = 0;
    chartData["datasets"][4]["pointHoverRadius"] = 0;
    chartData["datasets"][4]["borderWidth"] = 1.5;
    //console.log(createDiffProfileArray(createInterpProfileArrays(chartData)))
    return [setLaneCJSData(chartData)];
  }

  //function tpnLaneMappingDisplay()

  function updateLaneDisplay(displayKey, fromWhichFunction, laneDict) {
    function setArray(whichRegion, displayKey, laneDict) {
      //console.log('whichRegion',whichRegion)
      //console.log('did i run this')
      //console.log(whichRegion,displayKey,laneDict)
      var laneCanvasRegionArray = [];
      var shouldClose = displayKey === "canvasLaneCenters" ? false : true;
      var laneIndexArray = [];
      for (var j = 0; j < Object.keys(laneDict).length; j++) {
        //console.log('did I even make it into loop')
        for (var i = 0; i < whichRegion["laneProfiles"].length; i++) {
          if (whichRegion["laneProfiles"][i]["borderID"] === laneDict[j]["borderID"]) {
            //console.log('did I ever make it in here')
            if (laneDict[j]["laneArray"].includes(whichRegion["laneProfiles"][i]["laneName"])) {
              laneIndexArray.push(parseInt(whichRegion["laneProfiles"][i]["laneName"]));

              laneCanvasRegionArray.push({
                id: "L" + String(i),
                color: whichRegion["laneProfiles"][i]["color"],
                points: whichRegion["laneProfiles"][i]["points"][displayKey],
                closed: shouldClose,
                fill: displayKey === "canvasLaneCenters" ? whichRegion["laneProfiles"][i]["color"] : "#00000000",
                laneIntegration: parseFloat(whichRegion["laneProfiles"][i]["laneIntegration"]),
                lineProfileOfLane: whichRegion["laneProfiles"][i]["points"]["lineProfile"],
                bgLineProfileOfLane: whichRegion["laneProfiles"][i]["points"]["bgLineProfile"],
                lineProfileInterp: whichRegion["laneProfiles"][i]["points"]["lineProfileInterp"],
                laneName: whichRegion["laneProfiles"][i]["laneName"],
              });
            }
          }
        }
      }
      return [createChartJSProfiles(laneCanvasRegionArray), setLaneRegionArray(laneCanvasRegionArray), setDisplayRegionArray(laneCanvasRegionArray), setLaneIndexArray(laneIndexArray)];
    }

    if (String(ORActiveImage) === "image0") {
      setArray(analyticResponse0, displayKey, laneDict);
    } else {
      //console.log('did i run this')
      setArray(analyticResponse1, displayKey, laneDict);
    }
  }

  function createChartJSData(theSwitch, tpnBool, theTPNSwitch) {
    //theSwitch is a historical artifact it was used to switch between showing lysates objects or band object.  This is always left to true now to show bands
    //tpnBool defines if tpn was requested
    //theTPNSwitch defines if all displays should reflect the tpn signals of the bands
    //console.log(theTPNSwitch)
    function constantLane(theLabel, whichResponse, whichROIs) {
      var theData = [];
      for (var i = 0; i < whichResponse[whichROIs].length; i++) {
        if (whichResponse[whichROIs][i]["active"] && whichResponse[whichROIs][i]["laneName"] === theLabel) {
          theData.push(whichResponse[whichROIs][i]["roiSignal"]);
        }
      }
      return theData;
    }

    function largestVal(scatPoints) {
      var cv = 0;
      var cx = 0;
      var cy = 0;
      var idx = 0;
      for (var i = 0; i < scatPoints.length; i++) {
        if (scatPoints[i]["r"] > cv) {
          cv = scatPoints[i]["r"];
          idx = i;
        }
        if (parseInt(scatPoints[i]["x"]) > cx) {
          cx = parseInt(scatPoints[i]["x"]);
        }
        if (parseInt(scatPoints[i]["y"]) > cy) {
          cy = parseInt(scatPoints[i]["y"]);
        }
      }
      return { lgv: cv, idxOflgv: idx, lgx: cx, lgy: cy };
    }

    function prepScatCJSData(scatPoints) {
      var totalLanePoints = [];
      var uniqueLanes = [];
      var scalar = largestVal(scatPoints);
      var theScalarPoint;
      for (var i = 0; i < scatPoints.length; i++) {
        scatPoints[i]["r"] = (scatPoints[i]["r"] / scalar["lgv"]) * bubbleScalar;
        if (!uniqueLanes.includes(scatPoints[i]["x"])) {
          //console.log(scatPoints[i])
          totalLanePoints.push({ x: Math.floor(scatPoints[i]["x"]).toString(), y: scatPoints[i]["laneIntegration"] });
          uniqueLanes.push(scatPoints[i]["x"]);
        }
        //totalLanePoints.push({x:scatPoints[i]['x'],y:scatPoints[i]['laneIntegration']})
        if (i === scalar["idxOflgv"]) {
          theScalarPoint = [scatPoints[i]];
          //console.log(theScalarPoint)
        }
      }
      return { scatPoints: scatPoints, theScalarPoint: theScalarPoint, totalLanePoints: totalLanePoints };
    }

    async function prepLanes(whichResponse) {
      //var color = []
      //var laneNames = []
      //console.log('in createChartJSData(), prepLanes(), whichResponse=',whichResponse)
      var laneCanvasRegionArray = [];
      var laneIndexArray = [];
      var boxCanvasRegionArray = [];
      for (var i = 0; i < whichResponse["laneProfiles"].length; i++) {
        laneIndexArray.push(parseInt(whichResponse["laneProfiles"][i]["laneName"]));
        laneCanvasRegionArray.push({
          id: "L" + String(i),
          color: whichResponse["laneProfiles"][i]["color"],
          points: whichResponse["laneProfiles"][i]["points"]["canvasLaneCenters"],
          laneIntegration: parseFloat(whichResponse["laneProfiles"][i]["laneIntegration"]),
          laneName: whichResponse["laneProfiles"][i]["laneName"],
          lineProfileOfLane: whichResponse["laneProfiles"][i]["points"]["lineProfile"],
          closed: false,
        });
        boxCanvasRegionArray.push({
          closed: true,
          id: "L" + String(i),
          color: whichResponse["laneProfiles"][i]["color"],
          points: whichResponse["laneProfiles"][i]["points"]["canvasLaneBox"],
        });
      }
      //return [setLaneRegionArray(laneCanvasRegionArray), setLaneIndexArray(laneIndexArray)]
      return [laneCanvasRegionArray, laneIndexArray, boxCanvasRegionArray];
    }
    function checkLaneFromLysate(gridRowData, theBorderID, theLane) {
      var hm = 0;
      for (var k = 0; k < gridRowData.length; k++) {
        if (parseInt(gridRowData[k]["lane"]) === theLane && gridRowData[k]["borderID"] === theBorderID) {
          hm += 1;
          break;
        }
      }
      return hm;
    }

    function createVisibleLaneArrayDictionary(borderIDs, whichResponse, whichROIs, gridRowData) {
      var lastID = gridRowData[gridRowData.length - 1].id;
      var count = 0;
      var laneDict = {};

      for (var j = 0; j < borderIDs.length; j++) {
        laneDict[j] = {};
        laneDict[j]["borderID"] = borderIDs[j];
        laneDict[j]["laneArray"] = [];
        for (var i = 0; i < whichResponse["laneProfiles"].length; i++) {
          if (whichResponse["laneProfiles"][i]["borderID"] === borderIDs[j]) {
            if (!laneDict[j]["laneArray"].includes(whichResponse["laneProfiles"][i]["laneName"])) {
              laneDict[j]["laneArray"].push(whichResponse["laneProfiles"][i]["laneName"]);
              //console.log('hey',borderIDs[j])
              if (checkLaneFromLysate(gridRowData, borderIDs[j], whichResponse["laneProfiles"][i]["laneName"]) === 0) {
                count += 1;
                var gridRowID = lastID + count;
                //console.log('I found the missing lane and it is',whichResponse['laneProfiles'][i]['laneName'])
                //gridRowData.push({borderID:-1})
                gridRowData.push({
                  borderID: borderIDs[j],
                  control: "undefined",
                  target: "undefined",
                  proteinStandard: "undefined",
                  composition: "undefined",
                  laneIntegration: parseFloat(whichResponse["laneProfiles"][i]["laneIntegration"]),
                  roiArea: "undefined",
                  id: gridRowID,
                  lane: String(whichResponse["laneProfiles"][i]["laneName"]),
                  band: -1,
                  signal: 0,
                  signal_bgr: 0,
                  molecularWeight: "tbd",
                  highlightColor: String(whichResponse["laneProfiles"][i]["color"]),
                  verticalPos: 0,
                  horizPos: 0,
                  points: whichResponse["laneProfiles"][i]["points"],
                });
              }
            }
          }
        }
      }
      return [setVisibleLaneArray(laneDict)];
    }

    function prepData(whichResponse, whichROIs) {
      var signals = [];
      var boxSignals = [];
      var boxCanvasArray = [];
      var signals_bgr = [];
      var boxSignals_bgr = [];
      var labels = [];
      var color = [];
      var ORCanvasRegionArray = [];
      var laneCanvasRegionArray = [];
      var data = {};
      var scatData = {};
      var laneNames = [];
      var gridRowData = [];
      var scatPoint = [];
      var roiArea = [];
      var laneIntegration = [];
      var bgLaneIntegration = [];
      var borderIDs = [];
      var userRTB = [];
      var userRTL = [];
      var totalLaneData = {};
      var normedLaneIntegration = [];
      var normedSignals = [];
      var normedBoxSignals = [];
      var bandBoxesPresent = whichResponse.hasOwnProperty("bandBoxes");
      for (var i = 0; i < whichResponse[whichROIs].length; i++) {
        if (whichResponse[whichROIs][i]["active"]) {
          if (bandBoxesPresent) {
            boxCanvasArray.push({
              id: String(whichResponse["bandBoxes"][i]["id"]),
              color: whichResponse["bandBoxes"][i]["color"],
              points: whichResponse["bandBoxes"][i]["points"],
              closed: true,
              //fill: String(userColor) === 'undefined' ? String(whichResponse[whichROIs][i]['color']) : userColor
            });
            boxSignals.push(parseFloat(whichResponse["bandBoxes"][i]["roiSignal"]));
            boxSignals_bgr.push(parseFloat(whichResponse["bandBoxes"][i]["roiSignal_bgr"]));
          }

          if (whichResponse[whichROIs][i].hasOwnProperty("borderID")) {
            var cbid = parseInt(whichResponse[whichROIs][i]["borderID"]);
            if (!borderIDs.includes(cbid)) {
              borderIDs.push(cbid);
            }
          } else {
            if (!borderIDs.includes(0)) {
              borderIDs.push(0);
            }
          }
          if (whichResponse[whichROIs][i].hasOwnProperty("bgLaneIntegration")) {
            bgLaneIntegration.push(parseFloat(whichResponse[whichROIs][i]["bgLaneIntegration"]));
          } else {
            bgLaneIntegration.push(1);
          }
          if (whichResponse[whichROIs][i].hasOwnProperty("laneIntegration")) {
            //console.log(whichResponse[whichROIs][i]['laneIntegration'])
            laneIntegration.push(parseFloat(whichResponse[whichROIs][i]["laneIntegration"]));
          } else {
            laneIntegration.push(1);
          }
          let userColor = whichResponse[whichROIs][i]["userTagList"]["displayColor"];
          let userMW = whichResponse[whichROIs][i]["userTagList"]["mw"];
          let userRMB = whichResponse[whichROIs][i]["userTagList"]["rmb"];
          if (whichResponse[whichROIs][i]["userTagList"].hasOwnProperty(["relativeToBand"])) {
            userRTB = whichResponse[whichROIs][i]["userTagList"]["relativeToLargestBand"];
            userRTL = whichResponse[whichROIs][i]["userTagList"]["relativeToLane"];
          } else {
            userRTB = "undefined";
            userRTL = "undefined";
          }
          labels.push(String(whichResponse[whichROIs][i]["laneName"]));
          signals.push(parseFloat(whichResponse[whichROIs][i]["roiSignal"]));
          signals_bgr.push(parseFloat(whichResponse[whichROIs][i]["roiSignal_bgRemoved"]));

          if (whichResponse[whichROIs][i].hasOwnProperty("normedROISignal")) {
            //if (whichROIs === 'bands'){
            normedSignals.push(parseFloat(whichResponse[whichROIs][i]["normedROISignal"]));
            normedBoxSignals.push(parseFloat(whichResponse["bandBoxes"][i]["normedROISignal"]));
            normedLaneIntegration.push(parseFloat(whichResponse[whichROIs][i]["normedLaneIntegration"]));
            //}
          } else {
            //if (whichROIs === 'bands'){
            normedSignals.push("noTPN");
            normedLaneIntegration.push("noTPN");
            //}
          }
          color.push(String(changeColor()));
          if (!laneNames.includes(parseInt(whichResponse[whichROIs][i]["laneName"]))) {
            let theName = parseInt(whichResponse[whichROIs][i]["laneName"]);
            laneNames.push(theName);
            let index = whichResponse["laneProfiles"].findIndex((l) => l.laneName === theName);
            laneCanvasRegionArray.push({
              closed: true,
              id: "L" + String(whichResponse["laneProfiles"][index]["laneName"]),
              color: whichResponse["laneProfiles"][index]["color"],
              points: whichResponse["laneProfiles"][index]["points"]["canvasLaneBox"],
            });
          }

          if (whichResponse[whichROIs][i].hasOwnProperty("roiArea")) {
            if (whichResponse[whichROIs][i]["roiArea"] !== "inactive") {
              //console.log(i,'roiSignal=',whichResponse[whichROIs][i]['roiSignal'],'normedROISignal=',whichResponse[whichROIs][i]['normedROISignal'],'the scat r value',theTPNSwitch ? parseFloat(whichResponse[whichROIs][i]['normedROISignal']) : parseFloat(whichResponse[whichROIs][i]['roiSignal']))
              roiArea.push(whichResponse[whichROIs][i]["roiArea"]);
              scatPoint.push({
                y: whichResponse[whichROIs][i]["xyCenter"][1],
                x: parseInt(whichResponse[whichROIs][i]["laneName"]),
                r: theTPNSwitch
                  ? showBoxSignals
                    ? parseFloat(whichResponse["bandBoxes"][i]["normedROISignal"])
                    : parseFloat(whichResponse[whichROIs][i]["normedROISignal"])
                  : showBoxSignals
                  ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal"])
                  : parseFloat(whichResponse[whichROIs][i]["roiSignal"]),
                rmb: String(userRMB) === "undefined" ? "tbd" : userRMB,
                laneIntegration: theTPNSwitch ? whichResponse[whichROIs][i]["normedLaneIntegration"] : whichResponse[whichROIs][i]["laneIntegration"],
                immutLaneIntegration: whichResponse[whichROIs][i]["laneIntegration"],
              });
              gridRowData.push({
                boxPoints: bandBoxesPresent ? whichResponse["bandBoxes"][i]["points"] : "noBoxPoints",
                boxSignal: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal"]) : "noBox",
                boxSignal_bgr: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal_bgRemoved"]) : "noBox",
                normalizedLaneIntegration: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedLaneIntegration"]) : "noTPN",
                normalizedBoxSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse["bandBoxes"][i]["normedROISignal"]) : "noBoxTPN",
                normalizedSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedROISignal"]) : "noTPN",
                relativeToLane: String(userRTL) === "undefined" ? "tbd" : userRTL,
                relativeToLargetsBand: String(userRTB) === "undefined" ? "tbd" : userRTB,
                relativeMobility: String(userRMB) === "undefined" ? "tbd" : userRMB,
                borderID: whichResponse[whichROIs][i].hasOwnProperty("borderID") ? parseInt(whichResponse[whichROIs][i]["borderID"]) : 0,
                control: whichResponse[whichROIs][i]["userTagList"]["control"],
                target: whichResponse[whichROIs][i]["userTagList"]["target"],
                proteinStandard: whichResponse[whichROIs][i]["userTagList"]["standardType"],
                composition: whichResponse[whichROIs][i]["userTagList"]["composition"],
                laneIntegration: parseFloat(whichResponse[whichROIs][i]["laneIntegration"]),
                bgLaneIntegration: parseFloat(whichResponse[whichROIs][i]["bgLaneIntegration"]),
                roiArea: parseFloat(whichResponse[whichROIs][i]["roiArea"]),
                id: parseInt(whichResponse[whichROIs][i]["id"]),
                lane: String(whichResponse[whichROIs][i]["laneName"]),
                band: i + 1,
                signal: parseFloat(whichResponse[whichROIs][i]["roiSignal"]),
                signal_bgr: parseFloat(whichResponse[whichROIs][i]["roiSignal_bgRemoved"]),
                molecularWeight: String(userMW) === "undefined" ? "tbd" : userMW,
                highlightColor: String(userColor) === "undefined" ? String(whichResponse[whichROIs][i]["color"]) : userColor,
                verticalPos: whichResponse[whichROIs][i]["xyCenter"][1],
                horizPos: whichResponse[whichROIs][i]["xyCenter"][0],
                points: whichResponse[whichROIs][i]["points"],
              });
            } else {
              roiArea.push(1);
              gridRowData.push({
                boxPoints: bandBoxesPresent ? whichResponse["bandBoxes"][i]["points"] : "noBoxPoints",
                boxSignal: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal"]) : "noBox",
                boxSignal_bgr: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal_bgRemoved"]) : "noBox",
                normalizedLaneIntegration: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedLaneIntegration"]) : "noTPN",
                normalizedBoxSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse["bandBoxes"][i]["normedROISignal"]) : "noBoxTPN",
                normalizedSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedROISignal"]) : "noTPN",
                relativeToLane: String(userRTL) === "undefined" ? "tbd" : userRTL,
                relativeToLargetsBand: String(userRTB) === "undefined" ? "tbd" : userRTB,
                relativeMobility: String(userRMB) === "undefined" ? "tbd" : userRMB,
                borderID: whichResponse[whichROIs][i].hasOwnProperty("borderID") ? parseInt(whichResponse[whichROIs][i]["borderID"]) : 0,
                control: whichResponse[whichROIs][i]["userTagList"]["control"],
                target: whichResponse[whichROIs][i]["userTagList"]["target"],
                proteinStandard: whichResponse[whichROIs][i]["userTagList"]["standardType"],
                composition: whichResponse[whichROIs][i]["userTagList"]["composition"],
                laneIntegration: parseFloat(whichResponse[whichROIs][i]["laneIntegration"]),
                bgLaneIntegration: parseFloat(whichResponse[whichROIs][i]["bgLaneIntegration"]),
                roiArea: parseFloat(1),
                id: parseInt(whichResponse[whichROIs][i]["id"]),
                lane: String(whichResponse[whichROIs][i]["laneName"]),
                band: i + 1,
                signal: parseFloat(whichResponse[whichROIs][i]["roiSignal"]),
                signal_bgr: parseFloat(whichResponse[whichROIs][i]["roiSignal_bgRemoved"]),
                molecularWeight: String(userMW) === "undefined" ? "tbd" : userMW,
                highlightColor: String(userColor) === "undefined" ? String(whichResponse[whichROIs][i]["color"]) : userColor,
                verticalPos: whichResponse[whichROIs][i]["xyCenter"][1],
                horizPos: whichResponse[whichROIs][i]["xyCenter"][0],
                points: whichResponse[whichROIs][i]["points"],
              });
            }
          } else {
            roiArea.push(1);
            gridRowData.push({
              boxPoints: bandBoxesPresent ? whichResponse["bandBoxes"][i]["points"] : "noBoxPoints",
              boxSignal: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal"]) : "noBox",
              boxSignal_bgr: bandBoxesPresent ? parseFloat(whichResponse["bandBoxes"][i]["roiSignal_bgRemoved"]) : "noBox",
              normalizedLaneIntegration: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedLaneIntegration"]) : "noTPN",
              normalizedBoxSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse["bandBoxes"][i]["normedROISignal"]) : "noBoxTPN",
              normalizedSignal: whichResponse[whichROIs][i].hasOwnProperty("normedROISignal") ? parseFloat(whichResponse[whichROIs][i]["normedROISignal"]) : "noTPN",
              relativeToLane: String(userRTL) === "undefined" ? "tbd" : userRTL,
              relativeToLargetsBand: String(userRTB) === "undefined" ? "tbd" : userRTB,
              relativeMobility: String(userRMB) === "undefined" ? "tbd" : userRMB,
              borderID: whichResponse[whichROIs][i].hasOwnProperty("borderID") ? parseInt(whichResponse[whichROIs][i]["borderID"]) : 0,
              control: whichResponse[whichROIs][i]["userTagList"]["control"],
              target: whichResponse[whichROIs][i]["userTagList"]["target"],
              proteinStandard: whichResponse[whichROIs][i]["userTagList"]["standardType"],
              composition: whichResponse[whichROIs][i]["userTagList"]["composition"],
              laneIntegration: parseFloat(whichResponse[whichROIs][i]["laneIntegration"]),
              bgLaneIntegration: parseFloat(whichResponse[whichROIs][i]["bgLaneIntegration"]),
              roiArea: parseFloat(1),
              id: parseInt(whichResponse[whichROIs][i]["id"]),
              lane: String(whichResponse[whichROIs][i]["laneName"]),
              band: i + 1,
              signal: parseFloat(whichResponse[whichROIs][i]["roiSignal"]),
              signal_bgr: parseFloat(whichResponse[whichROIs][i]["roiSignal_bgRemoved"]),
              molecularWeight: String(userMW) === "undefined" ? "tbd" : userMW,
              highlightColor: String(userColor) === "undefined" ? String(whichResponse[whichROIs][i]["color"]) : userColor,
              verticalPos: whichResponse[whichROIs][i]["xyCenter"][1],
              horizPos: whichResponse[whichROIs][i]["xyCenter"][0],
              points: whichResponse[whichROIs][i]["points"],
            });
          }

          ORCanvasRegionArray.push({
            id: String(whichResponse[whichROIs][i]["id"]),
            color: String(userColor) === "undefined" ? String(whichResponse[whichROIs][i]["color"]) : userColor,
            points: showBoxSignals ? whichResponse["bandBoxes"][i]["points"] : whichResponse[whichROIs][i]["points"],
            closed: true,
            fill: showBoxSignals ? false : String(userColor) === "undefined" ? String(whichResponse[whichROIs][i]["color"]) : userColor,
          });
        }
        if (i === whichResponse[whichROIs].length - 1) {
          data["labels"] = labels;
          data["datasets"] = [{ label: "signals", data: signals, backgroundColor: color, borderWidth: 1 }];

          setCJSData(data);
          var test = prepScatCJSData(scatPoint);
          totalLaneData["datasets"] = [
            {
              label: "total lane",
              data: test["totalLanePoints"],
              backgroundColor: theTPNSwitch ? "#c9eb3480" : "#679dbd99",
              //borderColor:'rgb(255, 99, 132)',
              //pointStyle: 'circle',
              //hitRadius: 8,
              //hoverRadius: 8,
              //borderWidth:1,
              //radius:5,
              //showLine:true,
              //borderDash:[3, 3]
            },
          ];
          //console.log(showTPNSignal)
          scatData["datasets"] = [
            {
              label: whichROIs,
              backgroundColor: theTPNSwitch ? "#c9eb3480" : "#FFFFFF30",
              data: test["scatPoints"],
              order: 0,
              borderWidth: 0.5,
              borderColor: "white",
            },
            {
              label: "theScalar",
              backgroundColor: theTPNSwitch ? "#e71bfa95" : "#1563BD98",
              data: test["theScalarPoint"],
              order: 1,
            },
          ];
          //console.log('create CJSData',data)
          //console.log('create scatCJSData',scatData)
          //console.log('create totalLaneData',totalLaneData)
          setScatCJSData(scatData);
          setTotalLaneChartData(totalLaneData);
          setGridRowData(gridRowData);
          setCurrentTable(gridRowData);
          createVisibleLaneArrayDictionary(borderIDs, whichResponse, whichROIs, gridRowData);
          //if tpnBool == true I need to create an immutable visibleLaneArray from the first load

          //console.log(createVisibleLaneArrayDictionary(borderIDs,whichResponse,whichROIs,gridRowData))

          setORRegionArray(ORCanvasRegionArray);
          //if(bandBoxesPresent){
          //if(showBoxSignals){
          //  setORRegionArray(boxCanvasArray)
          //}else{
          //  setORRegionArray(ORCanvasRegionArray)
          //}
          //setORRegionArray(ORCanvasRegionArray)
          //setORRegionArray(boxCanvasArray)
          //This will show the band objects
          //setDisplayRegionArray(ORCanvasRegionArray)
          //This will show the lane boxes
          //console.log(laneCanvasRegionArray)

          setDisplayRegionArray(laneCanvasRegionArray);
        }
      }
    }
    //console.log('what is the manualAnalysisBool = to', manualAnalysisBool)
    //console.log('analyticResponse0',analyticResponse0)
    //console.log('analyticResponse1',analyticResponse1)
    //if (tpnBool && !manualAnalysisBool){
    if (tpnBool) {
      //console.log('analyticResponse0',analyticResponse0)
      //console.log('analyticResponse1',analyticResponse1)
      if (didCurrentUploadChange) {
        //console.log('am i resetting the tpn mapping, as I should be because,the currentUpload mapping changed this')
        resetTPNDialog();
        setDidCurrentUploadChange(false);
      } else {
        //console.log('am i not resetting the tpn mapping, as I should be because,the currentUpload remains unchanged')
        setDidCurrentUploadChange(false);
        prepTPNLanes(tpnMapping).then(function (value) {
          setTPNMappingLines(createTPNMappingLines(value["laneIndexArray0"], value["laneCanvasRegionArray0"], value["laneIndexArray1"], value["laneCanvasRegionArray1"], tpnMapping));
          setTPNLaneArrays({
            0: {
              imageID: analyticResponse0["imageID"],
              laneIndexArray: value["laneIndexArray0"],
              laneCanvasRegionArray: value["laneCanvasRegionArray0"],
            },
            1: { imageID: analyticResponse1["imageID"], laneIndexArray: value["laneIndexArray1"], laneCanvasRegionArray: value["laneCanvasRegionArray1"] },
          });
        });
      }

      //prepTPNLanes(tpnMapping).then(function(value){
      //  setTPNMappingLines(createTPNMappingLines(value['laneIndexArray0'],value['laneCanvasRegionArray0'],value['laneIndexArray1'],value['laneCanvasRegionArray1'],tpnMapping));
      //  setTPNLaneArrays({
      //    0:
      //      {
      //        'imageID':analyticResponse0['imageID'],
      //        'laneIndexArray':value['laneIndexArray0'],
      //        'laneCanvasRegionArray':value['laneCanvasRegionArray0']
      //      },
      //    1:
      //      {'imageID':analyticResponse1['imageID'],
      //      'laneIndexArray':value['laneIndexArray1'],
      //      'laneCanvasRegionArray':value['laneCanvasRegionArray1']
      //    }
      //  })
      //})
    }
    if (ORActiveImage === "image0") {
      //console.log(analyticResponse0)
      prepLanes(analyticResponse0).then(function (value) {
        setLaneRegionArray(value[0]);
        setLaneIndexArray(value[1]);
        setDisplayRegionArray(value[2]);
        //console.log('laneCanvasRegionArray',value[0])
        //console.log('laneIndexArray',value[1])
      });
      if (theSwitch) {
        prepData(analyticResponse0, "bands");
      } else {
        prepData(analyticResponse0, "lysates");
      }
    } else {
      //console.log(analyticResponse1)
      prepLanes(analyticResponse1);
      if (theSwitch) {
        prepData(analyticResponse1, "bands");
      } else {
        prepData(analyticResponse1, "lysates");
      }
    }
  }

  function showAnalysisResult(whichFunction) {
    //console.log('imagesForShow',imagesForShow)
    //console.log('activeImage',activeImage)
    if (imagesForShow !== null) {
      if (activeImage === "image0") {
        //console.log('i am setting ORImage to',imagesForShow[0],'from activeImage =',activeImage)
        setORImage(imagesForShow[0]);
        setRMBounds({ v_extent: naturalHeight0, top: 10, bottom: naturalHeight0 - 10 });
      } else if (activeImage === "image1") {
        //console.log('i am setting ORImage to',imagesForShow[1],'from activeImage =',activeImage)
        setORImage(imagesForShow[1]);
        setRMBounds({ v_extent: naturalHeight1, top: 10, bottom: naturalHeight1 - 10 });
      } else if (activeImage === null) {
        setORImage(null);
      }
    }

    if (whichFunction === "fromCurrentAnalysis") {
      //This is responsive from the Auto Button and not the Auto Select Item
      //console.log('analyticResponse0',analyticResponse0)
      //console.log('analyticResponse1',analyticResponse1)
      //console.log(currentUpload)
      setExpanded2("analyticResponse");
      setExpanded3("analyticCharts");
      setORActiveImage(activeImage);
      changeColor();
      //at this point I should not hard code the tpnBool, rather I should read it from the upload condition
      createChartJSData(true, tpnRequested, showTPNSignal);
      setReadyToViewCurrent0(false);
      setReadyToViewCurrent1(false);
      setImage0AnalysisStatus(null);
      setImage1AnalysisStatus(null);
    } else if (whichFunction === "fromPriorAnalysis") {
      //This is triggered from the Auto select item and not the Auto Button
      if (selectedPriorAnalysisID === "None") {
        console.log("did i do nothing");
        var donuthing = 0;
      } else {
        setORActiveImage(activeImage);
        setExpanded2("analyticResponse");
        setExpanded3("analyticCharts");
        changeColor();
        //in this position i am temporarily hard coding the tpn bool as false, meaning you will only see it from the auto button
        createChartJSData(true, tpnRequested, showTPNSignal);
        setRetrieveVisState({ image0: "hidden", image1: "hidden" });
        setReadyToViewCurrent0(false);
        setReadyToViewCurrent1(false);
        setImage0AnalysisStatus(null);
        setImage1AnalysisStatus(null);
      }
    }
  }

  async function getTheData(getObjectParams, analysisID, imageIndex, tpnBool, theImageID) {
    var theResponse;
    //console.log('in getTheData, what is the tpnBool()',tpnBool)
    //console.log('here i am')
    s3.getObject(getObjectParams, function (err, data) {
      if (err) {
        console.log(err);
        if (!tpnBool) {
          if (imageIndex === 0) {
            setLoadingPriorAnalysis({ image0: false, image1: loadingPriorAnalysis.image1 });
          } else {
            setLoadingPriorAnalysis({ image0: loadingPriorAnalysis.image0, image1: false });
          }
        } else {
          console.log("confirm that when tpnBool==true, this prints", tpnBool);
        }
      } else {
        //setLoadingPriorAnalysis(false)
        //console.log('here is the analytic response',JSON.parse(data.Body))

        theResponse = JSON.parse(data.Body);
        theResponse["imageID"] = theImageID;
        if (!tpnBool) {
          //console.log('confirm that when tpnBool==false, this prints',tpnBool)
          if (imageIndex === 0) {
            //console.log('here is the analytic response0',JSON.parse(data.Body))
            setViewingAnalysisID(analysisID);
            setAnalyticResponse0(theResponse);
            setReadyToViewCurrent0(true);
            setReadyToViewCurrent1(false);
            setUniqueUserBandTags(createUniqueArray(theResponse, "bands", null));
            setUniqueUserLysateTags(createUniqueArray(theResponse, "lysates", null));
            setLoadingPriorAnalysis({ image0: false, image1: loadingPriorAnalysis.image1 });
            setImage0AnalysisStatus("ready");
            setAnalysisStatusColor0("#399dbc");
          } else {
            //console.log('here is the analytic response1',JSON.parse(data.Body))
            setAnalyticResponse1(theResponse);
            setViewingAnalysisID(analysisID);
            setReadyToViewCurrent1(true);
            setReadyToViewCurrent0(false);
            setUniqueUserBandTags(createUniqueArray(theResponse, "bands", null));
            setUniqueUserLysateTags(createUniqueArray(theResponse, "lysates", null));
            setLoadingPriorAnalysis({ image0: loadingPriorAnalysis.image0, image1: false });
            setImage1AnalysisStatus("ready");
            setAnalysisStatusColor1("#399dbc");
          }
        } else {
          //console.log('confirm that when tpnBool==true, this prints',tpnBool)
          if (imageIndex === 0) {
            setAnalyticResponse0(theResponse);
          } else {
            setAnalyticResponse1(theResponse);
          }
        }
      }
    });
  }

  function getAnalysis(theImageID, analysisID, imageIndex, fromWhichFunction, analysisIndex) {
    //console.log('analysisID',analysisID)
    //console.log('getAnalysis called with fromWhichFunction variable =',fromWhichFunction)
    //console.log('what is the indexOfAnalysis at start of getAnalysis()',indexOfAnalysis)
    var theIndex = indexOfAnalysis === null ? 0 : indexOfAnalysis;
    var headObjectParams;
    //var headObjectParams = {
    //  Bucket: AWSConfig.creds.bucketName,
    //  Key: theImageID + '/' + analysisID + '_allROISignals_userMod.json'
    //}
    //console.log('prior to whichFunction the key =,',theImageID + '/' + analysisID +'_allROISignals_userMod.json')
    //var getObjectParams
    if (fromWhichFunction === "viewPriorAnalysis" || fromWhichFunction === "sfaLambda") {
      //this will be called by the following conditions:
      //1. no tpn, 1st time viewing auto analysis from list selection of 'auto'
      //2. no tpn, 1st time viewing of manual analysis from list selection of '0_x'
      //3. no tpn, view of edited auto analysis from list selection of 'auto' - this will trigger the dialog prompt
      //4. no tpn, view of edited manual analysis from list selection of '0_x' - this will trigger the dialog prompt
      //5. no tpn, view of 1st time manual analysis from Blue Manual button - this will not trigger the dialog prompt
      //console.log('fromWhichFunction',fromWhichFunction, 'tpn request status',tpnRequested)
      setViewingAnalysisID(analysisID);

      headObjectParams = {
        Bucket: database, //AWSConfig.creds.bucketName,
        Key: theImageID + "/" + analysisID + "_allROISignals_userMod.json",
      };
      s3.headObject(headObjectParams, function (err, data) {
        if (err) {
          //console.log('in the head object error')
          //getObjectParams = {
          //  Bucket: AWSConfig.creds.bucketName,
          //  Key: theImageID + '/' + analysisID + '_allROISignals.json'
          //}

          //getTheData(getObjectParams, analysisID, imageIndex, false, theImageID)
          specialCaseTPN("_allROISignals.json", "id");
        } else {
          //we need a dialog to let the use decide if they want to get their original raw data or user analyzed data
          //console.log('the analysisID',analysisID)
          //setViewingAnalysisID(analysisID)
          setWhichAnalysisDialog(true);
        }
      });
    } else if (fromWhichFunction === "viewDefaultAnalysis") {
      //the following conditions will trigger this:
      //1.  no tpn, list selection of auto that has been edited, but the user requests to see the default instead
      //2,  no tpn, list selection of '0_x' that has been edited, but the user requests to see the default instead
      //console.log('fromWhichFunction',fromWhichFunction, 'tpn request status',tpnRequested)
      setWhichAnalysisDialog(false);
      //getObjectParams = {
      //  Bucket: AWSConfig.creds.bucketName,
      //  Key: theImageID + '/' + analysisID + '_allROISignals.json'
      //}
      //getTheData(getObjectParams, analysisID, imageIndex, false, theImageID)
      if (Array.isArray(analysisID)) {
        //console.log('running specialCaseTPN() from "viewDefaultAnalysis", with "_allROISignals.json", and "response"')
        //console.log('analysisID',analysisID)
        //console.log('typeOf',Array.isArray(analysisID))
        specialCaseTPN("_allROISignals.json", "response");
      } else {
        //console.log('running specialCaseTPN()from "viewDefaultAnalysis", with "_allROISignals.json", and "id"')
        //console.log('analysisID',analysisID)
        //console.log('typeOf',Array.isArray(analysisID))
        specialCaseTPN("_allROISignals.json", "id");
      }
      //specialCaseTPN('_allROISignals.json','id')
    } else if (fromWhichFunction === "viewAutoAnalysis" || fromWhichFunction === "viewManualAnalysisWithTPN") {
      //for this special else if case, tthe incoming variable analysisID is the full dictionary of a DB query of the image ID
      //the following conditions will trigger this:
      //1. no tpn, 1st time view of Auto from Blue Auto button
      //2. no tpn, viewing of edited Auto analysis from Blue Auto button - no dialog is provided and the user is forced to view the edited analysis.  This needs correction
      setViewingAnalysisID(analysisID);

      //console.log('fromWhichFunction',fromWhichFunction, 'tpn request status',tpnRequested)
      //console.log('indexOfAnalysis',indexOfAnalysis)
      headObjectParams = {
        Bucket: database, //AWSConfig.creds.bucketName,
        Key: theImageID + "/" + analysisID[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"] + "_allROISignals_userMod.json",
      };
      s3.headObject(headObjectParams, function (err, data) {
        // if the this object does not exists, it means the user has not yet modified the analysis, so we can just get the original analysis
        if (err) {
          //console.log('in the head object error')
          //getObjectParams = {
          //  Bucket: AWSConfig.creds.bucketName,
          //  Key: theImageID + '/' + analysisID[0]['Items'][0]['analysisIDs']['L'][indexOfAnalysis]['S'] + '_allROISignals.json'
          //}
          specialCaseTPN("_allROISignals.json", "response");
        } else {
          // in this case the user has modified the analysis, so we need to open the dialog and confirm which analysis the user wants
          //console.log('analysisID',analysisID)
          //setViewingAnalysisID(analysisID[0]['Items'][0]['analysisIDs']['L'][indexOfAnalysis]['S'])
          setWhichAnalysisDialog(true);
          //specialCaseTPN('_allROISignals_userMod.json','response')
        }
      });
    } else if (fromWhichFunction === "viewUserModAnalysis") {
      //the following conditions will trigger this:
      //1. no tpn, auto selected from list and user requests to see the user modded analysis via the dialog
      //2. no tpn, '0_x' selected from list and user requests to see the user modded analysis via the dialog
      //console.log('fromWhichFunction',fromWhichFunction, 'tpn request status',tpnRequested)
      setWhichAnalysisDialog(false);

      //getObjectParams = {
      //  Bucket: AWSConfig.creds.bucketName,
      //  Key: theImageID + '/' + analysisID + '_allROISignals_userMod.json'
      //}
      //getTheData(getObjectParams, analysisID, imageIndex,false,theImageID)
      //console.log('what is the analysisID',analysisID,'what is tpnRequested',tpnRequested)
      if (Array.isArray(analysisID)) {
        //console.log('running specialCaseTPN() from "viewUserModAnalysis", with "_allROISignals_userMod.json", and "response"')
        specialCaseTPN("_allROISignals_userMod.json", "response");
      } else {
        //console.log('running specialCaseTPN() from "viewUserModAnalysis", with "_allROISignals_userMod.json", and "id"')
        specialCaseTPN("_allROISignals_userMod.json", "id");
      }
      //specialCaseTPN('_allROISignals_userMod.json','id')
    }

    function specialCaseTPN(userModString, IDorResponse) {
      var theAnalysisID;
      var wasTPNRequested;
      var theIndex = indexOfAnalysis === null ? 0 : indexOfAnalysis;
      //console.log('analysisID inside specialCaseTPN',analysisID)
      if (IDorResponse === "id") {
        theAnalysisID = analysisID;
        wasTPNRequested = tpnRequested;
      } else {
        //console.log(theIndex)
        theAnalysisID = analysisID[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"];
        wasTPNRequested = analysisID[0]["Items"][0]["TPNRequested"]["S"] === "true";
      }
      //var dataArray = analysisID[0]['Items'][0]
      //var wasTPNRequested = tpnRequested//(analysisID[0]['Items'][0]['TPNRequested']['S']==='true')
      setWhichAnalysisDialog(false);
      if (theAnalysisID !== null) {
        headObjectParams = {
          Bucket: database, //AWSConfig.creds.bucketName,
          Key: theImageID + "/" + theAnalysisID + userModString, //'_allROISignals.json'
        };
        //The first run of getTheData, I set tpnBool as false so that I only set all the appropriate data imageIndex corresponding the imageIndex for which the button "auto" was pressed
        //This ensures if only one image is present I still get that data
        //console.log('I am getting the analysis results of the requested image, which is in position '+String(imageIndex))
        getTheData(headObjectParams, theAnalysisID, imageIndex, false, theImageID);
        if (wasTPNRequested) {
          var dataArray = analysisID[0]["Items"][0];
          //then I prepare to get the data for the other image.  In this case as set tpnBool to true, so that I can set the analyticResponse of the other image index, without overwriting
          //the table data
          if (imageIDsForShow.length > 1) {
            var secondImageIndex;
            var secondObjectParams;
            if (imageIDsForShow.indexOf(theImageID) === 0) {
              secondImageIndex = 1;
              //console.log('I am also getting the analysis results of the second image, which is in position '+String(secondImageIndex) +' because TPN was requested')
              getAutoAnalysisID(imageIDsForShow[1]).then(function (value) {
                secondObjectParams = {
                  Bucket: database, //AWSConfig.creds.bucketName,
                  Key: value[0]["Items"][0]["imageID"]["S"] + "/" + value[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"] + userModString, //'_allROISignals.json'
                };
                getTheData(secondObjectParams, value[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"], secondImageIndex, true, value[0]["Items"][0]["imageID"]["S"]).then(() => {
                  currentUpload[0]["TPNAnalysisID"] = dataArray["TPNAnalysisIDs"]["L"][theIndex]["S"];
                  currentUpload[0]["TPNLaneMapping"] = dataArray["TPNLaneMapping"]["L"][theIndex]["M"];
                  currentUpload[1]["TPNAnalysisID"] = dataArray["TPNAnalysisIDs"]["L"][theIndex]["S"];
                  currentUpload[1]["TPNLaneMapping"] = dataArray["TPNLaneMapping"]["L"][theIndex]["M"];
                  setDidCurrentUploadChange(true);
                });
              });
            } else {
              secondImageIndex = 0;
              //console.log('I am also getting the analysis results of the second image , which is in position '+String(secondImageIndex) +' because TPN was requested')
              getAutoAnalysisID(imageIDsForShow[0]).then(function (value) {
                secondObjectParams = {
                  Bucket: database, //AWSConfig.creds.bucketName,
                  Key: value[0]["Items"][0]["imageID"]["S"] + "/" + value[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"] + userModString, //'_allROISignals.json'
                };
                getTheData(secondObjectParams, value[0]["Items"][0]["analysisIDs"]["L"][theIndex]["S"], secondImageIndex, true, value[0]["Items"][0]["imageID"]["S"]).then(() => {
                  currentUpload[0]["TPNAnalysisID"] = dataArray["TPNAnalysisIDs"]["L"][theIndex]["S"];
                  currentUpload[0]["TPNLaneMapping"] = dataArray["TPNLaneMapping"]["L"][theIndex]["M"];
                  currentUpload[1]["TPNAnalysisID"] = dataArray["TPNAnalysisIDs"]["L"][theIndex]["S"];
                  currentUpload[1]["TPNLaneMapping"] = dataArray["TPNLaneMapping"]["L"][theIndex]["M"];
                  setDidCurrentUploadChange(true);
                });
              });
            }
          }
        }
      } else {
        alert('Something went wrong with the "Auto" analysis.  You will need to run "Manual"');
      }
    }
  }

  async function queryTableWithSingleImageID(theImageID) {
    var theResponse = [];
    var queryDBParams = {
      TableName: "ila-rawImage-table",
      KeyConditions: {
        imageID: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: theImageID }],
        },
      },
    };
    theResponse.push(await query(queryDBParams));
    return theResponse;
  }

  var findIndexByString = function (arr, target) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].S === target) {
        return i;
      }
    }
    return -1; // Return -1 if not found
  };

  function sfaLambda(theImageID, analysisID, imageIndex, indexOfTargetImage, tpnAnalysisID) {
    var thePayload = '{"imageID":"' + String(theImageID) + '","analysisID":"' + String(analysisID) + '","asConvexHull":"True","calledFromClient":"True"}';
    var params = {
      FunctionName: "arn:aws:lambda:us-west-2:223634448983:function:ila-submitForAnalysis-3-lambda",
      Payload: thePayload,
    };

    lambda.invoke(params, function (err, data) {
      if (err) {
        console.log(err);
      } else {
        //need to institute a waitfor on the results to I can inform the user the results are ready

        // for individual analysis this waitfor is correct.  if a manual tpn analysis was run, I need to wait for the tpn analysis to complete
        if (tpnRequested) {
          // if tpn was requested then this manual analysis needs to:
          // 1. wait for the tpn analysis to complete and then get and set the analyticResponse(x) to the imageIndex of the target image
          // 3. if the imageIndex = the indexOfTargetImage I need to reset the TPNAnalysisID, TPNAnalysisStatus, TPNLaneMapping in currentUpload for both 0 and 1, then run create
          // 2. it needs to get and set the analyticResponse(x) to the imageIndex of the non-target image.  We do not need to wait for tpn analysis to be complete on this image

          if (imageIndex === indexOfTargetImage) {
            //s3.waitFor('objectExists', { Bucket: AWSConfig.creds.bucketName, Key: theImageID + '/' + tpnAnalysisID + '_finalTPNStatus.json' }, function (err, data) {
            s3.waitFor("objectExists", { Bucket: database, Key: theImageID + "/" + tpnAnalysisID + "_finalTPNStatus.json" }, function (err, data) {
              if (err) {
                console.log(err);
              } else {
                //var params = { Bucket: AWSConfig.creds.bucketName, Key: theImageID + '/' + tpnAnalysisID + '_finalTPNStatus.json' }
                var params = { Bucket: database, Key: theImageID + "/" + tpnAnalysisID + "_finalTPNStatus.json" };
                s3.getObject(params, function (err, data) {
                  if (err) {
                    console.log(err);
                  } else {
                    if (JSON.parse(data.Body)["finalTPNStatus"] === "success") {
                      //then I need to update the currentUpload TPN lane data and reset it
                      queryTableWithSingleImageID(theImageID).then(function (value) {
                        var dataArray = value[0]["Items"][0];
                        var analysisIndex = findIndexByString(dataArray["TPNAnalysisIDs"]["L"], tpnAnalysisID);
                        setIndexOfAnalysis(analysisIndex);
                        getAnalysis(theImageID, value, imageIndex, "viewManualAnalysisWithTPN", analysisIndex);
                      });
                    } else if (JSON.parse(data.Body)["finalTPNStatus"] !== "success") {
                      alert(
                        "Using the changes you made to the regions of interest submitted for analysis, the Total Protein Normalization lane mapping analysis failed. The results shown below, reflect the automated analysis.  You can try again with a change to the ROIs."
                      );
                      queryTableWithSingleImageID(theImageID).then(function (value) {
                        //var dataArray = value[0]['Items'][0]
                        //var indexOfAnalysis = findIndexByString(dataArray['TPNAnalysisIDs']['L'],tpnAnalysisID)
                        setIndexOfAnalysis(0);
                        getAnalysis(theImageID, value, imageIndex, "viewAutoAnalysis", 0);
                      });
                    }
                  }
                });
              }
            });
          }
        } else {
          //s3.waitFor('objectExists', { Bucket: AWSConfig.creds.bucketName, Key: theImageID + '/' + analysisID + '_allROISignals.json' }, function (err, data) {
          s3.waitFor("objectExists", { Bucket: database, Key: theImageID + "/" + analysisID + "_allROISignals.json" }, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              //console.log('the data time i am about to submit',sessionStorage.getItem('uploadDateTime'))
              syncTableWithDB(sessionStorage.getItem("uploadDateTime"), "from-sfaLambda");
              if (imageIndex === 0) {
                setSelectRegion0(null);
                setImage0AnalysisStatus("ready");
                setAnalysisStatusColor0("#399dbc"); //'green')
                getAnalysis(theImageID, analysisID, imageIndex, "sfaLambda", null);
              } else {
                setSelectRegion1(null);
                setImage1AnalysisStatus("ready");
                setAnalysisStatusColor1("#399dbc"); //'green')
                getAnalysis(theImageID, analysisID, imageIndex, "sfaLambda", null);
              }
            }
          });
        }
      }
    });
  }

  function updateDynamoAndTriggerLambda(theImageID, analysisID, tpnAnalysisID, lysateRegions, bandRegions, bandBoxRegions, borderRegions, imageIndex, indexOfTargetImage) {
    //console.log('updateDynamoAndTriggerLambda')
    async function putObjectsInS3(lysateRegions, bandRegions, bandBoxRegions, borderRegions, analysisID) {
      const uploadPromises = [
        uploadObject(lysateRegions, analysisID, "lysateRegions"),
        uploadObject(bandRegions, analysisID, "bandRegions"),
        uploadObject(bandBoxRegions, analysisID, "bandBoxRegions"),
        uploadObject(borderRegions, analysisID, "borderRegions"),
      ];

      await Promise.all(uploadPromises);
    }

    function uploadObject(data, analysisID, type) {
      const key = `${theImageID}/${analysisID}_${type}_User.json`;

      const uploadParams = {
        Bucket: database, //AWSConfig.creds.bucketName,
        Key: key,
        Body: JSON.stringify(data),
        StorageClass: "STANDARD_IA",
      };

      return new Promise((resolve, reject) => {
        s3.putObject(uploadParams, function (err, data) {
          if (err) {
            console.log("Error with putObject", err);
            reject(err);
          } else {
            //console.log('Uploaded', key);
            resolve();
          }
        });
      });
    }

    var roiKey = String(theImageID) + "/" + String(analysisID) + "_userImageAnnotationForML.json";
    var checkExistParams = {
      Key: { imageID: { S: theImageID } },
      TableName: "ila-rawImage-table",
      ConditionExpression: "attribute_exists(#anaid)",
      ExpressionAttributeNames: {
        "#anaid": "analysisIDs",
      },
    };
    dynamodb.updateItem(checkExistParams, function (err, data) {
      var updateROIParams = {};
      if (err) {
        console.log(err.code, "it does not exist so i will make it");
        if (err.code === "ConditionalCheckFailedException") {
          updateROIParams = {
            Key: { imageID: { S: theImageID } },
            TableName: "ila-rawImage-table",
            UpdateExpression: "set #anaid=:anaid, #anaROIKey=:anaROIKey",
            ExpressionAttributeNames: {
              "#anaid": "analysisIDs",
              "#anaROIKey": "analysisROIKeys",
            },
            ExpressionAttributeValues: {
              ":anaid": { L: [AWS.DynamoDB.Converter.input(analysisID)] },
              ":anaROIKey": { L: [AWS.DynamoDB.Converter.input(roiKey)] },
            },
          };
          dynamodb.updateItem(updateROIParams, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              putObjectsInS3(lysateRegions, bandRegions, bandBoxRegions, borderRegions, analysisID)
                .then(() => sfaLambda(theImageID, analysisID, imageIndex, indexOfTargetImage, tpnAnalysisID))
                .catch((error) => {
                  // Handle errors here
                  console.error("An error occurred:", error);
                });
              //  var uploadArrays = [lysateRegions, bandRegions, bandBoxRegions, borderRegions]
              //  var uploadKeys = [
              //    theImageID + '/' + analysisID + '_lysateRegions_User.json',
              //    theImageID + '/' + analysisID + '_bandRegions_User.json',
              //    theImageID + '/' + analysisID + '_bandBoxRegions_User.json',
              //    theImageID + '/' + analysisID + '_borderRegions_User.json'
              //  ]
              //  var uploadParams = [{}, {}, {}, {}]
              //  for (var i = 0; i < uploadArrays.length; i++) {
              //    uploadParams[i] = {
              //      Bucket: AWSConfig.creds.bucketName,
              //      Body: JSON.stringify(uploadArrays[i]),
              //      Key: uploadKeys[i],
              //      StorageClass: "STANDARD_IA"
              //    }
              //    console.log(uploadArrays[i])
              //    s3.putObject(uploadParams[i], function (err, data) {
              //      if (err) {
              //        console.log('the error with putObject', err)
              //      } else {
              //        console.log('upload successful')
              //        //now we can trigger the lambda
              //
              //        sfaLambda(theImageID, analysisID, imageIndex, indexOfTargetImage,tpnAnalysisID)
              //      }
              //    })
              //  }
            }
          });
        }
      } else {
        //console.log('it does exist so I will append')
        if (tpnRequested && tpnAnalysisID !== null) {
          //console.log('i am sending a manual tpn to the dynamo')
          updateROIParams = {
            Key: { imageID: { S: theImageID } },
            TableName: "ila-rawImage-table",
            UpdateExpression:
              "set #anaid=list_append(#anaid,:anaid), #anaROIKey=list_append(#anaROIKey,:anaROIKey), #sfaStatus=:sfaStatus, #TPNRequestOrigin=:TPNRequestOrigin, #TPNAnalysisIDs=list_append(#TPNAnalysisIDs,:TPNAnalysisIDs), #TPNAnalysisStatus=list_append(#TPNAnalysisStatus,:TPNAnalysisStatus), #TPNLaneMapping=list_append(#TPNLaneMapping,:TPNLaneMapping)",
            ExpressionAttributeNames: {
              "#anaid": "analysisIDs",
              "#anaROIKey": "analysisROIKeys",
              "#sfaStatus": "sfaStatus",
              "#TPNRequestOrigin": "TPNRequestOrigin",
              "#TPNAnalysisIDs": "TPNAnalysisIDs",
              "#TPNAnalysisStatus": "TPNAnalysisStatus",
              "#TPNLaneMapping": "TPNLaneMapping",
            },
            ExpressionAttributeValues: {
              ":anaid": { L: [AWS.DynamoDB.Converter.input(analysisID)] },
              ":anaROIKey": { L: [AWS.DynamoDB.Converter.input(roiKey)] },
              ":sfaStatus": { S: "incomplete" },
              ":TPNRequestOrigin": { S: "fromApp" },
              ":TPNAnalysisIDs": { L: [AWS.DynamoDB.Converter.input(tpnAnalysisID)] },
              ":TPNAnalysisStatus": { L: [AWS.DynamoDB.Converter.input("incomplete")] },
              ":TPNLaneMapping": { L: [AWS.DynamoDB.Converter.input({})] },
            },
          };
        } else {
          updateROIParams = {
            Key: { imageID: { S: theImageID } },
            TableName: "ila-rawImage-table",
            UpdateExpression: "set #anaid=list_append(#anaid,:anaid), #anaROIKey=list_append(#anaROIKey,:anaROIKey)",
            ExpressionAttributeNames: {
              "#anaid": "analysisIDs",
              "#anaROIKey": "analysisROIKeys",
            },
            ExpressionAttributeValues: {
              ":anaid": { L: [AWS.DynamoDB.Converter.input(analysisID)] },
              ":anaROIKey": { L: [AWS.DynamoDB.Converter.input(roiKey)] },
            },
          };
        }
        dynamodb.updateItem(updateROIParams, function (err, data) {
          if (err) {
            console.log(err);
          } else {
            putObjectsInS3(lysateRegions, bandRegions, bandBoxRegions, borderRegions, analysisID)
              .then(() => sfaLambda(theImageID, analysisID, imageIndex, indexOfTargetImage, tpnAnalysisID))
              .catch((error) => {
                // Handle errors here
                console.error("An error occurred:", error);
              });
            //var uploadArrays = [lysateRegions, bandRegions, bandBoxRegions, borderRegions]
            //var uploadKeys = [
            //  theImageID + '/' + analysisID + '_lysateRegions_User.json',
            //  theImageID + '/' + analysisID + '_bandRegions_User.json',
            //  theImageID + '/' + analysisID + '_bandBoxRegions_User.json',
            //  theImageID + '/' + analysisID + '_borderRegions_User.json']
            //var uploadParams = [{}, {}, {}, {}]
            //for (var i = 0; i < uploadArrays.length; i++) {
            //  uploadParams[i] = {
            //    Bucket: AWSConfig.creds.bucketName,
            //    Body: JSON.stringify(uploadArrays[i]),
            //    Key: uploadKeys[i],
            //    StorageClass: "STANDARD_IA"
            //  }
            //  //console.log(uploadArrays[i])
            //  s3.putObject(uploadParams[i], function (err, data) {
            //    if (err) {
            //      console.log('the error with putObject', err)
            //    } else {
            //      //this is the wrong place to have this, because it calls the sfaLambda four times.  I need the loop to finish, then call sfa lamda once
            //      console.log('calling sfaLambda',theImageID, analysisID, imageIndex, indexOfTargetImage,tpnAnalysisID)
            //      sfaLambda(theImageID, analysisID, imageIndex, indexOfTargetImage, tpnAnalysisID)
            //    }
            //  })
            //}
          }
        });
      }
    });
  }

  function formatTheDate(theDate, bg) {
    var year, month, day, hour, minute, second;
    if (theDate != null) {
      year = theDate.$y;
      month = theDate.$M + 1;
      day = theDate.$D;
      hour = bg === "start" ? 0 : 23;
      minute = bg === "start" ? 0 : 59;
      second = bg === "start" ? 0 : 59;
    } else {
      var nullToday = bg === "start" ? dayjs().startOf("day") : dayjs().endOf("day");
      year = nullToday.$y;
      month = nullToday.$M + 1;
      day = nullToday.$D;
      hour = nullToday.$h;
      minute = nullToday.$m;
      second = nullToday.$s;
    }
    if (month < 10) {
      month = "0" + String(month);
    }
    if (day < 10) {
      day = "0" + String(day);
    }
    if (hour < 10) {
      hour = "0" + String(hour);
    }
    if (minute < 10) {
      minute = "0" + String(minute);
    }
    if (second < 10) {
      second = "0" + String(second);
    }
    return parseInt(String(year) + String(month) + String(day) + String(hour) + String(minute) + String(second));
  }
  function whatIsTheRange(theRange) {
    //alert("This is currently being worked on. The dates don't get used yet, but will shortly. For the time being, still click the cloud refresh icon to see your prior 30 days of uploaded data.")
    const today = dayjs();
    var dateArray;
    if (theRange[0] === null && theRange[1] === null) {
      theRange = [today.startOf("day"), today.endOf("day")];
      dateArray = [formatTheDate(theRange[0], "start"), formatTheDate(theRange[1], "end")];
      dateArray.sort();
    } else {
      dateArray = [formatTheDate(theRange[0], "start"), formatTheDate(theRange[1], "end")];
      dateArray.sort();
    }
    return dateArray;
  }

  function continueSubmitForAnalysis(ROIs, imageIndex, tpnAnalysisID, indexOfTargetImage) {
    //console.log('currentUpload',currentUpload)
    setDialogs({ noAnaSelectDialog: false });
    var analysisID = String(uuid.v4());
    var lysateRegions = {};
    var bandRegions = {};
    var bandBoxRegions = {};
    var borderRegions = {};
    var theSwapIndex;
    var theImageID = String(imageIDsForShow[imageIndex]);
    theSwapIndex = currentUpload.findIndex((item) => item.imageID === imageIDsForShow[imageIndex]);

    if (ROIs === "users") {
      if (imageIndex === 0) {
        //console.log('i ran the finally')
        //console.log('currentUpload',currentUpload[theSwapIndex])
        //console.log('imageIndex',imageIndex)
        if (activeLysateRegions0.length === 0) {
          lysateRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203");
        } else {
          lysateRegions[analysisID] = activeLysateRegions0;
        }
        if (activeBandRegions0.length === 0) {
          bandRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90");
        } else {
          bandRegions[analysisID] = activeBandRegions0;
        }
        if (activeBandBoxRegions0.length === 0) {
          //console.log('am i running this?')
          bandBoxRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandBoxROIs"], "bandBoxes", "#03fc90");
        } else {
          bandBoxRegions[analysisID] = activeBandBoxRegions0;
        }
        if (activeGelBlotRegions0.length === 0) {
          //console.log('am i running this?')
          //console.log('currentUpload',currentUpload)
          borderRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc");
        } else {
          borderRegions[analysisID] = activeGelBlotRegions0;
        }
        updateDynamoAndTriggerLambda(theImageID, analysisID, tpnAnalysisID, lysateRegions, bandRegions, bandBoxRegions, borderRegions, imageIndex, indexOfTargetImage);
      } else if (imageIndex === 1) {
        //console.log('i ran the finally')
        //console.log('currentUpload',currentUpload[theSwapIndex])
        //console.log('imageIndex',imageIndex)
        if (activeLysateRegions1.length === 0) {
          lysateRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203");
        } else {
          lysateRegions[analysisID] = activeLysateRegions1;
        }
        if (activeBandRegions1.length === 0) {
          bandRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90");
        } else {
          bandRegions[analysisID] = activeBandRegions1;
        }
        if (activeBandBoxRegions1.length === 0) {
          //console.log('am i running the set default regions')
          //console.log(currentUpload)
          bandBoxRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandBoxROIs"], "bandBoxes", "#03fc90");
        } else {
          //console.log('am i running the set activeBandBoxRegions1')
          //console.log(activeBandBoxRegions1)
          bandBoxRegions[analysisID] = activeBandBoxRegions1;
        }
        if (activeGelBlotRegions1.length === 0) {
          borderRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc");
        } else {
          //console.log('here are the activeGelBlotRegions1',activeGelBlotRegions1)
          borderRegions[analysisID] = activeGelBlotRegions1;
        }
        updateDynamoAndTriggerLambda(theImageID, analysisID, tpnAnalysisID, lysateRegions, bandRegions, bandBoxRegions, borderRegions, imageIndex, indexOfTargetImage);
      }
    } else if (ROIs === "biorads") {
      console.log("trying to analyze bio-rads results");
      setExpanded("currentAnalysisHeader");
      if (imageIndex === 0) {
        setImage0AnalysisStatus("pending");
      } else {
        setImage1AnalysisStatus("pending");
      }
      bandRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandROIs"], "bands", "#03fc90");
      bandBoxRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["bandBoxROIs"], "bandBoxes", "#03fc90");
      lysateRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["lysateROIs"], "lysates", "#fc6203");
      borderRegions[analysisID] = setDefaultRegions(imageIndex, currentUpload[theSwapIndex]["gelROIs"], "gbBorder", "#0390fc");
      updateDynamoAndTriggerLambda(theImageID, analysisID, tpnAnalysisID, lysateRegions, bandRegions, bandBoxRegions, borderRegions, imageIndex, indexOfTargetImage);
    }
  }

  async function getAutoAnalysisID(theImageID) {
    //console.log('hey')
    var theAnalysisID = [];
    var queryDBParams = {
      TableName: "ila-rawImage-table",
      KeyConditions: {
        imageID: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: String(theImageID) }],
        },
      },
    };
    try {
      theAnalysisID.push(await query(queryDBParams));
    } catch {
      theAnalysisID.push(null);
    }
    //console.log(theAnalysisID['Items'][])
    return theAnalysisID;
  }

  function returnAutoAnalysis(index) {
    setCJSData(null);
    setScatCJSData(defaultScatCJSData);
    setMWLineChartData(defaultMWLineChartData);
    setTotalLaneChartData(defaultTotalLaneChartData);
    setManualAnalysisBool(false);
    setGridRowData(null);
    var analysisIndex = 0;
    setIndexOfAnalysis(0);
    if (index === 0) {
      setActiveImage("image0");
      setORActiveImage("image0");
      updateImageInfo("image0");
      setExpanded("currentAnalysisHeader");
      setImage0AnalysisStatus("pending");
      setAnalysisStatusColor0("#e66e04");
      getAutoAnalysisID(imageIDsForShow[0]).then(function (value) {
        getAnalysis(imageIDsForShow[0], value, 0, "viewAutoAnalysis", analysisIndex);
      });
    } else {
      setActiveImage("image1");
      setORActiveImage("image1");
      updateImageInfo("image1");
      setExpanded("currentAnalysisHeader");
      setImage1AnalysisStatus("pending");
      setAnalysisStatusColor1("#e66e04");
      getAutoAnalysisID(imageIDsForShow[1]).then(function (value) {
        getAnalysis(imageIDsForShow[1], value, 1, "viewAutoAnalysis", analysisIndex);
      });
    }
  }

  function checkForTPNAndSubmitForAnalysis(index) {
    //I wan to reset the size of the image to the middle dimension
    setStyleWidthOfCanvas0(String(middleDim) + "px");
    setStyleWidthOfCanvas1(String(middleDim) + "px");
    setStage0({
      posX: 0,
      posY: 0,
      transform: 1,
      canvasWidth: middleDim,
      canvasHeight: (middleDim / naturalWidth0) * naturalHeight0,
      scaleX: middleDim / naturalWidth0,
      scaleY: ((middleDim / naturalWidth0) * naturalHeight0) / naturalHeight0,
    });
    setStage1({
      posX: 0,
      posY: 0,
      transform: 1,
      canvasWidth: middleDim,
      canvasHeight: (middleDim / naturalWidth1) * naturalHeight1,
      scaleX: middleDim / naturalWidth1,
      scaleY: ((middleDim / naturalWidth1) * naturalHeight1) / naturalHeight1,
    });
    //And I need to set checkboxes back to default
    setfb0(false);
    setfb1(false);
    setfll0(false);
    setfll1(false);
    setgb0(false);
    setgb1(false);

    //console.log(currentUpload)
    //console.log(currentUpload[0])
    const findIndexByKeyValue = (array, key, value) => array.findIndex((item) => item[key] === value);
    //var indexOfTargetImageID = 1 - findIndexByKeyValue(currentUpload,'imageID',currentUpload[0]['TPNImageID'])
    //var targetImageID = currentUpload[indexOfTargetImageID]['imageID']
    //var imageIndexOfTargetImage = imageIDsForShow.findIndex(id => id === targetImageID)
    //console.log(targetImageID)
    //console.log(imageIndexOfTargetImage)
    //currentUpload[0]['targetImageID']
    //console.log(1 - findIndexByKeyValue(currentUpload,'imageID',currentUpload[0]['TPNImageID']))
    //console.log('the target image ID =',currentUpload[1 - findIndexByKeyValue(currentUpload,'imageID',currentUpload[0]['TPNImageID'])]['imageID'])

    //var theTargetImageID = submitForAnalysis(index,null)
    if (tpnRequested) {
      //console.log('I am in the tpnRequested manual workflow')
      var indexOfTargetImageID = 1 - findIndexByKeyValue(currentUpload, "imageID", currentUpload[0]["TPNImageID"]);
      var targetImageID = currentUpload[indexOfTargetImageID]["imageID"];
      var imageIndexOfTargetImage = imageIDsForShow.findIndex((id) => id === targetImageID);
      var theTPNAnalysisID = String(uuid.v4());
      submitForAnalysisWithTPN(imageIndexOfTargetImage, theTPNAnalysisID);
      //regardles of which image the manual button was clicked from - i need to:
      // 1. reset setCJSData(null) - done
      // 2. reset setScatCJSData(defaultScatCJSData) - done
      // 3. reset setMWLineChartData(defaultMWLineChartData) - done
      // 4. reset setTotalLaneChartData(defaultTotalLaneChartData) - done
      // 5. reset setGridRowData(null) - done
      // 6. set setManualAnalysisBool(true) - done
      // 7. set the active image to the target image. The image index of the target image is imageIndexOfTargetImage

      /////  THE following four I have completed //////
      //1 need to reset sfaStatus to 'incomplete' for both images in the dynamo - done
      //2 need to create a new tpnAnalysisID and append it to list of both images in dynamo - the id will match - done
      //3 need to create a new analysisID and append it to the list of both images in dynamo - the analysisID will not match -done
      //4 need to change the TPNRequestOrginin in the dyanamo to 'fromApp' for both images in the dynamo - done
      //5 need to change the TPNAnalysisStatus in the dynamo to 'incomplete' for both images in the dynamo - done
    } else {
      console.log("I am in the normal manual workflow");
      submitForAnalysis(index, null, null);
    }
  }

  function submitForAnalysisWithTPN(indexOfTargetImage, tpnAnalysisID) {
    setCJSData(null);
    setScatCJSData(defaultScatCJSData);
    setMWLineChartData(defaultMWLineChartData);
    setTotalLaneChartData(defaultTotalLaneChartData);
    setGridRowData(null);
    setManualAnalysisBool(true);
    setExpanded("currentAnalysisHeader");
    if (indexOfTargetImage === 0) {
      setActiveImage("image0");
      setORActiveImage("image0");
      updateImageInfo("image0");
      setAnalysisStatusColor0("#e66e04");
      setImage0AnalysisStatus("pending");
    } else {
      setActiveImage("image1");
      setORActiveImage("image1");
      updateImageInfo("image1");
      setAnalysisStatusColor1("#e66e04");
      setImage1AnalysisStatus("pending");
    }
    continueSubmitForAnalysis("users", 0, tpnAnalysisID, indexOfTargetImage);
    continueSubmitForAnalysis("users", 1, tpnAnalysisID, indexOfTargetImage);
  }

  function submitForAnalysis(index, tpnAnalysisID, indexOfTargetImage) {
    //checkForTPNAndSubmitForAnalysis(index)
    setCJSData(null);
    setScatCJSData(defaultScatCJSData);
    setMWLineChartData(defaultMWLineChartData);
    setTotalLaneChartData(defaultTotalLaneChartData);
    setGridRowData(null);
    setManualAnalysisBool(true);
    if (index === 0) {
      setActiveImage("image0");
      setORActiveImage("image0");
      updateImageInfo("image0");
      setAnalysisStatusColor0("#e66e04");
      //console.log('what i submitted for analysis', activeBandRegions0)
      if (activeBandRegions0.length === 0 && activeLysateRegions0.length === 0 && activeGelBlotRegions0.length === 0) {
        //setNoAnaSelectDialog(true)
        setDialogs({ noAnaSelectDialog: true });
      } else {
        setExpanded("currentAnalysisHeader");
        setImage0AnalysisStatus("pending");
        continueSubmitForAnalysis("users", index, tpnAnalysisID, indexOfTargetImage);
      }
    } else if (index === 1) {
      setActiveImage("image1");
      setORActiveImage("image1");
      updateImageInfo("image1");
      setAnalysisStatusColor1("#e66e04");
      if (activeBandRegions1.length === 0 && activeLysateRegions1.length === 0 && activeGelBlotRegions1.length === 0) {
        //setNoAnaSelectDialog(true)
        setDialogs({ noAnaSelectDialog: true });
      } else {
        setExpanded("currentAnalysisHeader");
        setImage1AnalysisStatus("pending");
        continueSubmitForAnalysis("users", index, tpnAnalysisID, indexOfTargetImage);
      }
    }
  }

  function setDefaultRegions(whichImage, whichROIs, whichRegion, regionColor) {
    //console.log('whichImage',whichImage)
    //console.log('whichROIs',whichROIs)
    //console.log('whichRegion',whichRegion)
    //console.log('regionColor',regionColor)
    var regionsToReturn;
    function setTheRegion(whichImage, whichRegion, whichROIs) {
      var regions = [];
      if (whichROIs !== "undefined") {
        for (var i = 0; i < whichROIs.length; i++) {
          regions[i] = {};
          regions[i]["id"] = i;
          regions[i]["color"] = regionColor;
          regions[i]["points"] = whichROIs[i];
          regions[i]["active"] = true;
          regions[i]["roiSignal"] = "undefined";
          if (whichImage === 0) {
            regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
          } else {
            regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
          }
          if (i === whichROIs.length - 1) {
            if (whichRegion === "lysates" && whichImage === 0) {
              //setRegions0(regions)
              setActiveLysateRegions0(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "bands" && whichImage === 0) {
              //setRegions0(regions)
              setActiveBandRegions0(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "bandBoxes" && whichImage === 0) {
              //setRegions0(regions)
              setActiveBandBoxRegions0(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "gbBorder" && whichImage === 0) {
              setActiveGelBlotRegions0(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "lysates" && whichImage === 1) {
              //setRegions1(regions)
              setActiveLysateRegions1(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "bands" && whichImage === 1) {
              //setRegions1(regions)
              setActiveBandRegions1(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "bandBoxes" && whichImage === 1) {
              //console.log('do I run the setActiveBandBoxRegions1?')
              //setRegions1(regions)
              setActiveBandBoxRegions1(regions);
              //setActiveRegions(regions)
            } else if (whichRegion === "gbBorder" && whichImage === 1) {
              setActiveGelBlotRegions1(regions);
              //setActiveRegions(regions)
            }
          }
        }
      }
      return regions;
    }

    //regionsToReturn = setTheRegion(whichImage, whichRegion, whichROIs)

    return setTheRegion(whichImage, whichRegion, whichROIs); //regionsToReturn
  }

  function setRegionsFromCheckBox(whichImage, whichROIs, whichRegion, regionColor, theSwapIndex) {
    var alr1 = activeLysateRegions1.length;
    var abr1 = activeBandRegions1.length;
    var alr0 = activeLysateRegions0.length;
    var abr0 = activeBandRegions0.length;
    var abbr0 = activeBandBoxRegions0.length;
    var abbr1 = activeBandBoxRegions1.length;
    var agbr0 = activeGelBlotRegions0.length;
    var agbr1 = activeGelBlotRegions1.length;
    var asat0 = activeSatRegions0.length;
    var asat1 = activeSatRegions1.length;
    var regions;
    var boxRegions;
    //console.log(activeGelBlotRegions0)
    //I need a check here for wether or not the whichROIs length is zero or not.
    //if it is zero the there are not regions to set
    //console.log('whichImage',whichImage)
    //console.log('whichROIs',whichROIs)
    //console.log('whichRegion',whichRegion)
    //console.log('activeBandRegions',activeBandRegions0.length)

    // if this length === 0 either this state has never been set, or the customer has removed very roi
    // that is an unacceptable answer so set ROI's to ML answers

    //if the length is not zero then the regions have already been set once or have been modified
    //so just reset them to current status, but only if the checkbox == false (goofy this is backwards)
    if (whichImage === 0) {
      if (whichRegion === "lysates") {
        if (alr0 !== 0) {
          //console.log('i am in if')
          if (fll0) {
            setRegions0([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveLysateRegions0([])
            //setActiveRegions([])
          } else {
            setRegions0(activeLysateRegions0);
            setActiveLysateRegions0(activeLysateRegions0);
            setActiveRegions(activeLysateRegions0);
          }
        } else if (alr0 === 0) {
          //console.log('i am in else if')
          //console.log('fll0',fll0)
          if (fll0) {
            setRegions0([]);
            setActiveLysateRegions0([]);
            setActiveRegions([]);
          } else {
            regions = [];
            if (whichROIs !== "undefined" && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions0(regions);
                  setActiveLysateRegions0(regions);
                  setActiveRegions(regions);
                }
              }
            } else {
              setRegions0([]);
              setActiveLysateRegions0([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "bands") {
        //console.log(activeBandRegions0)
        if (abr0 !== 0) {
          if (fb0) {
            setRegions0([]);
            setBoxRegions0([]);
          } else {
            //console.log('activeBandRegions0',activeBandRegions0)
            //console.log('activeBandBoxRegions0',activeBandBoxRegions0)
            setRegions0(activeBandRegions0);
            setBoxRegions0(activeBandBoxRegions0);
            setActiveBandRegions0(activeBandRegions0);
            setActiveBandBoxRegions0(activeBandBoxRegions0);
            setActiveRegions(activeBandRegions0);
          }
        } else if (abr0 === 0) {
          if (fb0) {
            setRegions0([]);
            setBoxRegions0([]);
            setActiveBandRegions0([]);
            setActiveBandBoxRegions0([]);
            setActiveRegions([]);
          } else {
            regions = [];
            boxRegions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              //console.log(whichROIs)
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                boxRegions[i] = {};
                boxRegions[i]["id"] = i;
                boxRegions[i]["color"] = regionColor;
                boxRegions[i]["points"] = currentUpload[theSwapIndex]["bandBoxROIs"][i];
                boxRegions[i]["active"] = true;
                boxRegions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                  boxRegions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                  boxRegions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  //console.log(regions)
                  //console.log(boxRegions)
                  setRegions0(regions);
                  setBoxRegions0(boxRegions);
                  setActiveBandRegions0(regions);
                  setActiveBandBoxRegions0(boxRegions);
                  setActiveRegions(regions);
                }
              }
            } else {
              setRegions0([]);
              setBoxRegions0([]);
              setActiveBandRegions0([]);
              setActiveBandBoxRegions0([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "gbBorder") {
        if (agbr0 !== 0) {
          //console.log('second time trigger (STT)')
          if (gb0) {
            //console.log('STT, clear the active regions')
            //console.log('Did I just reset the blot border to an empty array?')
            setRegions0([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveGelBlotRegions0([])
            //setActiveRegions([])
          } else {
            //console.log('STT, update the active regions')
            setRegions0(activeGelBlotRegions0);
            setActiveGelBlotRegions0(activeGelBlotRegions0);
            setActiveRegions(activeGelBlotRegions0);
          }
        } else if (agbr0 === 0) {
          //this should trigger if this is the first time the user has looked at these regions
          //console.log('first time trigger (FTT) to create the active regions')
          if (gb0) {
            //console.log('FTT, clear the active regions')
            console.log("Did I just reset the blot border to an empty array?");
            setRegions0([]);
            setActiveGelBlotRegions0([]);
            setActiveRegions([]);
          } else {
            //console.log('FTT, create the active regions')
            regions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions0(regions);
                  setActiveGelBlotRegions0(regions);
                  setActiveRegions(regions);
                }
              }
            } else {
              console.log("Did I just reset the blot border to an empty array?");
              setRegions0([]);
              setActiveGelBlotRegions0([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "saturatedPixels") {
        if (asat0 !== 0) {
          if (sat0) {
            setRegions0([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveSatRegions0([])
            //setActiveRegions([])
          } else {
            setRegions0(activeSatRegions0);
            setActiveSatRegions0([]);
            setActiveRegions([]);
          }
        } else if (asat0 === 0) {
          //console.log('hey again')
          if (sat0) {
            setRegions0([]);
            setActiveSatRegions0([]);
            setActiveRegions([]);
          } else {
            regions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions0(regions);
                  setActiveSatRegions0([]);
                  setActiveRegions([]);
                }
              }
            } else {
              setRegions0([]);
              setActiveSatRegions0([]);
              setActiveRegions([]);
            }
          }
        }
      }
    } else if (whichImage === 1) {
      if (whichRegion === "lysates") {
        if (alr1 !== 0) {
          if (fll1) {
            setRegions1([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveLysateRegions1([])
            //setActiveRegions([])
          } else {
            setRegions1(activeLysateRegions1);
            setActiveLysateRegions1(activeLysateRegions1);
            setActiveRegions(activeLysateRegions1);
          }
        } else if (alr1 === 0) {
          if (fll1) {
            setRegions1([]);
            setActiveLysateRegions1([]);
            setActiveRegions([]);
          } else {
            regions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions1(regions);
                  setActiveLysateRegions1(regions);
                  setActiveRegions(regions);
                }
              }
            } else {
              setRegions1([]);
              setActiveLysateRegions1([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "bands") {
        if (abr1 !== 0) {
          //console.log('I should only see this if the length of activeRegions does not = 0')
          if (fb1) {
            setRegions1([]);
            setBoxRegions1([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveBandRegions1([])
            //setActiveRegions([])
          } else {
            setRegions1(activeBandRegions1);
            setBoxRegions1(activeBandBoxRegions1);
            setActiveBandRegions1(activeBandRegions1);
            setActiveBandBoxRegions1(activeBandBoxRegions1);
            setActiveRegions(activeBandRegions1);
          }
        } else if (abr1 === 0) {
          //console.log('I should see this if the length of activeRegions = 0')
          if (fb1) {
            setRegions1([]);
            setBoxRegions1([]);
            setActiveBandRegions1([]);
            setActiveBandBoxRegions1([]);
            setActiveRegions([]);
          } else {
            regions = [];
            boxRegions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                boxRegions[i] = {};
                boxRegions[i]["id"] = i;
                boxRegions[i]["color"] = regionColor;
                boxRegions[i]["points"] = currentUpload[theSwapIndex]["bandBoxROIs"][i];
                boxRegions[i]["active"] = true;
                boxRegions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                  boxRegions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                  boxRegions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions1(regions);
                  setBoxRegions1(boxRegions);
                  setActiveBandRegions1(regions);
                  setActiveBandBoxRegions1(boxRegions);
                  setActiveRegions(regions);
                }
              }
            } else {
              setRegions1([]);
              setBoxRegions1([]);
              setActiveBandRegions1([]);
              setActiveBandBoxRegions1([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "gbBorder") {
        if (agbr1 !== 0) {
          if (gb1) {
            setRegions1([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveGelBlotRegions1([])
            //setActiveRegions([])
          } else {
            setRegions1(activeGelBlotRegions1);
            setActiveGelBlotRegions1(activeGelBlotRegions1);
            setActiveRegions(activeGelBlotRegions1);
          }
        } else if (agbr1 === 0) {
          if (gb1) {
            setRegions1([]);
            setActiveGelBlotRegions1([]);
            setActiveRegions([]);
          } else {
            regions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions1(regions);
                  setActiveGelBlotRegions1(regions);
                  setActiveRegions(regions);
                }
              }
            } else {
              setRegions1([]);
              setActiveGelBlotRegions1([]);
              setActiveRegions([]);
            }
          }
        }
      } else if (whichRegion === "saturatedPixels") {
        if (asat1 !== 0) {
          if (sat1) {
            setRegions1([]);
            //THESE NEXT TWO LINES ARE A NEW CONDITION
            //setActiveSatRegions1([])
            //setActiveRegions([])
          } else {
            setRegions1(activeSatRegions1);
            setActiveSatRegions1([]);
            setActiveRegions([]);
          }
        } else if (asat1 === 0) {
          //console.log('hey again')
          if (sat1) {
            setRegions1([]);
            setActiveSatRegions1([]);
            setActiveRegions([]);
          } else {
            regions = [];
            if (whichROIs !== undefined && whichROIs.length !== 0) {
              for (var i = 0; i < whichROIs.length; i++) {
                regions[i] = {};
                regions[i]["id"] = i;
                regions[i]["color"] = regionColor;
                regions[i]["points"] = whichROIs[i];
                regions[i]["active"] = true;
                regions[i]["roiSignal"] = "undefined";
                if (whichImage === 0) {
                  regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
                } else {
                  regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
                }
                if (i === whichROIs.length - 1) {
                  setRegions1(regions);
                  setActiveSatRegions1([]);
                  setActiveRegions([]);
                }
              }
            } else {
              setRegions1([]);
              setActiveSatRegions1([]);
              setActiveRegions([]);
            }
          }
        }
      }
    }
  }

  function query(params) {
    return new Promise((resolve, reject) => {
      dynamodb.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  async function queryTableWithGroup(theUploadGroup) {
    //console.log('running Query')
    let queryDBParams = {};
    var holder = [];
    let tableName = "ila-rawImage-table";
    var i = 0;
    let sizeOfGroup = theUploadGroup.length;
    for (i = 0; i < sizeOfGroup; i++) {
      queryDBParams = {
        TableName: tableName,
        KeyConditions: {
          imageID: {
            ComparisonOperator: "EQ",
            AttributeValueList: [{ S: theUploadGroup[i]["imageID"] }],
          },
        },
      };
      holder.push(await query(queryDBParams));
    }
    return holder;
  }

  function consolidateGroupForSelection(holder, fromWhichFunction) {
    //console.log('from consolidateGroupForSelection',holder)
    //console.log(fromWhichFunction)
    //console.log(holder.length)
    var tpnMapFound = false;
    var fullResponse = [];
    var rawPNGImageList = [];
    for (var k = 0; k < holder.length; k++) {
      if (holder[k]["Items"].length > 0) {
        for (var j = 0; j < holder[k]["Items"].length; j++) {
          fullResponse.push(holder[k]["Items"][j]);
        }
      }
      if (k === holder.length - 1) {
        var tempResponse = [];
        for (var i = 0; i < fullResponse.length; i++) {
          tempResponse[i] = {};
          tempResponse[i]["pngRawStatus"] = fullResponse[i]["pngRawStatus"]["S"];
          tempResponse[i]["fileName"] = fullResponse[i]["fileName"]["S"];
          tempResponse[i]["uploadComment"] = fullResponse[i]["uploadComment"]["S"];
          tempResponse[i]["statusColor"] = "orange";
          tempResponse[i]["imageClass"] = fullResponse[i]["imageClass"]["S"];
          tempResponse[i]["imageID"] = fullResponse[i]["imageID"]["S"];
          tempResponse[i]["scnTags"] = fullResponse[i]["scnTags"]["S"];
          if (fullResponse[i].hasOwnProperty("TPNRequested")) {
            //I think that this function is should always be filling the tpn data with the 'Auto' TPN analysis values. So Index position 0
            //If manual analysis is run, I need to update the tpnValues to the index of the analysisID
            tempResponse[i]["TPNRequested"] = fullResponse[i]["TPNRequested"]["S"];
            if (fullResponse[i]["TPNRequested"]["S"] === "true") {
              setTPNRequested(true);
              tempResponse[i]["TPNLane"] = fullResponse[i]["TPNLane"]["N"];
              tempResponse[i]["TPNImageID"] = fullResponse[i]["TPNImageID"]["S"];
              //if (fullResponse[i]['TPNAnalysisStatus']['S']==='complete'){
              //console.log(fullResponse[i]['TPNAnalysisStatus'])
              if (fullResponse[i]["TPNAnalysisStatus"]["L"][0]["S"] === "complete") {
                tempResponse[i]["TPNAnalysisStatus"] = "done";
                tempResponse[i]["TPNAnalysisID"] = fullResponse[i]["TPNAnalysisIDs"]["L"][0]["S"];
                //tempResponse[i]['TPNLaneMapping'] = fullResponse[i]['TPNLaneMapping']['M']
                tempResponse[i]["TPNLaneMapping"] = fullResponse[i]["TPNLaneMapping"]["L"][0]["M"];
                if (!tpnMapFound) {
                  setTPNLaneNumber(fullResponse[i]["TPNLane"]["N"]);
                  setTPNImageID(fullResponse[i]["TPNImageID"]["S"]);

                  tpnMapFound = true;
                }
                tempResponse[i]["TPNStatusColor"] = "#399dbc";
                setTPNPassed(true);
                //} else if (fullResponse[i]['TPNAnalysisStatus']['S']==='failed') {
              } else if (fullResponse[i]["TPNAnalysisStatus"]["L"][0]["S"] === "failed") {
                tempResponse[i]["TPNAnalysisStatus"] = "failed";
                tempResponse[i]["TPNStatusColor"] = "red";
                tempResponse[i]["TPNAnalysisID"] = "na";
                tempResponse[i]["TPNLaneMapping"] = "na";
                setTPNPassed(false);
              } else {
                tempResponse[i]["TPNStatusColor"] = "orange";
                tempResponse[i]["TPNAnalysisStatus"] = "pending";
                tempResponse[i]["TPNAnalysisID"] = "na";
                tempResponse[i]["TPNLaneMapping"] = "na";
                setTPNPassed(false);
              }
            } else {
              tempResponse[i]["TPNAnalysisStatus"] = "na";
              tempResponse[i]["TPNStatusColor"] = "#399dbc";
              tempResponse[i]["TPNLane"] = "na";
              tempResponse[i]["TPNImageID"] = "na";
              tempResponse[i]["TPNAnalysisID"] = "na";
              tempResponse[i]["TPNLaneMapping"] = "na";
              setTPNPassed(false);
            }
          }
          if (fullResponse[i].hasOwnProperty("userVerifiedClass")) {
            tempResponse[i]["userVerifiedClass"] = fullResponse[i]["userVerifiedClass"]["S"];
            //tempResponse[i]['tempStatusOfVerifiedClass']=fullResponse[i]['userVerifiedClass']['S']
          } else {
            tempResponse[i]["userVerifiedClass"] = "doesNotExist";
          }
          if (fullResponse[i].hasOwnProperty("saturationStatus")) {
            tempResponse[i]["saturationStatus"] = fullResponse[i]["saturationStatus"]["S"];
            if (fullResponse[i]["saturationStatus"]["S"] === "passed") {
              const getROIs = async (id, index, tempResponse) => {
                var fileKey = id + "/saturationRegions.json";
                var satParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                var promise = s3.getObject(satParams).promise();
                promise.then(
                  function (resp) {
                    tempResponse[index]["saturationROIs"] = JSON.parse(resp.Body)["saturationRegions"];
                  },
                  function (err) {
                    console.log(err);
                    tempResponse[index]["saturationROIs"] = "NA";
                  }
                );
              };
              getROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
            }
          } else {
            tempResponse[i]["saturationStatus"] = "not available";
          }

          if (fullResponse[i].hasOwnProperty("analysisIDs")) {
            tempResponse[i]["analysisIDs"] = fullResponse[i]["analysisIDs"]["L"];
          } else {
            tempResponse[i]["analysisIDs"] = ["empty"];
          }
          if (fullResponse[i]["bandFindKey_8bit"]["S"] !== "Pending") {
            if (fullResponse[i].hasOwnProperty("mlBandsRegionKey")) {
              //console.log('hey')
              const getBandBoxROIs = async (id, index, tempResponse) => {
                var boxFileKey = id + "/mlBandBoxRegions.json";
                var bandBoxParams = { Bucket: database, Key: boxFileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: boxFileKey }
                var promise = s3.getObject(bandBoxParams).promise();
                promise.then(
                  function (resp) {
                    tempResponse[index]["bandBoxROIs"] = JSON.parse(resp.Body)["bandBoxRegions"];
                  },
                  function (err) {
                    console.log(err);
                    tempResponse[index]["bandBoxROIs"] = "undefined";
                  }
                );
              };
              const getBandROIs = async (id, index, tempResponse) => {
                var fileKey = id + "/mlBandRegions.json";
                var bandParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                var promise = s3.getObject(bandParams).promise();
                promise.then(
                  function (resp) {
                    tempResponse[index]["bandROIs"] = JSON.parse(resp.Body)["bandRegions"];
                  },
                  function (err) {
                    console.log(err);
                    tempResponse[index]["bandROIs"] = "undefined";
                  }
                );
              };
              getBandROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
              getBandBoxROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
            } else {
              tempResponse[i]["bandROIs"] = "undefined";
              tempResponse[i]["bandBoxROIs"] = "undefined";
            }
          } else {
            tempResponse[i]["bandFindKey_8bit"] = mlNotReady;
          }
          if (fullResponse[i]["laneFindKey_8bit"]["S"] !== "Pending") {
            if (fullResponse[i].hasOwnProperty("mlLysatesRegionKey")) {
              //console.log('hey')
              const getLysateROIs = async (id, index, tempResponse) => {
                var fileKey = id + "/mlLysateRegions.json";
                var lysateParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                var promise = s3.getObject(lysateParams).promise();
                promise.then(
                  function (resp) {
                    tempResponse[index]["lysateROIs"] = JSON.parse(resp.Body)["lysateRegions"];
                  },
                  function (err) {
                    console.log(err);
                    tempResponse[index]["lysateROIs"] = "undefined";
                  }
                );
              };
              getLysateROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
            } else {
              tempResponse[i]["lysateROIs"] = "undefined";
            }
          } else {
            tempResponse[i]["laneFindKey_8bit"] = mlNotReady;
          }
          if (fullResponse[i]["gelFindKey_8bit"]["S"] !== "Pending") {
            if (fullResponse[i].hasOwnProperty("mlBorderRegionsKey")) {
              const getBorderROIs = async (id, index, tempResponse) => {
                var fileKey = id + "/mlBorderRegions.json";
                var borderParams = { Bucket: database, Key: fileKey }; //{Bucket:AWSConfig.creds.bucketName, Key: fileKey}
                var promise = s3.getObject(borderParams).promise();
                promise.then(
                  function (resp) {
                    tempResponse[index]["gelROIs"] = JSON.parse(resp.Body)["borderRegions"];
                  },
                  function (err) {
                    console.log(err);
                    tempResponse[index]["gelROIs"] = "undefined";
                  }
                );
              };
              getBorderROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
            } else {
              tempResponse[i]["gelFindKey_8bit"] = "undefined";
            }
          }

          if (fullResponse[i]["bgRemovedKey_8bit"]["S"] !== "Pending") {
            var paramsBG = { Bucket: database, Key: fullResponse[i]["bgRemovedKey_8bit"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['bgRemovedKey_8bit']['S'] };
            var paramsBG32 = { Bucket: database, Key: fullResponse[i]["pngRawMinusBGKey"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['pngRawMinusBGKey']['S'] };
            tempResponse[i]["bgRemovedKey_8bit"] = s3.getSignedUrl("getObject", paramsBG);
            tempResponse[i]["bgRemovedKey_32bit"] = s3.getSignedUrl("getObject", paramsBG32);
          } else {
            tempResponse[i]["bgRemovedKey_8bit"] = mlNotReady;
            tempResponse[i]["bgRemovedKey_32bit"] = mlNotReady;
          }
          if (fullResponse[i]["mlClassPrediction"]["S"] !== "Pending") {
            if (parseFloat(fullResponse[i]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"]) > parseFloat(fullResponse[i]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"])) {
              tempResponse[i]["mlClass"] = "membrane";
              tempResponse[i]["mlConf"] = fullResponse[i]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"];
            } else {
              tempResponse[i]["mlClass"] = "gel";
              tempResponse[i]["mlConf"] = fullResponse[i]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"];
            }
          } else {
            tempResponse[i]["mlClass"] = "pending";
          }
          if (fullResponse[i]["pngRawStatus"]["S"] === "Ready") {
            var params = { Bucket: database, Key: fullResponse[i]["pngRawKey"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['pngRawKey']['S'] };
            var url = s3.getSignedUrl("getObject", params);
            //console.log('The URL of the thumb =', url);
            rawPNGImageList[i] = {};
            rawPNGImageList[i]["img"] = url;
            rawPNGImageList[i]["title"] = fullResponse[i]["fileName"]["S"];
            rawPNGImageList[i]["id"] = "imageID_" + String(i);
            rawPNGImageList[i]["imageID"] = fullResponse[i]["imageID"]["S"];
            tempResponse[i]["imgURL"] = url;
            tempResponse[i]["statusColor"] = "#399dbc"; //'green'
          } else if (fullResponse[i]["pngRawStatus"]["S"] === "Pending") {
            tempResponse[i]["statusColor"] = "orange";
          }

          if (i === fullResponse.length - 1) {
            if (String(fromWhichFunction) === "from-recentFileSelect") {
              //console.log('here the currentUpload',tempResponse)
              setCurrentUpload(tempResponse);

              setOpenBackdrop(false);
            } else if (String(fromWhichFunction) === "from-findBandsCheckBox") {
              setCurrentUpload(tempResponse);
            } else if (String(fromWhichFunction) === "from-sfaLambda") {
              setCurrentUpload(tempResponse);
            }
          }
        }
      }
    }
  }

  function syncTableWithDB(submitDateTime, fromWhichFunction) {
    //console.log('in the function syncTableWithDB(),I arrived from ',fromWhichFunction)
    //These scan params ensure you should only recieve the files from the a single upload date time which is fairly specific
    //as the upload date is year-month-day-hour-minute-second
    //things we grab here can probably be eliminate from the recent table scan - so we don't over use memory on the page
    var holder = [];
    var tpnMapFound = false;
    let scanDBParams = {
      TableName: "ila-rawImage-table",
      ScanFilter: {
        userName: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: sessionStorage.getItem("username") }],
        },
        uploadDate: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ N: String(submitDateTime) }],
        },
      },
    };
    dynamodb.scan(scanDBParams, getRecentFiles);

    function getRecentFiles(err, data) {
      var rawPNGImageList = [];
      if (err) {
        console.log("logging err", err);
      } else {
        holder.push(data.Items);
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("scanning for more entries....")
          scanDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.scan(scanDBParams, getRecentFiles);
        } else {
          var fullResponse = [];
          for (var k = 0; k < holder.length; k++) {
            if (holder[k].length > 0) {
              for (var j = 0; j < holder[k].length; j++) {
                fullResponse.push(holder[k][j]);
              }
            }
            if (k === holder.length - 1) {
              var tempResponse = [];
              var analysisIndex;
              for (var i = 0; i < fullResponse.length; i++) {
                tempResponse[i] = {};
                tempResponse[i]["pngRawStatus"] = fullResponse[i]["pngRawStatus"]["S"];
                tempResponse[i]["fileName"] = fullResponse[i]["fileName"]["S"];
                tempResponse[i]["uploadComment"] = fullResponse[i]["uploadComment"]["S"];
                tempResponse[i]["statusColor"] = "orange";
                tempResponse[i]["imageClass"] = fullResponse[i]["imageClass"]["S"];
                tempResponse[i]["imageID"] = fullResponse[i]["imageID"]["S"];
                tempResponse[i]["scnTags"] = fullResponse[i]["scnTags"]["S"];
                //console.log(fullResponse[i]['analysisIDs']['L'],fullResponse[i]['analysisIDs']['L'].pop())
                //console.log('index of that last manual analysis',fullResponse[i]['analysisIDs']['L'].indexOf(fullResponse[i]['analysisIDs']['L'].pop()))
                if (fullResponse[i].hasOwnProperty("TPNRequested")) {
                  tempResponse[i]["TPNRequested"] = fullResponse[i]["TPNRequested"]["S"];
                  if (fullResponse[i]["TPNRequested"]["S"] === "true") {
                    setTPNRequested(true);
                    tempResponse[i]["TPNLane"] = fullResponse[i]["TPNLane"]["N"];
                    tempResponse[i]["TPNImageID"] = fullResponse[i]["TPNImageID"]["S"];
                    if (fullResponse[i]["TPNAnalysisStatus"]["S"] === "complete") {
                      tempResponse[i]["TPNAnalysisStatus"] = "done";
                      tempResponse[i]["TPNAnalysisID"] = fullResponse[i]["TPNAnalysisIDs"]["L"][0]["S"];
                      tempResponse[i]["TPNLaneMapping"] = fullResponse[i]["TPNLaneMapping"]["M"];
                      if (!tpnMapFound) {
                        setTPNLaneNumber(fullResponse[i]["TPNLane"]["N"]);
                        setTPNImageID(fullResponse[i]["TPNImageID"]["S"]);

                        tpnMapFound = true;
                      }
                      tempResponse[i]["TPNStatusColor"] = "#399dbc";
                      setTPNPassed(true);
                    } else if (fullResponse[i]["TPNAnalysisStatus"]["S"] === "failed") {
                      tempResponse[i]["TPNAnalysisStatus"] = "failed";
                      tempResponse[i]["TPNStatusColor"] = "red";
                      tempResponse[i]["TPNAnalysisID"] = "na";
                      tempResponse[i]["TPNLaneMapping"] = "na";
                      setTPNPassed(false);
                    } else {
                      tempResponse[i]["TPNStatusColor"] = "orange";
                      tempResponse[i]["TPNAnalysisStatus"] = "pending";
                      tempResponse[i]["TPNAnalysisID"] = "na";
                      tempResponse[i]["TPNLaneMapping"] = "na";
                      setTPNPassed(false);
                    }
                  } else {
                    tempResponse[i]["TPNAnalysisStatus"] = "na";
                    tempResponse[i]["TPNStatusColor"] = "#399dbc";
                    tempResponse[i]["TPNLane"] = "na";
                    tempResponse[i]["TPNImageID"] = "na";
                    tempResponse[i]["TPNAnalysisID"] = "na";
                    tempResponse[i]["TPNLaneMapping"] = "na";
                    setTPNPassed(false);
                  }
                }

                if (fullResponse[i].hasOwnProperty("userVerifiedClass")) {
                  tempResponse[i]["userVerifiedClass"] = fullResponse[i]["userVerifiedClass"]["S"];
                  //tempResponse[i]['tempStatusOfVerifiedClass']=fullResponse[i]['userVerifiedClass']['S']
                } else {
                  tempResponse[i]["userVerifiedClass"] = "doesNotExist";
                }
                if (fullResponse[i].hasOwnProperty("saturationStatus")) {
                  tempResponse[i]["saturationStatus"] = fullResponse[i]["saturationStatus"]["S"];
                  if (fullResponse[i]["saturationStatus"]["S"] === "passed") {
                    const getROIs = async (id, index, tempResponse) => {
                      var fileKey = id + "/saturationRegions.json";
                      var satParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                      var promise = s3.getObject(satParams).promise();
                      promise.then(
                        function (resp) {
                          tempResponse[index]["saturationROIs"] = JSON.parse(resp.Body)["saturationRegions"];
                        },
                        function (err) {
                          console.log(err);
                          tempResponse[index]["saturationROIs"] = "NA";
                        }
                      );
                    };
                    getROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
                  }
                } else {
                  tempResponse[i]["saturationStatus"] = "not available";
                }

                if (fullResponse[i].hasOwnProperty("analysisIDs")) {
                  tempResponse[i]["analysisIDs"] = fullResponse[i]["analysisIDs"]["L"];
                } else {
                  tempResponse[i]["analysisIDs"] = ["empty"];
                }
                if (fullResponse[i]["bandFindKey_8bit"]["S"] !== "Pending") {
                  if (fullResponse[i].hasOwnProperty("mlBandsRegionKey")) {
                    //console.log('hey')
                    const getBandBoxROIs = async (id, index, tempResponse) => {
                      var boxFileKey = id + "/mlBandBoxRegions.json";
                      var bandBoxParams = { Bucket: database, Key: boxFileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: boxFileKey }
                      var promise = s3.getObject(bandBoxParams).promise();
                      promise.then(
                        function (resp) {
                          tempResponse[index]["bandBoxROIs"] = JSON.parse(resp.Body)["bandBoxRegions"];
                        },
                        function (err) {
                          console.log(err);
                          tempResponse[index]["bandBoxROIs"] = "undefined";
                        }
                      );
                    };
                    const getBandROIs = async (id, index, tempResponse) => {
                      var fileKey = id + "/mlBandRegions.json";
                      var bandParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                      var promise = s3.getObject(bandParams).promise();
                      promise.then(
                        function (resp) {
                          tempResponse[index]["bandROIs"] = JSON.parse(resp.Body)["bandRegions"];
                        },
                        function (err) {
                          console.log(err);
                          tempResponse[index]["bandROIs"] = "undefined";
                        }
                      );
                    };
                    getBandROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
                    getBandBoxROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
                  } else {
                    tempResponse[i]["bandROIs"] = "undefined";
                    tempResponse[i]["bandBoxROIs"] = "undefined";
                  }
                } else {
                  tempResponse[i]["bandFindKey_8bit"] = mlNotReady;
                }
                if (fullResponse[i]["laneFindKey_8bit"]["S"] !== "Pending") {
                  if (fullResponse[i].hasOwnProperty("mlLysatesRegionKey")) {
                    //console.log('hey')
                    const getLysateROIs = async (id, index, tempResponse) => {
                      var fileKey = id + "/mlLysateRegions.json";
                      var lysateParams = { Bucket: database, Key: fileKey }; //{ Bucket: AWSConfig.creds.bucketName, Key: fileKey }
                      var promise = s3.getObject(lysateParams).promise();
                      promise.then(
                        function (resp) {
                          tempResponse[index]["lysateROIs"] = JSON.parse(resp.Body)["lysateRegions"];
                        },
                        function (err) {
                          console.log(err);
                          tempResponse[index]["lysateROIs"] = "undefined";
                        }
                      );
                    };
                    getLysateROIs(fullResponse[i]["imageID"]["S"], i, tempResponse);
                  } else {
                    tempResponse[i]["lysateROIs"] = "undefined";
                  }
                } else {
                  tempResponse[i]["laneFindKey_8bit"] = mlNotReady;
                }
                if (fullResponse[i]["gelFindKey_8bit"]["S"] !== "Pending") {
                  var paramsGel = { Bucket: database, Key: fullResponse[i]["gelFindKey_8bit"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['gelFindKey_8bit']['S'] };
                  tempResponse[i]["gelFindKey_8bit"] = s3.getSignedUrl("getObject", paramsGel);
                  try {
                    tempResponse[i]["gelROIs"] = AWS.DynamoDB.Converter.output(fullResponse[i]["gel_regions"]);
                  } catch {
                    tempResponse[i]["gelROIs"] = "undefined";
                  }
                } else {
                  tempResponse[i]["gelFindKey_8bit"] = mlNotReady;
                }
                if (fullResponse[i]["bgRemovedKey_8bit"]["S"] !== "Pending") {
                  var paramsBG = { Bucket: database, Key: fullResponse[i]["bgRemovedKey_8bit"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['bgRemovedKey_8bit']['S'] };
                  var paramsBG32 = { Bucket: database, Key: fullResponse[i]["pngRawMinusBGKey"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['pngRawMinusBGKey']['S'] };
                  tempResponse[i]["bgRemovedKey_8bit"] = s3.getSignedUrl("getObject", paramsBG);
                  tempResponse[i]["bgRemovedKey_32bit"] = s3.getSignedUrl("getObject", paramsBG32);
                } else {
                  tempResponse[i]["bgRemovedKey_8bit"] = mlNotReady;
                  tempResponse[i]["bgRemovedKey_32bit"] = mlNotReady;
                }
                if (fullResponse[i]["mlClassPrediction"]["S"] !== "Pending") {
                  if (parseFloat(fullResponse[i]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"]) > parseFloat(fullResponse[i]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"])) {
                    tempResponse[i]["mlClass"] = "membrane";
                    tempResponse[i]["mlConf"] = fullResponse[i]["mlClassPrediction"]["L"][0]["M"]["confidence"]["S"];
                  } else {
                    tempResponse[i]["mlClass"] = "gel";
                    tempResponse[i]["mlConf"] = fullResponse[i]["mlClassPrediction"]["L"][1]["M"]["confidence"]["S"];
                  }
                } else {
                  tempResponse[i]["mlClass"] = "pending";
                }
                if (fullResponse[i]["pngRawStatus"]["S"] === "Ready") {
                  var params = { Bucket: database, Key: fullResponse[i]["pngRawKey"]["S"] }; //{ Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]['pngRawKey']['S'] };
                  var url = s3.getSignedUrl("getObject", params);
                  //console.log('The URL of the thumb =', url);
                  rawPNGImageList[i] = {};
                  rawPNGImageList[i]["img"] = url;
                  rawPNGImageList[i]["title"] = fullResponse[i]["fileName"]["S"];
                  rawPNGImageList[i]["id"] = "imageID_" + String(i);
                  rawPNGImageList[i]["imageID"] = fullResponse[i]["imageID"]["S"];
                  tempResponse[i]["imgURL"] = url;
                  tempResponse[i]["statusColor"] = "#399dbc"; //'green'
                } else if (fullResponse[i]["pngRawStatus"]["S"] === "Pending") {
                  tempResponse[i]["statusColor"] = "orange";
                }

                if (i === fullResponse.length - 1) {
                  if (String(fromWhichFunction) === "from-recentFileSelect") {
                    //console.log('here the currentUpload',tempResponse[0]['dataBody'])
                    //setInitialRegionsList(tempResponse)
                    console.log("tempResponse", tempResponse);
                    setCurrentUpload(tempResponse);
                    setOpenBackdrop(false);
                  } else if (String(fromWhichFunction) === "from-findBandsCheckBox") {
                    setCurrentUpload(tempResponse);
                  } else if (String(fromWhichFunction) === "from-sfaLambda") {
                    console.log("wtf - why would I overwrite the currentUpload?");
                    //setCurrentUpload(tempResponse)
                    //setSizeOfCurrentUpload(tempResponse.length)
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  let onImageGroupClick = (fullUploadDate, fullRow) => {
    //console.log('the full row',fullRow)
    setTPNRequested(false);
    setIndexOfAnalysis(null);
    setMinimizedDim(null);
    setMiddleDim(null);
    setMaximizedDim(null);
    setNaturalWidth0(0);
    setNaturalHeight0(0);
    setNaturalWidth1(0);
    setNaturalHeight1(0);
    setStyleWidthOfCanvas0("650px");
    setStyleWidthOfCanvas1("650px");
    setStage0({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
    setStage1({ scaleX: null, scaleY: null, posX: 0, posY: 0, transform: 1 });
    setOpenBackdrop(true);
    setDisplaySelectionColor("default");
    setUploadIconColor("default");
    setCJSData(null);
    setGridRowData(null);
    setSelected([]);
    setSelectedID([]);
    setSelectedURL([]);
    setSelectedBGRURL([]);
    setImagesForShow(null);
    setImageIDsForShow(null);
    setfb0(false);
    setfb1(false);
    setfll0(false);
    setfll1(false);
    setgb0(false);
    setgb1(false);
    setActiveRegions([]);
    setActiveLysateRegions1([]);
    setActiveBandRegions1([]);
    setActiveBandBoxRegions0([]);
    setActiveBandBoxRegions1([]);
    setActiveLysateRegions0([]);
    setActiveBandRegions0([]);
    setActiveGelBlotRegions0([]);
    setActiveGelBlotRegions1([]);
    setActiveSatRegions0([]);
    setActiveSatRegions1([]);
    setActiveImage(null);
    setActiveROI(null);
    setORActiveImage(null);
    setORImage(null);
    setLaneChecks({ centerLane: false, boxLane: true, polygonLane: false, relativeMobility: false });
    setRelativeChecks({ relativeToBand: true, relativeToLane: false, totalLane: false, mwFit: false });
    setYAxisChecks({ mw: false, vertPos: true });
    setORRegionArray(null);
    setLaneRegionArray(null);
    setLaneIndexArray([]);
    setRegions0([]);
    setRegions1([]);
    setBoxRegions0([]);
    setBoxRegions1([]);
    setTheImageInfo(null);
    setExpanded(false);
    setExpanded2(false);
    setExpanded3(false);
    setPriorAnalysis0(null);
    setPriorAnalysis1(null);
    sessionStorage.setItem("uploadDateTime", fullUploadDate);
    queryTableWithGroup(fullRow.uploadGroup).then(function (value) {
      consolidateGroupForSelection(value, "from-recentFileSelect");
    });
  };

  function updateDBWithShareID(shareID) {
    //var shareID = String(uuid.v4())

    var imageID;
    var updateParams = {};
    if (ORActiveImage !== null) {
      if (ORActiveImage === "image0") {
        imageID = imageIDsForShow[0];
      } else {
        imageID = imageIDsForShow[1];
      }
      //console.log('analysisID',viewingAnalysisID)
      //console.log('imageID',imageID)
      updateParams = {
        Key: { imageID: { S: String(imageID) } },
        TableName: "ila-rawImage-table",
        UpdateExpression: "set #activeSID=:activeSID, #shareID=list_append(#shareID,:shareID), #anaid=list_append(#anaid,:anaid)",
        ExpressionAttributeNames: {
          "#activeSID": "activeSharingID",
          "#shareID": "sharingIDs",
          "#anaid": "sharingAnalysisIDs",
        },
        ExpressionAttributeValues: {
          ":activeSID": { S: shareID },
          ":shareID": { L: [AWS.DynamoDB.Converter.input(shareID)] },
          ":anaid": { L: [AWS.DynamoDB.Converter.input(viewingAnalysisID)] },
        },
      };
      dynamodb.updateItem(updateParams, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          console.log("create a dialog, go see if", imageID, "has an updated active sharing ID, shareIDlist entry, and shareAnalysisID");
        }
      });
    } else {
      console.log("null");
    }
  }

  function uploadUserAnalysisToCloud() {
    // setOpenStorage(true)
    //console.log('ready mf')
    //console.log(ORActiveImage)
    var fileKey;
    if (ORActiveImage === "image0") {
      //console.log('The ImageID = ',String(imageIDsForShow[0]))
      //console.log('and the analysis id = ',String(viewingAnalysisID))
      //console.log('here is what I am about to save from image0',analyticResponse0)
      fileKey = String(imageIDsForShow[0]) + "/" + String(viewingAnalysisID) + "_allROISignals_userMod.json";
      s3.putObject(
        {
          Bucket: database, //AWSConfig.creds.bucketName,
          Key: fileKey,
          Body: JSON.stringify(analyticResponse0),
          StorageClass: "STANDARD_IA",
          ContentType: "application/json",
        },
        function (err, data) {
          if (err) {
            console.log(err);
          } else {
            console.log("successfully uploaded user modified analytics"); //,fileKey)
            setUploadIconColor("default");
            //setOpenStorage(true)
          }
        }
      );
    } else if (ORActiveImage === "image1") {
      //console.log('The ImageID =',String(imageIDsForShow[1]))
      //console.log('and the analysis id = ',String(viewingAnalysisID))
      fileKey = String(imageIDsForShow[1]) + "/" + String(viewingAnalysisID) + "_allROISignals_userMod.json";
      //console.log('here is what I am about to save from image1',analyticResponse1)
      //console.log('to this key', fileKey)
      s3.putObject(
        {
          Bucket: database, //AWSConfig.creds.bucketName,
          Key: fileKey,
          Body: JSON.stringify(analyticResponse1),
          StorageClass: "STANDARD_IA",
          ContentType: "application/json",
        },
        function (err, data) {
          if (err) {
            console.log(err);
          } else {
            //console.log('successfully uploaded user modified analytics')//,fileKey)
            setUploadIconColor("default");
          }
        }
      );
    }
  }

  function updateRegionsForShow(regionDetails, addOrRemove, whichActiveRegion, activeRegionID, howMany) {
    //console.log('regionDetails', regionDetails)
    //console.log('addOrRemove', addOrRemove)
    //console.log('whichActiveRegion', whichActiveRegion)
    //console.log('activeRegionID', activeRegionID)
    //console.log('howMany', howMany)
    //console.log('activeBandBoxRegions1',activeBandBoxRegions1)
    //console.log('activeBandBoxRegions0',activeBandBoxRegions0)
    let regions = [];
    let boxRegions = [];
    for (var i = 0; i < whichActiveRegion.length; i++) {
      regions[i] = {};
      regions[i]["id"] = i;
      regions[i]["color"] = whichActiveRegion[i].color;
      regions[i]["points"] = whichActiveRegion[i].points;
      regions[i]["roiSignal"] = whichActiveRegion[i].roiSignal;
      if (activeRegionID === 3 || activeRegionID === 1) {
        boxRegions[i] = {};
        boxRegions[i]["id"] = i;
        boxRegions[i]["color"] = activeRegionID === 3 ? activeBandBoxRegions0[i].color : activeBandBoxRegions1[i].color;
        boxRegions[i]["points"] = activeRegionID === 3 ? activeBandBoxRegions0[i].points : activeBandBoxRegions1[i].points;
        boxRegions[i]["roiSignal"] = activeRegionID === 3 ? activeBandBoxRegions0[i].roiSignal : activeBandBoxRegions1[i].roiSignal;
      }
      if (howMany === "single") {
        if (i === regionDetails.id) {
          if (addOrRemove === "remove") {
            regions[i]["active"] = false;
            if (activeRegionID === 3 || activeRegionID === 1) {
              //console.log('removing box region',i)
              //console.log('activeRegionID',activeRegionID)
              boxRegions[i]["active"] = false;
            }
          } else {
            regions[i]["active"] = true;
            if (activeRegionID === 3 || activeRegionID === 1) {
              boxRegions[i]["active"] = true;
            }
          }
        } else {
          regions[i]["active"] = whichActiveRegion[i].active;
          if (activeRegionID === 3 || activeRegionID === 1) {
            boxRegions[i]["active"] = activeRegionID === 3 ? activeBandBoxRegions0[i].active : activeBandBoxRegions1[i].active;
          }
        }
      } else if (howMany === "all") {
        if (addOrRemove === "remove") {
          regions[i]["active"] = false;
          if (activeRegionID === 3 || activeRegionID === 1) {
            boxRegions[i]["active"] = false;
          }
        } else {
          regions[i]["active"] = true;
          if (activeRegionID === 3 || activeRegionID === 1) {
            boxRegions[i]["active"] = true;
          }
        }
      }
      if (activeImage === 0) {
        regions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
        if (activeRegionID === 3 || activeRegionID === 1) {
          boxRegions[i]["canvasDims"] = [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0];
        }
      } else {
        regions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
        if (activeRegionID === 3 || activeRegionID === 1) {
          boxRegions[i]["canvasDims"] = [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1];
        }
      }
      if (i === activeRegions.length - 1) {
        if (activeImage === "image0") {
          //console.log('boxRegions',boxRegions)
          setRegions0(regions);
          setActiveRegions(regions);
          if (activeRegionID === 2) {
            setActiveLysateRegions0(regions);
          } else if (activeRegionID === 3) {
            setActiveBandRegions0(regions);
            setActiveBandBoxRegions0(boxRegions);
            setBoxRegions0(boxRegions);
          } else if (activeRegionID === 4) {
            setActiveGelBlotRegions0(regions);
          }
        } else if (activeImage === "image1") {
          //console.log('boxRegions',boxRegions)
          setRegions1(regions);
          setActiveRegions(regions);
          if (activeRegionID === 0) {
            setActiveLysateRegions1(regions);
          } else if (activeRegionID === 1) {
            setActiveBandRegions1(regions);
            setActiveBandBoxRegions1(boxRegions);
            setBoxRegions1(boxRegions);
          } else if (activeRegionID === 5) {
            setActiveGelBlotRegions1(regions);
          }
        }
      }
    }
  }

  function addRemoveRegion(theRegion, addOrRemove) {
    //console.log('lys0 =',fll0,'lys1 =',fll1,'band0 =',fb0,'band1 =',fb1,'border0 =',gb0,'border1 =',gb1)
    if (fll1) {
      console.log("ran lys1");
      updateRegionsForShow(theRegion, addOrRemove, activeLysateRegions1, 0, "single");
    } else if (fb1) {
      //for the case of bands, if a band is removed, I need to remove the corresponding band box
      console.log("ran band1");
      updateRegionsForShow(theRegion, addOrRemove, activeBandRegions1, 1, "single");
    } else if (fll0) {
      console.log("ran lys0");
      updateRegionsForShow(theRegion, addOrRemove, activeLysateRegions0, 2, "single");
    } else if (fb0) {
      //for the case of bands, if a band is removed, I need to remove the corresponding band box
      console.log("ran band0");
      updateRegionsForShow(theRegion, addOrRemove, activeBandRegions0, 3, "single");
    } else if (gb0) {
      console.log("ran border0");
      updateRegionsForShow(theRegion, addOrRemove, activeGelBlotRegions0, 4, "single");
    } else if (gb1) {
      console.log("ran border1");
      updateRegionsForShow(theRegion, addOrRemove, activeGelBlotRegions1, 5, "single");
    }
  }

  function updateVerifiedClass(index, whichButton) {
    function getIndex(index) {
      return currentUpload.findIndex((item) => item.imageID === selectedID[index]);
    }
    function returnVFC(index, whichButton) {
      var brClass = verificationDict[index]["mlClass"];
      if (whichButton === "agree") {
        return brClass;
      } else if (whichButton === "disagree") {
        if (brClass === "gel") {
          return "membrane";
        } else {
          return "gel";
        }
      } else if (whichButton === "notSure") {
        return "notVerified";
      }
    }
    function updateItem(index, whichButton) {
      //console.log(returnVFC(index,whichButton))
      //console.log()
      var updateParams = {
        Key: { imageID: { S: String(verificationDict[index]["imageID"]) } },
        TableName: "ila-rawImage-table",
        UpdateExpression: "set #uvc=:uvc",
        ExpressionAttributeNames: { "#uvc": "userVerifiedClass" },
        ExpressionAttributeValues: { ":uvc": { S: String(returnVFC(index, whichButton)) } },
      };
      //console.log(updateParams)
      dynamodb.updateItem(updateParams, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          console.log("successfully updated user verification");
          currentUpload[getIndex(index)]["userVerifiedClass"] = returnVFC(index, whichButton);
          setCurrentUpload(currentUpload);
        }
      });
    }
    if (index === 0) {
      setTestDisableB0(true);
      updateItem(index, whichButton);
      if (testDisableB1 === true) {
        setAllowedToLeave(true);
      }
      //console.log(verificationDict[index])
      //console.log(currentUpload[getIndex(index)])
    } else if (index === 1) {
      setTestDisableB1(true);
      updateItem(index, whichButton);
      if (testDisableB0 === true) {
        setAllowedToLeave(true);
      }
      //console.log(verificationDict[index])
      //console.log(currentUpload[getIndex(index)])
    }
  }
  function highlightRegion(theRegion) {
    //console.log('the selected Region',theRegion)
    var icon;
    var priorIcon;
    if (activeImage === "image0") {
      if (selectRegion0 != null) {
        //console.log('selectRegion0',selectRegion0)
        priorIcon = document.getElementById("highlightIcon" + String(selectRegion0));
        priorIcon.style.color = "grey";
      }
      icon = document.getElementById("highlightIcon" + String(theRegion.id));
      //console.log('icon',icon)
      icon.style.color = "#ff00f1"; //'#b98d01'
      setSelectRegion0(theRegion.id);
    } else if (activeImage === "image1") {
      if (selectRegion1 != null) {
        priorIcon = document.getElementById("highlightIcon" + String(selectRegion1));
        priorIcon.style.color = "grey";
      }
      icon = document.getElementById("highlightIcon" + String(theRegion.id));
      icon.style.color = "#ff00f1"; //'#b98d01'
      setSelectRegion1(theRegion.id);
    }
  }

  function preparePriorAnalysisDictionary(selectedID) {
    //console.log(currentUpload)
    var priorAnalysisDictionary = {};
    priorAnalysisDictionary["image0"] = [];
    priorAnalysisDictionary["image1"] = [];
    var theSwapIndex0;
    var theSwapIndex1;
    //theSwapIndex0 = currentUpload.findIndex(item => item.imageID === selectedID[0])
    //theSwapIndex1 = currentUpload.findIndex(item => item.imageID === selectedID[1])

    if (selectedID.length === 1) {
      //theSwapIndex0 = currentUpload.findIndex(item => item.imageID === selectedID[0])
      //console.log('theSwapIndex0 from selectIDLenght===1',theSwapIndex0)
      queryTableWithSingleImageID(String(selectedID[0])).then(function (value) {
        var dataArray = value[0]["Items"][0];
        for (var i = 0; i < dataArray["analysisIDs"]["L"].length; i++) {
          priorAnalysisDictionary["image0"].push({ id: dataArray["analysisIDs"]["L"][i]["S"], genID: i === 0 ? "auto" : "0_" + String(i) });
          if (i === dataArray["analysisIDs"]["L"].length - 1) {
            setPriorAnalysis0(priorAnalysisDictionary["image0"]);
          }
        }
      });
    } else {
      //theSwapIndex0 = currentUpload.findIndex(item => item.imageID === selectedID[0])
      //theSwapIndex1 = currentUpload.findIndex(item => item.imageID === selectedID[1])
      queryTableWithSingleImageID(String(selectedID[0])).then(function (value) {
        var dataArray = value[0]["Items"][0];
        for (var i = 0; i < dataArray["analysisIDs"]["L"].length; i++) {
          priorAnalysisDictionary["image0"].push({ id: dataArray["analysisIDs"]["L"][i]["S"], genID: i === 0 ? "auto" : "0_" + String(i) });
          if (i === dataArray["analysisIDs"]["L"].length - 1) {
            setPriorAnalysis0(priorAnalysisDictionary["image0"]);
          }
        }
      });
      queryTableWithSingleImageID(String(selectedID[1])).then(function (value) {
        var dataArray = value[0]["Items"][0];
        for (var i = 0; i < dataArray["analysisIDs"]["L"].length; i++) {
          priorAnalysisDictionary["image1"].push({ id: dataArray["analysisIDs"]["L"][i]["S"], genID: i === 0 ? "auto" : "1_" + String(i) });
          if (i === dataArray["analysisIDs"]["L"].length - 1) {
            setPriorAnalysis1(priorAnalysisDictionary["image1"]);
          }
        }
      });
    }

    //THIS IS FLAWED.  I need to get a response from the dynamo, first, to get the updated analysisIDs
    //if (currentUpload[theSwapIndex0]['analysisIDs'][0] !== 'empty') {
    //  for (var i = 0; i < currentUpload[theSwapIndex0]['analysisIDs'].length; i++) {
    //    priorAnalysisDictionary['image0'].push({ id: currentUpload[theSwapIndex0]['analysisIDs'][i]['S'], genID:i===0?'auto':'0_' + String(i) })
    //    if (i === currentUpload[theSwapIndex0]['analysisIDs'].length - 1) {
    //      setPriorAnalysis0(priorAnalysisDictionary['image0'])
    //    }
    //  }
    //} else {
    //  setPriorAnalysis0(null)
    //}
    //if (selectedID.length > 1) {
    //  if (currentUpload[theSwapIndex1]['analysisIDs'][0] !== 'empty') {
    //    for (var j = 0; j < currentUpload[theSwapIndex1]['analysisIDs'].length; j++) {
    //      priorAnalysisDictionary['image1'].push({ id: currentUpload[theSwapIndex1]['analysisIDs'][j]['S'], genID:j===0?'auto':'1_' + String(j) })
    //      if (j === currentUpload[theSwapIndex1]['analysisIDs'].length - 1) {
    //        setPriorAnalysis1(priorAnalysisDictionary['image1'])
    //      }
    //    }
    //  } else {
    //    setPriorAnalysis1(null)
    //  }
    //}
    return priorAnalysisDictionary;
  }

  //CANVAS ROI FUNCTIONS

  function prepCanvasForDrawing() {
    //console.log('how many times does this run')
    var image0 = document.getElementById("source-of-roi0");
    var image1 = document.getElementById("source-of-roi1");
    //var imageTPN0 = document.getElementById('source-of-tpn0')
    var newMiddleDim = imageDisplayBoxRef.current.children[0].children[0].offsetWidth;
    //console.log(imageTPN0,image0)
    image0.addEventListener("load", (e) => {
      setNaturalWidth0(image0.naturalWidth);
      setNaturalHeight0(image0.naturalHeight);
      setStage0({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasWidth: newMiddleDim,
        canvasHeight: (newMiddleDim / image0.naturalWidth) * image0.naturalHeight,
        scaleX: newMiddleDim / image0.naturalWidth,
        scaleY: ((newMiddleDim / image0.naturalWidth) * image0.naturalHeight) / image0.naturalHeight,
      });
    });
    image1.addEventListener("load", (e) => {
      setNaturalWidth1(image1.naturalWidth);
      setNaturalHeight1(image1.naturalHeight);
      setStage1({
        posX: 0,
        posY: 0,
        transform: 1,
        canvasHeight: (newMiddleDim / image1.naturalWidth) * image1.naturalHeight,
        canvasWidth: newMiddleDim,
        scaleX: newMiddleDim / image1.naturalWidth,
        scaleY: ((newMiddleDim / image1.naturalWidth) * image1.naturalHeight) / image1.naturalHeight,
      });
    });
  }

  function getCanvasPointerPosition(node) {
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();

    // now we find relative point
    return transform.point(pos);
  }

  function theNextID(whichRegion) {
    let currentVal = 0;
    var currentLength;
    if (whichRegion === "R0") {
      currentLength = regions0.length;
      if (currentLength === 0) {
        //setROIID0(0)
        currentVal = 0;
      } else {
        //setROIID0(currentLength)
        currentVal = currentLength;
      }
    } else if (whichRegion === "R1") {
      currentLength = regions1.length;
      if (currentLength === 0) {
        //setROIID1(0)
        currentVal = 0;
      } else {
        //setROIID1(currentLength)
        currentVal = currentLength;
      }
    }
    return currentVal;
  }

  function speedDialResponse(iconName) {
    setObjectAnnots({
      objectComposition: "undefined",
      proteinStandard: "undefined",
      proteinTarget: "",
      proteinControl: "undefined",
      userColorTag: "#b32aa9",
    });
    if (currentTable !== null) {
      if (iconName === "Label") {
        setDialogs({ labelDialog: true, targetDialog: false, colorDialog: false });
      } else if (iconName === "Target") {
        setDialogs({ labelDialog: false, targetDialog: true, colorDialog: false });
        //setColorDialog(false)
      } else if (iconName === "Color") {
        setDialogs({ labelDialog: false, targetDialog: false, colorDialog: true });
      }
    } else {
      console.log("nothing to show", iconName);
    }
  }

  function ImageOne() {
    // set crossOrigin of image as second argument, set referrerpolicy as third argument
    const [image, status] = useImage(imagesForShow[0], "anonymous", "origin");
    return <Image image={image} listening={false} />;
  }
  function ImageTwo() {
    // set crossOrigin of image as second argument, set referrerpolicy as third argument
    const [image, status] = useImage(imagesForShow[1], "anonymous", "origin");
    return <Image image={image} listening={false} width={naturalWidth1} height={naturalHeight1} />;
  }

  return (
    <div className="content">
      <div class="screen1Cover screen1">
        <Box className="analyticsMain" sx={{ display: "flex", flexDirection: "row", pb: 20 }}>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <AnalyticsSelectBox refreshAllAnalysis={refreshAllAnalysis} imageRetrievalWidth={widthOfImageRetrieval} changeImageRetrievalWidth={changeImageRetrievalWidth}>
            <RecentUpload
              recentUpload={recentUpload}
              onImageGroupClick={onImageGroupClick}
              handleChange={handleChange}
              linearProgressIndicator={linearProgressIndicator}
              theDateRange={theDateRange}
              setTheDateRange={setTheDateRange}
              imageRetrievalBool={imageRetrievalBool}
            />
            <CurrentSelection
              diplaySelection={diplaySelection}
              currentUpload={currentUpload}
              handleChange={handleChange}
              selected={selected}
              setSelected={setSelected}
              displaySelectionColor={displaySelectionColor}
              setDisplaySelectionColor={setDisplaySelectionColor}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              selectedURL={selectedURL}
              setSelectedURL={setSelectedURL}
              selectedBGRURL={selectedBGRURL}
              setSelectedBGRURL={setSelectedBGRURL}
              imageRetrievalBool={imageRetrievalBool}
            />
            <PrivateDownload
              sharedID={inputSharedID}
              changeTheID={changeInputSharedID}
              handleChange={handleChange}
              displayGetPrivateID={displayGetPrivateID}
              setDatabase={setDatabase}
              database={database}
            />
          </AnalyticsSelectBox>

          <ImageDisplayBox imageDisplayWidth={widthOfImageDisplay}>
            <Accordion sx={accordionHeaderStyle} expanded={expanded2 === "imageAnnotate"} onChange={switchAccordion2("imageAnnotate")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="imageAnnotate-content" id="imageAnnotate-header">
                <Typography sx={{ width: "50%", flexShrink: 0 }}>Review Images</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className="parentDisplayImages" overflow="auto" ref={imageDisplayBoxRef} sx={{ mb: 2, width: "100%" }}>
                  {whatToDisplay === "new" && (
                    <Stack className="displayImage0Stack" direction="row" spacing={0.5} divider={<Divider orientation="vertical" flexItem />}>
                      <Box className="displayImage0Box" sx={{ width: styleWidthOfCanvas0 }}>
                        {/*remove that width to go back to normal */}
                        <Stack>
                          {stage0.canvasWidth === maximizedDim && (
                            <Box>
                              <Typography>
                                <span>&#8226;</span> Hover over the image to zoom, highlight and/or draw new regions of interest using your mouse.
                              </Typography>
                              <Typography>
                                <span>&#8226;</span> Use the table on the right to add or remove highlighted selections for analysis.
                              </Typography>
                            </Box>
                          )}
                          <Box className="displayImage0Content" sx={{ "max-width": styleWidthOfCanvas0 }}>
                            <TransformWrapper
                              //maxScale={2}
                              initialScale={1}
                              initialPositionX={0}
                              initialPositionY={0}
                              panning={{ disabled: [true] }}
                            >
                              <Box className="displayImage0" style={{ display: "none" }}>
                                <img
                                  id="source-of-roi0"
                                  crossOrigin="Anonymous"
                                  src={imagesForShow !== null && imagesForShow[0]}
                                  alt=""
                                  //style={{ filter: 'brightness(' + String(imageFilters[0]['br']) + '%) invert(' + String(imageFilters[0]['inv']) + '%) contrast(' + String(imageFilters[0]['con']) + '%)' }}
                                />
                              </Box>
                              <div>
                                <TransformComponent>
                                  <React.Fragment>
                                    {imagesForShow !== null && !isNaN(stage0.scaleX * stage0.transform) && !isNaN(stage0.canvasHeight) && (
                                      <Stage
                                        className="konva-canvas0"
                                        ref={stageRef0}
                                        width={stage0.canvasWidth}
                                        height={stage0.canvasHeight}
                                        scaleX={stage0.scaleX * stage0.transform}
                                        scaleY={stage0.scaleY * stage0.transform}
                                        x={stage0.posX}
                                        y={stage0.posY}
                                        style={
                                          sat0
                                            ? { filter: "brightness(100%) invert(0%) contrast(100%)" }
                                            : {
                                                filter:
                                                  "brightness(" +
                                                  String(imageFilters[0]["br"]) +
                                                  "%) invert(" +
                                                  String(imageFilters[0]["inv"]) +
                                                  "%) contrast(" +
                                                  String(imageFilters[0]["con"]) +
                                                  "%)",
                                              }
                                        }
                                        onMouseDown={
                                          sat0
                                            ? (event) => {
                                                console.log("annotation on saturation layer not allowed");
                                              }
                                            : (event) => {
                                                setActiveImage("image0");
                                                updateImageInfo("image0");
                                                if (allowInteraction0) {
                                                  setDrawingROI0(true);
                                                  //point is the canvas dimension xy, not natural dimension xy
                                                  const point = getCanvasPointerPosition(event.target.getStage());
                                                  const region = {
                                                    id: theNextID("R0"),
                                                    color: "#FF00C1",
                                                    points: [point],
                                                    canvasDims: [stage0.canvasWidth, stage0.canvasHeight, naturalWidth0, naturalHeight0],
                                                    active: true,
                                                    roiSignal: "undefined",
                                                  };
                                                  setRegions0(regions0.concat([region]));
                                                  setActiveRegions(regions0.concat([region]));
                                                  if (fll0) {
                                                    setActiveLysateRegions0(regions0.concat([region]));
                                                  } else if (fb0) {
                                                    //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                                    //console.log('cursor down regions',regions0)
                                                    //console.log('cursor down boxRegions',boxRegions0)
                                                    setBoxRegions0(boxRegions0.concat([region]));
                                                    setActiveBandBoxRegions0(boxRegions0.concat([region]));
                                                    setActiveBandRegions0(regions0.concat([region]));
                                                  } else if (gb0) {
                                                    setActiveGelBlotRegions0(regions0.concat([region]));
                                                  }
                                                }
                                              }
                                        }
                                        onWheel={(event) => {
                                          event.evt.preventDefault();
                                        }}
                                        onMouseMove={(event) => {
                                          if (!drawingROI0) {
                                            return;
                                          }
                                          if (allowInteraction0) {
                                            const lastRegion = { ...regions0[regions0.length - 1] };
                                            const point = getCanvasPointerPosition(event.target.getStage());
                                            lastRegion.points = lastRegion.points.concat([point]);
                                            regions0.splice(regions0.length - 1, 1);
                                            boxRegions0.splice(boxRegions0.length - 1, 1);
                                            setRegions0(regions0.concat([lastRegion]));
                                            setActiveRegions(regions0.concat([lastRegion]));
                                            if (fll0) {
                                              setActiveLysateRegions0(regions0.concat([lastRegion]));
                                            } else if (fb0) {
                                              //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                              setBoxRegions0(boxRegions0.concat([lastRegion]));
                                              setActiveBandBoxRegions0(boxRegions0.concat([lastRegion]));
                                              setActiveBandRegions0(regions0.concat([lastRegion]));
                                            } else if (gb0) {
                                              setActiveGelBlotRegions0(regions0.concat([lastRegion]));
                                            }
                                          }
                                        }}
                                        onMouseUp={(event) => {
                                          setDrawingROI0(false);
                                          if (allowInteraction0) {
                                            const lastRegion = regions0[regions0.length - 1];
                                            //const lastBoxRegion = boxRegions0[boxRegions0.length - 1];
                                            if (lastRegion.points.length < 3) {
                                              regions0.splice(regions0.length - 1, 1);
                                              boxRegions0.splice(boxRegions0.length - 1, 1);
                                              setRegions0(regions0.concat());
                                              setActiveRegions(regions0.concat());
                                              if (fll0) {
                                                setActiveLysateRegions0(regions0.concat());
                                              } else if (fb0) {
                                                //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                                //console.log('cursor up regions',regions0)
                                                //console.log('cursor up boxRegions',boxRegions0)
                                                setBoxRegions0(boxRegions0.concat());
                                                setActiveBandBoxRegions0(boxRegions0.concat());
                                                setActiveBandRegions0(regions0.concat());
                                              } else if (gb0) {
                                                setActiveGelBlotRegions0(regions0.concat());
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        <Layer ref={ref0_L0} listening={false}>
                                          {/**
                                        <ImageOne></ImageOne>
                                         */}
                                          <Image image={document.getElementById("source-of-roi0")} width={naturalWidth0} height={naturalHeight0} listening={false}></Image>
                                        </Layer>

                                        <Layer ref={ref0_L1}>
                                          {regions0.map((region) => {
                                            //console.log('what is regions0',regions0)
                                            const isSelected = region.id === selectRegion0;
                                            if (region.active) {
                                              return (
                                                <React.Fragment key={region.id}>
                                                  <Line
                                                    globalCompositeOperation="destination-out"
                                                    points={region.points.flatMap((p) => [p.x, p.y])}
                                                    fill="black"
                                                    listening={false}
                                                    perfectDrawEnabled={false}
                                                    closed
                                                  />
                                                  <Line
                                                    name="region"
                                                    points={region.points.flatMap((p) => [p.x, p.y])}
                                                    fill={sat0 ? region.color : "#ffffff00"}
                                                    strokeWidth={2}
                                                    stroke={isSelected ? "#00FFF0" : region.color}
                                                    closed
                                                    //opacity={isSelected ? 0.7 : 0.4}
                                                    perfectDrawEnabled={false}
                                                    onClick={() => {
                                                      setSelectRegion0(region.id);
                                                      highlightRegion(region);
                                                      //console.log(region.points);
                                                    }}
                                                  />
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                        </Layer>
                                      </Stage>
                                    )}
                                  </React.Fragment>
                                </TransformComponent>
                              </div>
                            </TransformWrapper>
                            <Box className="displayImage0Mods" sx={{ display: hideImageMods0, width: stage0.canvasWidth }}>
                              {imagesForShow !== null && (
                                <div className="bgColorTest">
                                  <div className="allImageContent">
                                    <div className="imageContentR1">
                                      <div className="FandIContent">
                                        <ImageFilters
                                          changeContrast={changeContrast0}
                                          changeBrightness={changeBrightness0}
                                          invertImage={invertImage0}
                                          handleChange={handleChange}
                                          imageFilterBrightness={imageFilters[0]["br"]}
                                          imageFilterContrast={imageFilters[0]["con"]}
                                        />
                                        <ImageInfo whichImage={"Image 0"} handleChange={handleChange} displayGetPrivateID={displayGetPrivateID} imageIDOfDisplayImage={imageIDsForShow[0]} />
                                      </div>
                                      <ImageROIDisplay handleProteinBand={handleProteinBand0} handleChange={handleChange} whichImage={"0"} imageFilterROI={imageFilters[0]} />
                                    </div>
                                    {/* <ImageSubmitControl share={() => shareImage('image0')} save={() => () => saveImage('image0')} submit={() => submitForAnalysis(0)} /> */}

                                    <Divider color="#a1a1a1" sx={{ "margin-left": "0px", "margin-right": "0px" }} variant="middle" />
                                    <Box className="submitSaveShareContent" sx={{ backgroundColor: "#2a5582", width: "100%" }}>
                                      <Stack direction="row" spacing={1}>
                                        {/**                                                               
                                        <Box 
                                          sx={{ pt:0.4}}
                                        >
                                          <IconButton 
                                              onClick={handleChange('shareImage0')}
                                            >
                                              <ShareIcon sx={{'color':'#88e5ff'}}></ShareIcon>
                                          </IconButton>
                                        </Box>
                                        */}
                                        <Box className="saveImage0" sx={{ pt: 0.4 }}>
                                          <MUITooltip title="Download Original and BGR Image">
                                            <IconButton onClick={handleChange("saveImage0")}>
                                              <SaveOutlinedIcon sx={{ color: "#88e5ff", padding: "0px", "&:hover": { color: "orange" } }}></SaveOutlinedIcon>
                                            </IconButton>
                                          </MUITooltip>
                                        </Box>
                                        <IconButton sx={{ padding: "0px", color: "#88e5ff", padding: "0px", "&:hover": { color: "orange" } }} onClick={showPriorAnalysisDialog}>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                        <Divider color="#a1a1a1" varient="middle" orientation="vertical" />
                                        <Box>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography sx={genericImageNameStyle}>
                                                Analysis<br></br>Method
                                              </Typography>
                                            </Box>
                                            <Box sx={{ pl: 1.5, pt: 1 }}>
                                              <Stack direction="row" spacing={1}>
                                                <Box sx={{ mb: 0.5 }}>
                                                  <Button
                                                    sx={{ width: 80, "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
                                                    variant="contained"
                                                    onClick={() => returnAutoAnalysis(0)}
                                                  >
                                                    Auto
                                                  </Button>
                                                </Box>
                                                <Box
                                                //sx={{pt:1,width:'40%'}}
                                                >
                                                  <Button
                                                    sx={{ width: 80, "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
                                                    //onClick={()=>submitForAnalysis(0,null)}
                                                    onClick={() => checkForTPNAndSubmitForAnalysis(0)}
                                                    variant="contained"
                                                  >
                                                    Manual
                                                  </Button>
                                                </Box>
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </div>
                                </div>
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                      <Box className="displayImage1Box" sx={{ width: styleWidthOfCanvas1 }}>
                        {/*remove that width to go back to normal */}
                        <Stack>
                          {stage1.canvasWidth === maximizedDim && (
                            <Box>
                              <Typography>
                                <span>&#8226;</span> Hover over the image to zoom, highlight and/or draw new regions of interest with your mouse.
                              </Typography>
                              <Typography>
                                <span>&#8226;</span> Use the table on the right to add or remove highlighted selections for analysis.
                              </Typography>
                            </Box>
                          )}
                          <Box className="displayImage1Content" sx={{ "max-width": styleWidthOfCanvas1 }}>
                            <div>
                              <TransformWrapper
                                //maxScale={5}
                                ref={wrapperRef1}
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                panning={{ disabled: [true] }}
                              >
                                <div className="displayImage1" style={{ display: "none" }}>
                                  <img
                                    id="source-of-roi1"
                                    crossOrigin="Anonymous"
                                    src={imagesForShow !== null && imagesForShow.length > 1 && imagesForShow[1]}
                                    alt=""
                                    //style={{ filter: 'brightness(' + String(imageFilters[1]['br']) + '%) invert(' + String(imageFilters[1]['inv']) + '%) contrast(' + String(imageFilters[1]['con']) + '%)' }}
                                  />
                                </div>
                                <TransformComponent>
                                  <React.Fragment>
                                    {imagesForShow !== null && imagesForShow.length > 1 && !isNaN(stage1.scaleX * stage1.transform) && (
                                      <Stage
                                        className="konva-canvas1"
                                        ref={stageRef1}
                                        width={stage1.canvasWidth}
                                        height={stage1.canvasHeight}
                                        scaleX={stage1.scaleX * stage1.transform}
                                        scaleY={stage1.scaleY * stage1.transform}
                                        x={stage1.posX}
                                        y={stage1.posY}
                                        style={
                                          sat1
                                            ? { filter: "brightness(100%) invert(0%) contrast(100%)" }
                                            : {
                                                filter:
                                                  "brightness(" +
                                                  String(imageFilters[1]["br"]) +
                                                  "%) invert(" +
                                                  String(imageFilters[1]["inv"]) +
                                                  "%) contrast(" +
                                                  String(imageFilters[1]["con"]) +
                                                  "%)",
                                              }
                                        }
                                        onMouseDown={
                                          sat1
                                            ? (event) => {
                                                console.log("annotation on saturation layer not allowed");
                                              }
                                            : (event) => {
                                                setActiveImage("image1");
                                                updateImageInfo("image1");
                                                if (allowInteraction1) {
                                                  setDrawingROI1(true);
                                                  const point = getCanvasPointerPosition(event.target.getStage());
                                                  const region = {
                                                    id: theNextID("R1"),
                                                    color: "#FF00C1",
                                                    points: [point],
                                                    canvasDims: [stage1.canvasWidth, stage1.canvasHeight, naturalWidth1, naturalHeight1],
                                                    active: true,
                                                    roiSignal: "undefined",
                                                  };
                                                  setRegions1(regions1.concat([region]));
                                                  setActiveRegions(regions1.concat([region]));
                                                  if (fll1) {
                                                    setActiveLysateRegions1(regions1.concat([region]));
                                                  } else if (fb1) {
                                                    //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                                    setBoxRegions1(boxRegions1.concat([region]));
                                                    setActiveBandBoxRegions1(boxRegions1.concat([region]));
                                                    setActiveBandRegions1(regions1.concat([region]));
                                                  } else if (gb1) {
                                                    setActiveGelBlotRegions1(regions1.concat([region]));
                                                  }
                                                }
                                              }
                                        }
                                        onWheel={(event) => {
                                          event.evt.preventDefault();
                                        }}
                                        onMouseMove={(event) => {
                                          if (!drawingROI1) {
                                            return;
                                          }
                                          if (allowInteraction1) {
                                            const lastRegion = { ...regions1[regions1.length - 1] };
                                            const point = getCanvasPointerPosition(event.target.getStage());
                                            //console.log(point)
                                            lastRegion.points = lastRegion.points.concat([point]);
                                            regions1.splice(regions1.length - 1, 1);
                                            boxRegions1.splice(boxRegions1.length - 1, 1);
                                            setRegions1(regions1.concat([lastRegion]));
                                            setActiveRegions(regions1.concat([lastRegion]));
                                            if (fll1) {
                                              setActiveLysateRegions1(regions1.concat([lastRegion]));
                                            } else if (fb1) {
                                              //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                              setBoxRegions1(boxRegions1.concat([lastRegion]));
                                              setActiveBandBoxRegions1(boxRegions1.concat([lastRegion]));
                                              setActiveBandRegions1(regions1.concat([lastRegion]));
                                            } else if (gb1) {
                                              setActiveGelBlotRegions1(regions1.concat([lastRegion]));
                                            }
                                          }
                                        }}
                                        onMouseUp={(event) => {
                                          setDrawingROI1(false);
                                          if (allowInteraction1) {
                                            const lastRegion = regions1[regions1.length - 1];
                                            if (lastRegion.points.length < 3) {
                                              regions1.splice(regions1.length - 1, 1);
                                              boxRegions1.splice(boxRegions1.length - 1, 1);
                                              setRegions1(regions1.concat());
                                              setActiveRegions(regions1.concat());
                                              if (fll1) {
                                                setActiveLysateRegions1(regions1.concat());
                                              } else if (fb1) {
                                                //for the boxes i am going to add on a hand drawing that I need to convert to a conserved box on the backend if present
                                                setBoxRegions1(boxRegions1.concat());
                                                setActiveBandBoxRegions1(boxRegions1.concat());
                                                setActiveBandRegions1(regions1.concat());
                                              } else if (gb1) {
                                                setActiveGelBlotRegions1(regions1.concat());
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        <Layer ref={ref1_L0} listening={false}>
                                          {/**
                                        <ImageTwo></ImageTwo>
                                        */}
                                          <Image image={document.getElementById("source-of-roi1")} width={naturalWidth1} height={naturalHeight1} listening={false}></Image>
                                        </Layer>

                                        <Layer ref={ref1_L1}>
                                          {regions1.map((region) => (
                                            <React.Fragment key={region.id}>
                                              <Line
                                                globalCompositeOperation="destination-out"
                                                points={region.points.flatMap((p) => [p.x, p.y])}
                                                fill="black"
                                                listening={false}
                                                closed
                                                perfectDrawEnabled={false}
                                              />
                                              {region.active && (
                                                <Line
                                                  name="region"
                                                  points={region.points.flatMap((p) => [p.x, p.y])}
                                                  fill={sat1 ? region.color : "#ffffff00"}
                                                  strokeWidth={2}
                                                  stroke={region.id === selectRegion1 ? "#00FFF0" : region.color}
                                                  closed
                                                  //opacity={region.id === selectRegion1 ? 0.7 : 0.4}
                                                  perfectDrawEnabled={false}
                                                  //draggable={true}
                                                  onClick={() => {
                                                    setSelectRegion1(region.id);
                                                    highlightRegion(region);
                                                    //console.log(region)
                                                  }}
                                                />
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </Layer>
                                      </Stage>
                                    )}
                                  </React.Fragment>
                                </TransformComponent>
                              </TransformWrapper>
                            </div>
                            <Box className="displayImage1Mods" sx={{ display: hideImageMods1, width: stage1.canvasWidth }}>
                              {imagesForShow !== null && imagesForShow.length > 1 && (
                                <div className="bgColorTest">
                                  <div className="allImageContent">
                                    <div className="imageContentR1">
                                      <div className="FandIContent">
                                        <ImageFilters
                                          changeContrast={changeContrast1}
                                          changeBrightness={changeBrightness1}
                                          invertImage={invertImage1}
                                          handleChange={handleChange}
                                          imageFilterBrightness={imageFilters[1]["br"]}
                                          imageFilterContrast={imageFilters[1]["con"]}
                                        />
                                        <ImageInfo whichImage={"Image 1"} handleChange={handleChange} displayGetPrivateID={displayGetPrivateID} imageIDOfDisplayImage={imageIDsForShow[1]} />
                                      </div>
                                      <ImageROIDisplay handleProteinBand={handleProteinBand1} handleChange={handleChange} whichImage={"1"} imageFilterROI={imageFilters[1]} />
                                    </div>
                                    {/* <ImageSubmitControl share={() => shareImage('image1')} save={() => saveImage('image1')} submit={() => submitForAnalysis(1)} /> */}
                                    <Divider color="#a1a1a1" sx={{ "margin-left": "0px", "margin-right": "0px" }} variant="middle" />
                                    <Box className="submitSaveShareContent" sx={{ backgroundColor: "#2a5582", width: "100%" }}>
                                      <Stack direction="row" spacing={1}>
                                        {/** 
                                        <Box 
                                          sx={{ pt:0.4}}
                                        >
                                          <IconButton
                                            onClick={handleChange('shareImage1')}
                                          >
                                            <ShareIcon sx={{ 'color': '#88e5ff' }}></ShareIcon>
                                          </IconButton>
                                        </Box>
                                        */}
                                        <Box className="saveImage1" sx={{ pt: 0.4 }}>
                                          <MUITooltip title="Download Original and BGR Image">
                                            <IconButton onClick={handleChange("saveImage1")}>
                                              <SaveOutlinedIcon sx={{ color: "#88e5ff", padding: "0px", "&:hover": { color: "orange" } }}></SaveOutlinedIcon>
                                            </IconButton>
                                          </MUITooltip>
                                        </Box>
                                        <IconButton sx={{ padding: "0px", color: "#88e5ff", "&:hover": { color: "orange" } }} onClick={showPriorAnalysisDialog}>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                        <Divider color="#a1a1a1" varient="middle" orientation="vertical" />
                                        <Stack direction="row">
                                          <Box>
                                            <Typography sx={genericImageNameStyle}>
                                              Analysis<br></br> Method
                                            </Typography>
                                          </Box>
                                          <Box sx={{ pl: 1.5, pt: 1 }}>
                                            <Stack direction="row" spacing={1}>
                                              <Box sx={{ mb: 0.5 }}>
                                                <Button
                                                  sx={{ width: 80, "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
                                                  variant="contained"
                                                  onClick={() => returnAutoAnalysis(1)}
                                                >
                                                  Auto
                                                </Button>
                                              </Box>
                                              <Box
                                              //sx={{ pt: 1, width: '40%' }}
                                              >
                                                <Button
                                                  sx={{ width: 80, "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
                                                  //onClick={() => submitForAnalysis(1,null)}
                                                  onClick={() => checkForTPNAndSubmitForAnalysis(1)}
                                                  variant="contained"
                                                >
                                                  Manual
                                                </Button>
                                              </Box>
                                            </Stack>
                                          </Box>
                                        </Stack>
                                      </Stack>
                                    </Box>
                                  </div>
                                </div>
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            <AnalyticsTableAndGraphContainer
              expanded2={expanded2}
              uploadIconColor={uploadIconColor}
              uploadUserAnalysisToCloud={uploadUserAnalysisToCloud}
              switchAccordion2={switchAccordion2}
              showShareAnalysisDialog={showShareAnalysisDialog}
              ORActiveImage={ORActiveImage}
            >
              <AnalyticsTable
                gridRowData={gridRowData}
                apiRef={apiRef}
                showWhichResults={showWhichResults}
                testFunction={testFunction}
                handleChange={handleChange}
                updateChartFromTable={updateChartFromTable}
                changeIconColor={changeIconColor}
                tpnPassed={tpnPassed}
                openTPNAlignDialog={openTPNAlignDialog}
                manualAnalysisBool={manualAnalysisBool}
                showTPNSignal={showTPNSignal}
                showBoxSignals={showBoxSignals}
                swapBoxSignals={swapBoxSignals}
              />
              <AnalyticsGraph
                toggleAxisToMW={toggleAxisToMW}
                toggleBoxLane={toggleBoxLane}
                imageFilters={imageFilters}
                ORImage={ORImage}
                CJSData={CJSData}
                expanded3={expanded3}
                switchAccordion3={switchAccordion3}
                middleDim={middleDim}
                ORActiveImage={ORActiveImage}
                displayRegionArray={displayRegionArray}
                bubbleChartRef={bubbleChartRef}
                lineChartRef={lineChartRef}
                naturalWidth0={naturalWidth0}
                naturalWidth1={naturalWidth1}
                naturalHeight0={naturalHeight0}
                naturalHeight1={naturalHeight1}
                togglePolygonLane={togglePolygonLane}
                toggleRelativeMobility={toggleRelativeMobility}
                AnalyticsGraph={AnalyticsGraph}
                // AnalyticsGraph={AnalyticsGraph}
                toggleCenterLaneLine={toggleCenterLaneLine}
                toggleRelativeToBand={toggleRelativeToBand}
                toggleRelativeToLane={toggleRelativeToLane}
                toggleTotalLane={toggleTotalLane}
                toggleMWFit={toggleMWFit}
                handleChange={handleChange}
                laneChecks={laneChecks}
                laneCJSData={laneCJSData}
                speedDialResponse={speedDialResponse}
                scatCJSData={scatCJSData}
                bubbleOptions={bubbleOptions}
                lineProfileOptions={lineProfileOptions}
                yAxisChecks={yAxisChecks}
                relativeChecks={relativeChecks}
                UpdateORImage={UpdateORImage}
                calcRelativeMobility={calcRelativeMobility}
                mwLineChartOptions={mwLineChartOptions}
                mwLineChartData={mwLineChartData}
                totalLaneChartOptions={totalLaneChartOptions}
                totalLaneChartData={totalLaneChartData}
                RMBounds={RMBounds}
                bubbleScalar={bubbleScalar}
                laneScalar={laneScalar}
                bubbleChartTitles={bubbleChartTitles}
              />
            </AnalyticsTableAndGraphContainer>
          </ImageDisplayBox>

          <ImageContentBox activeImage={activeImage}>
            <ImageInfoContent expanded={expanded} switchAccordion={switchAccordion} theImageInfo={theImageInfo} />
            <ImageROI
              switchAccordion={switchAccordion}
              expanded={expanded}
              handleChange={handleChange}
              activeRegions={activeRegions}
              addRemoveCheck={addRemoveCheck}
              highlightRegion={highlightRegion}
              addRemoveRegion={addRemoveRegion}
              highlightColor={highlightColor}
            />
            <CurrentAnalysis
              expanded={expanded}
              switchAccordion={switchAccordion}
              image0AnalysisStatus={image0AnalysisStatus}
              image1AnalysisStatus={image1AnalysisStatus}
              analysisStatusColor0={analysisStatusColor0}
              analysisStatusColor1={analysisStatusColor1}
              readyToViewCurrent0={readyToViewCurrent0}
              readyToViewCurrent1={readyToViewCurrent1}
              showAnalysisResult={showAnalysisResult}
              activeImage={activeImage}
            />
            <PreviousAnalysis
              PreviousAnalysis={PreviousAnalysis}
              expanded={expanded}
              switchAccordion={switchAccordion}
              handleChange={handleChange}
              selectedPriorAnalysis0={selectedPriorAnalysis0}
              priorAnalysis0={priorAnalysis0}
              priorAnalysis1={priorAnalysis1}
              loadingPriorAnalysis={loadingPriorAnalysis}
              selectedPriorAnalysis1={selectedPriorAnalysis1}
              retrieveVisState={retrieveVisState}
              showAnalysisResult={showAnalysisResult}
              showPriorAnalysisDialog={showPriorAnalysisDialog}
              updatePriorAnalysis={updatePriorAnalysis}
            />
          </ImageContentBox>

          {/* Dialogs */}
          <ShareAnalysisDialog open={openShareAnalysisDialog} sharingID={sharingAnalysisID} closeSharingDialog={closeShareAnalysisDialog}></ShareAnalysisDialog>
          <PriorAnalysisDialog handleChange={handleChange} open={openPriorAnalysisDialog} closePriorAnalysisDialog={closePriorAnalysisDialog} />
          <WhichAnalysisDialog WhichAnalysisDialog={WhichAnalysisDialog} open={whichAnalysisDialog} handleChange={handleChange} />
          <ColorDialog closeColorDialog={closeColorDialog} open={dialogs.colorDialog} changeORColor={changeORColor} handleChange={handleChange} objectAnnots={objectAnnots} />
          <TargetDialog
            closeTargetDialog={closeTargetDialog}
            TargetDialog={TargetDialog}
            setTargetTags={setTargetTags}
            changeTarget={changeTarget}
            handleChange={handleChange}
            open={dialogs.targetDialog}
            showWhichResults={showWhichResults}
            proteinList={proteinList}
            lysateList={lysateList}
            lysateTarget={lysateTarget}
            objectAnnots={objectAnnots}
          />
          <TPNAlignDialog
            open={dialogs.tpnAlignDialog}
            closeTPNAlignDialog={closeTPNAlignDialog}
            imagesForShow={imagesForShow}
            imageFilters={imageFilters}
            stage0={stage0}
            stage1={stage1}
            tpnLaneArrays={tpnLaneArrays}
            tpnMapping={tpnMapping}
            tpnMappingLines={tpnMappingLines}
            //tpnGridRowData={tpnGridRowData}
            handleTPNStepLeft={handleTPNStepLeft}
            handleTPNStepRight={handleTPNStepRight}
            tpnGridAPIRef={tpnGridAPIRef}
            resetTPNDialog={resetTPNDialog}
            changeTPNNormLane={changeTPNNormLane}
          ></TPNAlignDialog>
          <TPNRequestedDialog openTPNRequestedDialog={dialogs.openTPNRequestedDialog} closeTPNRequestedDialog={closeTPNRequestedDialog}></TPNRequestedDialog>
          <LabelDialog closeLabelDialog={closeLabelDialog} open={dialogs.labelDialog} handleChange={handleChange} objectAnnots={objectAnnots} analyzingMW={analyzingMW} />
          <NoFilesAlertDialog open={dialogs.noFilesAlertDialog} closeNoFilesDialog={closeNoFilesDialog} handleChange={handleChange} />
          <ToManyAlertDialog open={dialogs.toManyAlertDialog} handleChange={handleChange} />
          <GelBlotBorderDialog open={dialogs.gelBlotBorderDialog} handleChange={handleChange} />
          <ConfirmMLClassDialog
            open={dialogs.confirmMLClassDialog}
            handleChange={handleChange}
            verificationDict={verificationDict}
            updateVerifiedClass={updateVerifiedClass}
            allowedToLeave={allowedToLeave}
            setDialogs={setDialogs}
            testDisableB0={testDisableB0}
            testDisableB1={testDisableB1}
          />
          <NoAnaSelectDialog open={dialogs.noAnaSelectDialog} handleChange={handleChange} continueSubmitForAnalysis={continueSubmitForAnalysis} activeImage={activeImage} />
        </Box>
      </div>
    </div>
  );
}
