import React, { createContext, useContext, useState, useEffect } from "react";
import useAppStore from "../store/useAppStore";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Initialize theme state to light mode by default
  const [theme, setTheme] = useState("light");
  const [userID] = useAppStore((state) => [state?.userID]);

  useEffect(() => {
    // Define the list of users who prefer dark mode
    const darkModeUsers = ["18a315c2-2819-4cba-b54f-3433e5d3bd89", "8fcee69f-660a-421e-ac20-5f0837ffba34"];

    // Check if the current user prefers dark mode
    const userPrefersDarkMode = darkModeUsers.includes(userID);

    // Update the theme state based on the user's preference
    setTheme(userPrefersDarkMode ? "dark" : "light");
  }, [userID]); // Empty dependency array means this effect runs once on mount

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
