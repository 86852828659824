import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import * as uuid from "uuid";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const Row = ({ row, onImageGroupClick }) => {
  const { theme } = useTheme();
  const tableRowLinkStyleReady = {
    "&:hover": { cursor: "pointer" },
    "padding-left": "0px",
    "text-decoration": "underline",
    color: theme === "light" ? "#399dbc" : "#7ce38b",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const tableRowLinkStyleNotReady = {
    "&:hover": { cursor: "pointer" },
    "padding-left": "0px",
    "text-decoration": "line-through",
    color: theme === "light" ? "#666" : "#fa7970",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const toolTipReady = "Choose this group for analysis.";
  const toolTipNotReady = "Images in this group are not ready. Click Refresh Icon to Update.";
  const tableHeadRowTextStyle = {
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#c6cdd5",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const tableRowStyle = {
    "padding-left": "0px",
    color: theme === "light" ? "#399dbc" : "#7ce38b",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const [open, setOpen] = React.useState(false);
  //const tableRowLinkStyle_ready = {'&:hover':{'cursor':'pointer'},'padding-left':'0px','color':'#399dbc','font-size':'13px',"font-weight":'300',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}
  //const tableRowLinkStyle_noReady = {'&:hover':{'cursor':'pointer'},'padding-left':'0px','color':'grey','font-size':'13px',"font-weight":'300',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}
  return (
    <React.Fragment>
      <TableRow key={String(uuid.v4())} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} /> : <KeyboardArrowDownIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />}
          </IconButton>
        </TableCell>
        <TableCell sx={tableRowStyle} component="th" scope="row">
          <Tooltip title={row.status.reduce((a, b) => a + b, 0) === 0 ? toolTipReady : toolTipNotReady}>
            <Typography sx={row.status.reduce((a, b) => a + b, 0) === 0 ? tableRowLinkStyleReady : tableRowLinkStyleNotReady} color="#666" onClick={() => onImageGroupClick(row.fullUploadDate, row)}>
              {" "}
              {row.uploadDate}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={tableRowStyle} align="right">
          {row.uploadComment}
        </TableCell>
      </TableRow>
      <TableRow key={String(uuid.v4())}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} variant="subtitle1">
                Files in Upload Group
              </Typography>

              <Table size="small" aria-label="files">
                <TableHead>
                  <TableRow
                    hover
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    key={uuid.v4()}
                    //onClick={()=>showSelectedRecent(row.fullUploadDate)}
                    id={row.fullUploadDate}
                  >
                    <TableCell sx={tableHeadRowTextStyle}>
                      <span>Filename</span>
                    </TableCell>
                    <TableCell sx={tableHeadRowTextStyle} align="center">
                      Lane and Band <br></br>Progress
                    </TableCell>
                    <TableCell sx={tableHeadRowTextStyle} align="center">
                      TPN <br></br>Req.
                    </TableCell>
                    <TableCell sx={tableHeadRowTextStyle} align="right">
                      TPN <br></br>Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.uploadGroup.map((fileRow) => (
                    <TableRow key={String(uuid.v4())}>
                      <TableCell sx={tableRowStyle}>{fileRow.fileName}</TableCell>
                      <TableCell sx={tableRowStyle} align="center">
                        <span style={{ color: fileRow.allMLStatusColor }}>{fileRow.allMLStatus}</span>
                      </TableCell>
                      <TableCell sx={tableRowStyle} align="center">
                        <span>{fileRow.TPNRequested}</span>
                      </TableCell>
                      <TableCell sx={tableRowStyle} align="right">
                        <span style={{ color: fileRow.TPNStatusColor }}>{fileRow.TPNAnalysisStatus}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  onImageGroupClick: PropTypes.func.isRequired,
  row: PropTypes.shape({
    uploadDate: PropTypes.string.isRequired,
    uploadComment: PropTypes.string.isRequired,
    uploadGroup: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        statusColor: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Row;
