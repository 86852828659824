import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

export default function CustomizedTable({ dataList, onChange, moduleName, Row, uuid }) {
  const { theme } = useTheme();
  const tableHeadRowBGStyle = { "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41", "border-bottom": "solid", "border-width": "1.1px", "border-color": "#666" };
  const tableHeadRowTextStyle = {
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "white",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const tableStickyHeadRowTextStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "white",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const tableRowStyle = {
    "padding-left": "0px",
    "background-color": theme === "light" ? "#f9f9f9" : "#474c6f",
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const refreshTableStyle = { padding: "0px", color: theme === "light" ? "#adadad" : "#2a2d41", "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41" };

  return (
    <div>
      {moduleName === "fileUploadTable" && (
        <div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={tableHeadRowBGStyle}>
                  <TableCell sx={refreshTableStyle}>
                    <Tooltip title="clear list">
                      <IconButton onClick={onChange}>
                        <RefreshIcon sx={{ color: "#8b8b8b" }} fontSize="small"></RefreshIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={tableHeadRowTextStyle}>Name</TableCell>
                  <TableCell sx={tableHeadRowTextStyle} align="right">
                    Size (MB)
                  </TableCell>
                  <TableCell sx={tableHeadRowTextStyle} align="right">
                    Extension
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList !== null &&
                  dataList.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell sx={tableRowStyle}></TableCell>
                      <TableCell sx={tableRowStyle} component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell sx={tableRowStyle} align="right">
                        {row.size / 1000000}
                      </TableCell>
                      <TableCell sx={tableRowStyle} align="right">
                        {row.name.split(".").pop()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {moduleName === "currentUploadTable" && (
        <div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={tableHeadRowBGStyle}>
                  <TableCell sx={refreshTableStyle}>
                    <Tooltip title="refresh current upload">
                      <IconButton onClick={onChange}>
                        <CloudSyncOutlinedIcon sx={{ color: "#8b8b8b" }} fontSize="small"></CloudSyncOutlinedIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={tableHeadRowTextStyle}>Name</TableCell>
                  <TableCell sx={tableHeadRowTextStyle} align="right">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList !== null &&
                  dataList.map((statusrow) => (
                    <TableRow key={statusrow.name}>
                      <TableCell sx={tableRowStyle}></TableCell>
                      <TableCell sx={tableRowStyle} component="th" scope="row">
                        {statusrow.fileName}
                      </TableCell>

                      <TableCell sx={tableRowStyle} align="right">
                        <span style={{ color: statusrow.statusColor }}>{statusrow.pngRawStatus}</span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {moduleName === "recentUploadTable" && (
        <div>
          <TableContainer component={Paper} sx={{ "max-height": "376px" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={tableHeadRowBGStyle}>
                  <TableCell sx={refreshTableStyle}>
                    <Tooltip title="scan for recent uploads">
                      <IconButton onClick={onChange}>
                        <CloudSyncOutlinedIcon sx={{ color: "#8b8b8b" }} fontSize="small"></CloudSyncOutlinedIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" sx={tableStickyHeadRowTextStyle}>
                    Upload Date
                  </TableCell>
                  <TableCell align="right" sx={tableStickyHeadRowTextStyle}>
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{dataList !== null && dataList.map((recentRow, index) => <Row key={recentRow.uploadDate + "_" + String(uuid.v4())} row={recentRow} />)}</TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
