//import "../../App.scss";

import "./home.scss";

// import './../../style.scss';
import React, { useState, useRef, useEffect } from "react";
import CustomizedDialogs from "../shared/dialog/dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FileDrop } from "react-file-drop";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomizedTable from "../shared/table/table";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AWS from "aws-sdk";
import * as AWSConfig from "../../awsConfig";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import { useIsAuthenticated } from "@azure/msal-react";
import * as uuid from "uuid";
import { authentication, redirectUri } from "./../../updatedAuthConfig";
import { useMsal } from "@azure/msal-react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TPNSelectedDialog from "./dialogs/TPNSelectedDialog";
import TPNAlertDialog from "./dialogs/TPNAlertDialog";
import { tpnGridData } from "./config";
import { useTheme } from "../../theme/ThemeContext";
import { styles } from "../../theme/ThemeStyle";

AWS.config.update({
  region: AWSConfig.creds.bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSConfig.creds.indentityPoolId,
    RoleArn: AWSConfig.creds.roleArn,
  }),
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: AWSConfig.creds.bucketName },
  useAccelerateEndpoint: true,
});

const dynamodb = new AWS.DynamoDB({
  apiVersion: "2012-08-10",
});

const lambda = new AWS.Lambda({
  apiVersion: "2015-03-31",
});

// sessionStorage.setItem('username', "Burijintirahul Reddy")

export default function Home() {
  const { theme } = useTheme();
  const [username, setUsername] = useState();
  const [authToken, setAuthToken] = useState();
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [menuExpand, setMenuExpand] = useState();
  const [activeMenu, setActiveMenu] = useState("home");
  const [wbUploadFiles, setWBFiles] = useState(null);
  const [gelUploadFiles, setGelFiles] = useState(null);
  const [uploadFamily, setUploadFamily] = useState("family");
  const [uploadAlertDialog, setUploadAlertDialog] = useState(false);
  const [uploadMSCNAlertDialog, setUploadMSCNAlertDialog] = useState(false);
  const [uploadComment, setUploadComment] = useState("");
  const [imageDialog, setImageDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadCommentRef = useRef("");
  const [currentUpload, setCurrentUpload] = useState(null);
  const [recentUpload, setRecentUpload] = useState(null);
  const [currentUploadDateTime, setCurrentUploadDateTime] = useState(null);
  const [thumbsForDisplay, setThumbsForDisplay] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  let submitDateTime;
  let submitUploadComment;
  let [tpnToggle, setTPNToggle] = useState(false);
  const sectionTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#666" : "#cea5fb",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
  };
  const subTitleStyle = {
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    fontFamily: styles.fontFamily,
  };
  const dropBoxStyle = {
    marginTop: "2px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
    border: "2px dotted #a4a4a4",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme === "light" ? "#dfdfdf" : "#2a2d41",
  };

  const commentStyle = {
    color: theme === "light" ? "primary" : "#c3c0c0",
    input: { color: theme === "light" ? "primary" : "#c3c0c0" },
    fontSize: "15px",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
    "& .MuiInput-underline:before": {
      // default underline color
      borderBottomColor: theme === "light" ? "primary" : "#c3c0c0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // underline color on hover (when not disabled)
      borderBottomColor: theme === "light" ? "primary" : "#faa356",
    },
    "& .MuiInput-underline:after": {
      // underline color when focused
      borderBottomColor: theme === "light" ? "primary" : "#7ce38b",
    },
    "& .MuiFormHelperText-root": {
      color: theme === "light" ? "primary" : "#c3c0c0",
    },
  };

  const tableHeadRowTextStyle = {
    "padding-left": "0px",
    color: "#666",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const tableRowStyle = {
    "padding-left": "0px",
    color: "#399dbc",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const buttonStyle = { "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "& .MuiLoadingButton-loadingIndicator": { color: theme === "light" ? "primary" : "white" } };
  const addImageIconStyle = { "padding-left": "20px", "padding-right": "10px", color: theme === "light" ? "#399dbc" : "#77bdfb" };
  let [dialogs, setDialogs] = useState({
    tpnSelectDialog: false,
    tpnAlertDialog: false,
  });
  let [tpnAnalysisID, setTPNAnalysisID] = useState("");
  let [selectedTPNFile, setSelectedTPNFile] = useState({});
  let [selectedTPNLane, setSelectedTPNLane] = useState("");
  let [tpnReadyToUpload, setTPNReadyToUpload] = useState({ fileSet: false, laneSet: false, readyToUpload: false });
  let [tpnImageID, setTPNImageID] = useState({ imageID: "NA" });
  let [tpnAlertMessage, setTPNAlertMessage] = useState({ title: "", message: "" });

  function setTheTPNFile(selection, gridData) {
    //console.log(selection,gridData)
    //console.log(selection)
    //console.log(gridData['rows'][parseInt(selection[0])]['imageID'])
    setTPNImageID(gridData["rows"][parseInt(selection[0])]);
    setSelectedTPNFile(selection);
    setTPNReadyToUpload({ fileSet: true, laneSet: tpnReadyToUpload.laneSet, readyToUpload: tpnReadyToUpload.readyToUpload });
    if (tpnReadyToUpload.laneSet) {
      setTPNReadyToUpload({ fileSet: true, laneSet: true, readyToUpload: true });
    }
  }
  function setTheTPNLane(event) {
    setSelectedTPNLane(event.target.value);
    setTPNReadyToUpload({ fileSet: tpnReadyToUpload.fileSet, laneSet: true, readyToUpload: tpnReadyToUpload.readyToUpload });
    if (tpnReadyToUpload.fileSet) {
      setTPNReadyToUpload({ fileSet: true, laneSet: true, readyToUpload: true });
    }
  }
  function cancelTPNSelectDialog() {
    setTPNReadyToUpload({ fileSet: false, laneSet: false, readyToUpload: false });
    //setTPNReadyToUpload(false)
    setSelectedTPNFile({});
    setSelectedTPNLane("");
    setDialogs({ tpnSelectDialog: false });
  }
  function closeTPNAlertDialog() {
    setDialogs({ tpnAlertDialog: false });
  }

  function queryForRecentFiles() {
    let uniqueFamilyIDs = [];
    var fullResponse = [];
    let d = new Date(Date.now());
    let oneMonthFilter = String(td(d).year) + String(td(d).month) + String(td(d).day) + String(td(d).hour) + String(td(d).minute) + String(td(d).second);
    let queryDBParams = {
      IndexName: "userID-index",
      ExpressionAttributeValues: {
        ":id": { S: sessionStorage.getItem("userID") },
        ":sd": { N: oneMonthFilter },
      },
      ExpressionAttributeNames: { "#ud": "uploadDate" },
      KeyConditionExpression: "userID = :id",
      FilterExpression: "#ud >= :sd",
      TableName: "ila-rawImage-table",
    };

    function getRecentFiles(err, data) {
      if (err) {
        console.log(err);
      } else {
        for (var i = 0; i < data.Items.length; i++) {
          fullResponse.push(data.Items[i]);
          try {
            uniqueFamilyIDs.push(data.Items[i].familyID.S);
          } catch (err) {
            uniqueFamilyIDs.push("undefined");
          }
        }
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("Scanning for more...");
          queryDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.scan(queryDBParams, getRecentFiles);
        } else {
          setOpenBackdrop(false);
          //setLinearProgressIndicator(false)
          setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
        }
      }
    }
    function consolidateFamilies(uniqueIDs, fileDict) {
      var rowArray = [];
      for (var i = 0; i < uniqueIDs.length; i++) {
        rowArray[i] = {};
        rowArray[i]["uploadGroup"] = [];
        rowArray[i]["familyID"] = uniqueIDs[i];
        for (var j = 0; j < Object.keys(fileDict).length; j++) {
          if (String(fileDict[j]["familyID"]["S"]) === String(uniqueIDs[i])) {
            var cd = fileDict[j]["uploadDate"]["N"];
            rowArray[i]["uploadDate"] = String(cd.slice(4, 6) + "-" + cd.slice(6, 8) + "-" + cd.slice(0, 4));
            rowArray[i]["fullUploadDate"] = cd;
            rowArray[i]["uploadComment"] = fileDict[j]["uploadComment"]["S"];
            var group = {};
            group["fileName"] = fileDict[j]["fileName"]["S"];
            group["status"] = fileDict[j]["pngRawStatus"]["S"];
            if (fileDict[j]["pngRawStatus"]["S"] === "Pending") {
              group["statusColor"] = "orange";
            } else {
              group["statusColor"] = "green";
            }
            rowArray[i]["uploadGroup"].push(group);
          }
        }
      }
      return rowArray;
    }
    function td(d) {
      var theReturnDate = {};
      var theMonth = d.getMonth(); //+1
      var theDay = d.getDate();
      var theHour = d.getHours();
      var theMinute = d.getMinutes();
      var theSecond = d.getSeconds();
      var theYear = d.getFullYear();
      if (theMonth === 0) {
        theReturnDate["month"] = "12";
        theReturnDate["year"] = theYear - 1;
      } else if (theMonth > 0 && theMonth < 10) {
        theReturnDate["month"] = "0" + String(theMonth);
        theReturnDate["year"] = theYear;
      } else {
        theReturnDate["month"] = String(theMonth);
        theReturnDate["year"] = theYear;
      }

      if (theDay < 10) {
        theReturnDate["day"] = "0" + String(theDay);
      } else {
        theReturnDate["day"] = String(theDay);
      }
      if (theHour < 10) {
        theReturnDate["hour"] = "0" + String(theHour);
      } else {
        theReturnDate["hour"] = String(theHour);
      }
      if (theMinute < 10) {
        theReturnDate["minute"] = "0" + String(theMinute);
      } else {
        theReturnDate["minute"] = String(theMinute);
      }
      if (theSecond < 10) {
        theReturnDate["second"] = "0" + String(theSecond);
      } else {
        theReturnDate["second"] = String(theSecond);
      }
      return theReturnDate;
    }

    dynamodb.query(queryDBParams, getRecentFiles);
  }

  function scanForRecentFiles() {
    let d = new Date(Date.now());
    let uniqueFamilyIDs = [];
    var fullResponse = [];
    let theExpressionDateFilter = String(td(d).year) + String(td(d).month) + String(td(d).day) + String(td(d).hour) + String(td(d).minute) + String(td(d).second);
    let scanDBParams = {
      TableName: "ila-rawImage-table",
      ScanFilter: {
        userName: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: sessionStorage.getItem("username") }],
        },
        uploadDate: {
          ComparisonOperator: "GE",
          AttributeValueList: [{ N: theExpressionDateFilter }],
        },
      },
    };

    dynamodb.scan(scanDBParams, getRecentFiles);
    function td(d) {
      var theReturnDate = {};
      var theMonth = d.getMonth(); //+1
      var theDay = d.getDate();
      var theHour = d.getHours();
      var theMinute = d.getMinutes();
      var theSecond = d.getSeconds();
      var theYear = d.getFullYear();
      if (theMonth === 0) {
        theReturnDate["month"] = "12";
        theReturnDate["year"] = theYear - 1;
      } else if (theMonth > 0 && theMonth < 10) {
        theReturnDate["month"] = "0" + String(theMonth);
        theReturnDate["year"] = theYear;
      } else {
        theReturnDate["month"] = String(theMonth);
        theReturnDate["year"] = theYear;
      }

      if (theDay < 10) {
        theReturnDate["day"] = "0" + String(theDay);
      } else {
        theReturnDate["day"] = String(theDay);
      }
      if (theHour < 10) {
        theReturnDate["hour"] = "0" + String(theHour);
      } else {
        theReturnDate["hour"] = String(theHour);
      }
      if (theMinute < 10) {
        theReturnDate["minute"] = "0" + String(theMinute);
      } else {
        theReturnDate["minute"] = String(theMinute);
      }
      if (theSecond < 10) {
        theReturnDate["second"] = "0" + String(theSecond);
      } else {
        theReturnDate["second"] = String(theSecond);
      }
      return theReturnDate;
    }

    function getRecentFiles(err, data) {
      if (err) {
        console.log(err);
      } else {
        for (var i = 0; i < data.Items.length; i++) {
          fullResponse.push(data.Items[i]);
          try {
            uniqueFamilyIDs.push(data.Items[i].familyID.S);
          } catch (err) {
            uniqueFamilyIDs.push("undefined");
          }
        }
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("Scanning for more...");
          scanDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.scan(scanDBParams, getRecentFiles);
        } else {
          setOpenBackdrop(false);
          //setLinearProgressIndicator(false)
          setRecentUpload(consolidateFamilies([...new Set(uniqueFamilyIDs)], fullResponse));
        }
      }
    }
    function consolidateFamilies(uniqueIDs, fileDict) {
      var rowArray = [];
      for (var i = 0; i < uniqueIDs.length; i++) {
        rowArray[i] = {};
        rowArray[i]["uploadGroup"] = [];
        rowArray[i]["familyID"] = uniqueIDs[i];
        for (var j = 0; j < Object.keys(fileDict).length; j++) {
          if (String(fileDict[j]["familyID"]["S"]) === String(uniqueIDs[i])) {
            var cd = fileDict[j]["uploadDate"]["N"];
            rowArray[i]["uploadDate"] = String(cd.slice(4, 6) + "-" + cd.slice(6, 8) + "-" + cd.slice(0, 4));
            rowArray[i]["fullUploadDate"] = cd;
            rowArray[i]["uploadComment"] = fileDict[j]["uploadComment"]["S"];
            var group = {};
            group["fileName"] = fileDict[j]["fileName"]["S"];
            group["status"] = fileDict[j]["pngRawStatus"]["S"];
            if (fileDict[j]["pngRawStatus"]["S"] === "Pending") {
              group["statusColor"] = "orange";
            } else {
              group["statusColor"] = "green";
            }
            rowArray[i]["uploadGroup"].push(group);
          }
        }
      }
      return rowArray;
    }
  }

  function td(d) {
    var theReturnDate = {};
    var theMonth = d.getMonth(); //+1
    var theDay = d.getDate();
    var theHour = d.getHours();
    var theMinute = d.getMinutes();
    var theSecond = d.getSeconds();
    if (theMonth <= 8) {
      theReturnDate["month"] = "0" + String(theMonth + 1);
    } else {
      theReturnDate["month"] = String(theMonth + 1);
    }
    if (theDay < 10) {
      theReturnDate["day"] = "0" + String(theDay);
    } else {
      theReturnDate["day"] = String(theDay);
    }
    if (theHour < 10) {
      theReturnDate["hour"] = "0" + String(theHour);
    } else {
      theReturnDate["hour"] = String(theHour);
    }
    if (theMinute < 10) {
      theReturnDate["minute"] = "0" + String(theMinute);
    } else {
      theReturnDate["minute"] = String(theMinute);
    }
    if (theSecond < 10) {
      theReturnDate["second"] = "0" + String(theSecond);
    } else {
      theReturnDate["second"] = String(theSecond);
    }
    return theReturnDate;
  }

  function preprocessUploadFile(imageID, familyID, submitDateTime) {
    var thePayload = '{"imageID":"' + String(imageID) + '"}';
    var params = {
      //FunctionName: 'arn:aws:lambda:us-west-2:223634448983:function:ila-preprocess2-lambda',
      FunctionName: "arn:aws:lambda:us-west-2:223634448983:function:ila-preprocess-lambda",
      Payload: thePayload,
    };
    lambda.invoke(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        setLoading(false);
        setOpenBackdrop(false);
      } else {
        s3.waitFor("objectExists", { Bucket: AWSConfig.creds.bucketName, Key: imageID + "/success.png" }, function (err, data) {
          if (err) {
            console.log(err);
            //syncTableWithDB(submitDateTime, 'from-preprocessUploadFile')
            queryTable(familyID, "from-preprocessUploadFile");
            setLoading(false);
            setOpenBackdrop(false);
          } else {
            console.log("success image is present");
            //syncTableWithDB(submitDateTime, 'from-preprocessUploadFile')
            queryTable(familyID, "from-preprocessUploadFile");
            setLoading(false);
            setOpenBackdrop(false);
          }
        });
      }
    });
  }

  function checkForCompletion(fileKey, submitDateTime, imageID, familyID, theFile, submitUploadComment, userClass) {
    s3.waitFor("objectExists", { Bucket: AWSConfig.creds.bucketName, Key: fileKey }, function (err, data) {
      if (err) {
        console.log(err);
        setLoading(false);
        setOpenBackdrop(false);
      } else {
        updateDynamoItem(imageID, familyID, theFile, fileKey, "upload complete", submitDateTime, submitUploadComment, userClass);
      }
    });
  }

  function s3Upload(theFile, fileKey, imageID, familyID, submitDateTime, submitUploadComment, userClass) {
    s3.putObject(
      {
        Bucket: AWSConfig.creds.bucketName,
        Key: fileKey,
        Body: theFile,
        StorageClass: "STANDARD_IA",
      },
      function (err, data) {
        if (err) {
          console.log("it failed", err.message);
          setLoading(false);
          setOpenBackdrop(false);
        } else {
          checkForCompletion(fileKey, submitDateTime, imageID, familyID, theFile, submitUploadComment, userClass);
        }
      }
    );
  }
  //activeSharingID=:asid, sharingIDs=:sids, sharingAnalysisIDs=:saids,
  function updateDynamoItem(imageID, familyID, theFile, fileKey, uploadStatus, submitDateTime, submitUploadComment, userClass) {
    //console.log('here is the problem',tpnImageID, typeof tpnImageID)
    //if(typeof tpnImage==='undefined'){
    //    console.log('if is the solution')
    //}else{
    //    console.log('else is the solution')
    //}

    var updateParams = {
      Key: { imageID: { S: String(imageID) } },
      TableName: "ila-rawImage-table",
      UpdateExpression:
        "set imageDataBucket=:idb, TPNLaneMapping=:tpnlanemap, TPNAnalysisStatus=:tpnstat, TPNRequested=:tpnreq, sfaStatus=:sfas, TPNAnalysisIDs=:tpnaids, TPNImageID=:tpnid, TPNLane=:tpnLane, TPNRequestOrigin=:tpnatup, activeSharingID=:asid, sharingIDs=:sids, sharingAnalysisIDs=:saids, userID=:uid, inTrainingManifest=:itm, userVerifiedClass=:uvc, gelFindKey_8bit=:gfk, scnTags=:scnt,mlClassPrediction=:mlc,bgRemovedKey_8bit=:bgrk,laneFindKey_8bit=:lfk,bandFindKey_8bit=:bfk,imageClass=:ic,uploadCondition=:ucon,preprocessed=:pp,userName=:un,familyID=:fi,fileName=:fn,fileType=:ft,fileSize=:fs,uploadDate=:ud,fileKey=:fk,thumbKey=:tk,thumbStatus=:ts,uploadComment=:uc,uploadStatus=:us,pngRawStatus=:prs,pngRawKey=:prk,png8BitStatus=:p8s,png8BitKey=:p8k",
      ExpressionAttributeValues: {
        ":idb": AWS.DynamoDB.Converter.input("ila-app-data-storage"),
        ":tpnlanemap": AWS.DynamoDB.Converter.input([]),
        ":tpnstat": AWS.DynamoDB.Converter.input(["incomplete"]), //updated to be a list for manual tpn analysis
        ":sfas": AWS.DynamoDB.Converter.input("incomplete"),
        ":tpnaids": AWS.DynamoDB.Converter.input(dialogs.tpnSelectDialog ? [tpnAnalysisID] : []),
        ":tpnid": AWS.DynamoDB.Converter.input(typeof tpnImageID === "undefined" ? "NA" : String(tpnImageID["imageID"])),
        ":tpnLane": AWS.DynamoDB.Converter.input(selectedTPNLane === "" ? "NA" : selectedTPNLane + 1),
        ":tpnreq": AWS.DynamoDB.Converter.input(dialogs.tpnSelectDialog ? String(true) : String(false)),
        ":tpnatup": AWS.DynamoDB.Converter.input("fromAuto"),
        ":asid": AWS.DynamoDB.Converter.input("na"),
        ":sids": AWS.DynamoDB.Converter.input([]),
        ":saids": AWS.DynamoDB.Converter.input([]),
        ":uid": AWS.DynamoDB.Converter.input(sessionStorage.getItem("userID")),
        ":itm": AWS.DynamoDB.Converter.input("false"),
        ":uvc": AWS.DynamoDB.Converter.input("notVerified"),
        ":gfk": AWS.DynamoDB.Converter.input("Pending"),
        ":scnt": AWS.DynamoDB.Converter.input("false"),
        ":mlc": AWS.DynamoDB.Converter.input("Pending"),
        ":bgrk": AWS.DynamoDB.Converter.input("Pending"),
        ":lfk": AWS.DynamoDB.Converter.input("Pending"),
        ":bfk": AWS.DynamoDB.Converter.input("Pending"),
        ":ic": AWS.DynamoDB.Converter.input(userClass),
        ":ucon": AWS.DynamoDB.Converter.input(uploadFamily),
        ":pp": AWS.DynamoDB.Converter.input("false"),
        ":un": AWS.DynamoDB.Converter.input(sessionStorage.getItem("username")),
        ":fi": AWS.DynamoDB.Converter.input(familyID),
        ":fn": AWS.DynamoDB.Converter.input(theFile.name),
        ":ft": AWS.DynamoDB.Converter.input(theFile.name.split(".").pop()),
        ":fs": AWS.DynamoDB.Converter.input(theFile.size),
        ":ud": AWS.DynamoDB.Converter.input(submitDateTime),
        ":fk": AWS.DynamoDB.Converter.input(fileKey),
        ":tk": AWS.DynamoDB.Converter.input("Pending"),
        ":uc": AWS.DynamoDB.Converter.input(submitUploadComment),
        ":us": AWS.DynamoDB.Converter.input(uploadStatus),
        ":ts": AWS.DynamoDB.Converter.input("Pending"),
        ":prs": AWS.DynamoDB.Converter.input("Pending"),
        ":prk": AWS.DynamoDB.Converter.input("Pending"),
        ":p8k": AWS.DynamoDB.Converter.input("Pending"),
        ":p8s": AWS.DynamoDB.Converter.input("Pending"),
      },
    };
    dynamodb.updateItem(updateParams, function (err, data) {
      if (err) {
        console.log(err);
        setLoading(false);
        setOpenBackdrop(false);
      } else {
        preprocessUploadFile(imageID, familyID, submitDateTime);
      }
    });
  }

  function prepUploadList() {
    var uploadList = [];
    if (wbUploadFiles !== null && gelUploadFiles !== null) {
      uploadList = wbUploadFiles.concat(gelUploadFiles);
    } else if (wbUploadFiles == null && gelUploadFiles !== null) {
      uploadList = gelUploadFiles;
    } else if (wbUploadFiles !== null && gelUploadFiles == null) {
      uploadList = wbUploadFiles;
    }
    return uploadList;
  }

  function uploadImages(submitDateTime, submitUploadComment, theFullUploadList) {
    setCurrentUploadDateTime(submitDateTime);
    var currentKey;
    var imageID;
    var familyID;
    var familyIDList = [];
    if (uploadFamily === null) {
      setUploadAlertDialog(true);
      setLoading(false);
      setOpenBackdrop(false);
    } else {
      if (uploadFamily === "family") {
        familyID = String(uuid.v4());
        familyIDList[0] = { S: familyID };
        for (var i = 0; i < theFullUploadList.length; i++) {
          //originally I set the imageID here. For new TPN pathway, I set the imageID when an image is dropped into the membrane or gel bucket
          //imageID = String(uuid.v4())
          //new imageID route
          imageID = theFullUploadList[i].imageID;
          currentKey = imageID + "/" + String(theFullUploadList[i].name);
          s3Upload(theFullUploadList[i], currentKey, imageID, familyID, submitDateTime, submitUploadComment, theFullUploadList[i].userClass);
        }
      } else if (uploadFamily === "single") {
        for (var j = 0; j < theFullUploadList.length; j++) {
          familyID = String(uuid.v4());
          familyIDList.push({ S: familyID });
          //imageID = String(uuid.v4())
          //For new TPN pathway, I set the imageID when an image is dropped into the membrane or gel bucket
          imageID = theFullUploadList[j].imageID;
          currentKey = imageID + "/" + String(theFullUploadList[j].name);
          s3Upload(theFullUploadList[j], currentKey, imageID, familyID, submitDateTime, submitUploadComment, theFullUploadList[j].userClass);
        }
      }
    }
    return [familyIDList];
  }
  var alertUser = false;
  function createRowData(fileDictionary, imageType) {
    var tempFileArray;
    var uploadFileArray;
    var userClass;
    alertUser = false;
    if (String(imageType) === "WB") {
      userClass = "membrane";
      if (wbUploadFiles !== null) {
        tempFileArray = wbUploadFiles.map((x) => x);
        uploadFileArray = wbUploadFiles.map((x) => x);
      } else {
        tempFileArray = [];
        uploadFileArray = [];
      }
    } else if (String(imageType) === "GEL") {
      userClass = "gel";
      if (gelUploadFiles !== null) {
        tempFileArray = gelUploadFiles.map((x) => x);
        uploadFileArray = gelUploadFiles.map((x) => x);
      } else {
        tempFileArray = [];
        uploadFileArray = [];
      }
    }

    for (var i = 0; i < Object.keys(fileDictionary).length; i++) {
      const current = fileDictionary[i];
      const index = uploadFileArray.findIndex((object) => object.name === current.name);
      //don't allow same file to get into table twice
      if (index === -1) {
        if (fileDictionary[i]["name"].split(".").pop() !== "mscn") {
          tempFileArray.push({ name: fileDictionary[i]["name"], size: fileDictionary[i]["size"] / 1000000, type: fileDictionary[i]["name"].split(".").pop() });
          fileDictionary[i]["userClass"] = userClass;
          fileDictionary[i]["imageID"] = String(uuid.v4());
          uploadFileArray.push(fileDictionary[i]);
        } else {
          alertUser = true;
        }
      }

      if (i === Object.keys(fileDictionary).length - 1) {
        if (alertUser) {
          setUploadMSCNAlertDialog(true);
        }
        if (String(imageType) === "WB") {
          setWBFiles(uploadFileArray);
        } else if (String(imageType) === "GEL") {
          setGelFiles(uploadFileArray);
        }
      }
    }
  }

  function queryTable(familyID, fromWhichFunction) {
    var thumbImageList = [];
    var tempResponse = [];
    let queryDBParams = {
      IndexName: "familyID-index",
      ExpressionAttributeValues: {
        ":id": { S: familyID },
      },
      KeyConditionExpression: "familyID = :id",
      TableName: "ila-rawImage-table",
    };
    dynamodb.query(queryDBParams, getQueryResponse);
    function getQueryResponse(err, data) {
      if (err) {
        console.log("logging err", err);
      } else {
        for (var i = 0; i < data.Count; i++) {
          tempResponse[i] = {};
          tempResponse[i]["statusColor"] = "orange";
          tempResponse[i]["pngRawStatus"] = data.Items[i]["pngRawStatus"]["S"];
          tempResponse[i]["fileName"] = data.Items[i]["fileName"]["S"];
          tempResponse[i]["uploadComment"] = data.Items[i]["uploadComment"]["S"];
          if (data.Items[i]["thumbStatus"]["S"] === "Ready") {
            var params = { Bucket: "ila-app-data-storage", Key: data.Items[i]["thumbKey"]["S"] };
            var url = s3.getSignedUrl("getObject", params);
            thumbImageList[i] = {};
            thumbImageList[i]["img"] = url;
            thumbImageList[i]["title"] = data.Items[i]["fileName"]["S"];
            thumbImageList[i]["id"] = "imageID_" + String(i);
            tempResponse[i]["statusColor"] = "green";
          } else if (data.Items[i]["thumbStatus"]["S"] === "Pending") {
            tempResponse[i]["statusColor"] = "orange";
          }
        }
      }
      return [setCurrentUpload(tempResponse), setThumbsForDisplay(thumbImageList)];
    }
  }
  function syncTableWithDB(submitDateTime, fromWhichFunction) {
    var holder = [];
    let scanDBParams = {
      TableName: "ila-rawImage-table",
      ScanFilter: {
        userName: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ S: sessionStorage.getItem("username") }],
        },
        uploadDate: {
          ComparisonOperator: "EQ",
          AttributeValueList: [{ N: String(submitDateTime) }],
        },
      },
    };
    dynamodb.scan(scanDBParams, getRecentFiles);
    function getRecentFiles(err, data) {
      var thumbImageList = [];
      if (err) {
        console.log("logging err", err);
      } else {
        holder.push(data.Items);
        if (typeof data.LastEvaluatedKey != "undefined") {
          //console.log("scanning for more entries....")
          scanDBParams.ExclusiveStartKey = data.LastEvaluatedKey;
          dynamodb.scan(scanDBParams, getRecentFiles);
        } else {
          var fullResponse = [];
          for (var k = 0; k < holder.length; k++) {
            if (holder[k].length > 0) {
              for (var j = 0; j < holder[k].length; j++) {
                fullResponse.push(holder[k][j]);
              }
            }
            if (k === holder.length - 1) {
              var tempResponse = [];
              for (var i = 0; i < fullResponse.length; i++) {
                tempResponse[i] = {};
                tempResponse[i]["pngRawStatus"] = fullResponse[i]["pngRawStatus"]["S"];
                tempResponse[i]["fileName"] = fullResponse[i]["fileName"]["S"];
                tempResponse[i]["uploadComment"] = fullResponse[i]["uploadComment"]["S"];
                tempResponse[i]["statusColor"] = "orange";
                if (fullResponse[i]["thumbStatus"]["S"] === "Ready") {
                  var params = { Bucket: AWSConfig.creds.bucketName, Key: fullResponse[i]["thumbKey"]["S"] };
                  var url = s3.getSignedUrl("getObject", params);
                  //console.log('The URL of the thumb =', url);
                  thumbImageList[i] = {};
                  thumbImageList[i]["img"] = url;
                  thumbImageList[i]["title"] = fullResponse[i]["fileName"]["S"];
                  thumbImageList[i]["id"] = "imageID_" + String(i);
                  tempResponse[i]["statusColor"] = "green";
                } else if (fullResponse[i]["thumbStatus"]["S"] === "Pending") {
                  tempResponse[i]["statusColor"] = "orange";
                }
                if (i === fullResponse.length - 1) {
                  if (String(fromWhichFunction) === "from-showSelectedRecent") {
                    setThumbsForDisplay(thumbImageList);
                  } else {
                    setCurrentUpload(tempResponse);
                    setThumbsForDisplay(thumbImageList);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const handleChange = (prop) => (event, value) => {
    switch (prop) {
      default:
        break;
      case "dropWBFile":
        createRowData(event, "WB");
        break;
      case "dropGelFile":
        createRowData(event, "GEL");
        break;
      case "uploadCondition":
        setUploadFamily(value);

        break;
      case "uploadImages":
        //console.log(uploadCommentRef.current.value)
        //setThumbsForDisplay(null)
        //setCurrentUpload(null)
        //var d = new Date(Date.now())
        //submitDateTime = parseInt(String(d.getFullYear()) + String(td(d).month) + String(td(d).day) + String(td(d).hour) + String(td(d).minute) + String(td(d).second))
        //setLoading(true)
        //submitUploadComment = uploadCommentRef.current.value
        if (tpnToggle) {
          //setLoading(false)
          //setOpenBackdrop(false)
          setTPNAnalysisID(String(uuid.v4()));
          var uploadList = prepUploadList();
          //console.log(uploadList)
          uploadTPNList().then(function (data) {
            if (uploadList.length !== 2) {
              setDialogs({ tpnAlertDialog: true });
              setTPNAlertMessage({ title: "Incorrect Number of Files", message: "For automated TPN analysis, you may only upload 2 files: a Stain-Free image and a Target image." });
            } else if (data[0] !== "scn" || data[1] !== "scn") {
              console.log(data);
              setDialogs({ tpnAlertDialog: true });
              setTPNAlertMessage({ title: "Incorrect File Type", message: "Automated TPN analysis is only functional with .scn files. Please upload scn files only for this feature." });
            } else {
              setDialogs({ tpnSelectDialog: true });
            }
          });
          //tpnGridData.rows=[]
          //for(var i=0;i<uploadList.length;i++){
          //    tpnGridData['rows'].push({id:String(i),fileName:uploadList[i]['name'],imageType:uploadList[i]['userClass'],imageID:uploadList[i]['imageID']})
          //    console.log(uploadList[i]['name'].split('.').pop())
          //}
          //if(uploadList.length!==2){
          //    setDialogs({tpnAlertDialog:true})
          //    setTPNAlertMessage({title:'Incorrect Number of Files',message:'Please select 2 files, only, for automated TPN analysis'})
          //}else{
          //    setDialogs({tpnSelectDialog:true})
          //}

          //console.log(useDemoData())
          //callImageUpload()
        } else {
          //setOpenBackdrop(true)
          setTPNImageID({ imageID: "NA" });
          callImageUpload();
          //uploadImages(submitDateTime, submitUploadComment, prepUploadList())
        }
        //uploadImages(submitDateTime, submitUploadComment, prepUploadList())
        break;
      case "refreshAllUpload":
        setTPNImageID({ imageID: "NA" });
        setDialogs({ tpnSelectDialog: false, tpnAlertDialog: false });
        setTPNToggle(false);
        setCurrentUpload(null);
        setOpenBackdrop(false);
        //setLinearProgressIndicator(false)
        setRecentUpload(null);
        setThumbsForDisplay(null);
        setLoading(false);
        setOpenBackdrop(false);
        setWBFiles(null);
        setGelFiles(null);
        setUploadFamily("family");
        uploadCommentRef.current.value = "";
        break;
      case "clearWBTable":
        setWBFiles(null);
        break;
      case "clearGelTable":
        setGelFiles(null);
        break;
      case "closeDialog":
        setUploadAlertDialog(false);
        break;
      case "closeMSCNDialog":
        setUploadMSCNAlertDialog(false);
        break;
      case "uploadComment":
        setUploadComment(uploadCommentRef.current.value);
        submitUploadComment = uploadCommentRef.current.value;

        break;
      case "syncCurrentStatusTable":
        syncTableWithDB(currentUploadDateTime, "from-syncCurrentStatusTable");
        break;
      case "syncRecentStatusTable":
        setOpenBackdrop(true);
        //setLinearProgressIndicator(true)
        queryForRecentFiles();
        //scanForRecentFiles()
        break;
      case "closeImageDialog":
        setImageDialog(false);
        break;
      case "openImageDialog":
        setImageDialog(true);
        break;
      case "onLanding":
        console.log("hey");
        break;
    }
  };

  //CLEANUP
  //let onImageGroupClick = (value) => {
  //    sessionStorage.setItem('selectedMenu', 'screen1');
  //    sessionStorage.setItem('uploadDateTime', value);
  //    //setSelectedBatchId(value);
  //    //console.log(value);
  //    selectMenu();
  //}

  async function uploadTPNList() {
    var uploadList = prepUploadList();
    var scnList = [];
    //console.log(uploadList)
    tpnGridData.rows = [];
    for (var i = 0; i < uploadList.length; i++) {
      tpnGridData["rows"].push({ id: String(i), fileName: uploadList[i]["name"], imageType: uploadList[i]["userClass"], imageID: uploadList[i]["imageID"] });
      scnList.push(uploadList[i]["name"].split(".").pop());
      //console.log(uploadList[i]['name'].split('.').pop())
    }
    return scnList;
  }

  function callImageUpload() {
    cancelTPNSelectDialog();
    setLoading(true);
    setThumbsForDisplay(null);
    setCurrentUpload(null);
    var d = new Date(Date.now());
    submitDateTime = parseInt(String(d.getFullYear()) + String(td(d).month) + String(td(d).day) + String(td(d).hour) + String(td(d).minute) + String(td(d).second));
    uploadImages(submitDateTime, uploadComment, prepUploadList());
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    function showSelectedRecent(fullUploadDate, row) {
      //console.log('this should show familyID of row',fullUploadDate)
      //console.log(row)
      //syncTableWithDB(fullUploadDate, 'from-showSelectedRecent')
      queryTable(row.familyID, "from-showSelectedRecent");
    }
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={tableRowStyle} component="th" scope="row">
            {/*<Link sx={tableRowLinkStyle} onClick={()=> onImageGroupClick(row.fullUploadDate)} title="Click to Move to Analysis"> {row.uploadDate}</Link>*/}
            {row.uploadDate}
          </TableCell>
          <TableCell sx={tableRowStyle} align="right">
            {row.uploadComment}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  Files in Upload Group
                </Typography>
                <Table size="small" aria-label="files">
                  <TableHead>
                    <TableRow hover sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => showSelectedRecent(row.fullUploadDate, row)} id={row.fullUploadDate}>
                      <TableCell sx={tableHeadRowTextStyle}>
                        <span>Filename</span>&nbsp;<span>(</span>
                        <span>click to show thumbnails</span>
                        <span>)</span>
                      </TableCell>
                      <TableCell sx={tableHeadRowTextStyle}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.uploadGroup.map((fileRow) => (
                      <TableRow>
                        <TableCell sx={tableRowStyle}>{fileRow.fileName}</TableCell>
                        <TableCell sx={tableRowStyle}>
                          <span style={{ color: fileRow.statusColor }}>{fileRow.status}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  Row.propTypes = {
    row: PropTypes.shape({
      uploadDate: PropTypes.string.isRequired,
      uploadComment: PropTypes.string.isRequired,
      uploadGroup: PropTypes.arrayOf(
        PropTypes.shape({
          fileName: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          statusColor: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

  //CLEANUP
  //let history = useHistory();
  //let selectMenu = () => {
  //    let m = sessionStorage.getItem('selectedMenu');
  //    //console.log('active menu',m)
  //    setSelectedMenu(m);
  //    setActiveMenu(m);
  //}
  //let menuState = () => {
  //    let m = sessionStorage.getItem('menuState');
  //    setMenuExpand(m);
  //}

  useEffect(() => {
    async function testAsyncRedirect() {
      // we need to wait for handleRedirectPromise to resolve before calling any interactive API
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
      await instance.handleRedirectPromise();
      const accounts = instance.getAllAccounts();
      //console.log('accounts length',accounts.length)
      if (accounts.length === 0) {
        instance.loginRedirect(redirectUri["redirectUri"]);
      } else {
        const name = accounts[0] && accounts[0].name;
        setUsername(name);
        authentication.accessToken(instance, accounts, setAccessToken);
        sessionStorage.setItem("username", name);
        if (sessionStorage.getItem("accessToken")) {
          setAccessToken(sessionStorage.getItem("accessToken"));
          setAuthToken(sessionStorage.getItem("accessToken"));
        }
      }
    }
    testAsyncRedirect();
  }, [selectedMenu, instance]);

  const FileDropComponent = ({ handleChange, dropTypeText, dropType }) => {
    const [isDragging, setIsDragging] = useState(false);
    let handleChangeType = "";
    let classname = "";
    let targetclassname = "";
    let draggingclassname = "";
    if (dropType === "Membrane") {
      handleChangeType = "dropWBFile";
      classname = "wbfile-drop";
      targetclassname = "wbfile-drop-target";
      draggingclassname = "wbfile-drop-dragging-over-target";
    } else if (dropType === "Gel") {
      handleChangeType = "dropGelFile";
      classname = "gelfile-drop";
      targetclassname = "gelfile-drop-target";
      draggingclassname = "gelfile-drop-dragging-over-target";
    }

    const handleDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      setIsDragging(false);
    };

    const handleDrop = (files, event) => {
      event.preventDefault();
      handleChange(handleChangeType)(files);
      setIsDragging(false);
    };

    return (
      <FileDrop onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave} className={classname} targetClassName={targetclassname} draggingOverTargetClassName={draggingclassname}>
        <Box
          sx={{
            position: "relative",
            height: "60px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            borderRadius: "2px",
            backgroundColor: isDragging ? "rgba(37, 138, 188, 0.507)" : "transparent",
            color: isDragging ? (theme === "light" ? "white" : "white") : theme === "light" ? "#666" : "white",
            [`& .${targetclassname}`]: {
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            },
          }}
        >
          <span>Drop {dropTypeText}</span>
        </Box>
      </FileDrop>
    );
  };

  return (
    <div>
      <div id="app" className={`${menuExpand}`}>
        <div className="content">
          <div className={`homeDiv ${selectedMenu}`}>
            <Box className="uploadMain" sx={{ display: "flex", flexDirection: "row", pb: 20 }}>
              <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Box
                sx={{
                  height: "50%",
                  width: "30%",
                  "box-shadow": "0 0 10px rgba(132, 130, 130, 0.43)",
                  "border-radius": "10px",
                  "margin-right": "5px",
                }}
              >
                <Box className="stagingHeader" sx={{ display: "flex", flexDirection: "row" }}>
                  <Box className="stagingHeaderText" sx={{ width: "90%" }}>
                    <Typography sx={sectionTitleStyle} variant="h5">
                      Staging
                    </Typography>
                  </Box>
                  <Box className="stagingHeaderRefresh" sx={{ marginTop: "12px" }}>
                    <Tooltip title="refresh all">
                      <IconButton onClick={handleChange("refreshAllUpload")}>
                        <RefreshIcon sx={{ color: "#8b8b8b" }}></RefreshIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                  Drag and Drop Files
                </Divider>
                <Box className="wbFileUpload" sx={dropBoxStyle}>
                  <Box>
                    <AddPhotoAlternateOutlinedIcon sx={addImageIconStyle} fontSize="large" />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FileDropComponent handleChange={handleChange} dropType={"Membrane"} dropTypeText={"Membrane Image(s)"} />
                  </Box>
                </Box>
                <Box className="gelFileUpload" sx={dropBoxStyle}>
                  <Box>
                    <AddPhotoAlternateOutlinedIcon sx={addImageIconStyle} fontSize="large" />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FileDropComponent handleChange={handleChange} dropType={"Gel"} dropTypeText={"Gel Image(s)"} />
                  </Box>
                </Box>
                <Box className="uploadTables" sx={{ width: "auto", minHeight: "100px", maxHeight: "300px", overflowY: "auto" }}>
                  <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                    Membrane Image Files
                  </Divider>
                  <Box className="fileUploadTable" sx={{ marginTop: "2px", marginBottom: "20px", marginLeft: "20px", marginRight: "20px", paddingBottom: "5px" }}>
                    <CustomizedTable dataList={wbUploadFiles} onChange={handleChange("clearWBTable")} moduleName={"fileUploadTable"}></CustomizedTable>
                  </Box>
                  <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                    Gel Image Files
                  </Divider>
                  <Box className="fileUploadTable" sx={{ marginTop: "2px", marginBottom: "20px", marginLeft: "20px", marginRight: "20px", paddingBottom: "5px" }}>
                    <CustomizedTable dataList={gelUploadFiles} onChange={handleChange("clearGelTable")} moduleName={"fileUploadTable"}></CustomizedTable>
                  </Box>
                </Box>

                <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                  Comment & Upload
                </Divider>
                <Box sx={{ ml: 1 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: "40%" }}>
                      <Box
                        component="form"
                        sx={{ ...commentStyle, m: 1 }} //{{ "& .MuiTextField-root": { m: 1, width: "100%" } }} //width: '25ch' }}},
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-comment-input"
                          variant="standard"
                          inputRef={uploadCommentRef}
                          onChange={handleChange("uploadComment")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AddCommentOutlinedIcon sx={{ color: "#399dbc" }} fontSize="medium" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <LoadingButton variant="contained" loading={loading} sx={buttonStyle} onClick={handleChange("uploadImages")}>
                        Upload
                      </LoadingButton>
                    </Box>
                    <Box>
                      <FormGroup sx={{ display: "block" }}>
                        <FormControlLabel
                          sx={{ color: "#a1a1a1" }}
                          control={
                            <Checkbox
                              size="small"
                              checked={tpnToggle}
                              sx={{ padding: "0px", "margin-top": "2px", "margin-left": "8.5px", "margin-right": "10px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                              inputProps={{
                                "aria-labelledby": "TPN Upload",
                              }}
                              onChange={() => (tpnToggle ? setTPNToggle(false) : setTPNToggle(true))}
                            />
                          }
                          label="TPN Upload?"
                        ></FormControlLabel>
                      </FormGroup>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <CustomizedDialogs
                moduleName={"UploadStage"}
                alertDialog={uploadFamily === null ? uploadAlertDialog : alertUser ? uploadMSCNAlertDialog : imageDialog}
                onClose={handleChange(uploadFamily === null ? "closeDialog" : alertUser ? "closeMSCNDialog" : "closeImageDialog")}
                severity={uploadFamily === null ? "error" : alertUser ? "warning" : "info"}
                alertTitle={uploadFamily === null ? "Error" : alertUser ? "Warning" : "Check Back Later"}
                title={
                  uploadFamily === null
                    ? "You must first set an "
                    : alertUser
                    ? " This application does not currently support multi-channel (.mscn) files. In the short-term, you can split your .mscn file into single .scn files using Image Lab for upload."
                    : "Suggest what you might list to see here."
                }
                strongTitle={uploadFamily === null ? "Upload Condition" : alertUser ? "" : "Feasibilty Content"}
              ></CustomizedDialogs>
              <Box
                sx={{
                  //"height": "50%",
                  width: "70%",
                  "box-shadow": "0 0 10px rgba(132, 130, 130, 0.43)",
                  "border-radius": "10px",
                }}
              >
                <Box className="statusHeader" sx={{ display: "flex", flexDirection: "row" }}>
                  <Box className="statusHeaderText" sx={{ width: "20%" }}>
                    <Typography sx={sectionTitleStyle} variant="h5">
                      Upload Status
                    </Typography>
                  </Box>
                </Box>
                <Box className="uploadStatusContent" sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                  <Box className="uploadStatusTables" sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
                    <Box className="currentUploadContent">
                      <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                        Current Upload
                      </Divider>
                      <Box className="currentUploadTable" sx={{ marginTop: "2px", marginBottom: "20px", marginLeft: "20px", marginRight: "20px", paddingBottom: "5px" }}>
                        <CustomizedTable dataList={currentUpload} onChange={handleChange("syncCurrentStatusTable")} moduleName={"currentUploadTable"}></CustomizedTable>
                      </Box>
                    </Box>
                    {/** 
                    <Box className="recentUploadsContent" style={{ display: "none" }}>
                      <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                        Recent Uploads
                      </Divider>
                      <div className="recentUploadTable">
                        <CustomizedTable dataList={recentUpload} onChange={handleChange("syncRecentStatusTable")} moduleName={"recentUploadTable"} Row={Row} uuid={uuid}></CustomizedTable>
                        {linearProgressIndicator && (
                          <Box sx={{ color: "#a1a1a1" }}>
                            <LinearProgress color="inherit" />
                          </Box>
                        )}
                      </div>
                    </Box>
                    */}
                  </Box>
                  <Box className="uploadThumbs" sx={{ width: "50%", marginRight: "10px" }}>
                    <Box className="currentThumbsContent">
                      <Divider sx={subTitleStyle} textAlign="left" variant="middle">
                        Quick View (thumbnails)
                      </Divider>
                      <Box className="thumbnailContent" sx={{ marginLeft: "25px", marginRight: "20px" }}>
                        <ImageList>
                          {thumbsForDisplay !== null &&
                            thumbsForDisplay.map((item) => (
                              <ImageListItem key={item.img}>
                                <img src={item.img} srcSet={item.img} alt={item.title} id={item.id} loading="lazy" />
                                <ImageListItemBar
                                  subtitle={item.title}
                                  style={{ color: "#399dbc", "padding-left": "10px" }}
                                  actionIcon={
                                    <IconButton sx={{ color: "#8b8b8b" }} aria-label={`info about ${item.title}`} onClick={handleChange("openImageDialog")}>
                                      <FullscreenOutlinedIcon />
                                    </IconButton>
                                  }
                                />
                              </ImageListItem>
                            ))}
                        </ImageList>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <TPNAlertDialog openTPNAlertDialog={dialogs.tpnAlertDialog} closeTPNAlertDialog={closeTPNAlertDialog} tpnAlertMessage={tpnAlertMessage}></TPNAlertDialog>
              <TPNSelectedDialog
                open={dialogs.tpnSelectDialog}
                cancelTPNSelectDialog={cancelTPNSelectDialog}
                callImageUpload={callImageUpload}
                upDatedGridData={tpnGridData}
                selectedTPNFile={selectedTPNFile}
                setTheTPNFile={setTheTPNFile}
                selectedTPNLane={selectedTPNLane}
                setTheTPNLane={setTheTPNLane}
                tpnReadyToUpload={tpnReadyToUpload}
              ></TPNSelectedDialog>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
