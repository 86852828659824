
import React from 'react';
import Typography from'@mui/material/Typography'
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import ListSubheader from '@mui/material/ListSubheader';
import DraggablePaper from '../common/DraggablePaper';

const menuLabelItemStyle = {'background-color':'#0042801c','padding-left':'0px','color':'#666','font-size':'13px',"font-weight":'300',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}
const menuListGroupStyle = {'color':'#666',"font-weight":'300',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}
  

const LabelDialog = ({ closeLabelDialog,open, handleChange, objectAnnots, analyzingMW }) => {
  
  return (
    <Dialog
      open={open}
      onClose={closeLabelDialog}
      aria-describedby='set-label-dialog-description'
      PaperComponent={DraggablePaper}
    >
      <DialogTitle style={{ cursor: 'move' }}>Optional Labels</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>Choose from a list of labels that describe the currently visible objects. Once set, you can view and filter against them in the table. <br></br><br></br></Typography>
        </DialogContentText>
        <DialogContentText sx={{ mb: 2 }}>
          <Typography><strong><u>If</u></strong> you have selected a single lane and your highlighted selection is a MW standard Lane, setting a MW standard will auto run a MW algorithm and update all objects in your table.</Typography>
        </DialogContentText>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <FormControl size="small">
            <Select
              autoFocus
              value={objectAnnots.objectComposition}
              onChange={handleChange('changeObjectComposition')}
            //label="composition"
            >
              <MenuItem sx={{ menuLabelItemStyle }} value='undefined'><em>None</em></MenuItem>
              <MenuItem sx={{ menuLabelItemStyle }} value='protein'>protein</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle }} value='dna'>dna</MenuItem>
            </Select>
            <FormHelperText>composition</FormHelperText>
          </FormControl>
          <FormControl size="small">
            <Select
              value={objectAnnots.proteinStandard}
              onChange={handleChange('changeProteinStandard')}
            >
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='undefined'><em>None</em></MenuItem>
              <ListSubheader sx={{ menuListGroupStyle }} value='ppps'>Precision Plus Protein Standards</ListSubheader>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='dualColor'>Dual Color</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='allBlue'>All Blue</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='kaleidoscope'>Kaleidoscope</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='dualExtra'>Dual Extra</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='unstained'>Unstained</MenuItem>
              <ListSubheader sx={{ menuListGroupStyle }} value='nps'>Natural Protein Standards</ListSubheader>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='broadRange'>Broad-Range</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='lowRange'>Low-Range</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='highRange'>High-Range</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle, ml: 2 }} value='polypeptide'>Polypeptide</MenuItem>
            </Select>
            <FormHelperText>mw standard type</FormHelperText>
          </FormControl>
          <FormControl size="small">
            <Select
              autoFocus
              value={objectAnnots.proteinControl}
              onChange={handleChange('changeProteinControl')}
            //label="composition"
            >
              <MenuItem sx={{ menuLabelItemStyle }} value='undefined'><em>None</em></MenuItem>
              <MenuItem sx={{ menuLabelItemStyle }} value='positive'>positive</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle }} value='negative'>negative</MenuItem>
              <MenuItem sx={{ menuLabelItemStyle }} value='sample'>sample</MenuItem>
            </Select>
            <FormHelperText>control type</FormHelperText>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>

        <LoadingButton
          variant='contained'
          loading={analyzingMW}
          sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }}
          onClick={handleChange('setLabelTags')}
        >
          Set Tags
        </LoadingButton>
        {/*<Button sx={{'color':'white',"background-color":'#399dbc','&:hover': {'background-color': '#c4673a'},"&:disabled": {'background-color': 'grey'}}} onClick={handleChange('setLabelTags')}>
    Set Tags
  </Button> */}
  
        <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeLabelDialog}>Close</Button>
      </DialogActions>
    </Dialog>)
}

export default LabelDialog;