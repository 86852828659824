import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ShareAnalysisDialog = ({
    closeSharingDialog,
    open,
    sharingID,
    ORActiveImage
})=>{
    
    const handleClose = (value) => {
        closeSharingDialog();        
    };
    return (         
        <Dialog
            open = {open}
            onClose = {handleClose}           
            
        >
            <DialogTitle>Let Others View Your Worked Up Analysis</DialogTitle>
            
            <DialogContent>
                <Box sx={{mb:2}}>
                    <Typography>With this private ID, others can view and/or edit your analysis of this dataset.</Typography>
                </Box>
                <TextField
                  sx={{width:280}}
                  autoComplete='off'
                  size='small'
                  label='Your Private ID'
                  defaultValue={sharingID}
                  variant='standard'                
                ></TextField>
                
            </DialogContent>
            
        </Dialog>        
    )
}
export default ShareAnalysisDialog;