// Determine environment and set clientId accordingly
const getEnvironmentConfig = () => {
  const hostname = window.location.hostname;

  const environmentConfig = {
    // Assuming datascience-development.lsg-applications.com is your dev environment
    "ila-dev.lsg-applications.com": {
      clientId: "3df23a74-3565-48fa-8d83-d87c684a5a9f", // Client ID for dev
    },
    // Assuming datascience.lsg-applications.com is your production environment
    "ila.lsg-applications.com": {
      clientId: "2ba78c62-6dca-464e-a1ee-94dcc3576607", // Client ID for prod
    },
    // Default to production clientId if localhost or any other hostname
    default: {
      clientId: "2ba78c62-6dca-464e-a1ee-94dcc3576607", // Client ID for prod and localhost
    },
  };

  return environmentConfig[hostname] || environmentConfig["default"];
};

// Use the environment-specific config to set up the MSAL configuration
export const msalConfig = {
  auth: {
    clientId: getEnvironmentConfig().clientId,
    authority: "https://login.microsoftonline.com/ef0aebeb-2af0-47a3-b8ae-5665f8a62d11",
    redirectUri: window.location.href, // This value must be included in the list of addresses in the Azure AD app registration redirects
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const redirectUri = {
  redirectUri: window.location.href,
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

export const authentication = {
  accessToken: async (instance, accounts, setAccessToken) => {
    const request = {
      ...loginRequest,
      account: accounts[0],
      prompt: "none", //optional, prompt: 'none' will show the login UI only if necessary, otherwise this will return a token from cache
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    try {
      const response = await instance.acquireTokenSilent(request);
      sessionStorage.setItem("accessToken", response.accessToken);
      setAccessToken(response.accessToken);
    } catch (error) {
      console.error("Silent token acquisition failed, acquiring via popup:", error);
      const response = await instance.acquireTokenPopup(request);
      sessionStorage.setItem("accessToken", response.accessToken);
      setAccessToken(response.accessToken);
    }

    //instance
    //  .acquireTokenSilent(request)
    //  .then((response) => {
    //    sessionStorage.setItem("accessToken", response.accessToken);
    //  })
    //  .catch((e) => {
    //    console.log("Silent token acquisition failed because of" + e + ". Acquiring token using popup.");
    //    instance.acquireTokenPopup(request).then((response) => {
    //      sessionStorage.setItem("accessToken", response.accessToken);
    //    });
    //  });
  },
  signOut: (instance) => {
    return instance.logoutRedirect(redirectUri["redirectUri"]).catch((e) => {
      console.error(e);
    });
  },
};
