export const sectionTitleStyle = {
  padding: "15px",
  color: "#666",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const sectionImageTitleStyle = {
  padding: "15px",
  color: "#399dbc",
  "font-weight": "350",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const accordionHeaderStyle = {
  "background-color": "#dfdfdf",
  "padding-left": "0px",
  color: "#666",
  "font-size": "13px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const regionsOfInterestHeader = {
  color: "#666",
  "font-size": "15px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const genericImageNameStyle = {
  color: "#399dbc",
  "font-style": "italic",
  "text-decoration": "underline",
  "font-size": "14px",
  "font-weight": "400",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};
