import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useAppStore = create(
  immer((set) => ({
    userID: "",
    userName: "",
    actions: {
      setUserID: (userID) =>
        set((state) => {
          state.userID = userID;
        }),
      setUserName: (userName) =>
        set((state) => {
          state.userName = userName;
        }),
    },
  }))
);
export default useAppStore;
