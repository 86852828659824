import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

const TPNSelectedDialog = ({ open, cancelTPNSelectDialog, callImageUpload, upDatedGridData, selectedTPNFile, setTheTPNFile, selectedTPNLane,setTheTPNLane,tpnReadyToUpload})=>{
    //const [selectionModel, setSelectionModel] = React.useState([]);
    //console.log(upDatedGridData.rows[selectionModel[0]])
    const possibleLanes = ['one','two','three','four','five','six','seven','eight','nine','ten','eleven','twelve','thirteen','fourteen','fifteen','sixteen','seventeen','eighteen','nineteen','twenty','twenty-one','twenty-two','twenty-three','twenty-four','twenty-five','twenty-six']
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 50,
            },
        },
    };
    return(
    <Dialog
        open={open}
        keepMounted
        onClose={cancelTPNSelectDialog}
        aria-describedby="tpn checkbox was selected"
    >
        <DialogTitle>Automated Total Protein Normalization</DialogTitle>
        <DialogContent>            
            <DialogContentText sx={{  mb: 2 }}>
                Select the "Stain-Free" image that will be used for TPN normalization, by clicking on the row. The selected image will be highlighted in blue and should contain the lysate profiles. 
            </DialogContentText>
            <Box 
                sx={{height:180,width:'auto','& .tpnTableHeader': {backgroundColor: '#b1d377',}}}>                
                <DataGridPro
                    sx={{
                        boxShadow: 4,
                        //border: 2,
                        //borderColor: '#acb0ad',
                        '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                        },
                        
                    }}
                    disableColumnReorder
                    disableColumnMenu
                    columns={upDatedGridData.columns}
                    rows={upDatedGridData.rows}
                    rowHeight={38}
                    hideFooter
                    headerHeight={20}
                    onSelectionModelChange={(newSelection)=>{
                        setTheTPNFile(newSelection,upDatedGridData)
                    }}
                    selectionModel = {selectedTPNFile}                                                    
                />                
            </Box>
            <DialogContentText sx={{ mt:3, mb: 2 }}>
                Choose a lane for normalization (automated TPN analysis). You will be able to change this selection after you view the automated outcome. Lane One is always the left-most lane.
            </DialogContentText>            
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="select-tpn-lane">TPN Lane</InputLabel>
                <Select                                                    
                    value={selectedTPNLane}
                    onChange={setTheTPNLane}
                    label="Lane"
                    MenuProps={MenuProps}
                >
                    
                    {possibleLanes.map((theLane,index)=><MenuItem value={index}>{theLane}</MenuItem>)}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>            
            <Button 
                sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} 
                onClick={callImageUpload}
                disabled={!tpnReadyToUpload.readyToUpload}
            >
                Upload
            </Button>
            <Button 
                sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} 
                onClick={cancelTPNSelectDialog}
            >
                Cancel
            </Button>
        </DialogActions>
    </Dialog>  
)};

export default TPNSelectedDialog