// import React from 'react';
import React, { useState, useEffect } from "react";
import Header from "../shared/header/header";
import MainMenu from "../shared/menu/menu";
import Home from "../home/home";
import Analytics from "../analytics/analytics";
import Parchitecture from "../parchitecture/parchitecture";
import NotFoundPage from "../shared/notfoundpage/notfoundpage";
import { BrowserRouter as Route, Routes } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./../SignInButton";
import { authentication, redirectUri } from "./../../updatedAuthConfig";
import { useMsal } from "@azure/msal-react";
import { useTheme } from "../../theme/ThemeContext";
import { styles } from "../../theme/ThemeStyle";
import useAppStore from "../../store/useAppStore";
import Box from "@mui/material/Box";

const Layout = ({ children }) => {
  const { theme } = useTheme();
  const { instance } = useMsal();
  const [username, setUsername] = useState();
  const [userID, setUserID] = useState();
  const [authToken, setAuthToken] = useState();
  const [accessToken, setAccessToken] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const [menuExpand, setMenuExpand] = useState();
  const [activeMenu, setActiveMenu] = useState("home");
  const [selectedMenu, setSelectedMenu] = useState("home");

  const { actions: appStoreActions } = useAppStore.getState();

  let selectMenu = () => {
    let m = sessionStorage.getItem("selectedMenu");
    console.log("active menu", m);
    setSelectedMenu(m);
    setActiveMenu(m);
  };
  let menuState = () => {
    let m = sessionStorage.getItem("menuState");
    setMenuExpand(m);
  };

  useEffect(() => {
    async function testAsyncRedirect() {
      // we need to wait for handleRedirectPromise to resolve before calling any interactive API
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
      await instance.handleRedirectPromise();
      const accounts = instance.getAllAccounts();
      //console.log('accounts length',accounts.length)
      if (accounts.length === 0) {
        instance.loginRedirect(redirectUri["redirectUri"]);
      } else {
        const name = accounts[0] && accounts[0].name;
        const userID = accounts[0] && accounts[0].idTokenClaims.oid;
        //console.log(accounts[0])
        setUsername(name);
        setUserID(userID);
        authentication.accessToken(instance, accounts, setAccessToken);
        sessionStorage.setItem("username", name);
        sessionStorage.setItem("userID", userID);
        appStoreActions.setUserName(name);
        appStoreActions.setUserID(userID);
        if (sessionStorage.getItem("accessToken")) {
          setAccessToken(sessionStorage.getItem("accessToken"));
          setAuthToken(sessionStorage.getItem("accessToken"));
        }
      }
    }
    testAsyncRedirect();
  }, [selectedMenu, instance]);

  return (
    <div id="menu" className={`App ${menuExpand}`}>
      <Box
        className="backgroundOfApp"
        sx={{
          backgroundColor: theme === "light" ? styles.appBG.light.backgroundColor : styles.appBG.dark.backgroundColor,
          backgroundImage: theme === "light" ? styles.appBG.light.backgroundImage : styles.appBG.dark.backgroundImage,
          backgroundRepeat: "repeat-y",
          backgroundOrigin: "content-box",
          backgroundPosition: "right top",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        {isAuthenticated ? (
          <React.Fragment>
            <Header showContent={selectedMenu} />
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                top: 64,
                position: "relative",
                height: "calc(100% - 64px)",
                width: "100%",
                flex: "auto",
                flexDirection: "column",
              }}
            >
              <MainMenu callback={selectMenu} menuState={menuState} activeMenu={activeMenu} />
              <Box
                component="main"
                sx={{
                  width: "100%",
                  height: { xs: "auto", md: "100vh" },
                  overflow: { xs: "visible", md: "scroll" },
                }}
              >
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/architecture" element={<Parchitecture />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <SignInButton />
        )}
      </Box>
    </div>
  );
};
export default Layout;
