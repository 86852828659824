import React, { useRef } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Stage, Layer, Line } from "react-konva";
import FormGroup from "@mui/material/FormGroup";
import { Bubble, Scatter, Bar } from "react-chartjs-2";
import LabelIcon from "@mui/icons-material/Label";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EditIcon from "@mui/icons-material/Edit";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Tooltip from "@mui/material/Tooltip";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const AnalyticsGraph = ({
  toggleAxisToMW,
  toggleCenterLaneLine,
  toggleBoxLane,
  imageFilters,
  ORImage,
  CJSData,
  expanded3,
  switchAccordion3,
  middleDim,
  ORActiveImage,
  displayRegionArray,
  bubbleChartRef,
  lineChartRef,
  naturalWidth0,
  naturalWidth1,
  naturalHeight0,
  naturalHeight1,
  togglePolygonLane,
  toggleRelativeMobility,
  toggleRelativeToBand,
  toggleRelativeToLane,
  toggleTotalLane,
  toggleMWFit,
  handleChange,
  laneChecks,
  laneCJSData,
  speedDialResponse,
  scatCJSData,
  bubbleOptions,
  lineProfileOptions,
  yAxisChecks,
  relativeChecks,
  UpdateORImage,
  calcRelativeMobility,
  mwLineChartOptions,
  mwLineChartData,
  totalLaneChartOptions,
  totalLaneChartData,
  RMBounds,
  bubbleScalar,
  laneScalar,
  bubbleChartTitles,
}) => {
  //console.log('totalLaneData',totalLaneChartData)
  //console.log('mwFitData',mwLineChartData)
  const { theme } = useTheme();
  const chartPaperStyle = { backgroundColor: theme === "light" ? "#111" : "#2a2d41" };
  const subTitleStyle = {
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    fontFamily: styles.fontFamily,
  };
  const innerAccordionHeaderStyle = {
    "background-color": theme === "light" ? "#ebecec" : "#474c6f",
    "padding-left": "0px",
    color: "#666",
    "font-size": "13px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const objectRegHeaderStyle = {
    ml: 2,
    mt: 1.2,
    mb: 0.5,
    textAlign: "center",
    color: "white",
    "font-size": "14px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const laneChartPaperStyle = { backgroundColor: "#111" };
  const speedDialActions = [
    { icon: <LabelIcon />, name: "Label" },
    { icon: <FingerprintIcon />, name: "Target" },
    { icon: <ColorLensIcon />, name: "Color" },
  ];
  const ref_OR = useRef("objectRegistryImage");
  let laneProfileRef = useRef(null);
  const h_extent = ORActiveImage === "image0" ? naturalWidth0 : naturalWidth1;
  const v_extent = ORActiveImage === "image0" ? naturalHeight0 : naturalHeight1;
  const showTotalLane = true;
  const handleResetZoom = () => {
    if (lineChartRef && lineChartRef.current) {
      lineChartRef.current.resetZoom();
      lineChartRef.current.options.plugins.annotation.annotations = {};
      lineChartRef.current.update();
    }
  };

  return (
    <Box>
      <Stack direction="column" spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
        <Box>
          <Accordion sx={innerAccordionHeaderStyle} expanded={expanded3 === "analyticCharts"} onChange={switchAccordion3("analyticCharts")} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="analyticCharts-content" id="analyticCharts-header">
              <Typography sx={{ ...subTitleStyle, width: "50%", flexShrink: 0 }}>Charts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                <Box className="theObjectAndSignalChart">
                  {CJSData !== null && (
                    <React.Fragment>
                      <Paper sx={chartPaperStyle} elevation={3}>
                        <Stack direction="row" spacing={2}>
                          {ORImage !== null && (
                            <Box className="theObjectChart" sx={{ ml: 1, width: "50%" }}>
                              <Typography sx={objectRegHeaderStyle}>Objects of Filtered Table</Typography>
                              <Stack>
                                <Box className="displayObjectRegistry" sx={{ width: "100%" }}>
                                  <Box className="displayORImage" style={{ display: "none" }}>
                                    <img
                                      id="source-of-ORImage"
                                      src={ORImage !== null && ORImage}
                                      alt=""
                                      style={{ filter: "brightness(" + String(imageFilters[0]["br"]) + "%) invert(" + String(imageFilters[0]["inv"]) + "%)", backgroundColor: "#000" }}
                                    />
                                  </Box>
                                  <React.Fragment>
                                    {ORImage !== null && (
                                      <Stage
                                        ClassName="konva-objectRegistry"
                                        width={middleDim}
                                        height={ORActiveImage === "image0" ? (middleDim / naturalWidth0) * naturalHeight0 : (middleDim / naturalWidth1) * naturalHeight1}
                                        scaleX={ORActiveImage === "image0" ? middleDim / naturalWidth0 : middleDim / naturalWidth1}
                                        scaleY={
                                          ORActiveImage === "image0" ? ((middleDim / naturalWidth0) * naturalHeight0) / naturalHeight0 : ((middleDim / naturalWidth1) * naturalHeight1) / naturalHeight1
                                        }
                                        //ORIGINAL
                                        //width = {ORActiveImage==='image0' ? stage0.canvasWidth : stage1.canvasWidth}
                                        //height = {ORActiveImage==='image0' ? (stage0.canvasWidth/naturalWidth0)*naturalHeight0 : (stage1.canvasWidth/naturalWidth1)*naturalHeight1}//{ORActiveImage==='image1' ? stage0.canvasHeight : stage1.canvasHeight}
                                        //scaleX = {ORActiveImage==='image0' ? stage0.canvasWidth/naturalWidth0 : stage1.canvasWidth/naturalWidth1}
                                        //scaleY = {ORActiveImage==='image0' ? stage0.canvasWidth/naturalWidth0 : stage1.canvasWidth/naturalWidth1}
                                        x={0}
                                        y={0}
                                        style={{ filter: "brightness(" + String(imageFilters[0]["br"]) + "%) invert(" + String(imageFilters[0]["inv"]) + "%)", backgroundColor: "#000" }}
                                      >
                                        <Layer ref={ref_OR} listening={false}>
                                          <UpdateORImage />
                                        </Layer>
                                        <Layer>
                                          {displayRegionArray.map((region) => {
                                            return (
                                              <React.Fragment key={region.id}>
                                                {/*<Line 
                                                                    globalCompositeOperation='destination-out'
                                                                    points = {region.points.flatMap(p=>[p.x,p.y])}
                                                                    fill='black'
                                                                    listening={false}
                                                                    perfectDrawEnabled={false}
                                                                    closed
                                                                  /> */}

                                                <Line
                                                  name={"region" + String(region.id)}
                                                  points={region.points.flatMap((p) => [p.x, p.y])}
                                                  stroke={region.color}
                                                  fill={region.fill}
                                                  strokeWidth={3}
                                                  //tension={1}
                                                  closed={region.closed}
                                                  //perfectDrawEnabled={false}
                                                />
                                              </React.Fragment>
                                            );
                                          })}
                                        </Layer>
                                        {laneChecks.relativeMobility && (
                                          <Layer>
                                            <Line
                                              name={"startRF"}
                                              points={[0, 10, 50, 10, 75, 30, h_extent, 30, 0, 30]}
                                              stroke="#399dbc"
                                              x={0}
                                              closed={true}
                                              fill="#399dbc"
                                              strokeWidth={2}
                                              draggable={true}
                                              onDragEnd={calcRelativeMobility}
                                              dragBoundFunc={function (pos) {
                                                return {
                                                  x: this.absolutePosition().x,
                                                  y: pos.y,
                                                };
                                              }}
                                            />
                                            <Line
                                              name={"endRF"}
                                              points={[0, v_extent - 10, 50, v_extent - 10, 75, v_extent - 30, h_extent, v_extent - 30, 0, v_extent - 30]}
                                              stroke="#399dbc"
                                              x={0}
                                              closed={true}
                                              fill="#399dbc"
                                              strokeWidth={2}
                                              onDragEnd={calcRelativeMobility}
                                              draggable={true}
                                              dragBoundFunc={function (pos) {
                                                return {
                                                  x: this.absolutePosition().x,
                                                  y: pos.y,
                                                };
                                              }}
                                            />
                                          </Layer>
                                        )}
                                      </Stage>
                                    )}
                                  </React.Fragment>
                                </Box>
                                <Box className="theSpeedDialAndLaneBoxes" sx={{ width: "100%" }}>
                                  <Stack direction="column">
                                    <Box
                                      className="objectCheckBoxes"
                                      //sx={{justifyContent:'flex-end', width:'100%', mt:1}}
                                      sx={{ mt: 1, ml: 2 }}
                                    >
                                      <Stack spacing={1}>
                                        <Box className="laneObjectCheckBoxes">
                                          <Stack direction="row" divider={<Divider sx={{ backgroundColor: "white" }} orientation="vertical" flexItem />} spacing={2}>
                                            <Typography
                                              sx={{
                                                color: "#a1a1a1",
                                                fontSize: "1.06em",
                                                "font-family":
                                                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                              }}
                                            >
                                              Lane
                                            </Typography>
                                            <FormGroup row={true}>
                                              <FormControlLabel
                                                sx={{ color: "#a1a1a1" }}
                                                labelPlacement="end"
                                                control={
                                                  <Checkbox
                                                    size="small"
                                                    sx={{ pb: 0, pt: 0, color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                                    inputProps={{ "aria-labelledby": "centerShow" }}
                                                    checked={laneChecks.centerLane}
                                                    onChange={toggleCenterLaneLine}
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    sx={{
                                                      fontSize: "1.06em",
                                                      "font-family":
                                                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                                    }}
                                                  >
                                                    center line
                                                  </Typography>
                                                }
                                              />
                                              <FormControlLabel
                                                sx={{ color: "#a1a1a1" }}
                                                labelPlacement="end"
                                                control={
                                                  <Checkbox
                                                    size="small"
                                                    sx={{ pb: 0, pt: 0, color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                                    inputProps={{ "aria-labelledby": "boxShow" }}
                                                    checked={laneChecks.boxLane}
                                                    onChange={toggleBoxLane}
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    sx={{
                                                      fontSize: "1.06em",
                                                      "font-family":
                                                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                                    }}
                                                  >
                                                    box
                                                  </Typography>
                                                }
                                              />
                                              <FormControlLabel
                                                sx={{ color: "#a1a1a1" }}
                                                labelPlacement="end"
                                                control={
                                                  <Checkbox
                                                    size="small"
                                                    sx={{ pb: 0, pt: 0, color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                                    inputProps={{ "aria-labelledby": "polyShow" }}
                                                    checked={laneChecks.polygonLane}
                                                    onChange={togglePolygonLane}
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    sx={{
                                                      fontSize: "1.06em",
                                                      "font-family":
                                                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                                    }}
                                                  >
                                                    polygon
                                                  </Typography>
                                                }
                                              />
                                            </FormGroup>
                                          </Stack>
                                        </Box>
                                        <Box className="bandObjectCheckBoxes">
                                          <Stack direction="row" divider={<Divider sx={{ backgroundColor: "white" }} orientation="vertical" flexItem />} spacing={1.7}>
                                            <Typography
                                              sx={{
                                                color: "#a1a1a1",
                                                fontSize: "1.06em",
                                                "font-family":
                                                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                              }}
                                            >
                                              Band
                                            </Typography>
                                            <FormGroup row={true}>
                                              <FormControlLabel
                                                sx={{ color: "#a1a1a1" }}
                                                labelPlacement="end"
                                                control={
                                                  <Checkbox
                                                    size="small"
                                                    sx={{ pb: 0, pt: 0, color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                                    inputProps={{ "aria-labelledby": "rfStartShow" }}
                                                    checked={laneChecks.relativeMobility}
                                                    onChange={toggleRelativeMobility}
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    sx={{
                                                      fontSize: "1.06em",
                                                      "font-family":
                                                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                                    }}
                                                  >
                                                    relative mobility
                                                  </Typography>
                                                }
                                              />
                                            </FormGroup>
                                          </Stack>
                                        </Box>
                                      </Stack>
                                    </Box>
                                    <Box className="theSpeedDial" sx={{ ml: 1, width: "100%" }}>
                                      <Tooltip placement="bottom-start" title="Annotate Highlighted Objects">
                                        <SpeedDial
                                          ariaLabel="label speed dial"
                                          direction={"right"}
                                          icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                                          FabProps={{ size: "small" }}
                                          sx={{ ".MuiSpeedDial-fab": { "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" } } }}
                                        >
                                          {speedDialActions.map((action) => (
                                            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={() => speedDialResponse(action.name)} />
                                          ))}
                                        </SpeedDial>
                                      </Tooltip>
                                    </Box>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          )}
                          <Box className="theSignalChart" sx={{ width: "50%" }}>
                            <Stack direction="column">
                              <Box className="theSignalPlot" sx={{ width: "100%", display: relativeChecks.relativeToBand === true || relativeChecks.relativeToLane === true ? "block" : "none" }}>
                                <Bubble ref={bubbleChartRef} data={scatCJSData} options={bubbleOptions} width={10} height={10} />
                              </Box>
                              <Box className="theLinePlot" sx={{ width: "100%", display: relativeChecks.mwFit === true ? "block" : "none" }}>
                                <Scatter
                                  //ref={bubbleChartRef}
                                  data={mwLineChartData}
                                  options={mwLineChartOptions}
                                  width={10}
                                  height={10}
                                />
                              </Box>
                              <Box className="theBarPlot" sx={{ display: relativeChecks.totalLane === true ? "block" : "none" }}>
                                <Bar
                                  //ref={bubbleChartRef}
                                  data={totalLaneChartData}
                                  options={totalLaneChartOptions}
                                  width={10}
                                  height={10}
                                />
                              </Box>
                              <Box className="theSignalPlotCheckBoxes" sx={{ width: "100%" }}>
                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                                  <Box sx={{ width: "50%", mb: 1 }}>
                                    <FormGroup>
                                      <FormControlLabel
                                        sx={{ color: "#a1a1a1" }}
                                        labelPlacement="start"
                                        control={
                                          <Checkbox
                                            size="small"
                                            sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                            inputProps={{ "aria-labelledby": "yaxisMW" }}
                                            checked={yAxisChecks.mw}
                                            onChange={toggleAxisToMW}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "1.06em",
                                              "font-family":
                                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            }}
                                          >
                                            molecular weight
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        sx={{ color: "#a1a1a1" }}
                                        labelPlacement="start"
                                        control={
                                          <Checkbox
                                            size="small"
                                            sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                            inputProps={{ "aria-labelledby": "yaxisVP" }}
                                            checked={yAxisChecks.vertPos}
                                            onChange={handleChange("toggleAxisToVertPos")}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "1.06em",
                                              "font-family":
                                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            }}
                                          >
                                            vertical position
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        sx={{ color: "#a1a1a1" }}
                                        labelPlacement="start"
                                        control={
                                          <Checkbox
                                            size="small"
                                            sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                            inputProps={{ "aria-labelledby": "mwFit" }}
                                            checked={relativeChecks.mwFit}
                                            onChange={toggleMWFit}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "1.06em",
                                              "font-family":
                                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            }}
                                          >
                                            mw fitting
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                  </Box>
                                  <Box sx={{ width: "50%", mr: 4, mb: 1 }}>
                                    <FormGroup>
                                      <FormControlLabel
                                        sx={{ color: "#a1a1a1" }}
                                        labelPlacement="start"
                                        control={
                                          <Checkbox
                                            size="small"
                                            sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                            inputProps={{ "aria-labelledby": "relativeToBand" }}
                                            checked={relativeChecks.relativeToBand}
                                            onChange={toggleRelativeToBand}
                                            //onChange={()=>handleBubbleToggle(yAxisChecks.mw,true,'relativeToBand')}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "1.06em",
                                              "font-family":
                                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            }}
                                          >
                                            relative to band
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        sx={{ color: "#a1a1a1" }}
                                        labelPlacement="start"
                                        control={
                                          <Checkbox
                                            size="small"
                                            sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                            inputProps={{ "aria-labelledby": "relativeToLane" }}
                                            checked={relativeChecks.relativeToLane}
                                            onChange={toggleRelativeToLane}
                                            //onChange={()=>handleBubbleToggle(yAxisChecks.mw,false,'relativeToLane')}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "1.06em",
                                              "font-family":
                                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            }}
                                          >
                                            relative to lane
                                          </Typography>
                                        }
                                      />
                                      {showTotalLane === true && (
                                        <FormControlLabel
                                          sx={{ color: "#a1a1a1" }}
                                          labelPlacement="start"
                                          control={
                                            <Checkbox
                                              size="small"
                                              sx={{ padding: "0px", color: "#399dbc", "&.Mui-checked": { color: "#399dbc" } }}
                                              inputProps={{ "aria-labelledby": "totalLane" }}
                                              checked={relativeChecks.totalLane}
                                              onChange={toggleTotalLane}
                                            />
                                          }
                                          label={
                                            <Typography
                                              sx={{
                                                fontSize: "1.06em",
                                                "font-family":
                                                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                              }}
                                            >
                                              total lane signal
                                            </Typography>
                                          }
                                        />
                                      )}
                                    </FormGroup>
                                  </Box>
                                </Stack>
                              </Box>
                            </Stack>
                          </Box>
                        </Stack>
                      </Paper>
                    </React.Fragment>
                  )}
                </Box>
                <Box className="theLineProfileChart" sx={{ mt: 1 }}>
                  {laneCJSData != null && laneChecks.centerLane === true && laneCJSData["datasets"].length <= 8 && (
                    <Paper sx={chartPaperStyle} elevation={3}>
                      <Box>
                        <Scatter height={150} ref={lineChartRef} data={laneCJSData} options={lineProfileOptions} />
                        <Box sx={{ pt: 1, pl: 1, pb: 1 }}>
                          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                            <Button variant="outlined" onClick={handleResetZoom}>
                              Reset
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
    </Box>
  );
};

export default AnalyticsGraph;
