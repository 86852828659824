import React, { useState, useRef, useEffect } from 'react';
//import { LicenseInfo } from '@mui/x-license-pro';
import Box from '@mui/material/Box';
import './parchitecture.scss';
import '../home/home.scss'

//LicenseInfo.setLicenseKey(
//    'b4beea374e78bb9cdcb350ac7bdd4023T1JERVI6NDI1MjgsRVhQSVJZPTE2ODI1NTIyNTkwMDAsS0VZVkVSU0lPTj0x',
//  );

export default function Parchitecture() {
    return(
        <div className="content">
            <div className='parchitectureMain'>
                <Box>Under Construction</Box>
            </div>
        </div>
    )
}