
import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { genericImageNameStyle } from '../../style';

const ImageInfo = ({ 
  whichImage, 
  handleChange,
  displayGetPrivateID,
  imageIDOfDisplayImage 
}) => (
  
  <div className="imageInfo">
    <Divider
      sx={{ 'margin': '0px', 'color': '#FFFFFF', '&::before': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' }, '&::after': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' } }}
      textAlign="left"
      variant="middle"
    >
      Image Info
    </Divider>
      <div className ='imageInfoContent'>
        <Box>
          <Stack
            direction="row"
          >          
            <Box sx={{ml: 1.5, mt:1, width: 60}}> 
              
              <Typography sx={genericImageNameStyle}>{whichImage}</Typography>
            </Box>
            <Box>          
              <IconButton  onClick={handleChange('showImageInfo'+whichImage)}>
                <InfoOutlinedIcon sx={{"color":'#88e5ff','padding': '0px', '&:hover': { color: 'orange' }}}></InfoOutlinedIcon>
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box
         sx={{display: displayGetPrivateID}}
        >
          <Typography 
            sx={{'color': '#88e5ff', 'font-size': '11px', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}
          >
            {imageIDOfDisplayImage}
          </Typography>
        </Box>
      </div>
    </div>
);

export default ImageInfo;