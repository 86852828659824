import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

import { genericImageNames } from "../config";

const ImageContentBox = ({ activeImage, children }) => {
  const { theme } = useTheme();
  const sectionTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#666" : "#cea5fb",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
  };
  const sectionImageTitleStyle = {
    padding: "15px",
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    "font-weight": "350",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };

  return (
    <Box
      sx={{
        "min-height": "260px",
        width: "20%",
        "box-shadow": "0 0 10px rgba(132, 130, 130, 0.43)",
        "border-radius": "10px",
      }}
    >
      <div className="displayImageInfoHeader">
        <Box className="displayImageInfoHeaderText">
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Typography sx={sectionTitleStyle} variant="h5">
              Image Content
            </Typography>
            {activeImage !== null && (
              <Typography sx={sectionImageTitleStyle} variant="h5">
                {genericImageNames[String(activeImage)]}
              </Typography>
            )}
          </Stack>
        </Box>
      </div>
      <div className="displayImageInfoContent">
        <Box sx={{ ml: 0.25, mb: 0.5 }}>{children}</Box>
      </div>
    </Box>
  );
};

export default ImageContentBox;
