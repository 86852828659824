import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import NotDraggablePaperComponent from '../common/NonDraggablePaperComponent';
import ListboxComponent from '../common/ListboxComponent';
import StyledPopper from '../common/StyledPopper';


const TargetDialog = ({
  changeTarget,
  closeTargetDialog,
  handleChange,
  setTargetTags,
  open,
  showWhichResults,
  proteinList,
  lysateList,
  lysateTarget,
  objectAnnots
}) => (
  <Dialog
    open={open}
    onClose={closeTargetDialog}
    aria-describedby='set-target-dialog-description'
    PaperComponent={NotDraggablePaperComponent}
  >
    <DialogTitle>Protein Target</DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>
        Select a protein Target that describes the highlighted objects.
      </DialogContentText>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
      >
        <Autocomplete
          disableListWrap
          freeSolo
          sx={{ width: 300 }}
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          options={showWhichResults ? proteinList : lysateList}
          renderInput={(params) => <TextField {...params} label={showWhichResults ? "Search Human Proteome" : "Search Cell Lysate"} />}
          renderOption={(props, option) => [props, option]}
          value={showWhichResults ? objectAnnots.proteinTarget : lysateTarget}
          onChange={changeTarget}
        >
        </Autocomplete>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={setTargetTags}>Set Tags</Button>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeTargetDialog}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default TargetDialog;   
