import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const AnalyticsTableAndGraphContainer = ({ children, expanded2, uploadIconColor, uploadUserAnalysisToCloud, switchAccordion2, showShareAnalysisDialog, ORActiveImage }) => {
  const { theme } = useTheme();
  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const analyticsUploadStyle = {
    mr: 2,
    color: theme === "light" ? "#666" : "#ecf2f8",
    fontFamily: styles.fontFamily,
    "font-weight": "300",
  };
  const [openStorage, setOpenStorage] = useState(false);
  const handleStorageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenStorage(false);
  };

  const handleDialogOpen = () => {
    showShareAnalysisDialog();
  };

  const handleUpload = () => {
    if (ORActiveImage !== null) {
      setOpenStorage(true);
      uploadUserAnalysisToCloud();
    } else {
      alert("You have nothing to upload at the moment.");
    }
  };
  return (
    <Accordion sx={accordionHeaderStyle} expanded={expanded2 === "analyticResponse"} onChange={switchAccordion2("analyticResponse")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="analyticResponse-content" id="analyticResponse-header">
        <Typography sx={{ width: "50%", flexShrink: 0 }}>Analysis Tables and Graphs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Stack direction="column" spacing={1}>
            <Box>
              <Snackbar
                open={openStorage}
                autoHideDuration={6000}
                onClose={handleStorageClose}
                message="Analysis Archived"
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleStorageClose}>
                      <CloseIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
              <Box>
                <Stack direction="row" spacing={5}>
                  <Box>
                    <Stack direction="row">
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={analyticsUploadStyle} variant="subtitle1">
                          Save Your Work to the Cloud for Later Recovery
                        </Typography>
                      </Box>
                      <Tooltip title="Save Your Progress">
                        <IconButton size="large" color={uploadIconColor} onClick={handleUpload}>
                          <CloudUploadOutlinedIcon sx={{ color: theme === "light" ? "primary" : "#89929b" }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                  <Box sx={{ display: "none" }}>
                    <Stack direction="row">
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={analyticsUploadStyle} variant="subtitle1">
                          Share Your Results With Others
                        </Typography>
                      </Box>
                      <Tooltip title="Share Your Analysis">
                        <IconButton size="large" onClick={handleDialogOpen}>
                          <ShareIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
            {children}
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AnalyticsTableAndGraphContainer;
