import React from 'react';


import { GithubPicker,TwitterPicker } from 'react-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import NotDraggablePaperComponent from '../common/NonDraggablePaperComponent';
import Box from '@mui/material/Box';

const ColorDialog = ({ closeColorDialog, open, handleChange, changeORColor, objectAnnots }) => (
  <Dialog
    open={open}
    onClose={closeColorDialog}
    aria-describedby='set-color-dialog-description'
    PaperComponent={NotDraggablePaperComponent}
  >
    <DialogTitle>Color Options</DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>
        Assign a color to the currently visible objects.
      </DialogContentText>
      <Box>
        {/*
        <GithubPicker
          color={objectAnnots.userColorTag}
          onChangeComplete={changeORColor}
          colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C','jellyBean']}
        >
        
        </GithubPicker>
        */}
        <TwitterPicker
          colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
          color={objectAnnots.userColorTag}
          onChangeComplete={changeORColor}
        ></TwitterPicker> 
      
      </Box>
    
    </DialogContent>
    <DialogActions>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={handleChange('setColorTags')}>Set Color</Button>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={handleChange('jellyBeans')}>JellyBean'em</Button>
      <Button sx={{ 'color': 'white', "background-color": '#399dbc', '&:hover': { 'background-color': '#c4673a' }, "&:disabled": { 'background-color': 'grey' } }} onClick={closeColorDialog}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default ColorDialog;