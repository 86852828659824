import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from'@mui/material/Typography'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

const NoAnaSelectDialog = ({
  open,
  handleChange,
  continueSubmitForAnalysis,
  activeImage
}) => (
  <Dialog
    open={open}
    keepMounted
    //onClose={handleChange('closenoAnaSelectDialog')}
    aria-describedby="alert-dialog-description"
  >
    <Alert severity="warning" sx={{ 'width': '400px' }}>
      <AlertTitle sx={{ 'font-weight': 'bold' }}>Warning</AlertTitle>
      <Typography>It appears you have not reviewed any Regions Of Interest that Bio-Rad identified; or defined manually any Regions to analyze in the image above. <br></br> <br></br> If you still wish to proceed, analysis will be performed on Bio-Rad identified regions of interest (which could be empty).</Typography>
  
      <Box
        sx={{
          'width': '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          'margin-top': '20px'
        }}>
  
        <Button
          id='userChoseToReturn'
          sx={{ color: 'black' }}
          variant="text"
          onClick={handleChange('closenoAnaSelectDialog')}
        >
          Return
        </Button>
        <Button
          id='userChoseToProceed'
          sx={{ color: 'black' }}
          variant="text"
          onClick={() => continueSubmitForAnalysis('biorads', parseInt(activeImage.split('image')[1]),null,null)}
        >
          Proceed
        </Button>
      </Box>
    </Alert>
  </Dialog>);

export default NoAnaSelectDialog;
