
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

export default function DraggablePaper(props) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}