export const devUsers = [
  { userName: "Clayton McKee", userID: "18a315c2-2819-4cba-b54f-3433e5d3bd89" },
  { userName: "Mark Shulewitz", userID: "4e0a85f2-2a3f-40a8-a1eb-591fef4fa898" },
  { userName: "Kevin McDonald", userID: "25e939b4-0674-4f33-b5f1-8dad4cdbf76c" },
  { userName: "Shiva Safaei", userID: "cb9b233d-a5fd-479b-bcab-3f6a1453c384" },
  { userName: "Raf Podowski", userID: "0f424bfd-5bb3-41e9-a42b-a843d3edf226" },
  { userName: "Wei Vivian Fu", userID: "abbee028-eba1-4182-a7dc-8622d96d25b9" },
  { userName: "Doris Yeung", userID: "54f9d057-99c1-4dad-8de3-61baddd9b54e" },
  { userName: "Nikolas Chmiel", userID: "bdacf3d5-b535-4916-9668-dd66eec5592a" },
  { userName: "John Riggs", userID: "8b384e9c-a1f7-4d9a-bae1-c3888fd06450" },
  { userName: "Joe Hilario", userID: "611165fd-f655-4a15-bcfc-56be22be3105" },
  { userName: "Kenneth Oh", userID: "98bdfcbe-cb61-4dc6-ab0b-a1815a084ccf" },
  { userName: "Deanna Woo", userID: "a89ab44d-8b3d-460b-a557-579b4985f46e" },
  { userName: "Lindsey Maccoux", userID: "33f4a15e-5c43-485b-a2aa-3f460f7a7816" },
  { userName: "Jerianne Lukban", userID: "3e1a6c7a-9606-4f44-8650-14028bfd812c" },
  { userName: "Smitha Handattu", userID: "8eff3a0a-2a8c-4f15-95fe-b0b162e0841e" },
  { userName: "Clayton Thompson", userID: "24d8ce48-bcde-4f8c-adca-2889d30d2b8a" },
  { userName: "David Vu", userID: "f2721fe1-9a3f-43bf-accf-546f6600e1ae" },
  { userName: "Nate Boyette-II", userID: "a9ba2606-73a1-40f5-b67c-5a0a10d03835" },
];
export const dataGridColumns = [
  { field: "id", width: 70, type: "number", hide: true },
  {
    field: "borderID",
    headerName: "BorderID",
    width: 80,
    editable: false,
    hide: false,
    type: "number",
    description: "id of border",
  },
  {
    field: "lane",
    headerName: "Lane",
    width: 80,
    editable: true,
    type: "number",
    description: "id of lane",
  },
  {
    field: "band",
    headerName: "Band",
    width: 80,
    editable: true,
    type: "number",
    hide: false,
    description: "id of band",
  },
  {
    field: "signal",
    headerName: "Signal",
    width: 80,
    editable: false,
    description: "sum of pixel intensities inside object divided by area of object",
    type: "number",
  },
  {
    field: "signal_bgr",
    headerName: "Signal(bgr)",
    width: 95,
    editable: false,
    description: "signal of object from ML generate image with background removed",
    type: "number",
  },
  {
    field: "normalizedSignal",
    headerName: "TPN Signal",
    width: 100,
    editable: false,
    hide: false,
    description: "normalized(tpn) band signal",
    type: "number",
  },
  {
    field: "boxSignal",
    headerName: "Box Signal",
    width: 95,
    editable: false,
    description: "sum of pixel intensities inside box around object",
    type: "number",
  },
  {
    field: "boxSignal_bgr",
    headerName: "Box Signal(bgr)",
    width: 95,
    editable: false,
    description: "sum of pixel intensities inside box around object from ML generated image with background removed",
    type: "number",
  },
  {
    field: "normalizedBoxSignal",
    headerName: "TPN Box Signal",
    width: 100,
    editable: false,
    hide: false,
    description: "normalized(tpn) band box signal",
    type: "number",
  },
  {
    field: "relativeToLargetsBand",
    headerName: "RTLB",
    width: 80,
    editable: false,
    description: "signal relative to largest band signal",
    hide: "true",
    type: "number",
  },
  {
    field: "relativeToLane",
    headerName: "RTL",
    width: 80,
    editable: false,
    description: "signal relative to lane signal",
    hide: true,
    type: "number",
  },
  {
    field: "molecularWeight",
    headerName: "MW",
    width: 80,
    editable: false,
    type: "number",
    description: "mw of band",
  },
  {
    field: "relativeMobility",
    headerName: "RMB",
    width: 80,
    editable: false,
    type: "number",
    hide: false,
    description: "relative mobility of band",
  },
  {
    field: "verticalPos",
    headerName: "Vert. Pos",
    width: 80,
    editable: false,
    type: "number",
    hide: false,
    description: "vertical pixel position band center",
  },
  {
    field: "horizPos",
    headerName: "Horiz. Pos",
    width: 85,
    editable: false,
    type: "number",
    hide: false,
    description: "horizontal pixel position of band center",
  },
  {
    field: "composition",
    headerName: "Composition",
    width: 80,
    editable: true,
    hide: true,
    description: "protein or dna",
  },
  {
    field: "proteinStandard",
    headerName: "Standard",
    width: 80,
    editable: true,
    hide: true,
    description: "protein standard",
  },
  {
    field: "control",
    headerName: "Control",
    width: 80,
    editable: true,
    hide: true,
    description: "e.g. negative",
  },
  {
    field: "target",
    headerName: "Target",
    width: 80,
    editable: true,
    hide: true,
    description: "protein name",
  },
  {
    field: "highlightColor",
    headerName: "Color",
    width: 80,
    editable: true,
    hide: true,
    description: "color of label",
  },
  {
    field: "roiArea",
    headerName: "Area",
    width: 80,
    editable: false,
    hide: true,
    type: "number",
    description: "area of band",
  },
  {
    field: "laneIntegration",
    headerName: "Lane Signal",
    width: 80,
    editable: false,
    hide: true,
    description: "accumulated sum of the signal inside the lane polygon",
    type: "number",
  },
  {
    field: "normalizedLaneIntegration",
    headerName: "TPN Lane Signal",
    width: 80,
    editable: false,
    hide: true,
    description: "normalized (tpn) lane integration",
    type: "number",
  },
  {
    field: "bgLaneIntegration",
    headerName: "Lane Signal(bgr)",
    width: 80,
    editable: false,
    hide: true,
    description: "= accumulated sum of the signal (from BG Removed Image) inside the lane polygon",
    type: "number",
  },
];

export const bubbleChartTitles = {
  xAxis: "Lane Number",
  yAxis: "Vertical Position",
  title: "Signal Chart",
  boxTitle: "Box Signal Chart",
};

export const normalizedBubbleChartTitles = {
  xAxis: "Lane Number",
  yAxis: "Vertical Position",
  title: "Signal Chart ( Total Protein Normalized )",
  boxTitle: "Box Signal Chart ( Total Protein Normalized )",
};

export const totalLaneChartOptions = {
  plugins: {
    zoom: {
      zoom: {
        wheel: {
          enabled: false,
          mode: "y",
        },
      },
    },
    tooltip: {
      enabled: true,
      mode: "nearest",
    },
    legend: {
      display: false,
    },
    title: {
      color: "white",
      font: {
        weight: 300,
        size: 14,
        family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      display: true,
      text: "Total Lane Signal",
    },
  },
  scales: {
    y: {
      type: "linear",
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: "total lane signal",
        color: "#a1a1a1",
      },
      reverse: false,
    },
    x: {
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: "lane",
        color: "#a1a1a1",
      },
      offset: true,
    },
  },
};

export const mwLineChartOptions = {
  plugins: {
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
          mode: "y",
        },
      },
    },
    legend: {
      display: false,
    },
    title: {
      color: "white",
      font: {
        weight: 300,
        size: 14,
        family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      display: true,
      text: "MW Standard Curve",
    },
  },
  scales: {
    x: {
      type: "logarithmic",
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: "molecular weight / kD",
        color: "#a1a1a1",
      },
      reverse: false,
      min: 1,
      max: 600,
    },
    y: {
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: "vertical pixel position",
        color: "#a1a1a1",
      },
      reverse: true,
    },
  },
};

export const bubbleScalar = 20;

export const bubbleOptions = {
  plugins: {
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
          mode: "y",
        },
      },
    },

    legend: {
      display: false,
    },
    title: {
      color: "white",
      font: {
        weight: 300,
        size: 14,
        family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      display: true,
      text: bubbleChartTitles.boxTitle,
    },
    tooltip: {
      yAlign: "top",
      borderColor: "#399dbc",
      caretPadding: 20,
      bodyAlign: "left",
      textAlign: "left",
      backgroundColor: "#399dbc",
      bodyColor: "#000",
      callbacks: {
        label: (context) => {
          return [
            ` lane : ${context.raw.x}`,
            ` vertical pos. : ${context.raw.y.toFixed(2)}`,
            ` relative to largest band : ${(context.raw.r / bubbleScalar).toFixed(3)}`,
            ` relative mobility : ${context.raw.rmb === "tbd" ? context.raw.rmb : context.raw.rmb.toFixed(2)}`,
          ];
        },
      },
    },
  },
  scales: {
    y: {
      type: "linear",
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: bubbleChartTitles.yAxis,
        color: "#a1a1a1",
      },
      reverse: true,
    },
    x: {
      offset: true,
      //min:0,
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: bubbleChartTitles.xAxis,
        color: "#a1a1a1",
      },
      callback: function (value) {
        if (value % 1 === 0) {
          return value;
        }
      },
    },
  },
};

export const lineChartTitles = {
  xAxis: "Vertical Position",
  yAxis: "Intensity / a.u.",
  title: "Lane Profiles (limit 4)",
};

function fetchData(x1, x2, dataSet) {
  const step = Math.max(1, Math.round((x2 - x1) / 100000));
  //const data = [];
  let cumsum = 0;
  let i = 0;
  let maxIDX = 0;
  let maxYValue = 0;
  let correspXValue = 0;

  while (i < dataSet.length && dataSet[i].x < x1) {
    i++;
  }
  while (i < dataSet.length && dataSet[i].x <= x2) {
    if (dataSet[i].y > maxYValue) {
      maxYValue = dataSet[i].y;
      correspXValue = dataSet[i].x;
      maxIDX = i;
    }
    cumsum += dataSet[i]["y"];
    i += step;
  }
  return { cumsum: cumsum.toFixed(2), maxY: maxYValue, xVal: correspXValue, maxIDX: maxIDX };
}
let integralAnnotations = {
  type: "label",
  content: "empty",
  display: false,
  color: "#FFFFFF",
};

const resetIntegral = function (chart) {
  console.log(chart);
  console.log(chart.chart.options.plugins.zoom); //.drag.enabled=false
  chart.chart.options.plugins.annotation.annotations = {};

  chart.chart.update();

  for (var i = 0; i < chart.chart.data.datasets.length; i++) {
    //console.log(chart.chart.legend.legendItems[i].hidden)
    var stats = fetchData(chart.chart.scales.x.min, chart.chart.scales.x.max, chart.chart.data.datasets[i].data);
    var color = chart.chart.data.datasets[i].borderColor;

    chart.chart.options.plugins.annotation.annotations[String(i * 2)] = {
      type: "label",
      content: "cumulative signal = " + String(stats.cumsum),
      display: chart.chart.legend.legendItems[i].hidden === true ? false : true,
      callout: {
        display: chart.chart.legend.legendItems[i].hidden === true ? false : true,
        borderColor: color,
      },
      color: color,
      borderColor: color,
      backgroundColor: "white",
      position: { x: "start", y: "center" },
      xValue: stats.xVal,
      yValue: stats.maxY,
      xAdjust: 20,
      yAdjust: 25 * (i + 1),
    };
    chart.chart.options.plugins.annotation.annotations[String(i * 2 + 1)] = {
      type: "point",
      display: chart.chart.legend.legendItems[i].hidden === true ? false : true,
      backgroundColor: "transparent",
      borderColor: color,
      pointStyle: "rectRounded",
      radius: 6,
      xValue: stats.xVal,
      yValue: stats.maxY,
    };
    chart.chart.update();
  }
};

const zoomOptions = {
  pan: {
    enabled: true,
    mode: "x",
    modifierKey: "ctrl",
    onPanComplete: resetIntegral,
  },
  zoom: {
    wheel: {
      enabled: false,
    },
    drag: {
      enabled: true,
    },
    pinch: {
      enabled: false,
    },
    mode: "x",
    onZoomComplete: resetIntegral,
  },
};

export const lineProfileOptions = {
  plugins: {
    tooltip: {
      enabled: true,
      mode: "nearest",
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
        modifierKey: "ctrl",
        onPanComplete: resetIntegral,
      },
      zoom: {
        wheel: {
          enabled: false,
        },
        drag: {
          enabled: true,
        },
        pinch: {
          enabled: false,
        },
        mode: "x",
        onZoomComplete: resetIntegral,
      },
    },
    annotation: {
      annotations: { integralAnnotations },
    },
    legend: {
      display: true,
      position: "right",
      align: "center",
      labels: { color: "#a1a1a1" },
    },
    title: {
      color: "white",
      font: {
        weight: 300,
        size: 14,
        family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      display: true,
      text: lineChartTitles.title,
    },
  },
  scales: {
    y: {
      type: "linear",
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: lineChartTitles.yAxis,
        color: "#a1a1a1",
      },
    },
    x: {
      grid: {
        color: "#aeaaaa",
        tickLength: 4,
      },
      title: {
        display: true,
        text: lineChartTitles.xAxis,
        color: "#a1a1a1",
      },
    },
  },
};

export const screenDims = {
  width: window.screen.width,
  height: window.screen.height,
  pixelRatio: window.devicePixelRatio,
};

export const mwStandards = {
  dualColor: [250, 150, 100, 75, 50, 37, 25, 20, 15, 10],
  allBlue: [250, 150, 100, 75, 50, 37, 25, 20, 15, 10],
  kaleidoscope: [250, 150, 100, 75, 50, 37, 25, 20, 15, 10],
  dualExtra: [250, 150, 100, 75, 50, 37, 25, 20, 15, 10, 5, 2],
  unstained: [250, 150, 100, 75, 50, 37, 25, 20, 15, 10],
  broadRange: [200, 116.3, 97.4, 66.2, 45, 31, 21.5, 14.4, 6.5],
  lowRange: [97.4, 66.2, 45, 31, 21.5, 14.4],
  highRange: [200, 116.3, 97.4, 66.2, 45],
  polypeptide: [26.6, 17, 14.4, 6.5, 3.5, 1.4],
};

//chartJS
export const defaultScatCJSData = {
  datasets: [
    {
      backgroundColor: "#FFFFFF30",
      data: [{ y: 0, x: 0, r: 0, rmb: "tbd" }],
      label: "bands",
    },
    {
      backgroundColor: "#1563BD98",
      data: [{ y: 0, x: 0, r: 0, rmb: "tbd" }],
      label: "theScalar",
    },
  ],
};

export const defaultTotalLaneChartData = {
  datasets: [
    {
      backgroundColor: "#FFFFFF30",
      data: [{ y: 0, x: 0 }],
      label: "Lane Signal",
    },
  ],
};

export const defaultMWLineChartData = {
  datasets: [
    {
      backgroundColor: "#FFFFFF30",
      data: [{ y: 0, x: 0 }],
      label: "standards",
    },
    {
      backgroundColor: "#1563BD98",
      data: [{ y: 0, x: 0 }],
      label: "fit",
    },
  ],
};

export const genericImageNames = { image0: "Image 0", image1: "Image 1" };

export const jellyBeanColors = [
  "#ff00d9",
  "#ff00cf",
  "#ff00e3",
  "#d500ff",
  "#00ff8d",
  "#00ff97",
  "#ffa700",
  "#ff00bb",
  "#ff1d00",
  "#00beff",
  "#0079ff",
  "#1a00ff",
  "#8600ff",
  "#7dff00",
  "#ffec00",
  "#ff00ec",
  "#00a1ff",
  "#00dcff",
  "#ff3b00",
  "#ff001d",
  "#ffd900",
  "#55ff00",
  "#ff0094",
  "#2eff00",
  "#4bff00",
  "#10ff00",
  "#00ff5c",
  "#7d00ff",
  "#73ff00",
  "#ff00f6",
  "#cbff00",
  "#0048ff",
  "#ffcf00",
  "#00ff6f",
  "#0083ff",
  "#2400ff",
  "#ff0000",
  "#fff600",
  "#b8ff00",
  "#fd00ff",
  "#ff0013",
  "#00ffe6",
  "#1aff00",
  "#00ffa1",
  "#ff4e00",
  "#ff0045",
  "#2e00ff",
  "#f3ff00",
  "#24ff00",
  "#3700ff",
  "#0020ff",
  "#0003ff",
  "#ff00b1",
  "#ff003b",
  "#ff0031",
  "#ff6c00",
  "#0066ff",
  "#ff8000",
  "#ff9d00",
  "#ff0027",
  "#00fffa",
  "#00ff48",
  "#00ff83",
  "#00ffdc",
  "#4100ff",
  "#ff0058",
  "#fdff00",
  "#00ffab",
  "#00faff",
  "#00b4ff",
  "#b800ff",
  "#ff1300",
  "#00ff0d",
  "#006fff",
  "#ff7600",
  "#86ff00",
  "#0052ff",
  "#ffe300",
  "#00ff79",
  "#0600ff",
  "#df00ff",
  "#37ff00",
  "#06ff00",
  "#ff8a00",
  "#00ff17",
  "#dfff00",
  "#ae00ff",
  "#aeff00",
  "#00ffb4",
  "#00ff20",
  "#9aff00",
  "#d5ff00",
  "#4b00ff",
  "#00e6ff",
  "#003eff",
  "#f300ff",
  "#00c8ff",
  "#e900ff",
  "#9a00ff",
  "#005cff",
  "#ffbb00",
  "#ff6200",
  "#00ffbe",
  "#ff5800",
  "#00abff",
  "#90ff00",
  "#e9ff00",
  "#00ff34",
  "#ff008a",
  "#c2ff00",
  "#00ff3e",
  "#ffb100",
  "#00f0ff",
  "#5f00ff",
  "#ff00c5",
  "#ff0009",
  "#00d2ff",
  "#a400ff",
  "#ffc500",
  "#00fff0",
  "#ff0080",
  "#1000ff",
  "#00ff03",
  "#5500ff",
  "#00ff2a",
  "#00ffc8",
  "#ff2700",
  "#6900ff",
  "#000dff",
  "#5fff00",
  "#cc00ff",
  "#00ff66",
  "#0017ff",
  "#ff00a7",
  "#69ff00",
  "#008dff",
  "#ff0900",
  "#0034ff",
  "#002aff",
  "#ff0076",
  "#00ffd2",
  "#00ff52",
  "#c200ff",
  "#ff0062",
  "#ff9400",
  "#41ff00",
  "#7300ff",
  "#ff009d",
  "#ff006c",
  "#0097ff",
  "#ff4500",
  "#a4ff00",
  "#ff3100",
  "#ff004e",
  "#9000ff",
  "#ff00d9",
  "#ff00cf",
  "#ff00e3",
  "#d500ff",
  "#00ff8d",
  "#00ff97",
  "#ffa700",
  "#ff00bb",
  "#ff1d00",
  "#00beff",
  "#0079ff",
  "#1a00ff",
  "#8600ff",
  "#7dff00",
  "#ffec00",
  "#ff00ec",
  "#00a1ff",
  "#00dcff",
  "#ff3b00",
  "#ff001d",
  "#ffd900",
  "#55ff00",
  "#ff0094",
  "#2eff00",
  "#4bff00",
  "#10ff00",
  "#00ff5c",
  "#7d00ff",
  "#73ff00",
  "#ff00f6",
  "#cbff00",
  "#0048ff",
  "#ffcf00",
  "#00ff6f",
  "#0083ff",
  "#2400ff",
  "#ff0000",
  "#fff600",
  "#b8ff00",
  "#fd00ff",
  "#ff0013",
  "#00ffe6",
  "#1aff00",
  "#00ffa1",
  "#ff4e00",
  "#ff0045",
  "#2e00ff",
  "#f3ff00",
  "#24ff00",
  "#3700ff",
  "#0020ff",
  "#0003ff",
  "#ff00b1",
  "#ff003b",
  "#ff0031",
  "#ff6c00",
  "#0066ff",
  "#ff8000",
  "#ff9d00",
  "#ff0027",
  "#00fffa",
  "#00ff48",
  "#00ff83",
  "#00ffdc",
  "#4100ff",
  "#ff0058",
  "#fdff00",
  "#00ffab",
  "#00faff",
  "#00b4ff",
  "#b800ff",
  "#ff1300",
  "#00ff0d",
  "#006fff",
  "#ff7600",
  "#86ff00",
  "#0052ff",
  "#ffe300",
  "#00ff79",
  "#0600ff",
  "#df00ff",
  "#37ff00",
  "#06ff00",
  "#ff8a00",
  "#00ff17",
  "#dfff00",
  "#ae00ff",
  "#aeff00",
  "#00ffb4",
  "#00ff20",
  "#9aff00",
  "#d5ff00",
  "#4b00ff",
  "#00e6ff",
  "#003eff",
  "#f300ff",
  "#00c8ff",
  "#e900ff",
  "#9a00ff",
  "#005cff",
  "#ffbb00",
  "#ff6200",
  "#00ffbe",
  "#ff5800",
  "#00abff",
  "#90ff00",
  "#e9ff00",
  "#00ff34",
  "#ff008a",
  "#c2ff00",
  "#00ff3e",
  "#ffb100",
  "#00f0ff",
  "#5f00ff",
  "#ff00c5",
  "#ff0009",
  "#00d2ff",
  "#a400ff",
  "#ffc500",
  "#00fff0",
  "#ff0080",
  "#1000ff",
  "#00ff03",
  "#5500ff",
  "#00ff2a",
  "#00ffc8",
  "#ff2700",
  "#6900ff",
  "#000dff",
  "#5fff00",
  "#cc00ff",
  "#00ff66",
  "#0017ff",
  "#ff00a7",
  "#69ff00",
  "#008dff",
  "#ff0900",
  "#0034ff",
  "#002aff",
  "#ff0076",
  "#00ffd2",
  "#00ff52",
  "#c200ff",
  "#ff0062",
  "#ff9400",
  "#41ff00",
  "#7300ff",
  "#ff009d",
  "#ff006c",
  "#0097ff",
  "#ff4500",
  "#a4ff00",
  "#ff3100",
  "#ff004e",
  "#9000ff",
];
