import * as React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { TextField, Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText, Button, Stack } from "@mui/material";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const RangeShortcut = {
  today: "TODAY",
  thisWeek: "THIS_WEEK",
  lastWeek: "LAST_WEEK",
  last7Days: "LAST_7_DAYS",
  currentMonth: "CURRENT_MONTH",
  reset: "RESET",
};

const rangeShortcuts = [
  {
    range: RangeShortcut.today,
    label: "Today",
  },
  {
    range: RangeShortcut.thisWeek,
    label: "This week",
  },
  {
    range: RangeShortcut.lastWeek,
    label: "Last week",
  },
  {
    range: RangeShortcut.last7Days,
    label: "Last 7 days",
  },
  {
    range: RangeShortcut.currentMonth,
    label: "Current month",
  },
  {
    range: RangeShortcut.reset,
    label: "Reset",
  },
];

const buildHandleRangeClick = (setTheDateRange) => (range) => {
  const today = dayjs();
  switch (range) {
    case RangeShortcut.today:
      setTheDateRange([today.startOf("day"), today.endOf("day")]);
      break;
    case RangeShortcut.thisWeek:
      setTheDateRange([today.startOf("week"), today.endOf("week")]);
      break;
    case RangeShortcut.lastWeek:
      setTheDateRange([today.startOf("week").subtract(1, "week"), today.endOf("week").subtract(1, "week")]);
      break;
    case RangeShortcut.last7Days:
      setTheDateRange([today.subtract(1, "week"), today]);
      break;
    case RangeShortcut.currentMonth:
      setTheDateRange([today.startOf("month"), today.endOf("month")]);
      break;
    case RangeShortcut.reset:
      setTheDateRange([null, null]);
      break;
    default:
      break;
  }
};

const RangeShortcutsPanel = ({ setTheDateRange, children }) => {
  const handleRangeClick = React.useCallback((range) => setTheDateRange && buildHandleRangeClick(setTheDateRange)(range), [setTheDateRange]);

  return (
    <React.Fragment>
      <Box sx={{ m: 2 }} display="flex" gap={2}>
        <div>
          <Typography variant="overline">Date range shortcuts</Typography>
          <List>
            {rangeShortcuts.map(({ range, label }) => (
              <ListItem key={range} disablePadding>
                <ListItemButton onClick={() => handleRangeClick(range)}>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
        <Divider orientation="vertical" />
      </Box>
      {children}
    </React.Fragment>
  );
};
RangeShortcutsPanel.propTypes = {
  children: PropTypes.node,
  setTheDateRange: PropTypes.func,
};

const DateRangeComponent = ({ theDateRange, setTheDateRange, handleChange }) => {
  const { theme } = useTheme();
  const buttonStyle = { "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "& .MuiLoadingButton-loadingIndicator": { color: theme === "light" ? "primary" : "white" } };
  const commentStyle = {
    //color: theme === "light" ? "primary" : "#c3c0c0",
    input: { color: theme === "light" ? "primary" : "#c3c0c0" },
    fontSize: "15px",
    fontWeight: "300",
    fontFamily: styles.fontFamily,
    "& .MuiInput-underline:before": {
      // default underline color
      borderBottomColor: theme === "light" ? "primary" : "#c3c0c0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // underline color on hover (when not disabled)
      borderBottomColor: theme === "light" ? "primary" : "#faa356",
    },
    "& .MuiInput-underline:after": {
      // underline color when focused
      borderBottomColor: theme === "light" ? "primary" : "#7ce38b",
    },
    "& .MuiFormHelperText-root": {
      color: theme === "light" ? "primary" : "#c3c0c0",
    },
    "& .MuiInputLabel-root": {
      color: theme === "light" ? "primary" : "#c6cdd5",
    },
    "& .MuiInputBase-input": {
      color: theme === "light" ? "primary" : "white",
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mb: 2, mt: 0, ml: 2, mr: 1 }}>
        <Stack direction="row">
          <DateRangePicker
            PopperProps={{
              placement: "bottom-end",
            }}
            onChange={(newValue) => setTheDateRange(newValue)}
            value={theDateRange}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField
                  sx={{
                    ...commentStyle,
                    width: 82,
                  }}
                  autoComplete="off"
                  variant="standard"
                  size="small"
                  {...startProps}
                />
                <Box
                  sx={{
                    mx: 1,
                    color: theme === "light" ? "#222" : "white",
                    fontFamily: styles.fontFamily,
                  }}
                >
                  {" "}
                  to{" "}
                </Box>
                <TextField
                  sx={{
                    ...commentStyle,
                    width: 82,
                  }}
                  autoComplete="off"
                  variant="standard"
                  size="small"
                  {...endProps}
                />
              </React.Fragment>
            )}
            components={{ PaperContent: RangeShortcutsPanel }}
            PaperProps={{ sx: { backgroundColor: theme === "light" ? "#d6d6d6" : "white", display: "flex", flexDirection: "row" } }}
            componentsProps={{ paperContent: { setTheDateRange } }}
          ></DateRangePicker>
          <Box sx={{ mt: 1.5, ml: 1 }}>
            <Button size="small" onClick={handleChange("syncRecentStatusTable")} variant="contained" sx={buttonStyle}>
              Search
            </Button>
          </Box>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangeComponent;
