import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const PreviousAnalysis = ({
  updatePriorAnalysis,
  showPriorAnalysisDialog,
  expanded,
  switchAccordion,
  handleChange,
  selectedPriorAnalysis0,
  priorAnalysis0,
  priorAnalysis1,
  loadingPriorAnalysis,
  selectedPriorAnalysis1,
  retrieveVisState,
  showAnalysisResult,
}) => {
  const { theme } = useTheme();
  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const priorAnalysisStatusStyle = {
    "& .MuiInputLabel-root": {
      color: theme === "light" ? "primary" : "#c6cdd5",
    },
    "& .MuiInputBase-input": {
      color: theme === "light" ? "primary" : "white",
    },
    "font-size": "14px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  const selectStyle = {
    fontFamily: styles.fontFamily,
    ".MuiSelect-icon": {
      color: theme === "light" ? "primary" : "#ecf2f8",
      borderColor: theme === "light" ? "primary" : "#ecf2f8",
    },
    ".MuiInputBase-input": {
      color: theme === "light" ? "primary" : "#dddddd",
    },
    ".MuiInputLabel-root": {
      color: theme === "light" ? "primary" : "#ecf2f8",
    },
    "& .MuiInput-underline:before": {
      // default underline color
      borderBottomColor: theme === "light" ? "primary" : "#c3c0c0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // underline color on hover (when not disabled)
      borderBottomColor: theme === "light" ? "primary" : "#faa356",
    },
    "& .MuiInput-underline:after": {
      // underline color when focused
      borderBottomColor: theme === "light" ? "primary" : "#7ce38b",
    },
    "margin-bottom": "5px",
    width: "100%",
  };
  return (
    <Accordion sx={accordionHeaderStyle} expanded={expanded === "priorAnalysisHeader"} onChange={switchAccordion("priorAnalysisHeader")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="priorAnalysisHeader-content" id="priorAnalysisHeader-header">
        <Typography sx={{ mr: 1 }}>Previous Analysis</Typography>
        <IconButton sx={{ padding: "0px", "&:hover": { color: "#399dbc" }, color: theme === "light" ? "primary" : "#89929b" }} onClick={showPriorAnalysisDialog}>
          <InfoOutlinedIcon />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: "100%" }}>
          <Stack direction="row" spacing={1}>
            <Box sx={{ width: "40%" }}>
              <FormControl className="prior1" variant="standard" sx={selectStyle}>
                <InputLabel id="select-label-for-priorAnalsyis0">Image 0</InputLabel>
                <Select labelId="select-label-for-priorAnalsyis0" id="select-prior-analysisID0" value={selectedPriorAnalysis0} onChange={handleChange("viewPriorAnalysis0")} label="Image 0">
                  <MenuItem sx={priorAnalysisStatusStyle} value="None">
                    <em>None</em>
                  </MenuItem>
                  {priorAnalysis0 !== null &&
                    priorAnalysis0.map((analysis) => (
                      <MenuItem sx={priorAnalysisStatusStyle} value={analysis.id}>
                        {analysis.genID}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "40%" }}>
              <FormControl className="prior2" variant="standard" sx={selectStyle}>
                <InputLabel id="select-label-for-priorAnalsyis1">Image 1</InputLabel>
                <Select
                  labelId="select-label-for-priorAnalsyis1"
                  id="select-prior-analysisID1"
                  value={selectedPriorAnalysis1}
                  onChange={handleChange("viewPriorAnalysis1")}
                  label="Image 1"
                  sx={priorAnalysisStatusStyle}
                >
                  <MenuItem sx={priorAnalysisStatusStyle} value="None">
                    <em>None</em>
                  </MenuItem>
                  {priorAnalysis1 !== null &&
                    priorAnalysis1.map((analysis) => (
                      <MenuItem sx={priorAnalysisStatusStyle} value={analysis.id}>
                        {analysis.genID}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="refreshPrior" sx={{ width: "20%" }}>
              <Tooltip title={"Update Lists"}>
                <IconButton onClick={updatePriorAnalysis}>
                  <CloudSyncOutlinedIcon sx={{ color: "#8b8b8b" }}></CloudSyncOutlinedIcon>
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ "& > button": { m: 1 } }}>
          <Stack direction="row" spacing={1.5}>
            <Box sx={{ visibility: retrieveVisState.image0 }}>
              <LoadingButton
                id="showImage0Data"
                size="small"
                variant="contained"
                startIcon={<ArrowCircleLeftOutlinedIcon />}
                loadingPosition="start"
                loading={loadingPriorAnalysis.image0}
                onClick={() => showAnalysisResult("fromPriorAnalysis")}
                sx={{ color: "white", "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
              >
                Retrieve
              </LoadingButton>
            </Box>
            <Box sx={{ visibility: retrieveVisState.image1 }}>
              <LoadingButton
                id="showImage1Data"
                size="small"
                variant="contained"
                startIcon={<ArrowCircleLeftOutlinedIcon />}
                loading={loadingPriorAnalysis.image1}
                loadingPosition="start"
                onClick={() => showAnalysisResult("fromPriorAnalysis")}
                sx={{ color: "white", "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
              >
                Retrieve
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PreviousAnalysis;
