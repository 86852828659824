import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../updatedAuthConfig";
import Button from "@mui/material/Button";

function handleLogin(instance, accounts) {
  //console.log(accounts)
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    // <></>
    <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>
      Sign in using Redirect
    </Button>
  );
};
