import React from 'react';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const priorAnalysisStatusStyle = { 'font-size': '14px', "font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }

const PriorAnalysisDialog = ({
  closePriorAnalysisDialog,
  handleChange,
  open,
}) => (
  <Dialog
    open={open}
    onClose={closePriorAnalysisDialog}
  >
    <DialogTitle>Retrieving and Working on Prior Analysis</DialogTitle>
    <DialogContent>
      <Box>
        <Typography>
          Bio-Rad "Auto" analyzes all uploaded image data.  You can view that by either clicking the blue Auto button or selecting 'auto' in the Previous Analysis content section.  Manual analysis means you want to make adjustments to band, lysate or border regions of interest, which will impact the analysis of your image.  Modification of a single band region would be a good place to start to understand how ROI's impact analytical output.
        </Typography>
        <Button 
          sx={{mb:2,ml:3,mt:1,"background-color":'#399dbc','&:hover': {'background-color': '#c4673a'},"&:disabled": {'background-color': 'grey'}}} 
          //onClick={()=>submitForAnalysis(0)} 
          variant="contained">Auto
        </Button>
        <Button 
          sx={{mb:2,ml:3,mt:1,"background-color":'#399dbc','&:hover': {'background-color': '#c4673a'},"&:disabled": {'background-color': 'grey'}}} 
          //onClick={()=>submitForAnalysis(0)} 
          variant="contained">Manual
        </Button>
        <Typography>
          The drop down menu inside Previous Analysis shows the "Auto" analytical response and then any other "Manual" analysis you have submitted (incremented as x_1, x_2, ...).  For example, if you have analyzed "Image 0" two times, you will see auto, 0_1 and 0_2 in the menu (if you do not, you can click the Refresh Icon to update the list).
        </Typography>
        <FormControl className='priorAnalysisDialog' variant="standard" sx={{ml:3,mb:2, minWidth: 100 }}>
          <InputLabel id="select-label-for-priorAnalsyisDialog">Image 0</InputLabel>
          <Select
            labelId="select-label-for-priorAnalsyisDialog"
            id="select-prior-analysisIDDialog"
            label="Image 0"
            sx = {priorAnalysisStatusStyle}
          >
            <MenuItem sx={priorAnalysisStatusStyle} value="None"><em>None</em></MenuItem>                            
            <MenuItem sx={priorAnalysisStatusStyle}>auto</MenuItem>
            <MenuItem sx={priorAnalysisStatusStyle}>0_1</MenuItem>
            <MenuItem sx={priorAnalysisStatusStyle}>0_2</MenuItem>
          </Select>
        </FormControl>
        <Typography>
          When you select an item in the list, you will see the "Retrieve" button appear. All prior saved work will be displayed for the selection and any new work that you perform can be saved relative to that id by cliking the cloud upload icon (colored orange when new data needs to be saved).<br></br><br></br> <em><strong>Changes are not automatically saved. You must click the cloud backup icon to save any changes you make. It will be orange if backup is required.</strong></em>              
          <IconButton sx={{color:'orange'}} size="large"><CloudUploadOutlinedIcon /></IconButton>
        </Typography>
        
        
      </Box>          
    </DialogContent>
    <DialogActions>
      <Button variant="contained" sx={{ml:3,mt:1,"background-color":'#399dbc','&:hover': {'background-color': '#c4673a'},"&:disabled": {'background-color': 'grey'}}} onClick={closePriorAnalysisDialog}>Got It!</Button>
    </DialogActions>
  </Dialog>
);

export default PriorAnalysisDialog;