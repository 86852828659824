import React from 'react';
import '../../home/home.scss'
export default function NotFoundPage() {
    const pathname = window.location.pathname
    return (
        <div className="content">
            <p><b>404.</b> That’s an error.
            </p><p>The requested URL <code>{pathname}</code> was not found on this server.
            </p>
        </div>
    )
}