import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

const TPNRequestedDialog = ({
    openTPNRequestedDialog,
    closeTPNRequestedDialog,    
}) => {
    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }
    //const handleClose = () => {
    //    setOpen(false);
    //};  
    return (
        <Box>
            <Dialog
                open={openTPNRequestedDialog}
                onClose={closeTPNRequestedDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor:'move'}} id='draggable-dialog-title'>
                    Oops!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You selected a single image to display from a group that was identified for Total Protein Normalization. You must select the two files which were identified for TPN during upload. 
                    </DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeTPNRequestedDialog}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
        
};
export default TPNRequestedDialog;