
import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ImageROIDisplay = ({
  handleProteinBand,
  handleChange,
  whichImage,
  imageFilterROI,
}) => (
  <div className='imageAnalytics'>
    <Divider sx={{ 'margin': '0px', 'color': '#FFFFFF', '&::before': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' }, '&::after': { 'border-top-width': 'thin', 'border-top-style': 'solid', 'border-top-color': '#a1a1a1' } }} textAlign="left" variant="middle">Regions of Interest</Divider>
    <div className='imageAnalyticsContent'>
      <Box>
        <Stack
         spacing={1}
         sx={{mb:1}}
        >
          <FormGroup >
            <FormControlLabel
              sx={{ color: '#a1a1a1'}}
              control={
                <Checkbox
                  size='small'
                  checked={imageFilterROI['fb']}
                  sx={{ 'padding': '0px', 'margin-top': '2px', 'margin-left': '8.5px', 'margin-right': '10px', color: '#399dbc', '&.Mui-checked': { 'color': 'orange' } }}
                  inputProps={{
                    'aria-labelledby': 'findBands',
                  }}
                  onChange={handleProteinBand}
                />
              }
              label={<Typography sx={{fontSize:'1.05em',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>protein bands</Typography>}//"protein bands"
            >
            </FormControlLabel>
          </FormGroup>
          <FormGroup >
            <FormControlLabel
              sx={{ color: '#a1a1a1' }}
              control={
                <Checkbox
                  size='small'
                  checked={imageFilterROI['fll']}
                  sx={{ 'padding': '0px', 'margin-top': '2px', 'margin-left': '8.5px', 'margin-right': '10px', color: '#399dbc', '&.Mui-checked': { 'color': 'orange' } }}
                  inputProps={{
                    'aria-labelledby': 'findLanes',
                  }}
                  onChange={handleChange('fll'+whichImage)}
                />
              }
              label={<Typography sx={{fontSize:'1.05em',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>lysate lanes</Typography>}
            >
            </FormControlLabel>
          </FormGroup>
          <FormGroup >
            <FormControlLabel
              sx={{ color: '#a1a1a1' }}
              control={
                <Checkbox
                  size='small'
                  checked={imageFilterROI['gb']}
                  sx={{ 'padding': '0px', 'margin-top': '2px', 'margin-left': '8.5px', 'margin-right': '10px', color: '#399dbc', '&.Mui-checked': { 'color': 'orange' } }}
                  inputProps={{
                    'aria-labelledby': 'gelBlotBorder',
                  }}
                  onChange={handleChange('gb'+whichImage)}
                />
              }
              label={<Typography sx={{fontSize:'1.05em',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>gel/blot border</Typography>}
            >
            </FormControlLabel>
          </FormGroup>
          <FormGroup >
            <FormControlLabel
              sx={{ color: '#a1a1a1' }}
              control={
                <Checkbox
                  size='small'
                  checked={imageFilterROI['sat']}
                  sx={{ 'padding': '0px', 'margin-top': '2px', 'margin-left': '8.5px', 'margin-right': '10px', color: '#399dbc', '&.Mui-checked': { 'color': 'orange' } }}
                  inputProps={{
                    'aria-labelledby': 'imageSaturation',
                  }}
                  onChange={handleChange('sat'+whichImage)}
                />
              }
              label={<Typography sx={{fontSize:'1.05em',"font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>saturated pixels</Typography>}
            >

            </FormControlLabel>
          </FormGroup>
        </Stack>                              
      
      </Box>
    </div>
  </div>
);

export default ImageROIDisplay;