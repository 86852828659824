import React from 'react';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography'

const TPNAlertDialog = ({ 
  openTPNAlertDialog, 
  closeTPNAlertDialog,
  tpnAlertMessage
}) => (
  <Dialog
    open={openTPNAlertDialog}
    keepMounted
    onClose={closeTPNAlertDialog}
    aria-describedby="alert-dialog-description"
  >
    <Alert severity="info" sx={{ 'width': '500px' }}>
      <AlertTitle><strong>{tpnAlertMessage.title}</strong></AlertTitle>
      <Typography>{tpnAlertMessage.message}</Typography>      
    </Alert>
  </Dialog>
);

export default TPNAlertDialog;