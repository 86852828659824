import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { GridToolbarExport, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro";
//import { DataGridPremium, GridToolbarExport, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { dataGridColumns } from "../../config";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

//NOTE - I HAVEN'T FIGURED OUT HOW THE CHANGE THE changeIconColor to "warning" when the Filter Button Changes what is viewable in the table

const gridStyle = { backgroundColor: "white" };
const regionsOfInterestHeader = {
  color: "#000",
  "font-size": "15px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

const CustomToolbar = ({
  updateChartFromTable,
  showWhichResults,
  testFunction,
  handleChange,
  changeIconColor,
  tpnPassed,
  openTPNAlignDialog,
  manualAnalysisBool,
  showTPNSignal,
  showBoxSignals,
  swapBoxSignals,
}) => {
  return (
    <GridToolbarContainer className="testScroll" sx={{ "overflow-x": "auto" }}>
      <GridToolbarFilterButton
        sx={{
          mr: 1,
          border: "1px solid grey",
          color: "white",
          "background-color": "#399dbc",
          "&:hover": { "background-color": "#c4673a" },
          "&:disabled": { "background-color": "grey" },
          minWidth: 90,
        }}
      />
      <GridToolbarExport
        sx={{
          mr: 1,
          border: "1px solid grey",
          color: "white",
          "background-color": "#399dbc",
          "&:hover": { "background-color": "#c4673a" },
          "&:disabled": { "background-color": "grey" },
          minWidth: 90,
        }}
        csvOptions={{ allRows: true }}
      />

      <Box style={{ display: tpnPassed ? "block" : "none" }}>
        <Button
          size="small"
          sx={{ border: "1px solid grey", color: "white", "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" }, minWidth: 90 }}
          onClick={() => openTPNAlignDialog()}
        >
          Show TPN Alignment
        </Button>
      </Box>
      <Box sx={{ ml: 2 }} style={{ display: tpnPassed ? "block" : "none" }}>
        <FormGroup>
          <FormControlLabel
            sx={regionsOfInterestHeader}
            control={<Switch color="default" checked={showTPNSignal} onChange={() => testFunction()} size="small" />}
            label="Not Normalized / Normalized"
          />
        </FormGroup>
      </Box>
      <Box className="boxSignals">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showBoxSignals}
                onChange={swapBoxSignals}
                sx={{ padding: "0px", "margin-top": "1px", "margin-left": "5px", "margin-right": "10px", "&.Mui-checked": { color: "#03f1e8" } }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "1.05em",
                  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                }}
              >
                Box Signals
              </Typography>
            } //"protein bands"
            labelPlacement="start"
          />
        </FormGroup>
      </Box>
      <Box sx={{ ml: 3 }}>
        <Tooltip title="Update Charts Based Upon Changes to Filtered Table">
          <IconButton color={changeIconColor}>
            <DoubleArrowOutlinedIcon onClick={updateChartFromTable} />
          </IconButton>
        </Tooltip>
      </Box>
    </GridToolbarContainer>
  );
};
const AnalyticsTable = ({
  updateChartFromTable,
  gridRowData,
  apiRef,
  showWhichResults,
  testFunction,
  handleChange,
  changeIconColor,
  tpnPassed,
  openTPNAlignDialog,
  manualAnalysisBool,
  showTPNSignal,
  showBoxSignals,
  swapBoxSignals,
}) => {
  const { theme } = useTheme();

  return (
    <Box>
      <Paper sx={gridStyle} elevation={3}>
        {gridRowData !== null && (
          <div style={{ height: 300, width: "100%" }}>
            <DataGridPro
              componentsProps={{ columnMenu: { sx: { backgroundColor: theme === "light" ? "#399dbc" : "white", color: "white" } } }}
              sx={{ backgroundColor: theme === "light" ? "#0a0a0a61" : "#aeaeae", color: "#000000", fontWeight: "400", fontSize: "14px", fontFamily: styles.fontFamily }}
              rows={gridRowData}
              columns={dataGridColumns}
              density="compact"
              disableSelectionOnClick={true}
              pagination={false}
              apiRef={apiRef}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    showWhichResults={showWhichResults}
                    testFunction={testFunction}
                    updateChartFromTable={updateChartFromTable}
                    handleChange={handleChange}
                    changeIconColor={changeIconColor}
                    tpnPassed={tpnPassed}
                    openTPNAlignDialog={openTPNAlignDialog}
                    manualAnalysisBool={manualAnalysisBool}
                    showTPNSignal={showTPNSignal}
                    showBoxSignals={showBoxSignals}
                    swapBoxSignals={swapBoxSignals}
                  />
                ),
              }}
            ></DataGridPro>
          </div>
        )}
      </Paper>
    </Box>
  );
};

export default AnalyticsTable;
