import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";

const currentAnalysisStatus = {
  padding: "0px",
  border: "0px",
  margin: "0px",
  "font-size": "14px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};
const genericImageNames = { image0: "Image 0", image1: "Image 1" };

const CurrentImageAnalysis = ({
  expanded,
  switchAccordion,
  image0AnalysisStatus,
  image1AnalysisStatus,
  analysisStatusColor0,
  analysisStatusColor1,
  readyToViewCurrent0,
  readyToViewCurrent1,
  showAnalysisResult,
  activeImage,
}) => {
  const { theme } = useTheme();
  const accordionHeaderStyle = {
    "background-color": theme === "light" ? "#dfdfdf" : "#2a2d41",
    "padding-left": "0px",
    color: theme === "light" ? "#666" : "#77bdfb",
    "font-size": "13px",
    "font-weight": "300",
    fontFamily: styles.fontFamily,
  };
  const regionsOfInterestHeader = {
    color: theme === "light" ? "#666" : "#c6cdd5",
    "font-size": "15px",
    "font-weight": "300",
    "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  };
  return (
    <Accordion sx={accordionHeaderStyle} expanded={expanded === "currentAnalysisHeader"} onChange={switchAccordion("currentAnalysisHeader")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme === "light" ? "primary" : "#ecf2f8" }} />} aria-controls="currentAnalysisHeader-content" id="currentAnalysisHeader-header">
        <Typography sx={{ width: "70%", flexShrink: 0, marginRight: "15px" }}>Current Analysis Status</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Stack direction="row" divider={<Divider sx={{ backgroundColor: theme === "light" ? "primary" : "#c6cdd5" }} orientation="vertical" flexItem />} spacing={3.5}>
            <Typography sx={regionsOfInterestHeader}>Image</Typography>
            <Typography sx={regionsOfInterestHeader}>Analysis Status</Typography>
          </Stack>
        </Box>
      </AccordionDetails>
      <Divider sx={{ backgroundColor: theme === "light" ? "primary" : "#c6cdd5" }} orientation="horizontal"></Divider>
      <List>
        <ListItem>
          <ListItemText>
            <Typography sx={currentAnalysisStatus}>{genericImageNames[String(activeImage)]}</Typography>
          </ListItemText>
          <ListItemText>
            <Typography color={activeImage === "image0" ? analysisStatusColor0 : analysisStatusColor1} sx={currentAnalysisStatus}>
              {activeImage === "image0" ? image0AnalysisStatus : image1AnalysisStatus}
            </Typography>
          </ListItemText>
          {((activeImage === "image0" && readyToViewCurrent0) || (activeImage === "image1" && readyToViewCurrent1)) && (
            <Button
              sx={{ color: "white", "background-color": "#399dbc", "&:hover": { "background-color": "#c4673a" }, "&:disabled": { "background-color": "grey" } }}
              size="small"
              variant="text"
              onClick={() => showAnalysisResult("fromCurrentAnalysis")}
            >
              View Result
            </Button>
          )}
        </ListItem>
      </List>
    </Accordion>
  );
};

export default CurrentImageAnalysis;
