import React from 'react';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography'

const NoFilesAlertDialog = ({ open, handleChange ,closeNoFilesDialog}) => (
  <Dialog
    open={open}
    keepMounted
    onClose={closeNoFilesDialog}
    aria-describedby="alert-dialog-description"
  >
    <Alert severity="info" sx={{ 'width': '500px' }}>
      <AlertTitle><strong>No Uploads Found</strong></AlertTitle>
      <Typography>You have not uploaded any data within the date range you selected. Empty Date fields default to the start or end of today.</Typography>
      
    </Alert>
  </Dialog>
);

export default NoFilesAlertDialog;
