import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { useTheme } from "../../../../theme/ThemeContext";
import { styles } from "../../../../theme/ThemeStyle";

const tableRowStyle = {
  "padding-left": "0px",
  color: "#399dbc",
  "font-size": "13px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};
const tableRowMLClassStyle = {
  "padding-left": "0px",
  color: "#7000ff",
  "font-size": "13px",
  "font-weight": "300",
  "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const CurrentSelection = ({
  diplaySelection,
  currentUpload,
  handleChange,
  selected,
  setSelected,
  displaySelectionColor,
  setDisplaySelectionColor,
  selectedID,
  setSelectedID,
  selectedURL,
  setSelectedURL,
  selectedBGRURL,
  setSelectedBGRURL,
  imageRetrievalBool,
}) => {
  const { theme } = useTheme();
  const subTitleStyle = {
    color: theme === "light" ? "#399dbc" : "#77bdfb",
    fontFamily: styles.fontFamily,
  };

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fileName");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dense, setDense] = React.useState(false);

  const isSelected = (fileName) => selected.indexOf(fileName) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentUpload.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = currentUpload.map((n) => n.fileName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, fileName, imageID, row) => {
    ////////////////////////////////////////
    const selectedIndex = selectedID.indexOf(imageID);
    //const indexFromCU = currentUpload.indexOf()
    //console.log(selectedIndex)
    let newSelected = [];
    let newSelectedID = [];
    let newSelectedURL = [];
    let newSelectedBGRURL = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, fileName);
      newSelectedID = newSelectedID.concat(selectedID, imageID);
      newSelectedURL = newSelectedURL.concat(selectedURL, row.imgURL);
      newSelectedBGRURL = newSelectedBGRURL.concat(selectedBGRURL, row.bgRemovedKey_32bit);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedID = newSelectedID.concat(selectedID.slice(1));
      newSelectedURL = newSelectedURL.concat(selectedURL.slice(1));
      newSelectedBGRURL = newSelectedBGRURL.concat(selectedBGRURL.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedID = newSelectedID.concat(selectedID.slice(0, -1));
      newSelectedURL = newSelectedURL.concat(selectedURL.slice(0, -1));
      newSelectedBGRURL = newSelectedBGRURL.concat(selectedBGRURL.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newSelectedID = newSelectedID.concat(selectedID.slice(0, selectedIndex), selectedID.slice(selectedIndex + 1));
      newSelectedURL = newSelectedURL.concat(selectedURL.slice(0, selectedIndex), selectedURL.slice(selectedIndex + 1));
      newSelectedBGRURL = newSelectedBGRURL.concat(selectedBGRURL.slice(0, selectedIndex), selectedBGRURL.slice(selectedIndex + 1));
    }

    //console.log('at moment of checkbox click, selected=',newSelected)
    //console.log('at moment of checkbox click, selectedID=',newSelectedID)
    //console.log('at moment of checkbox click, selectedURL=',newSelectedURL)
    setSelected(newSelected);
    setSelectedID(newSelectedID);
    setSelectedURL(newSelectedURL);
    setSelectedBGRURL(newSelectedBGRURL);
    //setAllURLs([{'rawURLs':newSelectedURL}])
    setDisplaySelectionColor("warning");
  };

  return (
    <Box className="selectionForAnalysis" sx={{ overflow: "hidden", m: 1, border: "solid", borderColor: "#b1b1b1", borderStyle: "dashed", borderWidth: "1px", borderRadius: "10px", padding: "1px" }}>
      <Divider sx={subTitleStyle} textAlign="left" variant="middle">
        Choose an Image(s) From Your Selection
      </Divider>

      <Box className="currentUploadTable" sx={{ display: imageRetrievalBool ? "block" : "none" }}>
        {currentUpload !== null && (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar diplaySelection={diplaySelection} numSelected={selected.length} displaySelectionColor={displaySelectionColor} handleChange={handleChange} />
              <TableContainer sx={{ maxHeight: "200px" }}>
                <Table aria-labelledby="tableTitle" size="small">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={currentUpload.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                    {currentUpload !== null &&
                      stableSort(currentUpload, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.fileName);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.fileName, row.imageID, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.fileName}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  size="small"
                                  sx={{ "&.Mui-checked": { color: "#399dbc" } }}
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none" sx={tableRowStyle}>
                                {row.fileName}
                              </TableCell>
                              <TableCell sx={tableRowMLClassStyle} align="right">
                                <Tooltip title={"confidence = " + row.mlConf + "%"} placement="right-end">
                                  <u>{row.mlClass}</u>
                                </Tooltip>
                              </TableCell>
                              <TableCell sx={tableRowStyle} align="right">
                                {row.imageClass}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={3} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={currentUpload.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CurrentSelection;
