import React from 'react';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const GelBlotBorderDialog = ({ open, handleChange }) => (
  <Dialog
    open={open}
    keepMounted
    onClose={handleChange('closeGBBDialog')}
    aria-describedby="alert-dialog-description"
  >
    <Alert severity="info" sx={{ 'width': '400px' }}>
      <AlertTitle>ML Models Being Trained</AlertTitle>
      Check back soon - new models are being trained to segment gel and blot objects.
    </Alert>
  </Dialog>
);

export default GelBlotBorderDialog;
