export function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function changeColor() {
  let hex = randomHexColor();
  return hex;
}

export function randomInteger(max) {
  return Math.floor(Math.random() * (max + 1));
}

export function randomRgbColor() {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return [r, g, b];
}

function randomHexColor() {
  let [r, g, b] = randomRgbColor();

  let hr = r.toString(16).padStart(2, "0");
  let hg = g.toString(16).padStart(2, "0");
  let hb = b.toString(16).padStart(2, "0");

  return "#" + hr + hg + hb + "75";
}

export function createUniqueArray(analyticResponse, whichObject, visibleIndex) {
  var uniqueArray = [];
  var keysToInclude = [
    "primaryAb",
    "secondaryAb",
    "composition",
    "standardType",
    "control",
    "target",
  ];
  var i = 0;
  var donuthing;
  var indexType = typeof visibleIndex;
  //var j = 0
  if (visibleIndex === null) {
    for (i = 0; i < analyticResponse[whichObject].length; i++) {
      try {
        for (const [key, value] of Object.entries(
          analyticResponse[whichObject][i]["userTagList"]
        )) {
          if (
            keysToInclude.includes(`${key}`) &&
            !uniqueArray.includes(`${value}`) &&
            `${value}` !== "undefined"
          ) {
            uniqueArray.push(`${value}`);
          }
        }
      } catch (exceptionVar) {
        donuthing = 0;
      }
    }
  } else {
    //console.log('length',visibleIndex.length)
    //console.log(visibleIndex)
    if (indexType === "string") {
      for (i = 0; i < analyticResponse[whichObject].length; i++) {
        if (
          analyticResponse[whichObject][i]["id"].toString() === visibleIndex
        ) {
          try {
            for (const [key, value] of Object.entries(
              analyticResponse[whichObject][i]["userTagList"]
            )) {
              if (
                keysToInclude.includes(`${key}`) &&
                !uniqueArray.includes(`${value}`) &&
                `${value}` !== "undefined"
              ) {
                uniqueArray.push(`${value}`);
              }
            }
          } catch (exceptionVar) {
            donuthing = 0;
          }
        }
      }
    } else if (indexType === "object") {
      for (i = 0; i < visibleIndex.length; i++) {
        try {
          for (const [key, value] of Object.entries(
            analyticResponse[whichObject][visibleIndex[i]]["userTagList"]
          )) {
            if (
              keysToInclude.includes(`${key}`) &&
              !uniqueArray.includes(`${value}`) &&
              `${value}` !== "undefined"
            ) {
              uniqueArray.push(`${value}`);
            }
          }
        } catch (exceptionVar) {
          donuthing = 0;
        }
      }
    }
  }
  return uniqueArray;
}
